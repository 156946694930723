import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Expand from "@mui/icons-material/UnfoldMore";
import Collapse from "@mui/icons-material/UnfoldLess";
import Share from "@mui/icons-material/ShareOutlined";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { LinearProgress } from "app/components/linear-progress";
import { GenericPageBlockItemProps } from "app/components/generic-page-block-item/data";
import { Button, Skeleton, useMediaQuery } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ShareDialog from "../share-dialog";

export const GenericPageBlockItem: React.FC<GenericPageBlockItemProps> = (
  props: GenericPageBlockItemProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const notImplemented = React.useMemo(() => {
    return props.title.includes("Targets");
  }, [props.title]);

  return (
    <Box
      id={`block-${props.title}`}
      sx={{
        gap: "20px",
        display: "flex",
        padding: "20px 30px",
        position: "relative",
        flexDirection: "column",
        height: props.height ?? "auto",
        background: colors.primary.white,
        filter: notImplemented ? "blur(2px)" : "none",
        [customBreakpointsMax.mobile]: {
          padding: "20px",
        },
      }}
      data-cy="generic-page-block-item"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: props.rightToolbar ? "auto" : "100%",
          }}
        >
          <Typography
            fontSize={{ xs: "10px", md: "12px" }}
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.title}
          </Typography>
          <Typography
            flex="1"
            fontSize={{ xs: "14px", md: "18px" }}
            component="div"
            fontWeight="700"
            color={colors.text.title}
            sx={{
              "> a": {
                textDecoration: "none",
                color: colors.primary.blue,
              },
            }}
          >
            {props.loading || props.altLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: { xs: "14px", md: "18px" } }}
              />
            ) : (
              props.description
            )}
          </Typography>
        </Box>
        {props.rightToolbar}
        {!props.rightToolbar && (props.toolbarShare || props.toolbarExpand) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              "> button": {
                padding: "4px",
              },
            }}
          >
            {props.toolbarExpand && !mobile && (
              <IconButton data-cy="expand-button" onClick={props.onExpand}>
                {props.expanded ? <Collapse /> : <Expand />}
              </IconButton>
            )}
            {props.toolbarShare && !(mobile && props.toolbarExpand) && (
              <ShareDialog
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id={`block-${props.title}-share`}
                link={props.shareLink}
              >
                {(handleOpen) => (
                  <IconButton onClick={handleOpen}>
                    <Share fontSize="small" />
                  </IconButton>
                )}
              </ShareDialog>
            )}
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          borderColor: colors.secondary.iconGray,
        }}
      />
      {props.altLoading && <LinearProgress />}
      <Box
        height="100%"
        sx={{
          a: {
            textDecoration: "none",
          },
        }}
      >
        {props.loading ? (
          <Box
            width="100%"
            display="flex"
            height="200px"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="40%">
              <LinearProgress />
            </Box>
          </Box>
        ) : (
          props.content
        )}
      </Box>
      {mobile && props.toolbarExpand && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: props.expanded ? "row-reverse" : "row",
          }}
        >
          {" "}
          {props.toolbarShare && (
            <Button
              variant="contained"
              sx={{
                minWidth: "auto",
                width: "40px",
                background:
                  props.variant === "publisher"
                    ? colors.primary.green
                    : colors.primary.blue,
              }}
            >
              <Share fontSize="small" />
            </Button>
          )}
          {props.toolbarExpand && (
            <Button
              variant="contained"
              endIcon={props.expanded ? <ArrowBack /> : <ArrowForward />}
              sx={{
                fontSize: "12px",
                lineHeight: "normal",
                flex: 1,
                background:
                  props.variant === "publisher"
                    ? colors.primary.green
                    : colors.primary.blue,
              }}
              onClick={props.onExpand}
            >
              {props.expanded ? "Collapse the Chart" : "Expand the Chart"}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
