import React from "react";

export const ResetIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.54902 20.0574H9.5384C7.21274 20.0553 4.97097 19.2057 3.22726 17.668L2.12389 16.6871V18.9955C2.12389 19.5827 1.64814 20.0574 1.06195 20.0574C0.475751 20.0574 0 19.5827 0 18.9955V13.6857C0 13.0985 0.475751 12.6238 1.06195 12.6238H6.37167C6.95787 12.6238 7.43362 13.0985 7.43362 13.6857C7.43362 14.273 6.95787 14.7477 6.37167 14.7477H3.13845L4.6354 16.0783C5.98831 17.2719 7.73203 17.9314 9.54052 17.9335H9.54902C13.6439 17.9335 16.9784 14.6043 16.9826 10.5084C16.9869 6.40928 13.6556 3.07158 9.55751 3.06627H9.49804C5.74938 3.06627 2.58478 5.85282 2.11646 9.57069C2.04319 10.1526 1.50584 10.5519 0.930268 10.4914C0.348322 10.4181 -0.0637126 9.88715 0.00956163 9.3052C0.610623 4.52539 4.68 0.942383 9.49061 0.942383H9.56601C14.829 0.947693 19.1118 5.24008 19.1055 10.5105C19.0991 15.7767 14.8131 20.0574 9.54902 20.0574Z"
        fill="#454545"
      />
    </svg>
  );
};
