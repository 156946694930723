import React from "react";
import { Accord } from "../accord";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { title } from "process";

export const About = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");

  const content = [
    {
      title: "Vision and mission",
      text: `We aim to create a transparent world where you are empowered to use
          data to drive change for a better future. The Open Data Company
          empowers organizations by unlocking the full potential of data in
          order for them to drive change in today's complex & interconnected
          world.`,
    },
    {
      title: "About",
      text: (
        <span>
          The Open Data Company B.V. was set up in 2021 and is a joint venture
          between <u>Data4Development B.V.</u> and <u>Zimmerman B.V.</u> We are
          committed to guiding global organisations in their journey towards
          data-driven decision making. AIDA is the ultimate aid professional
          toolkit, the place where your data journey starts.
        </span>
      ),
    },
  ];
  return (
    <Accord
      title="About AIDA"
      subtitle={`Discover the heart and soul behind AIDA. Dive into the journey that brought us here, our mission, and the passionate team driving innovation. ${
        !tablet
          ? "Get to know the faces behind the brand and explore the values that shape every product we create."
          : ""
      }`}
      id="about-aida"
    >
      <Box
        sx={{
          padding: "20px",
          paddingTop: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {content.map((item) => (
          <Box key={item.title}>
            <Typography
              variant="h3"
              fontSize={{
                xs: "18px",
                lg: "24px",
              }}
              lineHeight={"normal"}
            >
              {item.title}
            </Typography>
            <Typography
              marginTop={"10px"}
              variant="h3"
              lineHeight={"24px"}
              fontSize={{
                xs: "14px",
                lg: "18px",
              }}
              fontWeight={"400"}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Accord>
  );
};
