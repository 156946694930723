import React from "react";
import { Accord } from "../accord";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PlayIcon } from "app/assets/vectors/jsx/PlayIcon";
import { colors } from "app/theme";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

export const Tutorials = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  const videos = [
    { title: "How to connect AIDA spreadsheets?" },
    { title: "How to use the Data Services for publishing in AIDA?" },
    {
      title:
        "How can I check the validity of my publication in AIDA and how do I fix the errors?",
    },
  ];
  return (
    <Accord
      title="Tutorials"
      subtitle={`Your gateway to mastering AIDA. Our step-by-step guides will empower you. ${
        tablet
          ? ""
          : "Dive into hands-on tutorials, insightful tips, and expert advice to make the most of every feature."
      } Explore the world of AIDA with confidence, guided by our comprehensive tutorials.`}
      id="aida-tutorials"
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            padding: "20px 0",
          }}
        >
          <Typography variant="h3" lineHeight={"normal"} fontSize={"24px"}>
            AIDA Explorer Tutorials
          </Typography>

          <Box
            sx={{
              padding: "20px",
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
              },
              rowGap: "40px",
              columnGap: "30px",
            }}
          >
            {videos.concat(videos).map((vid, index) => (
              <Box
                key={index}
                sx={{
                  "> a": {
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    columnGap: "6px",
                  },
                }}
              >
                <Box
                  sx={{
                    height: "270px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    transition: "all 300ms",
                    "&:hover": {
                      opacity: 0.6,
                    },
                  }}
                >
                  <PlayIcon />
                </Box>

                <Typography
                  variant="h5"
                  lineHeight={"20px"}
                  fontSize={"14px"}
                  marginTop={"20px"}
                  marginBottom={"8px"}
                >
                  {vid.title}
                </Typography>
                <Link to={""}>
                  <Typography
                    variant="button"
                    lineHeight={"20px"}
                    color={colors.secondary.red}
                  >
                    Watch the Video
                  </Typography>

                  <ArrowForwardIos
                    sx={{
                      width: "10px",
                      height: "10px",
                      color: colors.secondary.red,
                    }}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            padding: "20px 0",
            width: "100%",
          }}
        >
          <Typography variant="h3" lineHeight={"normal"} fontSize={"24px"}>
            AIDA Publishing Tutorials
          </Typography>

          <Box
            sx={{
              padding: "20px",
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
              },
              rowGap: "40px",
              columnGap: "30px",
            }}
          >
            {videos.concat(videos).map((vid, index) => (
              <Box
                key={index}
                sx={{
                  "> a": {
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    columnGap: "6px",
                  },
                }}
              >
                <Box
                  sx={{
                    height: "270px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    transition: "all 300ms",
                    "&:hover": {
                      opacity: 0.6,
                    },
                  }}
                >
                  <PlayIcon />
                </Box>

                <Typography
                  variant="h5"
                  lineHeight={"20px"}
                  fontSize={"14px"}
                  marginTop={"20px"}
                  marginBottom={"8px"}
                >
                  {vid.title}
                </Typography>
                <Link to={""}>
                  <Typography
                    variant="button"
                    lineHeight={"20px"}
                    color={colors.secondary.red}
                  >
                    Watch the Video
                  </Typography>

                  <ArrowForwardIos
                    sx={{
                      width: "10px",
                      height: "10px",
                      color: colors.secondary.red,
                    }}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Accord>
  );
};
