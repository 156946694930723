import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { HeaderButtonsProps } from "app/components/header/sub-components/buttons/data";
import { Collapse, Container, useMediaQuery } from "@mui/material";

import { HeaderProductView } from "../expanded-view/views/product";
import { HeaderPlansView } from "../expanded-view/views/plans";
import { HeaderResourcesView } from "../expanded-view/views/resources";
import { HeaderSignInView } from "../expanded-view/views/signin";
import { useAuth0 } from "@auth0/auth0-react";

export const HeaderButtons: React.FC<HeaderButtonsProps> = (
  props: HeaderButtonsProps
) => {
  const { isAuthenticated, user } = useAuth0();
  const mobile = useMediaQuery("(max-width: 767px)");

  const getView = (label: string) => {
    switch (label) {
      case props.buttons[0].label:
        return <HeaderProductView />;
      case props.buttons[1].label:
        return <HeaderPlansView />;
      case props.buttons[2].label:
        return <HeaderResourcesView />;
      case props.buttons[3].label:
        return <HeaderSignInView />;
      default:
        return null;
    }
  };

  if (mobile) {
    return (
      <Box
        sx={{
          background: colors.secondary.lightGrey,
          borderBottom: `1px solid ${colors.secondary.iconGray}`,
        }}
      >
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            padding: "0 24px",
          }}
        >
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              button: {
                height: "32px",
                fontSize: "14px",
                fontWeight: "700",
                borderRadius: "5px",
                color: colors.text.title,
                border: "1px solid transparent",
              },
            }}
          >
            {props.buttons.map((button) => {
              const buttonStyle =
                button.label === props.activeButton
                  ? {
                      borderColor: colors.secondary.iconGray + "!important",
                      background: colors.secondary.disableButton,
                    }
                  : {};
              if (button.link) {
                return (
                  <Button
                    key={button.label}
                    component={Link}
                    to={button.link}
                    sx={buttonStyle}
                  >
                    {button.label}
                  </Button>
                );
              }
              let icon;
              if (button.expandable) {
                icon =
                  button.label === props.activeButton ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  );
              }
              return (
                <Box
                  sx={{
                    width: "100%",
                    padding: "5px 0",
                    "&:not(:last-child)": {
                      borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                    },
                  }}
                >
                  <Button
                    key={button.label}
                    endIcon={icon}
                    onClick={() => props.handleButtonClick(button.label)}
                    sx={buttonStyle}
                    startIcon={
                      button.signIn && isAuthenticated ? (
                        <img
                          src={user?.picture}
                          width={20}
                          height={20}
                          style={{ borderRadius: "50%" }}
                          alt="user-avatar"
                        />
                      ) : undefined
                    }
                  >
                    {button.signIn && isAuthenticated
                      ? `Hello, ${user?.given_name}`
                      : button.label}
                  </Button>
                  <Collapse in={button.label === props.activeButton}>
                    <Box
                      sx={{
                        paddingTop: "20px",
                        paddingBottom: "5px",
                      }}
                    >
                      {getView(button.label)}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        gap: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "& > button": {
          height: "32px",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "5px",
          color: colors.text.title,
          border: "1px solid transparent",
        },
      }}
    >
      {props.buttons.map((button) => {
        const buttonStyle =
          button.label === props.activeButton
            ? {
                borderColor: colors.secondary.iconGray + "!important",
                background: colors.secondary.disableButton,
              }
            : {};
        if (button.link) {
          return (
            <Button
              key={button.label}
              component={Link}
              to={button.link}
              sx={buttonStyle}
            >
              {button.label}
            </Button>
          );
        }
        let icon;
        if (button.expandable) {
          icon =
            button.label === props.activeButton ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            );
        }
        return (
          <Button
            key={button.label}
            endIcon={icon}
            onClick={() => props.handleButtonClick(button.label)}
            sx={buttonStyle}
            startIcon={
              button.signIn && isAuthenticated ? (
                <img
                  src={user?.picture}
                  width={20}
                  height={20}
                  style={{ borderRadius: "50%" }}
                  alt="user-avatar"
                />
              ) : undefined
            }
            data-cy="navbar-button"
          >
            {button.signIn && isAuthenticated
              ? `Hello, ${user?.given_name}`
              : button.label}
          </Button>
        );
      })}
    </Box>
  );
};
