import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { LinearProgress } from "app/components/linear-progress";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { LandingPageBlockItemProps } from "app/components/landing-page-block-item/data";

export const LandingPageBlockItem: React.FC<LandingPageBlockItemProps> = (
  props: LandingPageBlockItemProps
) => {
  return (
    <Box
      id={`block-${props.title}`}
      sx={{
        padding: {
          xs: "15px",
          md: "10px 14px",
          lg: "20px 30px",
        },
        background: colors.primary.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
      }}
      data-cy="landing-page-block-item"
    >
      <Box
        height="100%"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        width={"100%"}
      >
        {props.loading ? (
          <Box
            width="100%"
            display="flex"
            height="200px"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="40%">
              <LinearProgress />
            </Box>
          </Box>
        ) : (
          props.content
        )}
      </Box>
      <Box
        sx={{
          "> a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            columnGap: "6px",
          },
        }}
      >
        <Divider />
        <Box height={20} />
        <Typography
          variant="h6"
          lineHeight={"normal"}
          fontSize={{
            xs: "12px",
            lg: "18px",
          }}
          sx={{
            "> a": {
              textDecoration: "none",
              color: colors.primary.blue,
            },
          }}
        >
          {props.description}
        </Typography>

        {props.title ? (
          <React.Fragment>
            <Box height={10} />
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", lg: "12px" }}
              lineHeight={"normal"}
            >
              {props.title}
            </Typography>
          </React.Fragment>
        ) : null}
        <Box height={10} />
        <Link to={props.linkUrl || ""}>
          <Typography
            variant="button"
            lineHeight={"20px"}
            color={colors.secondary.red}
            fontSize={{
              xs: "12px",
              lg: "14px",
            }}
          >
            {props.linkText}
          </Typography>

          <ArrowForwardIos
            sx={{
              width: "10px",
              height: "10px",
              color: colors.secondary.red,
            }}
          />
        </Link>
      </Box>
    </Box>
  );
};
