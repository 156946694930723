import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  canonicalUrl: string;
}

export function CanonicalUrl(props: Props) {
  return (
    <Helmet>
      <link
        rel="canonical"
        href={`${window.location.origin}${props.canonicalUrl}`}
      />
    </Helmet>
  );
}
