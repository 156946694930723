import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as YourDataHubIcon } from "app/assets/vectors/HeaderProductViewYourDataHub.svg";
import { ReactComponent as AIDAExplorerIcon } from "app/assets/vectors/HeaderProducsViewAIDAExplorer.svg";
import { ReactComponent as SectorExplorerIcon } from "app/assets/vectors/HeaderProductViewSectorExplorer.svg";
import { ReactComponent as YourFavouritesIcon } from "app/assets/vectors/HeaderProductViewYourFavourites.svg";
import { ReactComponent as LocationExplorerIcon } from "app/assets/vectors/HeaderProductViewLocationExplorer.svg";
import { ReactComponent as AIDAIATIPublisherIcon } from "app/assets/vectors/HeaderProductViewAIDAIATIPublisher.svg";
import { ReactComponent as ActivitiesExplorerIcon } from "app/assets/vectors/HeaderProductViewActivitiesExplorer.svg";
import { ReactComponent as OrganisationExplorerIcon } from "app/assets/vectors/HeaderProductViewOrganisationExplorer.svg";
import { customBreakpointsMax } from "app/theme";
import { useMediaQuery } from "@mui/material";

const PublicationTools = [
  {
    title: "AIDA IATI Publisher",
    icon: AIDAIATIPublisherIcon,
    subtitle: "Publish your data to IATI registry with ease.",
    link: "/publisher",
  },
  {
    title: "Your Data Hub",
    icon: YourDataHubIcon,
    subtitle: "See your data visualised all in one place.",
    link: "/data-hub",
  },
];

const DataDiscoveryTools = [
  {
    title: "AIDA Explorer",
    icon: AIDAExplorerIcon,
    subtitle: "See IATI data with visualisations & insights.",
    link: "/explorer",
  },
  {
    title: "Organisation Explorer",
    icon: OrganisationExplorerIcon,
    subtitle: "See Organisation-Based Insights.",
    link: "/explorer/organisations",
  },
  {
    title: "Activities Explorer",
    icon: ActivitiesExplorerIcon,
    subtitle: "See and filter activities.",
    link: "/explorer/activities",
  },
  {
    title: "Location Explorer",
    icon: LocationExplorerIcon,
    subtitle: "See Location-Based Insights.",
    link: "/explorer/locations",
  },
  {
    title: "Sector Explorer",
    icon: SectorExplorerIcon,
    subtitle: "See Sector-Based Insights.",
    link: "/explorer/sectors",
  },
  {
    title: "Your Favourites",
    icon: YourFavouritesIcon,
    subtitle: "See your favourite activities.",
    link: "/explorer/favourites",
  },
];

export const HeaderProductView: React.FC = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  return (
    <Grid
      sx={{
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        display: "grid",
        gap: "20px",
      }}
    >
      <Grid
        gridColumn={{
          xs: "span 12",
          md: "span 6",
          lg: "span 3",
        }}
        sx={{
          borderRight: "1px solid #A1AEBD",
          [customBreakpointsMax.mobile]: {
            borderRight: "none",
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          marginBottom={{
            xs: "20px",
            md: "24px",
          }}
        >
          Publication Tools
        </Typography>
        <Grid container spacing={2}>
          {PublicationTools.map((item) => (
            <Grid item xs={12} key={item.title}>
              <HeaderViewCommonBlockItem
                title={item.title}
                icon={<item.icon />}
                subtitle={item.subtitle}
                link={item.link}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        gridColumn={{
          xs: "span 12",
          md: "span 6",
          lg: "span 7",
        }}
        sx={{
          borderRight: "1px solid #A1AEBD",
          [customBreakpointsMax.tablet]: {
            borderRight: "none",
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          marginBottom={{
            xs: "20px",
            md: "24px",
          }}
        >
          Data Discovery Tools
        </Typography>
        <Grid container spacing={2}>
          {DataDiscoveryTools.map((item) => (
            <Grid item xs={12} md={12} lg={4} key={item.title}>
              <HeaderViewCommonBlockItem
                title={item.title}
                icon={<item.icon />}
                subtitle={item.subtitle}
                link={item.link}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      {!tablet && (
        <Grid
          gridColumn={{
            xs: "span 12",
            md: "span 2",
          }}
          sx={{
            a: {
              display: "block",
              textDecoration: "none",
              color: "#000",
              marginBottom: "16px",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          }}
        >
          <Typography
            variant="h5"
            fontSize="14px"
            marginBottom={{
              xs: "20px",
              md: "24px",
            }}
          >
            You might be interested in
          </Typography>
          <Link to="/data-services">
            <Typography
              variant="h5"
              fontSize="14px"
              fontWeight="400"
              marginBottom="10px"
              data-cy="data-services-link"
            >
              Data Services
            </Typography>
          </Link>
          <Link to="/user-guide">
            <Typography
              data-cy="user-guide-link"
              variant="h5"
              fontSize="14px"
              fontWeight="400"
            >
              User Guide
            </Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};
