import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { BookmarkCheckedIconButton } from "app/components/bookmark-button";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";

export const getFavouritesColumns = (
  onRemoveItem: (id: string) => void
): GridColDef[] => [
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
    minWidth: 300,
    renderCell: (params: any) => {
      const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onRemoveItem(params.row.id);
      };

      return (
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          marginLeft="-10px"
        >
          <BookmarkCheckedIconButton onClick={onClick} />
          <Typography
            variant="subtitle1"
            fontSize="12px"
            fontWeight={400}
            sx={{
              whiteSpace: "break-spaces",
              maxWidth: "calc(100% - 50px)",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "locations",
    headerName: "Location(s)",
    width: 150,
    renderCell: (params: any) => {
      if (params.value.length === 0) {
        return null;
      }
      const multi = params.value.length > 1;
      let name = params.value[0].name;
      if (multi) {
        name = "Multiple countries";
      }
      return (
        <Tooltip
          title={
            multi ? (
              <Box gap="5px" display="flex" flexDirection="column">
                {params.value.map((location: any) => (
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    columnGap={"5px"}
                    height={"100%"}
                  >
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={400}
                    >
                      {location.name}
                    </Typography>
                  </Grid>
                ))}
              </Box>
            ) : (
              ""
            )
          }
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            columnGap={"5px"}
            height={"100%"}
          >
            <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
              {name}
            </Typography>
          </Grid>
        </Tooltip>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        display={"flex"}
        alignItems={"center"}
        columnGap={"6px"}
        height={"100%"}
      >
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            flexShrink: 0,
            background: get(ACTIVITY_STATUS_COLORS, params.value, "#333ED4"),
          }}
        />
        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "commitment",
    headerName: "Total Commitment",
    width: 200,
    renderCell: (params: any) => (
      <Grid
        rowGap="2px"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="subtitle1" fontSize="12px" fontWeight={400}>
          {formatFinancialValue(params.value)}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "5px",
            background: colors.secondary.disableText,
          }}
        >
          <Box
            sx={{
              height: "100%",
              background: colors.primary.blue,
              width: `${
                params.row.commitmentPercentage > 100
                  ? 100
                  : params.row.commitmentPercentage
              }%`,
            }}
          />
        </Box>
      </Grid>
    ),
  },
];
