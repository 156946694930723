import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { GenericTableColumn } from "app/components/generic-table/data";

export const ORGANISATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "name",
    headerName: "Publishing Organisation Name",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box
        display="flex"
        columnGap="8px"
        component={Link}
        alignItems="center"
        to={`/explorer/organisations/${params.row.iatiIdentifier}`}
      >
        <Typography
          fontSize="12px"
          variant="subtitle1"
          color={colors.primary.blue}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "iatiIdentifier",
    headerName: "IATI Organisation Identifier",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "organisationType",
    headerName: "Organisation Type",
    width: 170,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "organisationHQ",
    headerName: "Organisation HQ",
    width: 150,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "totalDatasets",
    headerName: "Total Datasets",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "totalActivities",
    headerName: "Total Activities",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
  },
];
