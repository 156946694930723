import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import MuiStepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { StepperProps } from "app/components/stepper/data";

export const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        ".MuiStepConnector-root": {
          left: "calc(-50% + 60px)",
          right: "calc(50% + 60px)",
        },
        ".MuiStepLabel-iconContainer": {
          width: "20px",
          height: "20px",
        },
        ".MuiStepIcon-root": {
          width: "20px",
          height: "20px",
          color: colors.secondary.disableButton,
        },
        ".MuiStepIcon-root.Mui-completed": {
          color: colors.primary.green,
        },
        ".MuiStepIcon-text": {
          fontSize: "20px",
          fontWeight: "bold",
          fill: colors.secondary.grayText,
        },
        ".MuiStepIcon-root.Mui-active": {
          color: colors.primary.green,
          "> text": {
            fill: colors.primary.white,
          },
        },
        ".MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
          marginTop: "8px",
          fontWeight: "normal",
          color: colors.text.title,
          [customBreakpointsMax.tablet]: {
            fontSize: "10px",
          },
        },
        ".MuiStepConnector-line": {
          borderWidth: "2px",
        },
        [customBreakpointsMax.tablet]: {
          ".MuiStepConnector-root": {
            left: "calc(-50% + 40px)",
            right: "calc(50% + 40px)",
          },
          ".MuiStepLabel-labelContainer": {
            width: "82px",
          },
        },
      }}
    >
      <MuiStepper activeStep={props.activeStep} alternativeLabel>
        {props.steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              ".MuiStepConnector-line": {
                borderColor:
                  props.activeStep === index || props.activeStep === index - 1
                    ? colors.primary.green
                    : colors.secondary.iconGray,
              },
            }}
          >
            <StepLabel
              sx={{
                textTransform: "uppercase",
              }}
              icon={
                index === props.steps.length - 1 ? (
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="CheckCircleIcon"
                    fill={
                      props.activeStep === index
                        ? colors.primary.green
                        : colors.secondary.iconGray
                    }
                  >
                    <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"></path>
                  </svg>
                ) : undefined
              }
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
};
