import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ChevronDown } from "app/assets/vectors/jsx/arrow";
import { colors, customBreakpointsMax } from "app/theme";

export const Accord = ({
  id,
  title,
  subtitle,
  children,
}: {
  id?: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
}) => {
  return (
    <Accordion
      sx={{
        backgroundColor: "transparent",
        borderBottom: "none",
        "&.Mui-expanded": {
          padding: 0,
          backgroundColor: "transparent",
        },
      }}
      id={id}
    >
      <AccordionSummary
        expandIcon={<ChevronDown />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          background: colors.primary.blue,
          padding: {
            xs: "10px",
            md: "20px 30px 20px 20px",
            lg: "15px 80px 15px 30px",
          },
          minHeight: "unset",
          maxHeight: "unset",
          "&.Mui-expanded": {
            minHeight: "unset",
            maxHeight: "unset",
          },
          [customBreakpointsMax.mobile]: {
            svg: {
              width: "20px",
              height: "20px",
            },
          },
        }}
      >
        <Box
          sx={{
            paddingRight: {
              xs: "10px",
              lg: "50px",
            },
          }}
        >
          <Typography
            lineHeight={"normal"}
            variant="h2"
            color={colors.primary.white}
            fontSize={{
              xs: "24px",
              lg: "36px",
            }}
          >
            {title}
          </Typography>
          <Typography
            marginTop={"10px"}
            lineHeight={"20px"}
            fontSize={{
              xs: "12px",
              md: "14px",
            }}
            fontWeight={"400"}
            variant="h5"
            color={colors.primary.white}
          >
            {subtitle}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
