import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/material";

interface ChartsBlockProps {
  items: React.ReactNode[];
  gridCount?: number;
}

export const ChartsBlock: React.FC<ChartsBlockProps> = (
  props: ChartsBlockProps
) => {
  const gridCount = props.gridCount ?? 4;
  const GridStyle: SxProps = {
    // paddingTop: "16px",

    h4: {
      fontSize: "18px",
      fontWeight: "700",
    },
    "> div": {
      gap: "10px",
      display: "flex",
      paddingBottom: "16px",
      paddingRight: "16px",
      flexDirection: "column",
      [`&:not(:nth-of-type(${gridCount}n+0))`]: {
        borderRight: "1px solid #E0E0E0",
      },
    },
  };

  return (
    <Box data-cy="charts-block">
      {Array(Math.ceil(props.items.length / gridCount))
        .fill(0)
        .map((_, i) => (
          <Box
            key={i}
            sx={
              i !== props.items.length / gridCount - 1
                ? {
                    paddingBottom: "20px",
                    marginBottom: "36px",
                    borderBottom: "1px solid #E0E0E0",
                  }
                : {}
            }
          >
            <Grid container spacing={2} rowGap={2} sx={GridStyle}>
              {props.items
                .slice(i * gridCount, i * gridCount + gridCount)
                .map((item, i2) => (
                  <Grid key={`item-${i2}`} item xs={12 / gridCount}>
                    <Box>{item}</Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        ))}
    </Box>
  );
};
