import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Table } from "app/components/table";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { colors, customBreakpointsMax } from "app/theme";
import { CanonicalUrl } from "app/components/canonical-url";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { getFavouritesColumns } from "app/pages/explorer/pages/favourites/tableColums";
import { ReactComponent as ExplorerFavouritesHeaderIcon } from "app/assets/vectors/ExplorerFavouritesHeaderIcon.svg";

const Empty: React.FC = () => (
  <Box
    sx={{
      padding: "30px",
      display: "flex",
      columnGap: "30px",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      background: colors.secondary.lightGrey,
      [customBreakpointsMax.tablet]: {
        padding: "40px",
        height: "calc(100vh - 633px)",
      },
      [customBreakpointsMax.mobile]: {
        padding: "20px",
        height: "300px",
      },
    }}
  >
    <FavouriteIcon height={67} width={67} />
    <Box>
      <Typography
        variant="h2"
        fontSize={{
          xs: "18px",
          md: "24px",
          lg: "36px",
        }}
      >
        Add favourite data files for future reference!
      </Typography>
      <Typography
        variant="h5"
        fontSize={{
          xs: "12px",
          md: "14px",
          lg: "14px",
        }}
        fontWeight={400}
        lineHeight={"20px"}
      >
        Start marking activities now to follow the recent updates & archive your
        interests.
      </Typography>
      <Box
        sx={{
          display: "flex",
          columnGap: "15px",
          marginTop: "20px",
          a: {
            padding: "4px 10px",
          },
        }}
      >
        <Button
          to="/explorer"
          component={Link}
          variant="contained"
          endIcon={<ArrowForward />}
        >
          AIDA Explorer
        </Button>
        <Button
          component={Link}
          variant="contained"
          to="/explorer/activities"
          endIcon={<ArrowForward />}
        >
          Explore Activities
        </Button>
      </Box>
    </Box>
  </Box>
);

export const ExplorerFavourites: React.FC = () => {
  const navigate = useNavigate();
  const { count, loading, favourites, removeFavourite } = useFavouritesUtils();

  const onTableRowClick = (params: any) => {
    navigate(`/explorer/activities/${params.row.itemId}`);
  };

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/explorer/favourites" />
      <Box
        sx={{
          h1: {
            fontSize: "37px",
          },
        }}
      >
        <PageHeader
          variant="explorer"
          title="AIDA Explorer: Your Favourites"
          subtitle="Introducing 'Your Favourites' – your personalized area for humanitarian insights. Save and organize your favorite activities for quick access. This feature is designed with you in mind, making it easier to revisit critical information."
          icon={<ExplorerFavouritesHeaderIcon />}
        />
        <Box
          height={{
            xs: "20px",
            md: "30px",
            lg: "40px",
          }}
        />
        {count > 0 && !loading ? (
          <GenericPageBlockItem
            title="Your favourites"
            toolbarShare
            loading={loading}
            shareLink="/explorer/favourites#block-Your favourites"
            description={
              <React.Fragment>
                You have favoured {count} data assets.
              </React.Fragment>
            }
            content={
              <React.Fragment>
                <Table
                  withSearch
                  loading={loading}
                  rows={favourites}
                  headerFontSize="12px"
                  onRowClick={onTableRowClick}
                  columns={getFavouritesColumns(removeFavourite)}
                />
              </React.Fragment>
            }
          />
        ) : (
          <GenericPageBlockItem
            title="Your favourites"
            toolbarShare
            loading={loading}
            shareLink="/explorer/favourites#block-Your favourites"
            description={
              <React.Fragment>
                You've haven’t favourited any data files yet. Explore{" "}
                <Link to="/explorer">AIDA Explorer</Link> or{" "}
                <Link to="/explorer/activities">Activities</Link> pages to
                favourite data files.
              </React.Fragment>
            }
            content={<Empty />}
          />
        )}
      </Box>
    </React.Fragment>
  );
};
