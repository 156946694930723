import React from "react";
import get from "lodash/get";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "app/components/page-loader";
import { useLocalStorage, useSessionStorage } from "react-use";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

export const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { error, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [storedToken, setStoredToken] = React.useState("");

  const storedUser = useStoreState((state) =>
    get(state.user, "data.data", null)
  );
  const fetchUserAction = useStoreActions(
    (actions) => actions.user.authGetFetch
  );
  const fetchIntercomUserAction = useStoreActions(
    (actions) => actions.IntercomUser.authGetFetch
  );

  const [authRedirectData, _, clearAuthRedirectData] = useLocalStorage(
    "auth-redirect-data",
    {
      step: -1,
      pathname: "/",
    }
  );
  const setActiveStep = useSessionStorage("publisher-active-step", -1)[1];

  const onSuccessfulUserLoad = async () => {
    if (storedToken) {
      fetchIntercomUserAction({
        dontEncryptAuthCall: true,
        values: { token: storedToken, email: storedUser.email },
      });
    } else {
      getAccessTokenSilently().then((token: string) => {
        fetchIntercomUserAction({ values: { token } });
      });
    }
    navigate(authRedirectData?.pathname ?? "/");
    setActiveStep(authRedirectData?.step ?? -1);
    clearAuthRedirectData();
  };

  React.useEffect(() => {
    getAccessTokenSilently().then((token: string) => {
      setStoredToken(token);
      fetchUserAction({ values: { token } });
    });
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && !isLoading) {
      if (storedToken) {
        fetchUserAction({ values: { token: storedToken } });
        getAccessTokenSilently().then((token: string) => {
          fetchUserAction({ values: { token } });
        });
      }
    }
  }, [isAuthenticated, isLoading]);

  React.useEffect(() => {
    if (storedUser) {
      onSuccessfulUserLoad();
    }
  }, [storedUser]);

  return (
    <div>
      {!error && <PageLoader />}
      {error && <div>{error.message}</div>}
    </div>
  );
};
