import { Box, Container, Typography } from "@mui/material";
import theme, { colors, customBreakpointsMax } from "app/theme";
import React from "react";
import { BannerProps } from "./data";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

export const Banner = (props: BannerProps) => {
  const color =
    props.variant === "publisher" ? colors.primary.green : colors.primary.blue;

  return (
    <Box
      sx={{
        background: color,
        width: "100vw",
        marginLeft: `calc((100vw - ${theme.breakpoints.values.lg}px) / -2)`,
        [customBreakpointsMax.tablet]: {
          width: "100%",
          marginLeft: "0",
        },
      }}
      data-cy="banner"
    >
      <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection:
              props.variant === "publisher" ? "row" : "row-reverse",
            alignItems: "center",
            gap: "50px",
            padding: "50px 0",
            [customBreakpointsMax.tablet]: {
              flexDirection: "column",
              gap: "30px",
              padding: "30px 20px",
            },
            [customBreakpointsMax.mobile]: {
              gap: "20px",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "202px",
                md: "270px",
                lg: "351px",
              },
              flexShrink: 0,
              "> img": {
                width: "100%",
                height: "auto",
                filter:
                  "drop-shadow(0px 1.094px 2.189px rgba(97, 97, 97, 0.20)) drop-shadow(0px 0.547px 1.094px rgba(97, 97, 97, 0.20))",
              },
            }}
          >
            <img src={props.image} alt="explore-hero-image" />
          </Box>
          <Box
            sx={{
              "> a": {
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                columnGap: "6px",
              },
            }}
          >
            <Typography
              variant="h2"
              fontSize={{
                xs: "18px",
                md: "24px",
                lg: "36px",
              }}
              lineHeight={"normal"}
              color={colors.primary.white}
            >
              {props.title}
            </Typography>
            <Typography
              marginTop={"10px"}
              variant="h5"
              fontSize={"14px"}
              fontWeight={"400"}
              color={colors.primary.white}
            >
              {props.subtitle}
            </Typography>
            <Box sx={{ height: { xs: "20px", md: "30px" } }} />
            <Link to={props.linkUrl || ""}>
              <Typography
                variant="h6"
                lineHeight={"normal"}
                fontSize={"12px"}
                color={colors.primary.white}
              >
                {props.linkText}
              </Typography>

              <ArrowForwardIos
                sx={{
                  width: "10px",
                  height: "10px",
                  color: colors.primary.white,
                }}
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
