import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import ReactDOMServer from "react-dom/server";
import { useSessionStorage } from "react-use";
import { useNavigate } from "react-router-dom";
import { SVGRenderer } from "echarts/renderers";
import { TooltipComponentOption } from "echarts";
import { GridComponent } from "echarts/components";
import { formatNumber } from "app/utils/formatNumber";
import { TooltipComponent } from "echarts/components";
import { PieChartProps } from "app/components/charts/pie/data";
import { PieSeriesOption, PieChart as EChartsPie } from "echarts/charts";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { GeomapChartTooltip } from "app/components/charts/geomap/tooltip";
import { useMediaQuery } from "@mui/material";

echarts.use([EChartsPie, TooltipComponent, SVGRenderer, GridComponent]);

const Tooltip: React.FC<any> = (props) => {
  return (
    <div
      style={{
        gap: "8px",
        width: "230px",
        lineHeight: 1.2,
        display: "flex",
        flexDirection: "column",
        color: colors.text.title,
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "12px",
          maxWidth: "200px",
          overflow: "hidden",
          whiteSpace: "wrap",
        }}
      >
        {props.data.name}
      </div>
      <div
        style={{
          fontSize: "12px",
          maxWidth: "200px",
          overflow: "hidden",
          whiteSpace: "wrap",
        }}
      >
        {formatNumber(props.data.value)} ({props.percent}%) invested in{" "}
        {props.period}
      </div>
    </div>
  );
};

export const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");
  const navigate = useNavigate();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-pie-chart`,
  });

  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "pie-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    id: string;
    label: string;
    value: number;
  } | null>("pie-tooltip", null);

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        PieSeriesOption | TooltipComponentOption
      > = {
        color:
          props.data.length < 5
            ? colors.charts.filter((_, index) => index % 2 === 0)
            : colors.charts,
        series: {
          type: "pie",
          endAngle: props.half ? 360 : "auto",
          startAngle: props.half ? 180 : 0,
          data: props.data,
          center: [
            props.showTooltip && !tablet ? "35%" : "50%",
            props.half ? "70%" : "50%",
          ],
          radius: props.donut ? ["60%", "100%"] : ["0%", "100%"],
          bottom: props.half ? -50 : 20,
          padAngle: 0.2,
          top: props.half ? 0 : 20,
          emphasis: {
            disabled: !props.showEmphasisLabel,
            label: {
              show: props.showEmphasisLabel ?? false,
              fontSize: 25,
              fontWeight: "bold",
              overflow: "truncate",
              formatter: (params) => {
                return `{a|${params.name}}\n{b|${props.unit} ${formatNumber(
                  params.value as number
                )}}`;
              },
              rich: {
                a: {
                  fontSize: mobile ? "10px" : "12px",
                  fontWeight: 400,
                },
                b: {
                  fontSize: mobile ? "14px" : "25px",
                  fontWeight: 700,
                  padding: [5, 0, 0, 0],
                },
              },
            },
          },

          label: {
            show: props.showLabel ?? true,
            position: props.labelPosition ?? "outside",
            fontSize: props.labelFontSize ?? mobile ? "9px" : "12px",
            minMargin: 0,
            fontFamily: "'Inter', sans-serif",
            formatter: (params) => {
              return `${params.name}${
                mobile
                  ? ""
                  : `\n${props.unit} ${formatNumber(
                      params.value as number,
                      true
                    )}\n(${params.percent}%)`
              }`;
            },
            rich: {
              boldName: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
          },
        },
        tooltip: {
          show: true,
          padding: 10,
          backgroundColor: colors.secondary.lightGrey,
          borderColor: colors.secondary.lightGrayText,
          formatter: (params: any) => {
            return ReactDOMServer.renderToString(
              <Tooltip {...params} period={props.period} />
            );
          },
        },
      };

      chart.setOption(option);

      chart.on("click", (params: any) => {
        if (props.half) {
          if (params.data.id && props.unitType) {
            navigate(`/explorer/${props.unitType}/${params.data.id}`);
          }
          return;
        }
        const locked = sessionStorage.getItem("pie-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("pie-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.name === gtooltip?.label &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              id: params.data?.id,
              label: params.data?.name,
              value: params.data.value,
            });
            setIsLocked(true);
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("pie-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            id: params.data?.id,
            label: params.data?.name,
            value: params.data.value,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("pie-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });

      setStateChart(chart);
    }
  }, [
    containerRef.current,
    mobile,
    tablet,
    props.data,
    props.half,
    props.donut,
    props.unit,
    props.height,
    props.labelFontSize,
    props.showLabel,
    props.showEmphasisLabel,
    props.labelPosition,
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        id={`${props.id}-pie-chart`}
        ref={containerRef}
        width="100%"
        height={props?.height ?? "450px"}
        data-cy="pie-chart"
      />
      {props.showTooltip && tooltip && (
        <Box
          sx={{
            position: "absolute",
            right: { lg: "-70%", xs: 0 },
            top: "30%",
            width: "100%",
            [customBreakpointsMax.tablet]: {
              top: "calc(100% + 10px)",
            },
          }}
        >
          <GeomapChartTooltip
            isLocked={isLocked}
            label={"Global"}
            value={tooltip.value}
            subLabel={tooltip.label}
            activityCount={0}
            variant={props.variant}
            lockUnlock={() => {
              setTooltip(null);
              setIsLocked(!isLocked);
            }}
            buttonLink={`/explorer/organisation/${tooltip.id}`}
            buttonText="Organisation Page"
          />
        </Box>
      )}
    </Box>
  );
};
