import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import maxBy from "lodash/maxBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { useStoreState } from "app/state/store/hooks";
import { formatNumber } from "app/utils/formatNumber";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { LandingPageWidget } from "app/components/landing-page-widget";
import { LandingPageBlockItem } from "app/components/landing-page-block-item";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import {
  SectorWidgetInfoText,
  IatiDataInNumbersProps,
} from "app/pages/home/explorer/components/iati-in-numbers/data";

export const IatiDataInNumbers: React.FC<IatiDataInNumbersProps> = (
  props: IatiDataInNumbersProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  const sectorsFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );

  const SectorWidgets = (wProps: {
    items: IatiDataInNumbersProps["sectors"];
  }) => {
    const data = wProps.items.map((item) => ({
      title: item.name,
      description: `Disbursement for ${defaultYear}`,
      valueText: `USD ${formatNumber(item.value, true)}`,
      percentageChange: parseInt(item.changePercentage?.toFixed(2), 10),
    }));

    return (
      <React.Fragment>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr",
            },
          }}
        >
          {data.map((item) => {
            const fSector = find(sectorsFilterOptions, { label: item.title });
            return (
              <Box
                key={item.title}
                sx={{
                  borderBottom: {
                    xs: `0.5px solid ${colors.secondary.iconGray}`,
                    md: "none",
                    lg: `0.5px solid ${colors.secondary.iconGray}`,
                  },
                  borderRight: {
                    xs: `none`,
                    md: `0.5px solid ${colors.secondary.iconGray}`,
                    lg: `none`,
                  },
                  "&:last-of-type": {
                    borderBottom: "none",
                    borderRight: "none",
                  },
                  paddingBottom: "9.5px",
                  marginBottom: "9.5px",
                }}
              >
                <LandingPageWidget
                  title={item.title}
                  description={item.description}
                  valueText={item.valueText}
                  percentageChange={item.percentageChange}
                  infoText={<SectorWidgetInfoText />}
                  link={
                    fSector ? `/explorer/sectors/${fSector.value}` : undefined
                  }
                />
              </Box>
            );
          })}
        </Box>
        <Box height={20} />
      </React.Fragment>
    );
  };

  const orgDonutTotal = React.useMemo(
    () => sumBy(props.orgDonut, "value"),
    [props.orgDonut]
  );

  const sdgsLoading = useStoreState((state) => state.ExplorerHomeSDGs.loading);
  const budgetBySectorLoading = useStoreState(
    (state) => state.ExplorerHomeBudgetBySector.loading
  );
  const orgNetworkLoading = useStoreState(
    (state) => state.ExplorerHomeOrganisationsNetwork.loading
  );

  const topSector: {
    name: string;
    link?: string;
  } | null = React.useMemo(() => {
    const value = maxBy(
      props.budgetBySector.values,
      (value: number[]) => value[value.length - 1]
    );
    if (!value) return null;
    const index = props.budgetBySector.values.indexOf(value);
    const label = props.budgetBySector.series[index];
    const fSector = find(sectorsFilterOptions, { label });
    return {
      name: fSector ? fSector.label : label,
      link: fSector ? `/explorer/sectors/${fSector.value}` : undefined,
    };
  }, [props.budgetBySector, sectorsFilterOptions]);

  const topSectorGrowth: {
    name: string;
    link?: string;
  } | null = React.useMemo(() => {
    const label = get(props.sectors, "[0].name", "");
    const fSector = find(sectorsFilterOptions, { label });
    return {
      name: fSector ? fSector.label : label,
      link: fSector ? `/explorer/sectors/${fSector.value}` : undefined,
    };
  }, [props.sectors, sectorsFilterOptions]);

  return (
    <React.Fragment>
      <Typography
        variant="h3"
        fontSize={{
          xs: "24px",
          lg: "36px",
        }}
      >
        IATI Data, in Numbers
      </Typography>
      <Box height={20} />
      <Grid
        container
        spacing={{
          xs: "20px",
          lg: "30px",
        }}
      >
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <SDGChart
                labelGroup="Activities"
                data={props.sdgsData.data.slice(0, 9).map((item) => ({
                  sdg: item.code,
                  name: item.name,
                  value: item.value,
                }))}
              />
            }
            loading={sdgsLoading}
            title={`Top six trending Sustainable Development Goals of ${defaultYear}.`}
            description={
              <React.Fragment>
                In {defaultYear},{" "}
                <Link to="/explorer?e=sdgs">
                  {props.sdgsData.count} activities
                </Link>{" "}
                were contributing to the SDGs
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <BarChart
                barWidth={mobile ? "24px" : tablet ? "15px" : "30px"}
                height={mobile ? "232px" : tablet ? "200px" : "350px"}
                yAxisLabelName="USD"
                xAxisLabelRotate={0}
                data={props.budgetBySector}
                xAxisLabelFontSize={mobile ? 8 : tablet ? 7 : 10}
                yAxisLabelFontSize={mobile ? 8 : tablet ? 7 : 10}
              />
            }
            loading={budgetBySectorLoading}
            title={`Budgets presented for the year of ${defaultYear} by sector.`}
            description={
              <React.Fragment>
                Budget by sectors shows that{" "}
                {topSector?.link ? (
                  <Link to={topSector.link}>{topSector.name}</Link>
                ) : (
                  topSector?.name
                )}{" "}
                was the most funded sectors of {defaultYear}.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <NetworkChart
                  roam
                  id="landing-page-network-chart"
                  data={props.orgNetwork}
                  height={mobile ? "232px" : tablet ? "200px" : "310px"}
                />
                <Box height={20} />
              </React.Fragment>
            }
            loading={orgNetworkLoading}
            title={`Donor network present for the year ${defaultYear}, separated by sectors.`}
            description={
              <React.Fragment>
                <Link to="/explorer/organisations">
                  {get(props.orgNetwork, "nodes", []).length} organisations
                </Link>{" "}
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <Box
        sx={{
          display: "flex",
          columnGap: { xs: "12px", md: "20px" },
        }}
      >
        <CtaCommonBtn
          variant={"large"}
          label={
            mobile
              ? "AIDA Explorer"
              : tablet
              ? "Your Favourites"
              : "Explore Insights"
          }
          text={
            !mobile
              ? tablet
                ? "Introducing 'Your Bookmarks' - your personalized area for humanitarian insights."
                : "Unlock the Power of Data for Impactful Fundraising. Discover valuable insights and opportunities to elevate your fundraising efforts like never before."
              : undefined
          }
          link="/explorer"
          bgIcon={<ExplorerLargeCtaIcon />}
        />
        <CtaCommonBtn
          variant={"large"}
          label={
            mobile
              ? "AIDA Publisher"
              : tablet
              ? "Publish Your Data"
              : "Publish Your Information to IATI"
          }
          text={
            !mobile
              ? tablet
                ? "Join the ranks of NGOs that are already benefiting from AIDA Publication Service."
                : "Join the ranks of NGOs that are already benefiting from AIDA Publication Service. Embrace transparency, engage more donors, and make a difference that truly matters."
              : undefined
          }
          link="/publisher"
          bgIcon={<PublisherLargeCtaIcon />}
          bgColor="green"
        />
      </Box>
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <Grid
        container
        spacing={{
          xs: "20px",
          md: "30px",
        }}
        columns={131}
        flexDirection={{ xs: "column-reverse", lg: "row" }}
      >
        <Grid item xs={131} lg={40}>
          <LandingPageBlockItem
            content={<SectorWidgets items={props.sectors} />}
            title={`Sectors with significant funding rate changes for ${defaultYear}.`}
            description={
              <React.Fragment>
                {topSectorGrowth?.link ? (
                  <Link to={topSectorGrowth.link}>{topSectorGrowth.name}</Link>
                ) : (
                  topSectorGrowth?.name
                )}{" "}
                was the sector to have the highest percentage of growth for{" "}
                {defaultYear}.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={131} lg={91}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <Box height={20} />
                <PieChart
                  id="home-org-donut"
                  half
                  donut
                  data={props.orgDonut}
                  unit="USD"
                  height={mobile ? "130px" : tablet ? "300px" : "500px"}
                  unitType="organisations"
                  period={defaultYear}
                />
              </React.Fragment>
            }
            title="Sectors to which resources were allocated."
            description={
              <React.Fragment>
                Top 10 organisations provides in total of{" "}
                {formatNumber(orgDonutTotal)} development aid in {defaultYear}.
                Which makes up the 85% of the total development aid.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
