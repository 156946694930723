import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PieChart } from "app/components/charts/pie";
import { formatNumber } from "app/utils/formatNumber";
import { useStoreState } from "app/state/store/hooks";
import { GeomapChart } from "app/components/charts/geomap";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { KeyProjectionsBlockProps } from "app/pages/explorer/components/key-projections-block/data";

export const KeyProjectionsBlock: React.FC<KeyProjectionsBlockProps> = (
  props: KeyProjectionsBlockProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: [
      "key-locations",
      "key-organisations",
      "key-sectors",
      "key-targets",
    ],
  });

  const overviewStats: {
    total: number;
    activityCount: number;
    orgCount: number;
    countryCount: number;
  } = useStoreState((state) =>
    get(state.ExplorerProjectionsOverviewStats.data, "data", {
      total: 0,
      activityCount: 0,
      orgCount: 0,
      countryCount: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerProjectionsOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.total, true)}`,
      },
      { name: "Activities", value: overviewStats?.activityCount?.toString() },
      { name: "Organisations", value: overviewStats?.orgCount?.toString() },
      {
        name: "Recipient Countries",
        value: overviewStats?.countryCount?.toString(),
      },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        loading={overviewStatsLoading}
        title={`${props.period} Overview`}
        shareLink={`/explorer#${props.period} Overview`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.total, true)}
            </span>{" "}
            is set to be spent for
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {" "}
              92 recipient countries
            </span>{" "}
            in {props.period}.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, [
    props.period,
    overviewStats,
    overviewStatsLoading,
    props.transactionType,
  ]);

  const locationsData: {
    stats: {
      code: string;
      name: string;
      value: number;
      count: number;
    }[];
    geo_data: {
      name: string;
      iso2: string;
      value: number;
      full_name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerProjectionsLocations.data, "data", {
      stats: [],
      geo_data: [],
    })
  );
  const locationsLoading = useStoreState(
    (state) => state.ExplorerProjectionsLocations.loading
  );
  const locations = React.useMemo(() => {
    const items = locationsData.stats?.map((item) => ({
      code: item.code.toLowerCase(),
      name: item.name,
      funds: `USD ${formatNumber(item.value, true)}`,
      activities: item.count,
    }));

    const expanded = expandedBlock === "key-locations";

    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          loading={locationsLoading}
          title={`${props.period} Countries`}
          onExpand={() => handleSetExpandedBlock("key-locations")}
          height={expanded ? (tablet ? "auto" : "900px") : "100%"}
          shareLink="/explorer?p=key-locations"
          description={
            <React.Fragment>
              {locationsData.stats
                ?.slice(0, locationsData.stats.length - 1)
                .map((i) => (
                  <React.Fragment key={i.code}>
                    <span
                      style={{
                        color: colors.primary.blue,
                      }}
                    >
                      {i.name}
                    </span>
                    {", "}
                  </React.Fragment>
                ))}
              and{" "}
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {locationsData.stats?.[locationsData.stats?.length - 1]?.name}
                </span>{" "}
              </React.Fragment>
              are the largest recipient countries of {props.period}.
            </React.Fragment>
          }
          content={
            <React.Fragment>
              <LocationsBlock
                items={items}
                transactionType={props.transactionType.label}
              />
              {expanded && (
                <Box
                  height={mobile ? "560px" : tablet ? "520px" : "550px"}
                  bgcolor={colors.primary.white}
                >
                  <Divider />
                  <Box height={30} />
                  <GeomapChart
                    data={locationsData.geo_data}
                    showLegend
                    showTooltip
                    height={tablet ? "327px" : "500px"}
                    variant="explorer"
                    showZoomWidget
                    zoom={expanded && mobile ? 3 : 1}
                  />
                </Box>
              )}
            </React.Fragment>
          }
          expanded={expanded}
        />
      </React.Fragment>
    );
  }, [
    expandedBlock,
    locationsData,
    locationsLoading,
    props.period,
    props.transactionType,
    tablet,
    mobile,
  ]);

  const organisationsData: {
    id: string;
    name: string;
    count: number;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerProjectionsOrganisationsDonut.data, "data", [])
  );
  const organisationsLoading = useStoreState(
    (state) => state.ExplorerProjectionsOrganisationsDonut.loading
  );
  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        height={expandedBlock === "key-organisations" ? "auto" : "100%"}
        toolbarExpand
        loading={organisationsLoading}
        title={`${props.period} Organisations`}
        onExpand={() => handleSetExpandedBlock("key-organisations")}
        shareLink="/explorer?p=key-organisations"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {organisationsData[0]?.name}
            </span>{" "}
            will be one of the important organisations shaping our future in{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Organisations engaged in activities spanning in {props.period}:
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-organisations"
              unitType="organisations"
              data={organisationsData}
              period={props.period}
              unit="USD"
              variant="explorer"
              height={
                expandedBlock === "key-organisations"
                  ? mobile
                    ? "276px"
                    : tablet
                    ? "403px"
                    : "700px"
                  : mobile
                  ? "212px"
                  : "333px"
              }
              showTooltip={expandedBlock === "key-organisations" ? true : false}
              showLabel={expandedBlock === "key-organisations" ? false : true}
            />
            {expandedBlock === "key-organisations" && tablet && (
              <Box height={mobile ? 170 : 150} />
            )}
          </React.Fragment>
        }
        expanded={expandedBlock === "key-organisations"}
      />
    );
  }, [
    expandedBlock,
    organisationsData,
    organisationsLoading,
    props.period,
    mobile,
    tablet,
  ]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerProjectionsSectors.data, "data", [])
  );
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerProjectionsSectors.loading
  );
  const sectors = React.useMemo(() => {
    const expanded = expandedBlock === "key-sectors";
    return (
      <GenericPageBlockItem
        toolbarShare
        height={expanded ? "auto" : "100%"}
        toolbarExpand
        loading={sectorsLoading}
        title={`${props.period} Sectors`}
        onExpand={() => handleSetExpandedBlock("key-sectors")}
        shareLink="/explorer?p=key-sectors"
        description={
          <React.Fragment>
            The{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sectorsData[0]?.name}
            </span>{" "}
            sector is anticipated to secure the most substantial funding in{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Here's a curated collection of the visionary activities planned to
              extend their impact in {props.period}
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-sectors"
              half={!(expanded && tablet)}
              donut
              data={sectorsData.map((i) => ({
                id: i.code,
                name: i.name,
                value: i.value,
              }))}
              unit="USD"
              unitType="sectors"
              period={props.period}
              showLabel={!(expanded && tablet)}
              height={
                expanded
                  ? mobile
                    ? "300px"
                    : "500px"
                  : mobile
                  ? "120px"
                  : "350px"
              }
              showEmphasisLabel={expanded && tablet}
              labelPosition={expanded && tablet ? "center" : "outside"}
            />
          </React.Fragment>
        }
        expanded={expanded}
      />
    );
  }, [
    expandedBlock,
    sectorsData,
    sectorsLoading,
    props.period,
    mobile,
    tablet,
  ]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (expandedBlock) {
      case "key-locations":
        view = locations;
        break;
      case "key-organisations":
        view = organisations;
        break;
      case "key-sectors":
        view = sectors;
        break;
      default:
        break;
    }

    return <React.Fragment>{view}</React.Fragment>;
  }, [expandedBlock, locations, organisations, sectors]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Countries"),
    [locations]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Organisations"),
    [organisations]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Sectors"),
    [sectors]
  );

  return (
    <Grid container spacing={{ xs: "20px", md: "30px" }}>
      {expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} lg={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {expandedBlock !== "key-locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {expandedBlock !== "key-organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={12}>
        {expandedBlock !== "key-sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
