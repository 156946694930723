import { Box, Button, Typography } from "@mui/material";
import { colors, customBreakpointsMax } from "app/theme";
import React from "react";

export const PlanCard = ({
  option,
  selectedPlan,
  setSelectedPlan,
}: {
  option: {
    title: string;
    recommended: boolean;
    currency?: string;
    price: string;
    duration?: string;
    description: string;
    buttonText: string;
  };
  selectedPlan: string;
  setSelectedPlan: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const blur = selectedPlan && selectedPlan !== option.title;
  return (
    <Box
      key={option.title}
      sx={{
        padding: "40px 30px",
        width: "245px",
        display: "flex",
        flexDirection: "column",
        height: "341px",
        justifyContent: "space-between",
        boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
        border: `1px solid ${
          selectedPlan === option.title ? colors.primary.blue : "transparent"
        }`,
        borderRadius: "20px",
        alignItems: "center",
        position: "relative",
        [customBreakpointsMax.tablet]: {
          width: "100%",
          padding: "30px 20px",
          height: "auto",
          gap: "20px",
        },
        [customBreakpointsMax.mobile]: {
          padding: "10px",
          gap: "15px",
          paddingTop: "20px",
        },
      }}
    >
      {option.recommended ? (
        <Box
          sx={{
            position: "absolute",
            top: "-1px",
            right: "20.8px",
            backgroundColor: "#FFC145",
            padding: "4px 9.16px 3.28px 14px",
            borderRadius: "0px 0px 4.855px 4.855px",
            color: colors.text.title,
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "normal",
            [customBreakpointsMax.tablet]: {
              padding: "3px",
              fontSize: "9px",
            },
            [customBreakpointsMax.mobile]: {
              right: "16.33px",
            },
          }}
        >
          Recommended
        </Box>
      ) : null}
      <Typography
        variant="h3"
        fontSize={{ xs: "18px", md: "24px" }}
        lineHeight={"normal"}
        textAlign={"center"}
        color={blur ? colors.secondary.lightGrayText : colors.text.title}
      >
        {option.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "10px 12px",
            width: "170px",
            backgroundColor: blur
              ? colors.secondary.lightGrayText
              : colors.text.title,
            borderRadius: "21px",
            fontSize: "24px",
            span: {
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            lineHeight: "normal",
            color: colors.primary.white,
            fontWeight: "bold",
            [customBreakpointsMax.tablet]: {
              fontSize: "14px",
              span: {
                fontSize: "12px",
              },
              width: "110px",
              padding: "6px 5px",
              gap: "2px",
            },
            [customBreakpointsMax.mobile]: {
              width: "92px",
              lineHeight: "20px",
            },
          }}
        >
          <span>{option.currency ?? null}</span>
          {option.price}
          <span>{option.duration ? `/ ${option.duration}` : null}</span>
        </Box>
        <Typography
          variant="h5"
          fontSize={{
            xs: "10px",
            lg: "14px",
          }}
          fontWeight={"400"}
          lineHeight={{
            xs: "12px",
            lg: "20px",
          }}
        >
          {option.description}
        </Typography>
      </Box>

      <Button
        sx={{
          padding: "6px 10px",
          lineHeight: "20px",
          backgroundColor: blur ? colors.secondary.lightGrayText : "auto",
          [customBreakpointsMax.tablet]: {
            padding: 0,
            fontSize: "10px",
            backgroundColor: "transparent",
            color: blur ? colors.secondary.lightGrayText : colors.primary.red,
            textDecoration: "underline",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              textDecoration: "underline",
            },
          },
        }}
        variant="contained"
        onClick={() => setSelectedPlan(option.title)}
      >
        {option.buttonText}
      </Button>
    </Box>
  );
};
