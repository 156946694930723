import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import Box from "@mui/material/Box";
import groupBy from "lodash/groupBy";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Table } from "app/components/table";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { useStoreState } from "app/state/store/hooks";
import { formatNumber } from "app/utils/formatNumber";
import { colors, customBreakpointsMax } from "app/theme";
import { GeomapChart } from "app/components/charts/geomap";
import { ImpactChart } from "app/components/charts/impact";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { IMPACT_COLUMNS } from "app/components/charts/impact/tableData";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { ImpactChartItemProps } from "app/components/charts/impact/data";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { GenericPageBlockItemLocationDescription } from "app/components/generic-page-block-item/data";
import { InsightsBlockProps } from "app/pages/explorer/pages/organisations/pages/organisation/components/insights-block/data";
import { useParams } from "react-router-dom";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");
  const { id } = useParams();

  const locationsFilterOptions = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );

  const overviewStats: {
    name: string;
    value: number;
    countries: number;
    activities: number;
    organisations: number;
  } = useStoreState((state) =>
    get(state.ExplorerOrganisationPageOverviewStats.data, "data", {
      name: "",
      value: 0,
      countries: 0,
      activities: 0,
      organisations: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.value, true)}`,
      },
      { name: "Activities", value: overviewStats.activities },
      { name: "Planned Activities", value: overviewStats.activities },
      { name: "Recipient Countries", value: overviewStats.countries },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        height="100%"
        title="Overview"
        loading={overviewStatsLoading}
        shareLink={`/explorer/organisations/${id}#block-Overview`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.value, true)}
            </span>{" "}
            in {props.transactionType.label} for{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {overviewStats.countries} recipient countries
            </span>{" "}
            in {props.period}.
          </React.Fragment>
        }
        content={
          <OverviewBlock items={items} variant={tablet ? "vertical" : "grid"} />
        }
      />
    );
  }, [
    props.organisation,
    overviewStats,
    props.period,
    props.transactionType,
    tablet,
  ]);

  const locationsData: {
    stats: {
      code: string;
      name: string;
      value: number;
      count: number;
    }[];
    geo_data: {
      name: string;
      iso2: string;
      value: number;
      full_name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerOrganisationPageLocations.data, "data", {
      stats: [],
      geo_data: [],
    })
  );
  const locationsLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageLocations.loading
  );
  const locations = React.useMemo(() => {
    const items = locationsData.stats.map((item) => ({
      code: item.code.toLowerCase(),
      name: item.name,
      funds: `USD ${formatNumber(item.value, true)}`,
      activities: item.count,
    }));
    const expanded = props.expandedBlock === "locations";
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          loading={locationsLoading}
          onExpand={() => props.setExpandedBlock("locations")}
          height={expanded ? (tablet ? "auto" : "900px") : "100%"}
          shareLink={`/explorer/organisations/${id}?p=locations`}
          description={
            <GenericPageBlockItemLocationDescription
              period={props.period}
              items={
                locationsData.stats?.map((item) => {
                  const fLocation = find(locationsFilterOptions, {
                    label: item.name,
                  });
                  return {
                    label: item.name,
                    link: fLocation
                      ? `/explorer/locations/${fLocation.value}`
                      : "",
                  };
                }) ?? []
              }
            />
          }
          content={
            <React.Fragment>
              <LocationsBlock
                items={items}
                transactionType={props.transactionType.label}
              />
              {expanded && (
                <Box
                  height={mobile ? "560px" : tablet ? "520px" : "550px"}
                  bgcolor={colors.primary.white}
                >
                  <Divider />
                  <Box height={30} />

                  <GeomapChart
                    data={GeomapSampleData}
                    showLegend
                    showTooltip
                    height={tablet ? "327px" : "500px"}
                    variant="explorer"
                    showZoomWidget
                  />
                </Box>
              )}
            </React.Fragment>
          }
          expanded={expanded}
        />
      </React.Fragment>
    );
  }, [
    props.expandedBlock,
    locationsData,
    props.period,
    props.transactionType,
    mobile,
    tablet,
  ]);

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerOrganisationPageSDGs, "data.items", {
      count: 0,
      data: [],
    })
  );
  const sdgsLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageSDGs.loading
  );
  const sdgs = React.useMemo(() => {
    const items = sdgsData.data.map((item) => ({
      sdg: item.code,
      name: item.name,
      value: item.value,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        loading={sdgsLoading}
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        shareLink={`/explorer/organisations/${id}?p=sdgs`}
        description={
          <React.Fragment>
            In {props.period},{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sdgsData.count} activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Top six trending Sustainable Development Goals of {props.period}:
            </Typography>
            <Box height={28} />
            <SDGChart
              labelGroup="Activities"
              expanded={props.expandedBlock === "sdgs"}
              data={props.expandedBlock === "sdgs" ? items : items.slice(0, 6)}
              variant={tablet ? "horizontal" : "grid"}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, props.organisation, sdgsData, props.period, tablet]);

  const networksData: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
      category: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerOrganisationOrganisationsNetwork.data, "data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const networksLoading = useStoreState(
    (state) => state.ExplorerOrganisationOrganisationsNetwork.loading
  );
  const networks = React.useMemo(() => {
    const expanded = props.expandedBlock === "networks";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        loading={networksLoading}
        onExpand={() => props.setExpandedBlock("networks")}
        height={expanded ? (tablet ? "auto" : "900px") : "100%"}
        shareLink={`/explorer/organisations/${id}?p=networks`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {networksData.nodes.length} organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              See the organisation network by their organisation type:
            </Typography>
            <NetworkChart
              id="networks"
              data={networksData}
              showZoomWidget={expanded}
              height={expanded && !tablet ? "700px" : "450px"}
            />
          </React.Fragment>
        }
        expanded={expanded}
      />
    );
  }, [props.expandedBlock, networksData, networksLoading, tablet]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerOrganisationSectors.data, "data", [])
  );
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerOrganisationSectors.loading
  );
  const sectors = React.useMemo(() => {
    const expanded = props.expandedBlock === "sectors";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sectors"
        loading={sectorsLoading}
        onExpand={() => props.setExpandedBlock("sectors")}
        height={props.expandedBlock === "sectors" ? "auto" : "100%"}
        shareLink={`/explorer/organisations/${id}?p=sectors`}
        description={
          <React.Fragment>
            {props.organisation}{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sectorsData.length} sectors
            </span>{" "}
            in all years.
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half={!(expanded && tablet)}
            data={sectorsData.map((item) => ({
              ...item,
              id: item.code,
            }))}
            unit=""
            unitType="organisations"
            height={
              expanded
                ? mobile
                  ? "300px"
                  : "500px"
                : mobile
                ? "120px"
                : "350px"
            }
            showLabel={!(expanded && tablet)}
            showEmphasisLabel={expanded && tablet}
            labelPosition={expanded && tablet ? "center" : "outside"}
          />
        }
        expanded={props.expandedBlock === "sectors"}
      />
    );
  }, [
    props.expandedBlock,
    sectorsData,
    sectorsLoading,
    props.organisation,
    mobile,
    tablet,
  ]);

  const impactData = useStoreState(
    (state) =>
      get(
        state.ExplorerOrganisationPageImpactResults,
        "data.data",
        []
      ) as ImpactChartItemProps[]
  );
  const impactLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageImpactResults.loading
  );
  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        loading={impactLoading}
        onExpand={() => props.setExpandedBlock("impact")}
        shareLink={`/explorer/organisations/${id}?p=impact`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {Object.keys(groupBy(impactData, "title")).length} activities
            </span>{" "}
            went above and beyond their target results in {props.period},
            showcasing noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          props.expandedBlock === "impact" ? (
            <Table
              withSearch
              rows={impactData}
              headerFontSize="12px"
              columns={IMPACT_COLUMNS}
            />
          ) : (
            <ImpactChart items={impactData.slice(0, 4)} period={props.period} />
          )
        }
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock, impactData, impactLoading]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (props.expandedBlock) {
      case "locations":
        view = locations;
        break;
      case "sdgs":
        view = sdgs;
        break;
      case "networks":
        view = networks;
        break;
      case "sectors":
        view = sectors;
        break;
      case "impact":
        view = impact;
        break;
      default:
        break;
    }

    return <React.Fragment>{view}</React.Fragment>;
  }, [props.expandedBlock, locations, sdgs, networks, impact, sectors]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );
  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );
  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sectors"),
    [sectors]
  );
  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  return (
    <Grid container spacing={{ xs: "20px", md: "30px" }}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={props.expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} lg={5}>
        {overview}
      </Grid>
      <Grid item xs={12} lg={7}>
        {props.expandedBlock !== "locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {props.expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              display: "none",
            },
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        {props.expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={9.9}>
        {props.expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={2.1}>
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              display: "none",
            },
          }}
        >
          <CtaCommonBtn
            label="See Data Insights Overview"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
