import React from "react";
import { colors } from "app/theme";
import { Link } from "react-router-dom";

export interface GenericPageBlockItemProps {
  title: string;
  description: string | React.ReactNode;
  content: React.ReactNode | React.ReactNode[];
  rightToolbar?: React.ReactNode;
  toolbarShare?: boolean;
  toolbarExpand?: boolean;
  onExpand?: () => void;
  expanded?: boolean;
  height?: string;
  loading?: boolean;
  altLoading?: boolean;
  variant?: "explorer" | "publisher";
  shareLink?: string;
}

export const GenericPageBlockItemLocationDescription: React.FC<{
  items: {
    label: string;
    link: string;
  }[];
  period: string;
}> = (props) => {
  if (props.items.length === 0) {
    return (
      <React.Fragment>
        No countries found for period {props.period}.
      </React.Fragment>
    );
  }
  if (props.items.length === 1) {
    return (
      <React.Fragment>
        <Link to={props.items[0].link}>{props.items[0].label}</Link> was the
        largest recipient country in {props.period}.
      </React.Fragment>
    );
  }
  const countries = props.items.slice(0, props.items.length - 1).map((i) => (
    <React.Fragment key={i.label}>
      <Link to={i.link}>{i.label}</Link>
      {", "}
    </React.Fragment>
  ));

  return (
    <React.Fragment>
      {countries}
      and{" "}
      <Link to={props.items[props.items.length - 1].link}>
        {props.items[props.items.length - 1].label}
      </Link>{" "}
      were the largest recipient countries in {props.period}.
    </React.Fragment>
  );
};
