import React from "react";
import axios from "axios";
import get from "lodash/get";
import find from "lodash/find";
import uniq from "lodash/uniq";
import cryptoJS from "crypto-js";
import { colors } from "app/theme";
import isEqual from "lodash/isEqual";
import { useAuth0 } from "@auth0/auth0-react";
import { FileRejection } from "react-dropzone";
import { downloadFile } from "app/utils/downloadFile";
import { usePublisherUtils } from "app/hooks/usePublisherUtils";
import {
  unstable_usePrompt,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { useLocalStorage, useSessionStorage, useUpdateEffect } from "react-use";
import { PublisherBlockLayout } from "app/pages/publisher/components/publisher-block/layout";
import { PublisherBlockValidityReportPopupProps } from "app/pages/publisher/components/publisher-block/views/validityReportPopup";
import {
  ConvertedFile,
  ConvertedFileActivity,
} from "app/components/table/data";
import {
  FileValidationError,
  FileValidationActivity,
  FileValidationActivityError,
} from "app/pages/publisher/components/publisher-block/data";
import { useMediaQuery } from "@mui/material";

export const PublisherBlock: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const navigate = useNavigate();
  const params = useSearchParams()[0];
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const {
    createDataset,
    updateDataset,
    updateFileStatus,
    replaceFileWithEditVersion,
  } = usePublisherUtils();

  const [activeStep, setActiveStep] = useSessionStorage(
    "publisher-active-step",
    -1
  );
  const [fileContent, setFileContent] = useLocalStorage(
    "publisher-non-signed-in-user-file-content",
    ""
  );
  const setFileASAWorkspaceDir = useLocalStorage(
    "publisher-non-signed-in-user-file-asa-workspace-dir",
    ""
  )[1];
  const setFileDetails = useLocalStorage(
    "publisher-non-signed-in-user-file-details",
    {
      filename: "",
      orgRef: "",
      type: "",
    }
  )[1];

  const storedUser = useStoreState((state) => get(state.user, "data.data", {}));
  const groupId = get(storedUser, "groupId");
  const publisherId = get(storedUser, "app_metadata.publisherID", "");
  const apiKey = get(storedUser, "app_metadata.registryApiKey", "");
  const orgRef = get(
    storedUser,
    "app_metadata.publisherInfo.publisher_iati_id",
    ""
  );
  const intercomUserId = useStoreState((state) =>
    get(state.IntercomUser, "data.data.id", "")
  );

  const [fileURL, setFileURL] = React.useState("");
  const [filename, setFilename] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [token, setToken] = React.useState<string | null>(null);
  const [preVizPopupOpen, setPreVizPopupOpen] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [uploadViewMessage, setUploadViewMessage] = React.useState("");
  const [nextButtonPopupOpen, setNextButtonPopupOpen] = React.useState(false);
  const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
  const [uploadSuccess, setUploadSuccess] = React.useState<boolean | null>(
    null
  );
  const [validityReportPopupOpen, setValidityReportPopupOpen] = React.useState<
    string | null
  >(null);
  const [convertedFiles, setConvertedFiles] = React.useState<ConvertedFile[]>(
    []
  );
  const [convertedFileActivities, setConvertedFileActivities] = React.useState<
    ConvertedFileActivity[]
  >([]);
  const [validationReportData, setValidationReportData] = React.useState<{
    summary: {
      error: number;
      warning: number;
      critical: number;
    };
    activities: FileValidationActivity[];
    categories: string[];
  }>({
    summary: {
      error: 0,
      warning: 0,
      critical: 0,
    },
    activities: [],
    categories: [],
  });
  const [modal, setModal] = React.useState<{
    open: boolean;
    title: string;
    message: React.ReactNode;
  }>({
    open: false,
    title: "",
    message: "",
  });

  const fetchPublisherConvertedFiles = useStoreActions(
    (actions) => actions.PublisherConvertedFiles.authGetFetch
  );
  const dataPublisherConvertedFiles = useStoreState(
    (state) => state.PublisherConvertedFiles.data
  );
  const fetchPublisherConvertedFileActivities = useStoreActions(
    (actions) => actions.PublisherConvertedFileActivities.authGetFetch
  );
  const dataPublisherConvertedFileActivities = useStoreState(
    (state) => state.PublisherConvertedFileActivities.data
  );
  const clearPublisherConvertedFileActivities = useStoreActions(
    (actions) => actions.PublisherConvertedFileActivities.clear
  );
  const fetchPublisherConvertedFileValidationReport = useStoreActions(
    (actions) => actions.PublisherConvertedFileValidationReport.fetch
  );
  const dataPublisherConvertedFileValidationReport = useStoreState((state) =>
    get(state.PublisherConvertedFileValidationReport, "data.content", "")
  );

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  const loadFiles = async () => {
    const t = await getAccessTokenSilentlyWithState();
    fetchPublisherConvertedFiles({ values: { token: t, groupId } });
  };

  const convertFiles = async () => {
    let type = "activity";
    if (
      selectedFiles.length > 0 &&
      selectedFiles[0].name.indexOf("organisation-") > -1
    ) {
      type = "organisation";
    }
    let orgRefLocal = "";
    if (!orgRefLocal) {
      if (type === "activity") {
        const mainFile = find(
          selectedFiles,
          (file) => file.name === "main.csv"
        );
        if (mainFile) {
          orgRefLocal = await mainFile.text().then((text) => {
            const lines = text.split("\n");
            return get(get(lines, "[1]", "").split(","), "[20]", "");
          });
        }
      } else if (type === "organisation") {
        const mainFile = find(
          selectedFiles,
          (file) => file.name === "organisation.csv"
        );
        if (mainFile) {
          orgRefLocal = await mainFile.text().then((text) => {
            const lines = text.split("\n");
            return get(get(lines, "[1]", "").split(","), "[0]", "");
          });
        }
      }
    }
    let encryptedValues = {};
    if (!isAuthenticated) {
      encryptedValues = cryptoJS.AES.encrypt(
        JSON.stringify({ filename, type, orgRef: orgRefLocal }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      await axios
        .get(`${process.env.REACT_APP_ASA_API}/convert/anonymous`, {
          params: {
            payload: encryptedValues,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUploading(false);
          if (response.status === 200) {
            const content = get(response, "data.content", "");
            setUploadSuccess(true);
            setUploadViewMessage(
              `Upload of the ${selectedFiles.length} file${
                selectedFiles.length > 1 ? "s" : ""
              } is successful!`
            );
            setFileContent(content);
            setFileASAWorkspaceDir(get(response, "data.workspaceDir", ""));
            setFileDetails({ filename, orgRef: orgRefLocal, type });
            downloadFile(filename, "xml", content ?? fileContent);
          }
        })
        .catch((error) => {
          setUploadViewMessage(
            `Upload of the ${selectedFiles.length} file${
              selectedFiles.length > 1 ? "s" : ""
            } is unsuccessful..`
          );
          setUploading(false);
          console.error(error);
        });
      return;
    }
    let email = user?.email;
    if (!email) {
      email = get(storedUser, "email", "");
    }
    let userID = user?.sub;
    if (!userID) {
      userID = get(storedUser, "user_id", "");
    }
    encryptedValues = cryptoJS.AES.encrypt(
      JSON.stringify({
        filename,
        orgRef: orgRefLocal,
        orgType: "",
        orgName: "",
        // orgType: get(
        //   storedUser,
        //   "app_metadata.publisherInfo.publisher_organization_type",
        //   ""
        // ),
        // orgName: get(storedUser, "app_metadata.publisherInfo.name", ""),
        email,
        userID,
        type,
        groupId,
        intercomUserId,
      }),
      process.env.REACT_APP_ENCRYPTION_SECRET as string
    ).toString();
    await getAccessTokenSilentlyWithState().then(async (token) => {
      await axios
        .get(`${process.env.REACT_APP_ASA_API}/convert`, {
          params: {
            payload: encryptedValues,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUploading(false);
          if (response.status === 200) {
            setUploadSuccess(true);
            setUploadViewMessage(
              `Upload of the ${selectedFiles.length} file${
                selectedFiles.length > 1 ? "s" : ""
              } is successful!`
            );
            const fileURL = get(response, "data.files[0]", "");
            setFileURL(fileURL);
            const splits = fileURL.split("/");
            const key = [
              get(splits, "[3]", ""),
              get(splits, "[4]", ""),
              get(splits, "[5]", ""),
              get(splits, "[6]", ""),
            ].join("/");
            const validationKey = [
              get(splits, "[3]", "")
                .replace("activities", "validation")
                .replace("organisation", "validation"),
              get(splits, "[4]", ""),
              get(splits, "[5]", ""),
              get(splits, "[6]", "").replace(".xml", ".json"),
            ].join("/");
            fetchPublisherConvertedFileActivities({
              values: {
                token,
                key,
                validationKey,
              },
            });
            fetchPublisherConvertedFileValidationReport({
              values: { token },
              filterString: `key=${validationKey}`,
            });
            loadFiles();
            setActiveStep(activeStep + 1);
          }
        })
        .catch((error) => {
          setUploadViewMessage(
            `Upload of the ${selectedFiles.length} file${
              selectedFiles.length > 1 ? "s" : ""
            } is unsuccessful..`
          );
          setUploading(false);
          console.error(error);
        });
    });
  };

  const uploadFiles = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((f) => {
      let file = f;
      let filename = file.name;
      if (
        filename.indexOf(".xml") > -1 &&
        filename.indexOf(".iati.xml") === -1
      ) {
        filename = filename.replace(".xml", ".iati.xml");
        file = new File([file], filename);
        formData.append(filename, file);
      } else {
        formData.append(filename, file);
      }
    });
    await getAccessTokenSilentlyWithState().then(async (token) => {
      await axios
        .post(`${process.env.REACT_APP_ASA_API}/files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            await convertFiles();
          } else {
            setUploadViewMessage(
              `Upload of the ${selectedFiles.length} file${
                selectedFiles.length > 1 ? "s" : ""
              } is unsuccessful..`
            );
          }
        })
        .catch((error) => {
          setUploadViewMessage(
            `Upload of the ${selectedFiles.length} file${
              selectedFiles.length > 1 ? "s" : ""
            } is unsuccessful..`
          );
          setUploading(false);
          console.error(error);
        });
    });
  };

  const onNextClick = () => {
    if (!uploading) {
      if (activeStep === 0 && selectedFiles.length > 0 && !uploadSuccess) {
        setUploading(true);
        setUploadViewMessage(
          `Uploading ${selectedFiles.length} file${
            selectedFiles.length > 1 ? "s" : ""
          }, please wait...`
        );
        uploadFiles(selectedFiles);
      } else if (activeStep === 0 && !isAuthenticated) {
        // bypass the validation step for non-authenticated users
        setActiveStep(activeStep + 2);
      } else {
        if (activeStep === 2) loadFiles();
        setActiveStep(activeStep + 1);
      }
    }
  };

  const onBackClick = () => {
    if (!uploading) {
      if (activeStep === 2 && !isAuthenticated) {
        setActiveStep(activeStep - 2);
      } else {
        setActiveStep(activeStep - 1);
      }
    }
  };

  const isNextActive = React.useMemo(() => {
    if (uploading) return false;
    if (activeStep === 0)
      return Boolean(
        selectedFiles.length > 0 && filename && filename.length > 0
      );
    return !nextButtonPopupOpen;
  }, [activeStep, selectedFiles, uploading, nextButtonPopupOpen, filename]);

  const isBackActive = React.useMemo(() => {
    if (uploading) return false;
    return true;
  }, [uploading]);

  const handlePreVizPopupOpenChange = (value: boolean) => () => {
    setPreVizPopupOpen(value);
  };

  const handleValidityReportOpenChange = (value: string | null) => {
    setValidityReportPopupOpen(value);
  };

  const handleConvertedFilePublishStatusChange = async (
    fileId: string,
    value: boolean,
    isValid: boolean,
    registryFileId?: string,
    isAdmin?: boolean
  ) => {
    if (!isAdmin) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            You need to be an{" "}
            <span style={{ color: colors.primary.green }}>Admin</span> to
            publish a data file.
          </React.Fragment>
        ),
      });
      return;
    }
    if (!isValid) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            <span style={{ color: colors.secondary.red }}>Invalid</span> files
            needs to be validated. Till then we can save your data file as{" "}
            <span style={{ color: colors.secondary.orange }}>Draft</span>. Edit
            your file to{" "}
            <span style={{ color: colors.primary.blue }}>publish</span>.
          </React.Fragment>
        ),
      });
      return;
    }
    if (!apiKey) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            You need to{" "}
            <span style={{ color: colors.primary.blue }}>connect</span> to the
            IATI registry to publish your data file.
          </React.Fragment>
        ),
      });
      return;
    }
    const file = find(convertedFiles, { id: fileId });
    let dataset = null;
    let registryId = "";
    if (file) {
      if (!registryFileId) {
        dataset = await createDataset({
          name: `${publisherId}-${file.title}`,
          title: file.title,
          fileURL: file.url,
          iatiVersion: file.iatiVersion,
          publisherId: orgRef,
          publishStatus: value,
          activityCount: file.activityCount,
          type: file.type === "Activity" ? "activity" : "organisation",
        });
        registryId = dataset.result.id;
      } else {
        dataset = await updateDataset({
          id: registryFileId,
          publishStatus: value,
          iatiVersion: file.iatiVersion,
          activityCount: file.activityCount,
          type: file.type === "Activity" ? "activity" : "organisation",
        });
        registryId = registryFileId;
      }
      if (dataset) {
        updateFileStatus({
          fileId,
          registryId,
          publishStatus: value,
        });
      }
    }
  };

  const resetModal = () => {
    setModal({
      open: false,
      title: "",
      message: "",
    });
  };

  const clear = () => {
    clearPublisherConvertedFileActivities();
    setSelectedFiles([]);
    setRejectedFiles([]);
    setUploadSuccess(null);
    setUploadViewMessage("");
    setFileContent("");
    setFileASAWorkspaceDir("");
    setFileURL("");
    setFilename("");
  };

  const onToolbarButtonClick = () => {
    clear();
    if (activeStep > -1) {
      setActiveStep(-1);
    } else {
      setActiveStep(0);
    }
  };

  const getValidationReportData = (data: string) => {
    let content;
    try {
      content = JSON.parse(data);
    } catch (error) {
      console.error(error);
    }
    if (content) {
      const newCategories: string[] = [];
      const newActivities: FileValidationActivity[] = content.errors?.map(
        (activity: FileValidationActivity) => {
          const { errors } = activity;
          const activitySummary = {
            critical: 0,
            error: 0,
            warning: 0,
          };
          if (errors) {
            errors.forEach((error1: FileValidationActivityError) => {
              if (error1.errors) {
                error1.errors.forEach((error2: FileValidationError) => {
                  switch (error2.severity) {
                    case "critical":
                      activitySummary.critical += 1;
                      break;
                    case "error":
                      activitySummary.error += 1;
                      break;
                    case "warning":
                      activitySummary.warning += 1;
                      break;
                    default:
                      break;
                  }
                });
              }
              newCategories.push(error1.category);
            });
          }
          return {
            ...activity,
            summary: activitySummary,
          };
        }
      );
      return {
        summary: get(content, "summary", {
          error: 0,
          warning: 0,
          critical: 0,
        }),
        activities: newActivities,
        categories: uniq(newCategories),
      };
    }
    return null;
  };

  const usePropmtWhen = React.useMemo(() => {
    switch (activeStep) {
      case 0:
        return selectedFiles.length > 0;
      case 1:
        return convertedFileActivities.length > 0;
      case 2:
        return convertedFiles.length > 0;
      default:
        return uploading;
    }
  }, [
    uploading,
    activeStep,
    selectedFiles,
    convertedFiles,
    convertedFileActivities,
  ]);

  unstable_usePrompt({
    message:
      "Are you sure you want to leave this page?\nYour unsaved data will be lost.",
    when: usePropmtWhen,
  });

  React.useEffect(() => {
    if (isAuthenticated) {
      loadFiles();
    }
    return () => {
      if (activeStep === 3) {
        sessionStorage.setItem("publisher-active-step", "-1");
        clearPublisherConvertedFileActivities();
      }
    };
  }, []);

  React.useEffect(() => {
    if (!uploading && activeStep === 1 && selectedFiles.length > 0) {
      setUploadViewMessage(
        `${selectedFiles.length} file${
          selectedFiles.length > 1 ? "s" : ""
        } selected`
      );
    }
  }, [selectedFiles]);

  React.useEffect(() => {
    const fileId = params.get("fileId");
    if (fileId) {
      const fFile = find(convertedFiles, { id: fileId });
      if (fFile) {
        setFilename(fFile.title);
      }
    }
  }, [params.get("fileId"), convertedFiles]);

  useUpdateEffect(() => {
    setConvertedFiles(
      get(dataPublisherConvertedFiles, "files", []).map((file: any) => ({
        id: file.id,
        date: file.createdDate,
        title: file.fileName,
        type: file.type === "activity" ? "Activity" : "Organisation",
        status: file.validation.isValid ? "Valid" : "Invalid",
        isPublished: file.published,
        activityCount: file.count,
        url: file.path,
        iatiVersion: file.iatiVersion,
        editVersion: file.editVersion,
        registryId: file.registryId,
      }))
    );
  }, [dataPublisherConvertedFiles]);

  useUpdateEffect(() => {
    const newConvertedFileActivities = get(
      dataPublisherConvertedFileActivities,
      "data",
      []
    );
    if (!isEqual(convertedFileActivities, newConvertedFileActivities)) {
      setConvertedFileActivities(newConvertedFileActivities);
    }
  }, [dataPublisherConvertedFileActivities]);

  useUpdateEffect(() => {
    const data = getValidationReportData(
      dataPublisherConvertedFileValidationReport
    );
    if (data) {
      setValidationReportData(data);
    }
  }, [dataPublisherConvertedFileValidationReport]);

  const reportData: {
    errors?: PublisherBlockValidityReportPopupProps["errors"];
    items?: PublisherBlockValidityReportPopupProps["items"];
  } = React.useMemo(() => {
    if (!validityReportPopupOpen) {
      return {
        errors: undefined,
        items: undefined,
      };
    }
    let activities = validationReportData.activities;
    if (activities.length === 0) {
      activities =
        getValidationReportData(dataPublisherConvertedFileValidationReport)
          ?.activities ?? [];
    }
    const fActivity = find(activities, {
      identifier: validityReportPopupOpen,
    });
    if (!fActivity) {
      return {
        errors: undefined,
        items: undefined,
      };
    }
    const errors = [
      {
        type: "Critical Error",
        count: 0,
        color: colors.secondary.red,
      },
      {
        type: "Error",
        count: 0,
        color: colors.secondary.orange,
      },
      {
        type: "Warning",
        count: 0,
        color: colors.secondary.purple,
      },
    ];
    fActivity.errors.forEach((error) => {
      error.errors.forEach((error1) => {
        switch (error1.severity) {
          case "critical":
            errors[0].count += 1;
            break;
          case "error":
            errors[1].count += 1;
            break;
          case "warning":
            errors[2].count += 1;
            break;
          default:
            break;
        }
      });
    });
    const items: PublisherBlockValidityReportPopupProps["items"] = [
      {
        name: fActivity.title,
        counts: errors,
        items: [],
      },
    ];
    const severity = {
      critical: "Critical Error",
      error: "Error",
      warning: "Warning",
    };
    const severityColor = {
      critical: colors.secondary.red,
      error: colors.secondary.orange,
      warning: colors.secondary.purple,
    };
    fActivity.errors.forEach((error) => {
      error.errors.forEach((error1) => {
        items[0].items.push({
          type: get(severity, error1.severity, error1.severity),
          name: error1.message,
          info: error1.context[0].text,
          color: get(severityColor, error1.severity, "#fff"),
        });
      });
    });
    return { errors, items };
  }, [validationReportData, validityReportPopupOpen]);

  return (
    <PublisherBlockLayout
      onToolbarButtonClick={onToolbarButtonClick}
      handlePreVizPopupOpenChange={handlePreVizPopupOpenChange}
      onBackClick={onBackClick}
      onNextClick={onNextClick}
      isNextActive={isNextActive}
      isBackActive={isBackActive}
      nextButtonPopupOpen={nextButtonPopupOpen}
      setNextButtonPopupOpen={setNextButtonPopupOpen}
      clear={clear}
      filename={filename}
      uploading={uploading}
      setFilename={setFilename}
      uploadViewMessage={uploadViewMessage}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      rejectedFiles={rejectedFiles}
      setRejectedFiles={setRejectedFiles}
      convertedFileActivities={convertedFileActivities}
      handleValidityReportOpenChange={handleValidityReportOpenChange}
      convertedFiles={convertedFiles}
      handleConvertedFilePublishStatusChange={
        handleConvertedFilePublishStatusChange
      }
      preVizPopupOpen={preVizPopupOpen}
      fileURL={fileURL}
      reportData={reportData}
      validityReportPopupOpen={validityReportPopupOpen}
      modal={modal}
      setModal={setModal}
      resetModal={resetModal}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  );
};
