import React from "react";
import * as turf from "@turf/turf";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import ReactDOMServer from "react-dom/server";
import { useSessionStorage } from "react-use";
import { useNavigate } from "react-router-dom";
import { TooltipComponentOption } from "echarts";
import Typography from "@mui/material/Typography";
import { CanvasRenderer } from "echarts/renderers";
import { formatLocale } from "app/utils/formatLocale";
import { ZoomWidget } from "app/components/zoom-widget";
import { colors, customBreakpointsMax } from "app/theme";
import { GeomapChartProps } from "app/components/charts/geomap/data";
import { GeomapChartTooltip } from "app/components/charts/geomap/tooltip";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import {
  TooltipComponent,
  VisualMapComponent,
  VisualMapComponentOption,
} from "echarts/components";
import {
  MapSeriesOption,
  MapChart as EChartsMap,
  ScatterChart,
  ScatterSeriesOption,
} from "echarts/charts";

echarts.use([
  EChartsMap,
  ScatterChart,
  TooltipComponent,
  CanvasRenderer,
  VisualMapComponent,
]);

const Tooltip: React.FC<any> = (props) => {
  return (
    <div
      style={{
        gap: "8px",
        width: "230px",
        lineHeight: 1.2,
        display: "flex",
        flexDirection: "column",
        color: colors.text.title,
      }}
    >
      <div
        style={{
          fontSize: "12px",
          maxWidth: "100%",
          fontWeight: "700",
          whiteSpace: "wrap",
        }}
      >
        {props.data.activityId}
      </div>
      <div style={{ fontSize: "12px", maxWidth: "100%", whiteSpace: "wrap" }}>
        {props.data.activityTitle}
      </div>
    </div>
  );
};

export const GeomapChart: React.FC<GeomapChartProps> = (
  props: GeomapChartProps
) => {
  const navigate = useNavigate();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [geoJson, setGeoJson] = React.useState<any>(null);
  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "geomap-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    code: string;
    label: string;
    value: number;
    count: number;
  } | null>("geomap-tooltip", null);
  const [zoom, setZoom] = React.useState({
    zoom: props.zoom ?? 1,
    roam: props.roam ?? false,
  });
  const [fullScreen, setFullScreen] = React.useState(false);
  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  const mapColors = [
    colors.shades.blue[200],
    colors.shades.blue[400],
    colors.shades.blue[500],
    colors.shades.blue[600],
  ];

  function calculateCentroid(coordinates: [number, number][]) {
    let x = 0,
      y = 0;
    let signedArea = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      const [x0, y0] = coordinates[i];
      const [x1, y1] = coordinates[i + 1];

      const a = x0 * y1 - x1 * y0;
      signedArea += a;

      x += (x0 + x1) * a;
      y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    x /= 6 * signedArea;
    y /= 6 * signedArea;

    return [x, y];
  }

  React.useEffect(() => {
    if (!geoJson) {
      fetch("/static/world.geo.json")
        .then((res) => res.json())
        .then((data) => {
          setGeoJson({
            ...data,
            features: data.features.filter(
              (feature: any) => feature.id !== "ATA"
            ),
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    if (containerRef.current && geoJson) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "canvas",
      });

      echarts.registerMap("World", geoJson);

      const getCenter = (name: string) => {
        const feature = geoJson.features.find(
          (f: any) => f.properties.name === name
        );

        if (feature) {
          const centroid = calculateCentroid(feature.geometry.coordinates[0]);
          return centroid;
        }
        return [0, 0];
      };

      const sizes = [0, ...props.data.map((d) => d.value)];

      // height to width ratio

      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      const sizeRatio = 0.45;

      const responsiveHeight = sizeRatio * width;
      const responsiveWidth = height * (1 / sizeRatio);

      const newHeight = responsiveHeight > height ? height : responsiveHeight;
      const newWidth = responsiveHeight > height ? responsiveWidth : width;

      const top = height - newHeight > 0 ? (height - newHeight) / 2 : 0;
      const left = width - newWidth > 0 ? (width - newWidth) / 2 : 0;

      const geoData = {
        map: "World",
        roam: zoom.roam,
        top: top,
        left: left,
        right: 0,
        bottom: 20,
        width: newWidth,
        height: newHeight,
        center: props.focusCountry ? getCenter(props.focusCountry) : undefined,
        zoom: zoom.zoom,
        cursor: props.showTooltip ? "pointer" : "default",
        itemStyle: {
          borderWidth: zoom.zoom > 5 ? 1.3 : 0.8,
          borderColor: colors.text.title,
        },
        emphasis: {
          disabled: !props.showTooltip,
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: "#cdd4df",
          },
        },
        select: {
          disabled: true,
        },
      };

      let scatterData = props.scatterData?.map((i) => {
        return {
          value: [i.lat, i.lng],
          symbolSize: 4,
          itemStyle: {
            opacity: 1,
            color: "#ED6060",
          },
          // name: i.name,
          activityId: i.activityId,
          activityTitle: i.activityTitle,
        };
      });
      if (props.focusCountry) {
        scatterData = scatterData?.filter((i) => {
          const polygon = geoJson.features.find(
            (f: any) => f.properties.name === props.focusCountry
          );
          if (polygon) {
            try {
              const point = turf.point(i.value);
              const isInside = turf.booleanPointInPolygon(point, polygon);
              return isInside;
            } catch (e) {
              return false;
            }
          }
          return false;
        });
      }

      const option: echarts.ComposeOption<
        | MapSeriesOption
        | VisualMapComponentOption
        | ScatterSeriesOption
        | TooltipComponentOption
      > = {
        visualMap: {
          left: "right",
          min: Math.min(...sizes),
          max: Math.max(...sizes),
          inRange: {
            color: mapColors,
          },
          text: ["High", "Low"],
          calculable: true,
          show: false,
        },
        geo: geoData,
        series: [
          {
            cursor: props.showTooltip ? "pointer" : "default",
            type: "map",
            data: props.data,
            map: "World",
            geoIndex: 0,
            select: {
              disabled: true,
            },
            tooltip: {
              show: false,
            },
          },
          props.showScatter
            ? {
                type: "scatter",
                cursor: "pointer",
                coordinateSystem: "geo",
                data: scatterData,
                encode: {
                  value: 2,
                },
                itemStyle: {
                  color: "red", // Change color of the points
                },
                tooltip: {
                  show: true,
                  borderWidth: 1,
                  backgroundColor: colors.secondary.lightGrey,
                  borderColor: colors.secondary.lightGrayText,
                  formatter: (params: any) => {
                    return ReactDOMServer.renderToString(
                      <Tooltip {...params} />
                    );
                  },
                },
              }
            : {},
        ],
        tooltip: {
          show: true,
        },
      };

      chart.setOption(option);

      chart.on("click", (params: any) => {
        if (params.componentSubType === "scatter") {
          navigate(`/explorer/activities/${params.data.activityId}`);
          return;
        }
        const locked = sessionStorage.getItem("geomap-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("geomap-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.iso2 === gtooltip?.code &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              code: params.data?.iso2 ?? params.data?.name,
              label: params.data?.full_name ?? params.data?.name,
              value:
                params.componentSubType === "scatter"
                  ? params.data.newValue
                  : params.data.value,
              count: params.data?.count ?? 0,
            });
            setIsLocked(true);
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            code: params.data?.iso2 ?? params.data?.name,
            label: params.data?.full_name ?? params.data?.name,
            value:
              params.componentSubType === "scatter"
                ? params.data.newValue
                : params.data.value,
            count: params.data?.count ?? 0,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });

      setStateChart(chart);
    }
  }, [
    containerRef.current,
    containerRef.current?.clientWidth,
    containerRef.current?.clientHeight,
    props.data,
    geoJson,
    props.focusCountry,
    zoom,
    fullScreen,
  ]);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "geomap-chart",
  });

  return (
    <Box
      sx={{
        width: fullScreen ? "100vw" : "auto",
        position: fullScreen ? "fixed" : "relative",
        background: colors.primary.white,
        zIndex: fullScreen ? 1001 : 0,
        // height: fullScreen ? "100vh" : "calc(100% - 35px)",
        top: 0,
        left: 0,
        [customBreakpointsMax.mobile]: {
          width: "100%",
        },
      }}
    >
      {props.showZoomWidget && (
        <Box
          sx={{
            position: "absolute",
            top: fullScreen ? "20px" : 0,
            right: fullScreen ? "20px" : 0,
            zIndex: 1,
            height: fullScreen ? "90%" : "100%",
          }}
        >
          <ZoomWidget
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            zoomProperties={zoom}
            setZoomProperties={setZoom}
            defaultZoom={props.zoom ?? 1}
            focusCountry={props.focusCountry}
          />
        </Box>
      )}
      <Box
        id="geomap-chart"
        ref={containerRef}
        width="100%"
        height={fullScreen ? "100vh" : props.height ?? "450px"}
        data-cy="geomap-chart"
      />
      {props.showLegend && (
        <Box
          sx={{
            right: fullScreen ? "41px" : 0,
            bottom: fullScreen ? "32px" : 0,
            width: "350px",
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            "@media (max-width: 768px)": {
              width: "80%",
            },
          }}
        >
          <Typography fontSize="12px">Total commitment in US$</Typography>
          <Box
            sx={{
              width: "100%",
              height: "14px",
              display: "flex",
              padding: "0 7px",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "14px",
              justifyContent: "space-between",
              border: `1px solid ${colors.primary.blue}`,
              background: `linear-gradient(90deg, ${mapColors[0]} 5.78%, ${
                mapColors[mapColors.length - 1]
              } 93.75%)`,
            }}
          >
            <Typography fontSize="12px">0</Typography>
            <Typography fontSize="12px" color={colors.primary.white}>
              {formatLocale(Math.max(...props.data.map((d) => d.value)))}
            </Typography>
          </Box>
        </Box>
      )}
      {props.showTooltip && tooltip && (
        <Box
          sx={{
            left: fullScreen ? "41px" : 0,
            bottom: fullScreen ? "32px" : 0,
            position: "absolute",
            width: "100%",
            [customBreakpointsMax.tablet]: {
              top: "calc(100% + 10px)",
            },
          }}
        >
          <GeomapChartTooltip
            isLocked={isLocked}
            label={tooltip.label}
            value={tooltip.value}
            variant={props.variant}
            buttonText="Country Page"
            subLabel="Incoming Funds"
            activityCount={tooltip.count}
            buttonLink={`/explorer/locations/${tooltip.code}`}
            lockUnlock={() => {
              setTooltip(null);
              setIsLocked(!isLocked);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
