import React from "react";

export const BookmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        stroke="#0C162A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BookmarkCheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        fill="#9DB6E9"
      />
      <path d="M7.49984 8.33333L9.1665 10L12.4998 6.66667" fill="#9DB6E9" />
      <path
        d="M7.49984 8.33333L9.1665 10L12.4998 6.66667M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        stroke="#0C162A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BookmarkMinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        fill="#F7F7F7"
      />
      <path
        d="M12.4998 8.33333H7.49984M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        stroke="#0C162A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BookmarkPlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99984 5.83333V10.8333M12.4998 8.33333H7.49984M15.8332 17.5L9.99984 14.1667L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        stroke="#0C162A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
