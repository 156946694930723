import { Home } from "app/pages/home";
import { Login } from "app/pages/login";
import { Page } from "app/components/page";
import { DebugPage } from "app/pages/debug";
import { DataHub } from "app/pages/data-hub";
import { Explorer } from "app/pages/explorer";
import { RouteObject } from "react-router-dom";
import { Publisher } from "app/pages/publisher";
import { UserGuide } from "app/pages/user-guide";
import { Redirect } from "app/components/redirect";
import { AuthCallback } from "app/pages/auth-callback";
import { DataServices } from "app/pages/data-services";
import { AccountProfile } from "app/pages/account-profile";
import { ExplorerSectors } from "app/pages/explorer/pages/sectors";
import { AuthenticatedRoute } from "app/router/authenticated-route";
import { ExplorerLocations } from "app/pages/explorer/pages/locations";
import { ExplorerActivities } from "app/pages/explorer/pages/activities";
import { ExplorerFavourites } from "app/pages/explorer/pages/favourites";
import { ExplorerOrganisations } from "app/pages/explorer/pages/organisations";
import { ExplorerSector } from "app/pages/explorer/pages/sectors/pages/sector";
import { ExplorerLocation } from "app/pages/explorer/pages/locations/pages/location";
import { ExplorerActivity } from "app/pages/explorer/pages/activities/pages/activity";
import { ExplorerOrganisation } from "app/pages/explorer/pages/organisations/pages/organisation";
import { Pricing } from "app/pages/pricing";

const NotFound: React.FC = () => (
  <div
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 91px - 256px - 150px)",
    }}
  >
    Page not found
  </div>
);

const REDIRECT_ROUTES: RouteObject[] = [
  {
    path: "/app/search-results",
    element: <Redirect to="/explorer" />,
  },
  {
    path: "/app",
    element: <Redirect to="/explorer" />,
  },
  {
    path: "/app/country/:id",
    element: <Redirect to="/explorer/locations" />,
  },
  {
    path: "/app/donor/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/publisher/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/organisation/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/activity/:id",
    element: <Redirect to="/explorer/activities" />,
  },
  {
    path: "/app/data-manager",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/data-manager/bulk-management",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/data-manager/profile-settings",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/validation-report/:orgRef/:uuid/:filename",
    element: <Redirect to="/data-hub" />,
  },
  {
    path: "/app/xml-file/:orgRef/:uuid/:filename",
    element: <Redirect to="/data-hub" />,
  },
  {
    path: "/app/about",
    element: <Redirect to="/about" />,
  },
  {
    path: "/app/faq",
    element: <Redirect to="/faq" />,
  },
  {
    path: "/app/contact",
    element: <Redirect to="/contact" />,
  },
  {
    path: "/app/bookmarks",
    element: <Redirect to="/explore/favourites" />,
  },
  {
    path: "/app/user-settings",
    element: <Redirect to="/account-profile" />,
  },
  {
    path: "/app/user-settings/:id",
    element: <Redirect to="/account-profile" />,
  },
  {
    path: "/knowledgeHub",
    element: <Redirect to="/user-guide" />,
  },
  {
    path: "/support",
    element: <Redirect to="/user-guide" />,
  },
  {
    path: "/services",
    element: <Redirect to="/data-services" />,
  },
  {
    path: "/plans",
    element: <Redirect to="/pricing" />,
  },
  {
    path: "/onboarding/:page?",
    element: <Redirect to="/login" />,
  },
  {
    path: "/",
    element: <Redirect to="/data-services" />,
  },
];

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Page />,
    errorElement: <NotFound />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/explorer", element: <Explorer /> },
      { path: "/explorer/locations", element: <ExplorerLocations /> },
      { path: "/explorer/locations/:id", element: <ExplorerLocation /> },
      { path: "/explorer/sectors/:id", element: <ExplorerSector /> },
      {
        path: "/explorer/organisations/:id",
        element: <ExplorerOrganisation />,
      },
      { path: "/explorer/organisations", element: <ExplorerOrganisations /> },
      { path: "/explorer/activities", element: <ExplorerActivities /> },
      { path: "/explorer/activities/:id", element: <ExplorerActivity /> },
      { path: "/explorer/sectors", element: <ExplorerSectors /> },
      { path: "/data-services", element: <DataServices /> },
      { path: "/data-hub", element: <DataHub /> },
      { path: "/user-guide", element: <UserGuide /> },
      // Auth
      { path: "/login", element: <Login /> },
      { path: "/callback", element: <AuthCallback /> },
      { path: "/pricing", element: <Pricing /> },
      { path: "/publisher", element: <Publisher /> },
      {
        path: "/account-profile",
        element: <AuthenticatedRoute children={<AccountProfile />} />,
      },
      {
        path: "/explorer/favourites",
        element: <AuthenticatedRoute children={<ExplorerFavourites />} />,
      },
      { path: "/debug", element: <DebugPage /> },
      ...REDIRECT_ROUTES,
      // 404
      { path: "*", element: <NotFound /> },
    ],
  },
];
