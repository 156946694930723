import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { CircleFlag } from "react-circle-flags";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { HomeIcon } from "app/assets/vectors/jsx/HomeIcon";
import { UpDownIcon } from "app/assets/vectors/jsx/UpDownIcon";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import { GenericTableColumn } from "app/components/generic-table/data";
import { BallTriangleIcon } from "app/assets/vectors/jsx/BallTriangleIcon";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";

export const LOCATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "location",
    headerName: "Location",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box
        display="flex"
        columnGap="8px"
        component={Link}
        alignItems="center"
        to={`/explorer/locations/${params.row.code}`}
      >
        <CircleFlag
          width={12}
          height={12}
          countryCode={params.row.code.toLowerCase()}
        />
        <Typography
          fontSize="12px"
          variant="subtitle1"
          color={colors.primary.blue}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "incomingFunds",
    headerName: "Incoming Funds",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "activities",
    headerName: "Activities",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "organisations",
    headerName: "Organisations",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "sectors",
    headerName: "Sectors",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
  },
];
