import React from "react";

const MenuIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.6667H18V10.6667H0V12.6667ZM0 7.66675H18V5.66675H0V7.66675ZM0 0.666748V2.66675H18V0.666748H0Z"
        fill="#0C162A"
      />
    </svg>
  );
};

export default MenuIcon;
