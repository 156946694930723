import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { ReactComponent as LaptopCat } from "app/assets/vectors/LaptopCat.svg";
import { LeftBlock } from "./components/left-block";
import { customBreakpointsMax } from "app/theme";
import { ReactComponent as AccountProfileHeaderIcon } from "app/assets/vectors/AccountProfileHeaderIcon.svg";
import { CanonicalUrl } from "app/components/canonical-url";

export const Login: React.FC = () => {
  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/login" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "20px",
            md: "30px",
            lg: "40px",
          },
        }}
      >
        <PageHeader
          variant="explorer"
          title="Account Profile"
          icon={<AccountProfileHeaderIcon />}
          subtitle="Sign up, manage your account settings and get notifications on your activity alerts."
        />

        <Typography
          variant="h2"
          fontSize={{ xs: "24px", lg: "36px" }}
          lineHeight="normal"
        >
          Sign In
        </Typography>

        <Grid
          container
          columnSpacing={{
            md: "30px",
            lg: "40px",
          }}
        >
          <Grid item xs={12} md={6}>
            <LeftBlock />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                padding: "30px 50px",
                background: "#ffffff",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                [customBreakpointsMax.tablet]: {
                  padding: "30px 20px",
                  flexDirection: "column-reverse",
                },
                [customBreakpointsMax.mobile]: {
                  display: "none",
                },
              }}
            >
              <Box>
                <Typography variant="h3" fontSize="24px" lineHeight="normal">
                  Sign in to AIDA to benefit from;
                </Typography>

                <Typography
                  variant="body1"
                  fontSize="14px"
                  lineHeight="20px"
                  marginTop={"10px"}
                >
                  3-step publishing process that makes publishing development
                  aid data easier than ever, and bookmarking the selected
                  activities and projects to follow the latest updates.
                </Typography>
              </Box>

              <Box
                sx={{
                  margin: "0 auto",
                  width: "max-content",
                  [customBreakpointsMax.tablet]: {
                    svg: {
                      width: "287px",
                      height: "auto",
                    },
                  },
                }}
              >
                <LaptopCat />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
