import { Box, Button, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import CheckYesIcon from "app/assets/vectors/jsx/CheckYesIcon";
import { CheckGoodIcon } from "app/assets/vectors/jsx/CheckGoodIcon";
import { colors, customBreakpointsMax } from "app/theme";
import React from "react";

const SelectedPlanBlock = () => {
  const planSummary = [
    "Explore + (SSO)",
    "Geomap Data View",
    "Activity Detail Pages",
    "Data Export",
    "User Management",
    "Data Management",
  ];
  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          lineHeight: "normal",
          [customBreakpointsMax.mobile]: {
            display: "none",
          },
        }}
        startIcon={<ArrowBack />}
      >
        Go Back to Plans
      </Button>{" "}
      <Box height={30} />
      <Box
        sx={{
          backgroundColor: colors.primary.white,
          padding: "30px",
          display: "flex",
          gap: "30px",
          [customBreakpointsMax.mobile]: {
            flexDirection: "column",
            backgroundColor: "transparent",
            padding: "0",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            flex: 1,
            gap: "20px",
            [customBreakpointsMax.tablet]: {
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              "> svg": {
                flexShrink: 0,
                [customBreakpointsMax.mobile]: {
                  width: "22px",
                  height: "22px",
                },
              },
            }}
          >
            <Typography
              variant="h2"
              fontSize={{
                xs: "24px",
                md: "36px",
              }}
              lineHeight={"normal"}
            >
              Payment successful!
            </Typography>
            <CheckYesIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "max-content",
              [customBreakpointsMax.mobile]: {
                display: "none",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                lineHeight: "normal",
                fontSize: "12px",
              }}
            >
              Go to pricing page
            </Button>
            <Button
              variant="contained"
              sx={{
                lineHeight: "normal",
                fontSize: "12px",
              }}
            >
              Go to my account
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.primary.blue}`,
            position: "relative",
            width: "321px",
            [customBreakpointsMax.mobile]: {
              width: "100%",
              border: "none",
              backgroundColor: colors.primary.white,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "34px",
              backgroundColor: "#FFC145",
              padding: "3px 14px 3px 15px",
              borderRadius: "0px 0px 4px 4px",
              color: colors.text.title,
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "normal",
            }}
          >
            Most popular
          </Box>

          <Typography
            variant="h4"
            fontSize={"18px"}
            lineHeight={"normal"}
            padding={"15px"}
          >
            Standard Plan
          </Typography>
          <Box
            sx={{
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                padding: "12px",
              }}
            >
              <Typography variant="h5" lineHeight={"20px"} fontSize={"14px"}>
                Plan summary
              </Typography>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {planSummary.map((summary) => (
                  <Box
                    key={summary}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CheckGoodIcon />
                    <Typography
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      color={colors.text.title}
                    >
                      {summary}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                padding: "12px",
              }}
            >
              <Typography variant="h5" lineHeight={"20px"} fontSize={"14px"}>
                Standard Plan per Year
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    backgroundColor: colors.shades.blue[100],
                    borderRadius: "27px",
                    fontSize: "24px",
                    span: {
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    lineHeight: "normal",
                    color: colors.text.title,
                    fontWeight: "bold",
                  }}
                >
                  <span>€</span>
                  3600
                  <span>{"/ month"}</span>
                </Box>
                <Typography
                  variant="h5"
                  lineHeight={"20px"}
                  fontSize={"14px"}
                  fontWeight={"400"}
                >
                  (2 Users)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "12px",
              }}
            >
              <Typography variant="h5" lineHeight={"20px"} fontSize={"14px"}>
                Standard Support
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    backgroundColor: colors.shades.blue[100],
                    borderRadius: "27px",
                    fontSize: "24px",
                    span: {
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    lineHeight: "normal",
                    color: colors.text.title,
                    fontWeight: "bold",
                  }}
                >
                  <span>€</span>
                  1750
                  <span>{"/ month"}</span>
                </Box>
                <InfoIcon />
              </Box>
            </Box>
            <Box
              sx={{
                padding: "12px",
              }}
            >
              <Typography variant="h5" lineHeight={"20px"} fontSize={"14px"}>
                Total per Year
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    backgroundColor: colors.text.title,
                    borderRadius: "27px",
                    fontSize: "24px",
                    span: {
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    lineHeight: "normal",
                    color: colors.primary.white,
                    fontWeight: "bold",
                  }}
                >
                  <span>€</span>
                  5350
                  <span>{"/ month"}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "none",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            [customBreakpointsMax.mobile]: {
              display: "flex",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              lineHeight: "normal",
              fontSize: "12px",
              width: "100%",
            }}
          >
            Go to pricing page
          </Button>
          <Button
            variant="contained"
            sx={{
              lineHeight: "normal",
              fontSize: "12px",
              width: "100%",
            }}
          >
            Go to my account
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedPlanBlock;
