import { Box, Typography } from "@mui/material";

import FeatureBlock from "../feature-block";
import { features } from "./data";

export const Features = () => {
  return (
    <Box>
      {features.map((feature) => (
        <FeatureBlock key={feature.title} feature={feature} />
      ))}
    </Box>
  );
};
