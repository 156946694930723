import { AccountProfileCard } from "app/components/account-profile-card";
import { Box, useMediaQuery } from "@mui/material";
import { Table } from "app/components/table";
import { OrganisationmemberColumns, OrganisationmemberData } from "./tableData";
import { customBreakpointsMax } from "app/theme";

export const OrganisationMembersSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  return (
    <AccountProfileCard
      title="Organisation"
      content={
        <Box
          sx={{
            [customBreakpointsMax.mobile]: {
              ".MuiDataGrid-cell--flex": {
                alignItems: "flex-start !important",
                paddingTop: "10px !important",
              },
            },
          }}
        >
          <Table
            columns={
              mobile
                ? OrganisationmemberColumns.slice(0, 1)
                : OrganisationmemberColumns
            }
            rows={OrganisationmemberData}
            hideHeader
            withAddFileButton
            withDeleteButton
            withSearchButton
            title="5  Members"
            getRowHeight={() => "auto"}
            onAddButtonClick={() => props.setActiveSetting?.("add-member")}
            toolBarBorderBottom
          />
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
