import React from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Intercom from "@intercom/messenger-js-sdk";

export const useIntercom = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [token, setToken] = React.useState<string | null>(null);

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  React.useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      getAccessTokenSilentlyWithState().then((t) => {
        axios
          .get(`${process.env.REACT_APP_API}/user/intercom-hash`, {
            headers: {
              Authorization: `Bearer ${t}`,
            },
          })
          .then((response) => {
            if (response.data.hash) {
              Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
                user_id: user.sub,
                name: user.name,
                email: user.email,
                created_at: user.created_at,
                user_hash: response.data.hash,
              });
            } else {
              console.error(response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    } else {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
      });
    }
  }, [user, isAuthenticated]);

  return;
};
