import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors, customBreakpointsMax } from "app/theme";
import { useNavigate } from "react-router-dom";

export interface HeaderExpandedViewProps {
  activeButton: string;
  handleButtonClick: (label: string) => void;
}

interface HeaderViewCommonBlockItemProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  link?: string;
}

export const HeaderViewCommonBlockItem: React.FC<
  HeaderViewCommonBlockItemProps
> = (props: HeaderViewCommonBlockItemProps) => {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");
  return (
    <Box
      sx={{
        gap: "10px",
        padding: "5px",
        display: "flex",
        cursor: "pointer",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid transparent",
        ":hover": {
          borderColor: colors.secondary.iconGray,
          background: colors.secondary.disableButton,
          h5: {
            color: colors.primary.blue,
          },
        },
        [customBreakpointsMax.tablet]: {
          border: "none",
          padding: 0,
          "&:hover": {
            border: "none",
            background: "none",
          },
        },
      }}
      onClick={() => {
        if (props.link) {
          navigate(props.link);
        }
      }}
      data-cy="header-view-common-block-item"
    >
      {!tablet && props.icon}
      <Box
        sx={{
          gap: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          [customBreakpointsMax.tablet]: {
            gap: 0,
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          margin="0"
          sx={{
            [customBreakpointsMax.tablet]: {
              color: colors.primary.blue,
              ":hover": {
                textDecoration: "underline",
              },
            },
          }}
        >
          {props.title}
        </Typography>
        {!mobile && <Typography fontSize="10px">{props.subtitle}</Typography>}
      </Box>
    </Box>
  );
};
