import React from "react";
import get from "lodash/get";
import filter from "lodash/filter";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import { formatNumber } from "app/utils/formatNumber";
import { PageHeader } from "app/components/page-header";
import { GeomapChart } from "app/components/charts/geomap";
import { CanonicalUrl } from "app/components/canonical-url";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { LOCATION_COLUMNS } from "app/pages/explorer/pages/locations/tableColumns";
import { ReactComponent as ExplorerLocationsHeaderIcon } from "app/assets/vectors/ExplorerLocationsHeaderIcon.svg";

const box = (
  <Box
    height={{
      xs: "20px",
      md: "30px",
      lg: "40px",
    }}
  />
);

export const ExplorerLocations: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  const navigate = useNavigate();

  const locationOfTheMonth = useStoreState((state) =>
    get(state.ExplorerLocationOfTheMonth, "data.data", {
      code: "",
      name: "",
    })
  );
  const fetchLocationOfTheMonth = useStoreActions(
    (actions) => actions.ExplorerLocationOfTheMonth.fetch
  );
  const locationOfTheMonthLoading = useStoreState(
    (state) => state.ExplorerLocationOfTheMonth.loading
  );
  const stats = useStoreState((state) =>
    get(state.ExplorerLocationStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      orgs: 0,
      leastFundedSector: "",
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerLocationStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerLocationStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerLocationsList, "data.data", [])
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerLocationsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerLocationsList.fetch
  );
  const map = useStoreState((state) =>
    get(state.ExplorerLocationMap, "data.data", {
      data: [],
      geoData: [],
      coveragePercentage: 0,
    })
  );
  const fetchMap = useStoreActions(
    (actions) => actions.ExplorerLocationMap.fetch
  );
  const mapLoading = useStoreState(
    (state) => state.ExplorerLocationMap.loading
  );

  const locationOfTheMonthRef = locationOfTheMonth.code;
  const locationOfTheMonthName = locationOfTheMonth.name;

  const LOCATIONS = React.useMemo(() => {
    return list.map((c: any) => ({
      ...c,
      location: c.name,
      incomingFunds: c.incomingFunds
        ? formatNumber(c.incomingFunds, true)
        : "-",
    }));
  }, [list]);

  React.useEffect(() => {
    fetchLocationOfTheMonth({});
  }, []);

  React.useEffect(() => {
    if (!locationOfTheMonthLoading && locationOfTheMonthRef !== "") {
      fetchStats({
        routeParams: {
          code: locationOfTheMonthRef,
          year: defaultYear,
          transactionTypeCode: "1",
        },
      });
      fetchList({
        filterString: `startDate=${defaultYear}-01-01T00:00:00.000Z&endDate=${defaultYear}-12-31T00:00:00.000Z`,
      });
      fetchMap({
        routeParams: { code: locationOfTheMonthRef },
        filterString: `startDate=${defaultYear}-01-01T00:00:00.000Z&endDate=${defaultYear}-12-31T00:00:00.000Z&transactionTypeCode=1`,
      });
    }
  }, [locationOfTheMonthRef, locationOfTheMonthLoading]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/explorer/locations" />
      <Box>
        <PageHeader
          variant="explorer"
          title="AIDA Explorer: Discover Locations"
          subtitle="Embark on a journey to discover the vast landscape of humanitarian efforts around the world. With AIDA's location-specific data, you'll gain insights into how aid is distributed and utilized in various nations. "
          icon={<ExplorerLocationsHeaderIcon />}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight="normal"
        >
          Explore the Location of the Month
        </Typography>
        {box}
        <ExploreListBlockItem
          icon={
            <CircleFlag
              width="22"
              height="22"
              countryCode={locationOfTheMonthRef.toLowerCase()}
            />
          }
          title="LOCATION OF THE MONTH"
          description={locationOfTheMonthName}
          loading={statsLoading || locationOfTheMonthLoading}
          leftItems={[
            {
              title: "Overview",
              link: `/explorer/locations/${locationOfTheMonthRef}`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {formatNumber(stats.disbursement, true)}
                  </span>{" "}
                  was disbursed to {locationOfTheMonthName} in {defaultYear}.
                </React.Fragment>
              ),
            },
            {
              title: "Results",
              link: `/explorer/locations/${locationOfTheMonthRef}?e=impact`,
              description: (
                <React.Fragment>
                  In {defaultYear}, available results show activities surpassed
                  about{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    4.8%
                  </span>{" "}
                  of their target results on average.
                </React.Fragment>
              ),
            },
            {
              title: "SUSTAINABLE DEVELOPMENT GOALS",
              link: `/explorer/locations/${locationOfTheMonthRef}?e=sdgs`,
              description: (
                <React.Fragment>
                  In {defaultYear},{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.sdgActivities} activities{" "}
                  </span>
                  were contributing to the SDGs.
                </React.Fragment>
              ),
            },
            {
              title: "NETWORKS",
              link: `/explorer/locations/${locationOfTheMonthRef}?e=networks`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.orgs} organisations{" "}
                  </span>
                  have published data on their development and humanitarian
                  resources and activities.
                </React.Fragment>
              ),
            },
            {
              title: "FUNDING Analysis",
              link: `/explorer/locations/${locationOfTheMonthRef}?e=budget`,
              description: (
                <React.Fragment>
                  Funding gaps analysis by sectors shows that{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.leastFundedSector}
                  </span>{" "}
                  was the least funded sector of {defaultYear}.
                </React.Fragment>
              ),
            },
          ]}
          rightContent={{
            title: "GEOSPATIAL DATA",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {map.coveragePercentage.toFixed(2)}% of the activities
                </span>{" "}
                have provided exact locations.
              </React.Fragment>
            ),
            loading: mapLoading || locationOfTheMonthLoading,
            content: (
              <GeomapChart
                roam
                zoom={8}
                showScatter
                showZoomWidget
                height={mobile ? "288px" : tablet ? "400px" : "319px"}
                data={filter(map.geoData, { iso2: locationOfTheMonthRef })}
                scatterData={map.data}
                focusCountry={locationOfTheMonthName}
              />
            ),
          }}
          buttonText="See the Location Page"
          buttonLink={`/explorer/locations/${locationOfTheMonthRef}`}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight="normal"
        >
          Explore All Locations & Search
        </Typography>
        {box}
        <ExploreListAllBlock
          rows={LOCATIONS}
          assetType="Location"
          loading={listLoading}
          columns={
            mobile
              ? [
                  LOCATION_COLUMNS[0],
                  LOCATION_COLUMNS[LOCATION_COLUMNS.length - 1],
                ]
              : LOCATION_COLUMNS
          }
          onRowClick={(row) => navigate(`/explorer/locations/${row.code}`)}
        />
      </Box>
    </React.Fragment>
  );
};
