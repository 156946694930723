import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { colors, customBreakpointsMax } from "app/theme";
import React from "react";
import { Link } from "react-router-dom";
import { ProductOfferCardProps } from "./data";

export const ProductOfferCard = (props: ProductOfferCardProps) => {
  const color =
    props.variant === "publisher" ? colors.primary.green : colors.primary.blue;
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        alignItems: "center",
        gap: "30px",
        background: colors.primary.white,
        flexDirection: "row",
        [customBreakpointsMax.mobile]: {
          flexDirection: "column",
          gap: "10px",
          padding: "10px",
        },
      }}
      data-cy="product-offer-card"
    >
      <Box>{props.image}</Box>
      <Box
        sx={{
          "> a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            columnGap: "6px",
            justifyContent: {
              xs: "center",
              md: "flex-end",
            },
          },
        }}
      >
        <Typography
          variant="h3"
          fontSize={{
            xs: "18px",
            md: "24px",
          }}
          lineHeight={"normal"}
          textAlign={{
            xs: "center",
            md: "left",
          }}
        >
          {props.title}
        </Typography>

        <Box
          sx={{
            marginTop: "15px",
            marginBottom: "15px",
            gap: "5px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.tags.map((tag) => (
            <Typography
              variant="h6"
              sx={{
                background: color,
                padding: "6px 12px",
                borderRadius: "29px",
                fontSize: {
                  xs: "10px",
                  md: "12px",
                },
                lineHeight: "normal",
                color: colors.primary.white,
              }}
            >
              {tag}
            </Typography>
          ))}
        </Box>
        <Link to={props.linkUrl || ""}>
          <Typography
            variant="h6"
            lineHeight={"normal"}
            fontSize={"12px"}
            color={colors.secondary.red}
          >
            {props.linkText}
          </Typography>

          <ArrowForwardIos
            sx={{
              width: "10px",
              height: "10px",
              color: colors.secondary.red,
            }}
          />
        </Link>
      </Box>
    </Box>
  );
};
