export interface BarChartProps {
  height?: string;
  barWidth: string;
  yAxisLabelName: string;
  xAxisLabelRotate?: number;
  data: {
    bars: string[];
    series: string[];
    values: number[][];
  };
  yAxisLabelFontSize?: number;
  xAxisLabelFontSize?: number;
}

export const BarChartStackedSampleData: BarChartProps["data"] = {
  bars: ["2018", "2019", "2020", "2021", "2022"],
  series: [
    "series1",
    "series2",
    "series3",
    "series4",
    "series5",
    "series6",
    "series7",
    "series8",
    "series9",
    "series10",
    "series11",
    "series12",
  ],
  values: [
    [80000, 52000, 55000, 60000, 80000], // series1 (bottom-most)
    [10000, 12000, 14000, 16000, 18000], // series2
    [8000, 9000, 10000, 11000, 12000], // series3
    [12000, 13000, 14000, 15000, 16000], // series4
    [6000, 7000, 8000, 9000, 10000], // series5
    [9000, 10000, 11000, 12000, 13000], // series6
    [15000, 16000, 17000, 18000, 19000], // series7
    [20000, 21000, 22000, 23000, 24000], // series8
    [25000, 26000, 27000, 28000, 29000], // series9
    [30000, 31000, 32000, 33000, 34000], // series10
    [35000, 36000, 37000, 38000, 39000], // series11
    [40000, 41000, 42000, 43000, 44000], // series12 (top-most)
  ],
};

export const BarChartSampleData: BarChartProps["data"] = {
  bars: [
    "Post-Secondary\n Education",
    "Health, General",
    "Basic Health",
    "Non-communicable\n diseases (NCDs)",
    "Water Supply\n & Sanitation",
  ],
  series: ["Productive Sectors"],
  values: [
    [600000, 800000, 750000, 350000, 800000], // series1
  ],
};
