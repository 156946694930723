import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";

export const YourDataHub: React.FC = () => {
  const content = React.useMemo(() => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontSize="14px">
          You have an active collaboration with 7 organisations.
        </Typography>
        <NetworkChart id="content-network-chart" data={NetworkSampleData} />
      </Box>
    );
  }, []);

  return (
    <GenericPageBlockItem
      title="Your data hub"
      description="Connect your data and visualise your impact"
      content={content}
      rightToolbar={
        <IconButton
          size="small"
          sx={{
            padding: "2px",
            background: colors.primary.green,
            ":hover": {
              background: colors.shades.green[200],
              "& svg": {
                color: colors.primary.green,
              },
            },
          }}
          data-cy="data-hub-external-link"
          href="/data-hub"
        >
          <ArrowOutward fontSize="small" htmlColor={colors.primary.white} />
        </IconButton>
      }
    />
  );
};
