import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ExploreHeroImage from "app/assets/images/publisher-hero-image.png";
import { useMediaQuery } from "@mui/material";

const ButtonStyle = {
  color: colors.primary.white,
  padding: {
    xs: "6px 10px",
    lg: "10px 15px",
  },
  width: {
    xs: "max-content",
    md: "210px",
    lg: "max-content",
  },
  fontSize: {
    xs: "12px",
    md: "14px",
    lg: "18px",
  },
  lineHeight: "normal",
};

export const Hero: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  return (
    <Grid
      container
      columnSpacing={"50px"}
      rowSpacing={"20px"}
      columns={100}
      sx={{
        padding: "20px 0",
      }}
      data-cy="publisher-landing-hero"
    >
      <Grid
        item
        xs={100}
        md={60}
        lg={45.6}
        sx={{
          "> img": {
            width: { xs: "90%", md: "100%" },
            height: "auto",
            margin: "0 auto",
            display: "block",
            marginTop: {
              xs: "20px",
              md: 0,
            },
            boxShadow: "0px 0.854px 11.952px 0px rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            display: {
              md: "none",
            },
          }}
          textAlign={"center"}
          fontSize={"24px"}
        >
          Maximise Funding Opportunities
        </Typography>
        <img src={ExploreHeroImage} alt="explore-hero-image" />
      </Grid>
      <Grid
        item
        xs={100}
        md={40}
        lg={54.4}
        sx={{
          "> img": {
            width: "100%",
            height: "auto",
          },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}
          fontSize={{
            md: "24px",
            lg: "40px",
          }}
        >
          Maximise Funding Opportunities
        </Typography>
        {!mobile && (
          <Box
            height={{
              xs: 20,
              md: 10,
            }}
          />
        )}
        <Typography
          variant="h4"
          fontSize={{
            xs: "10px",
            md: "14px",
            lg: "18px",
          }}
          textAlign={{
            xs: "center",
            md: "left",
          }}
          fontWeight="400"
          lineHeight={{
            xs: "normal",
            lg: "24px",
          }}
        >
          Take your fundraising efforts to new heights with AIDA Publisher.
          Effortlessly publish your project data in the global aid data IATI
          standard. Be a top priority for donors by presenting your information
          in a trusted and universally comprehensible format. Elevate your
          fundraising game today.
        </Typography>
        <Box
          height={{
            xs: 20,
            md: 30,
          }}
        />
        <Box
          sx={{
            columnGap: "20px",
            justifyContent: {
              xs: "center",
              md: "flex-start",
            },
            display: { xs: "flex", md: "none", lg: "flex" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              ...ButtonStyle,
              background: colors.primary.blue,
            }}
            endIcon={<ArrowForward />}
            component={Link}
            to="/explorer"
          >
            Explore Data
          </Button>

          <Button
            variant="contained"
            sx={{
              ...ButtonStyle,
              background: colors.primary.green,
            }}
            endIcon={<ArrowForward />}
            component={Link}
            to="/publisher"
          >
            Publish your Data
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={100}
        sx={{
          display: { xs: "none", md: "flex", lg: "none" },
          justifyContent: "center",
          gap: "50px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            ...ButtonStyle,
            background: colors.primary.blue,
          }}
          endIcon={<ArrowForward />}
          component={Link}
          to="/explorer"
        >
          Explore Data
        </Button>
        <Button
          variant="contained"
          sx={{
            ...ButtonStyle,
            background: colors.primary.green,
            ":hover": {
              background: colors.shades.green[400],
            },
          }}
          endIcon={<ArrowForward />}
          component={Link}
          to="/publisher"
        >
          Publish Your Data
        </Button>
      </Grid>
    </Grid>
  );
};
