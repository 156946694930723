import { Box, Button, TableContainer, Typography } from "@mui/material";
import { IDealLogo } from "app/assets/vectors/jsx/IDealLogo";
import { AccountProfileCard } from "app/components/account-profile-card";
import { Table } from "app/components/table";
import React from "react";
import { InvoiceColumns, Invoices } from "./tableData";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import { customBreakpointsMax } from "app/theme";

export const PaymentDetailsSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  return (
    <AccountProfileCard
      title="Payment Details"
      content={
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              [customBreakpointsMax.mobile]: {
                flexDirection: "column",
              },
            }}
          >
            <Typography
              width={"175px"}
              variant="h5"
              fontSize={"14px"}
              lineHeight={"20px"}
              marginTop={"5px"}
            >
              Payment Method
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "18px",
                  alignItems: "center",
                }}
              >
                <IDealLogo />
                <Typography
                  width={"175px"}
                  variant="h5"
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  fontWeight={"400"}
                >
                  ING (Direct Debit) ••3076{" "}
                </Typography>
              </Box>
              <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "normal",
                  minWidth: "unset",
                  marginTop: "10px",
                }}
                variant="contained"
                onClick={() =>
                  props.setActiveSetting?.("change-payment-method")
                }
              >
                Change Payment Method
              </Button>
            </Box>
          </Box>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              [customBreakpointsMax.mobile]: {
                flexDirection: "column",
              },
            }}
          >
            <Typography
              width={"175px"}
              variant="h5"
              fontSize={"14px"}
              lineHeight={"20px"}
              marginTop={"5px"}
            >
              Billing Information
            </Typography>
            <Box>
              <Typography
                variant="h5"
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"400"}
              >
                Boerhaavelaan 7,
                <br /> 2500 DL Den Haag, The Netherlands
              </Typography>

              <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "normal",
                  minWidth: "unset",
                  marginTop: "10px",
                }}
                variant="contained"
                onClick={() =>
                  props.setActiveSetting?.("change-billing-information")
                }
              >
                Change Billing Information
              </Button>
            </Box>
          </Box>
          <Box height={20} />
          <TableContainer
            id="explorer-table"
            sx={{
              maxHeight: "400px",
            }}
          >
            <Table
              rows={Invoices}
              columns={InvoiceColumns}
              title={
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  variant="h5"
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  gap={"10px"}
                >
                  Invoices <InfoIcon />
                </Typography>
              }
              getRowHeight={() => 40}
              toolBarBorderBottom
              withPrintButton
              withDowloadButton
            />
          </TableContainer>
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
      hideSaveButton
      extraButtons={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              lineHeight: "normal",
              minWidth: "unset",
              [customBreakpointsMax.mobile]: {
                width: "100%",
              },
            }}
            variant="contained"
          >
            Contact Us
          </Button>
          <Button
            sx={{
              fontSize: "12px",
              lineHeight: "normal",
              minWidth: "unset",
              [customBreakpointsMax.mobile]: {
                width: "100%",
                padding: "6px 6px",
              },
            }}
            variant="contained"
          >
            See Plans & Pricing
          </Button>
        </Box>
      }
    />
  );
};
