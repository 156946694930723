import React from "react";
import { Accord } from "../accord";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FAQs } from "../../data";
import { ChevronDown } from "app/assets/vectors/jsx/arrow";
import { colors } from "app/theme";

export const FAQS = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  return (
    <Accord
      title="Frequently Asked Questions"
      subtitle={`${
        !tablet ? "Got Questions? We've Got Answers!" : ""
      } Find solutions to common queries about AIDA and make the most out of your experience. From troubleshooting tips to detailed insights, we've compiled a comprehensive list to address your concerns. ${
        !tablet ? "Save time and explore the answers you need right here." : ""
      }`}
      id="aida-faqs"
    >
      <Box
        sx={{
          padding: "20px",
          paddingTop: "40px",
          width: "100%",
        }}
      >
        {FAQs.map((faq, index) => (
          <Box key={index}>
            {faq.title ? (
              <React.Fragment>
                <Typography
                  fontSize={"24px"}
                  variant="h3"
                  lineHeight={"normal"}
                  margin={{
                    xs: "20px 0",
                    md: "30px 0",
                  }}
                >
                  {faq.title}
                </Typography>{" "}
              </React.Fragment>
            ) : null}

            {faq.questions.map((question, idx) => (
              <React.Fragment key={question.question}>
                <Accordion
                  sx={{
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    "&.Mui-expanded": {
                      padding: 0,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ChevronDown width={25} height={25} />}
                    aria-controls="faq-title"
                    id={question.question}
                    data-cy="faq-question"
                    sx={{
                      background: colors.shades.blue[100],
                      "&:hover": {
                        background: colors.shades.blue[200],
                      },
                      padding: {
                        xs: "10px",
                        md: "15px 30px",
                      },
                      minHeight: "unset",
                      maxHeight: "unset",
                      "&.Mui-expanded": {
                        minHeight: "unset",
                        maxHeight: "unset",
                      },
                      svg: {
                        path: {
                          fill: "#0C162A",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingRight: "30px",
                      }}
                    >
                      <Typography
                        lineHeight={"normal"}
                        variant="h4"
                        fontSize={"18px"}
                      >
                        {question.question}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {" "}
                    <Box height={10} />
                    <Typography
                      padding={{
                        xs: "10px",
                        md: "14px",
                      }}
                      variant="h5"
                      fontWeight={"400"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                    >
                      {question.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {idx !== faq.questions.length - 1 ? <Box height={10} /> : null}
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Accord>
  );
};
