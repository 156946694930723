import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { SimpleTable } from "app/components/simple-table";
import {
  IResultItem,
  RESULT_INDICATOR_COLUMNS,
  ResultItems,
} from "app/pages/explorer/pages/activities/pages/activity/components/expanded-results-block/data";

export const ExpandedResultsBlock = (props: { data: ResultItems }) => {
  const [glossaryOpen, setGlossaryOpen] = React.useState(false);

  const glossaryItems = [
    {
      title: "Output",
      description:
        "Results of the activity that came about as a direct effect of your work and specific, what is done, and what communities are reached. For example, X number of individuals.",
    },
    {
      title: "Outcome",
      description:
        "Results of the activity that produce an effect on the overall communities or issues you serve. For example lower rate of infection after a vaccination programme.",
    },
    {
      title: "Impact",
      description:
        "The long term effects of the outcomes, that lead to larger, over arching results, such as improved life-expectancy.",
    },
  ];
  return (
    <Box data-cy="expanded-results-block">
      <Box
        sx={{
          padding: { xs: "10px", md: "20px" },
          background: colors.secondary.lightGrey,
        }}
      >
        <Typography
          variant="h3"
          fontSize={{ xs: "14px", md: "18px", lg: "24px" }}
          lineHeight="normal"
          display="flex"
          alignItems="center"
          columnGap="20px"
          onClick={() => setGlossaryOpen(!glossaryOpen)}
          component="button"
          sx={{
            border: "none",
            background: "none",
            cursor: "pointer",
            "> svg": {
              transition: "transform 0.3s",
            },
            [customBreakpointsMax.mobile]: {
              "> svg": {
                width: "11px",
                height: "11px",
              },
            },
          }}
        >
          Results Glossary
          <ArrowDown
            stroke="#0C162A"
            width={28}
            height={28}
            transform={`rotate(${glossaryOpen ? "180" : "0"})`}
          />
        </Typography>
        <Collapse timeout={300} in={glossaryOpen}>
          <Grid
            paddingTop="20px"
            container
            spacing={{ xs: "20px", md: "37px" }}
          >
            {glossaryItems.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Typography
                  variant="h4"
                  fontSize={{ xs: "12px", md: "14px", lg: "18px" }}
                  lineHeight="normal"
                  color="#fff"
                  marginBottom={{
                    xs: "5px",
                    md: "10px",
                  }}
                  borderRadius="5px"
                  padding="6px 10px"
                  width="max-content"
                  sx={{
                    background: colors.shades.blue[400],
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={{
                    xs: "9px",
                    md: "10px",
                    lg: "12px",
                  }}
                  lineHeight="normal"
                >
                  {item.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
      <Divider sx={{ margin: "20px 0" }} />
      <Grid
        container
        spacing={{
          xs: "20px",
          md: "37px",
        }}
      >
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.output} title="Outputs" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.outcome} title="Outcome" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.impact} title="Impact" />
        </Grid>
      </Grid>
    </Box>
  );
};

const ResultGroup = (props: {
  data: ResultItems["outcome" | "impact" | "output"];
  title: string;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Box>
      <Typography
        variant="h3"
        fontSize={{ xs: "14px", md: "18px", lg: "24px" }}
        lineHeight="normal"
        display="flex"
        alignItems="center"
        columnGap="20px"
        onClick={() => setOpen(!open)}
        component="button"
        sx={{
          border: "none",
          background: "none",
          cursor: "pointer",
          "> svg": {
            transition: "transform 0.3s",
          },
          [customBreakpointsMax.mobile]: {
            "> svg": {
              width: "11px",
              height: "11px",
            },
          },
        }}
      >
        {props.title} ({props.data.length})
        <ArrowDown
          stroke="#0C162A"
          width={28}
          height={28}
          transform={`rotate(${open ? "180" : "0"})`}
        />
      </Typography>
      <Collapse in={open}>
        {props.data.map((d) => (
          <React.Fragment key={d.name}>
            <Box height={20} />
            <SingleResult {...d} />
          </React.Fragment>
        ))}
      </Collapse>
    </Box>
  );
};

const SingleResult = (props: IResultItem) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Box
        sx={{
          background: "#F1F3F5",
          padding: "10px",
        }}
      >
        <Typography
          color={colors.secondary.grey}
          variant="h4"
          fontSize={{
            xs: "14px",
            lg: "18px",
          }}
          lineHeight="normal"
        >
          {props.name}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontSize={{
          xs: "10px",
          md: "12px",
          lg: "14px",
        }}
        lineHeight="20px"
        fontWeight="400"
        marginTop="20px"
        color={colors.secondary.grey}
      >
        {props.description}
      </Typography>
      <Collapse in={open} timeout={300}>
        {props.indicators.map((indicator) => (
          <React.Fragment key={indicator.title}>
            <Typography
              variant="h6"
              fontSize="12px"
              lineHeight="normal"
              color={colors.secondary.grey}
              marginTop="20px"
            >
              Indicator
            </Typography>
            <Typography
              variant="h5"
              fontSize={{
                xs: "10px",
                md: "12px",
                lg: "14px",
              }}
              lineHeight="20px"
              fontWeight="400"
              marginTop="10px"
              color={colors.secondary.grey}
            >
              {indicator.title}
            </Typography>
            <Box height={20} />
            {indicator.items.map((item, i) => (
              <React.Fragment key={`${indicator}-${i}`}>
                <SimpleTable
                  rows={[item]}
                  variant="vertical"
                  columns={RESULT_INDICATOR_COLUMNS}
                  boxStyle={{
                    padding: "6px 10px",
                  }}
                />
                <Typography
                  variant="h6"
                  fontSize="12px"
                  lineHeight="normal"
                  color={colors.secondary.grey}
                  marginTop="20px"
                >
                  Reference
                </Typography>
                <Box height={10} />
                <Grid container columnSpacing="10px">
                  <Grid item xs={12} lg={6}>
                    <SimpleTable
                      columns={[
                        {
                          headerName: "VOCAB",
                          field: "vocab",
                        },
                      ]}
                      rows={[item]}
                      variant="vertical"
                      boxStyle={{
                        padding: "6px 10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SimpleTable
                      columns={[
                        {
                          headerName: "CODE",
                          field: "code",
                        },
                      ]}
                      rows={[item]}
                      variant="vertical"
                      boxStyle={{
                        padding: "6px 10px",
                      }}
                    />
                  </Grid>
                </Grid>
                <Box height={20} />
                <Divider />
                <Box height={20} />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Collapse>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          // component="button"
          variant="button"
          width="max-content"
          color={colors.primary.blue}
          onClick={() => setOpen(!open)}
          sx={{
            cursor: "pointer",
          }}
        >
          [{`${open ? "-" : "+"}`}]
        </Typography>
      </Box>
    </Box>
  );
};
