import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import { Header } from "app/components/header";
import { Footer } from "app/components/footer";
import Container from "@mui/material/Container";
import { useStoreState } from "app/state/store/hooks";
import { Breadcrumbs } from "app/components/breadcrumbs";
import { useRouteListener } from "app/hooks/useRouteListener";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";
import { customBreakpointsMax } from "app/theme";
import { useMediaQuery } from "@mui/material";
import ResponsiveCTAs from "../responsive-ctas";

export const Page: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  useRouteListener();
  const location = useLocation();

  const locationName = useStoreState((state) =>
    get(state.ExplorerLocationPageOverviewStats.data, "data.name", "")
  );
  const orgName = useStoreState((state) =>
    get(state.ExplorerOrganisationPageOverviewStats, "data.data.name", "")
  );
  const sectorName = useStoreState((state) =>
    get(state.ExplorerSectorPageOverviewStats, "data.data.name", "")
  );
  const activityName = useStoreState((state) =>
    get(state.ExplorerActivityPage, "data.data.title", "")
  );

  const breadcrumbItems = React.useMemo(() => {
    if (location.pathname === "/publisher") {
      return [{ title: "Publisher", link: "/publisher" }];
    }
    if (location.pathname === "/explorer") {
      return [{ title: "Explore", link: "/explorer" }];
    }
    if (location.pathname === "/explorer/locations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
      ];
    }
    const locationId = matchPath("/explorer/locations/:id", location.pathname)
      ?.params.id;
    if (locationId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
        { title: locationName },
      ];
    }
    if (location.pathname === "/explorer/organisations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Organisations", link: "/explorer/organisations" },
      ];
    }
    const organisationId = matchPath(
      "/explorer/organisations/:id",
      location.pathname
    )?.params.id;
    if (organisationId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Organisations", link: "/explorer/organisations" },
        { title: orgName },
      ];
    }
    if (location.pathname === "/explorer/sectors") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Sectors", link: "/explorer/sectors" },
      ];
    }
    const sectorId = matchPath("/explorer/sectors/:id", location.pathname)
      ?.params.id;
    if (sectorId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Sectors", link: "/explorer/sectors" },
        { title: sectorName },
      ];
    }
    if (location.pathname === "/explorer/activities") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Activities", link: "/explorer/activities" },
      ];
    }
    const activityId = matchPath("/explorer/activities/:id", location.pathname)
      ?.params.id;
    if (activityId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Activities", link: "/explorer/activities" },
        { title: activityName },
      ];
    }
    if (location.pathname === "/explorer/favourites") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Your Favourites", link: "/explorer/favourites" },
      ];
    }
    if (location.pathname === "/data-hub") {
      return [{ title: "Data Hub", link: "/data-hub" }];
    }
    if (location.pathname === "/login") {
      return [{ title: "Log in", link: "/login" }];
    }

    if (location.pathname === "/account-profile") {
      return [{ title: "Account Profile", link: "/account-profile" }];
    }
    if (location.pathname === "/user-guide") {
      return [{ title: "User Guide", link: "/user-guide" }];
    }
    if (location.pathname === "/pricing") {
      return [{ title: "Plans & Pricing", link: "/pricing" }];
    }
    return [];
  }, [location.pathname, locationName, orgName, sectorName, activityName]);

  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          paddingBottom: "50px",
          minHeight: "calc(100vh - 51px - 277px)",
          [customBreakpointsMax.tablet]: {
            padding: "0 30px",
            paddingBottom: "40px",
          },
          [customBreakpointsMax.mobile]: {
            padding: "0 24px",
            paddingBottom: "20px",
          },
        }}
      >
        {mobile || ["/", "/data-services"].includes(location.pathname) ? (
          <Box
            height={{
              xs: "20px",
              md: "50px",
            }}
          />
        ) : (
          <Breadcrumbs items={breadcrumbItems} />
        )}

        <Box id="main">
          <Outlet />
        </Box>
      </Container>
      <ResponsiveCTAs />
      <Footer />
    </React.Fragment>
  );
};
