import React from "react";
import axios from "axios";
import get from "lodash/get";
import find from "lodash/find";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useUpdateEffect } from "react-use";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import { PageLoader } from "app/components/page-loader";
import { PageHeader } from "app/components/page-header";
import { ConvertedFile } from "app/components/table/data";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { usePublisherUtils } from "app/hooks/usePublisherUtils";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { InsightsBlock } from "app/pages/data-hub/components/insights-block";
import { FreePlanBanner } from "app/pages/data-hub/components/free-plan-banner";
import { retrieveFileValidaionReport } from "app/utils/retrieveFileValidaionReport";
import { UploadedDataBlock } from "app/pages/data-hub/components/uploaded-data-block";
import { ReactComponent as PublisherHeaderIcon } from "app/assets/vectors/PublisherHeader.svg";
import { PublisherErrorMessageModal } from "app/pages/publisher/components/publisher-block/data";
import {
  PublisherBlockValidityReportPopup,
  PublisherBlockValidityReportPopupProps,
} from "app/pages/publisher/components/publisher-block/views/validityReportPopup";
import { CanonicalUrl } from "app/components/canonical-url";

export const DataHub: React.FC = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const {
    loading,
    deleteFiles,
    createDataset,
    updateDataset,
    updateFileStatus,
    replaceFileWithEditVersion,
  } = usePublisherUtils();

  const [convertedFiles, setConvertedFiles] = React.useState<ConvertedFile[]>(
    []
  );
  const [token, setToken] = React.useState<string | null>(null);
  const [showFreePlanBanner, setShowFreePlanBanner] = React.useState(false);
  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: ["locations", "sdgs", "network", "results", "impact", "budget"],
  });
  const [modal, setModal] = React.useState<{
    open: boolean;
    title: string;
    message: React.ReactNode;
  }>({
    open: false,
    title: "",
    message: "",
  });
  const [loadingValidationReport, setLoadingValidationReport] =
    React.useState(false);
  const [validityReportPopup, setValidityReportPopup] = React.useState<{
    show: boolean;
    data: {
      errors?: PublisherBlockValidityReportPopupProps["errors"];
      items?: PublisherBlockValidityReportPopupProps["items"];
    };
    fileURL: string;
    filename: string;
    fileId: string;
    date: string;
  }>({
    show: false,
    data: {},
    fileURL: "",
    filename: "",
    fileId: "",
    date: "",
  });

  const storedUser = useStoreState((state) => get(state.user, "data.data", {}));
  const groupId = get(storedUser, "groupId");
  const publisherId = get(storedUser, "app_metadata.publisherID", "");
  const apiKey = get(storedUser, "app_metadata.registryApiKey", "");
  const orgRef = get(
    storedUser,
    "app_metadata.publisherInfo.publisher_iati_id",
    ""
  );

  const fetchPublisherConvertedFiles = useStoreActions(
    (actions) => actions.PublisherConvertedFiles.authGetFetch
  );
  const dataPublisherConvertedFiles = useStoreState((state) =>
    get(state.PublisherConvertedFiles, "data.files", [])
  );
  const loadingPublisherConvertedFiles = useStoreState(
    (state) => state.PublisherConvertedFiles.loading
  );

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  const handleConvertedFilePublishStatusChange = async (
    fileId: string,
    value: boolean,
    isValid: boolean,
    registryFileId?: string,
    isAdmin?: boolean
  ) => {
    if (!isAdmin) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            You need to be an{" "}
            <span style={{ color: colors.primary.green }}>Admin</span> to
            publish a data file.
          </React.Fragment>
        ),
      });
      return;
    }
    if (!isValid) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            <span style={{ color: colors.secondary.red }}>Invalid</span> files
            needs to be validated. Till then we can save your data file as{" "}
            <span style={{ color: colors.secondary.orange }}>Draft</span>. Edit
            your file to{" "}
            <span style={{ color: colors.primary.blue }}>publish</span>.
          </React.Fragment>
        ),
      });
      return;
    }
    if (!apiKey) {
      setModal({
        open: true,
        title: "Cannot publish your data yet!",
        message: (
          <React.Fragment>
            You need to{" "}
            <span style={{ color: colors.primary.blue }}>connect</span> to the
            IATI registry to publish your data file.
          </React.Fragment>
        ),
      });
      return;
    }
    const file = find(convertedFiles, { id: fileId });
    let dataset = null;
    let registryId = "";
    if (file) {
      if (!registryFileId) {
        dataset = await createDataset({
          name: `${publisherId}-${file.title}`,
          title: file.title,
          fileURL: file.url,
          iatiVersion: file.iatiVersion,
          publisherId: orgRef,
          publishStatus: value,
          activityCount: file.activityCount,
          type: file.type === "Activity" ? "activity" : "organisation",
        });
        registryId = dataset.result.id;
      } else {
        dataset = await updateDataset({
          id: registryFileId,
          publishStatus: value,
          iatiVersion: file.iatiVersion,
          activityCount: file.activityCount,
          type: file.type === "Activity" ? "activity" : "organisation",
        });
        registryId = registryFileId;
      }
      if (dataset) {
        updateFileStatus({
          fileId,
          registryId,
          publishStatus: value,
        });
      }
    }
  };

  const resetModal = () => {
    setModal({
      open: false,
      title: "",
      message: "",
    });
  };

  const handleShowValidationReport = async (fileId: string) => {
    const t = await getAccessTokenSilentlyWithState();
    retrieveFileValidaionReport(
      fileId,
      convertedFiles,
      t as string,
      setLoadingValidationReport,
      setValidityReportPopup
    );
  };

  const clearValidityReportPopup = () => {
    setValidityReportPopup({
      show: false,
      data: {},
      fileURL: "",
      filename: "",
      fileId: "",
      date: "",
    });
  };

  const uploadedDataBlock = React.useMemo(() => {
    return (
      <React.Fragment>
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
        >
          Your Data Hub
        </Typography>
        <UploadedDataBlock
          files={convertedFiles}
          deleteFiles={deleteFiles}
          loading={loadingPublisherConvertedFiles || loading}
          replaceFileWithEditVersion={replaceFileWithEditVersion}
          handleShowValidationReport={handleShowValidationReport}
          changePublishStatus={handleConvertedFilePublishStatusChange}
        />
      </React.Fragment>
    );
  }, [convertedFiles, loadingPublisherConvertedFiles, loading]);

  React.useEffect(() => {
    getAccessTokenSilentlyWithState().then((token) => {
      fetchPublisherConvertedFiles({ values: { token, groupId } });
    });
  }, []);

  useUpdateEffect(() => {
    setConvertedFiles(
      dataPublisherConvertedFiles.map((file: any) => ({
        id: file.id,
        date: file.createdDate,
        title: file.fileName,
        type: file.type === "activity" ? "Activity" : "Organisation",
        status: file.validation.isValid ? "Valid" : "Invalid",
        isPublished: file.published,
        activityCount: file.count,
        url: file.path,
        iatiVersion: file.iatiVersion,
        registryId: file.registryId,
        editVersion: file.editVersion,
      }))
    );
  }, [dataPublisherConvertedFiles]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/data-hub" />
      <Box
        sx={{
          gap: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loadingValidationReport && <PageLoader />}
        <PageHeader
          variant="publisher"
          title="AIDA Publisher: Publish, Validate, Visualise Data"
          subtitle="Ready to share your impact with the world? Publish your data to contribute to a global network of meaningful insights."
          icon={<PublisherHeaderIcon />}
        />
        {!expandedBlock && uploadedDataBlock}
        {showFreePlanBanner && (
          <FreePlanBanner setShowFreePlanBanner={setShowFreePlanBanner} />
        )}
        <InsightsBlock
          expandedBlock={expandedBlock}
          setExpandedBlock={handleSetExpandedBlock}
        />
        {expandedBlock && uploadedDataBlock}
        <PublisherBlockValidityReportPopup
          fileURL={validityReportPopup.fileURL}
          items={validityReportPopup.data.items}
          fileName={validityReportPopup.filename}
          errors={validityReportPopup.data.errors}
          value={validityReportPopup.fileId}
          open={validityReportPopup.show}
          title={validityReportPopup.filename}
          onClose={clearValidityReportPopup}
          date={validityReportPopup.date}
        />
        <PublisherErrorMessageModal {...modal} close={resetModal} />
      </Box>
    </React.Fragment>
  );
};
