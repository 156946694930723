import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Switch } from "app/components/switch";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { SDGChart } from "app/components/charts/sdg";
import { ImpactChart } from "app/components/charts/impact";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { SDGSampleData2 } from "app/components/charts/sdg/data";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { NetworkSampleData } from "app/components/charts/network/data";
import { IMPACT_CHART_ITEMS } from "app/components/charts/impact/data";
import { BarChartStackedSampleData } from "app/components/charts/bar/data";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { GenericPageBlockItemLocationDescription } from "app/components/generic-page-block-item/data";
import {
  ChartPlaceholder,
  InsightsBlockProps,
} from "app/pages/data-hub/components/insights-block/data";
import { useMediaQuery } from "@mui/material";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import { GeomapChart } from "app/components/charts/geomap";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { Table } from "app/components/table";
import {
  IMPACT_COLUMNS,
  IMPACT_COLUMNS_TABLET,
} from "app/components/charts/impact/tableData";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const overview = React.useMemo(() => {
    const items = [
      { name: "Disbursements and Expenditures", value: "USD 14 million" },
      { name: "Activities", value: "23" },
      { name: "Recipient Countries", value: "6" },
      { name: "Organisations", value: "6" },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="Overview"
        variant="publisher"
        shareLink="/explorer#block-Overview"
        description={
          <React.Fragment>
            You've published data reveals a{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD 14 Million
            </span>{" "}
            impact across{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              6 recipient countries.
            </span>
          </React.Fragment>
        }
        content={
          <OverviewBlock items={items} variant={tablet ? "vertical" : "grid"} />
        }
      />
    );
  }, [tablet]);

  const locations = React.useMemo(() => {
    const items = [
      {
        code: "so",
        name: "Somalia",
        funds: "USD 456K",
        activities: 459,
      },
      {
        code: "ye",
        name: "Yemen",
        funds: "USD 356K",
        activities: 326,
      },
      {
        code: "ss",
        name: "South Sudan",
        funds: "USD 321K",
        activities: 764,
      },
      {
        code: "af",
        name: "Afghanistan",
        funds: "USD 294K",
        activities: 325,
      },
    ];

    const expanded = props.expandedBlock === "locations";
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          onExpand={() => props.setExpandedBlock("locations")}
          shareLink="/explorer?e=locations"
          description={
            <GenericPageBlockItemLocationDescription
              period=""
              items={[
                {
                  label: "Somalia",
                  link: "/explorer/locations/SO",
                },
                {
                  label: "Yemen",
                  link: "/explorer/locations/YE",
                },
                {
                  label: "South Sudan",
                  link: "/explorer/locations/SS",
                },
                {
                  label: "Afghanistan",
                  link: "/explorer/locations/AF",
                },
              ]}
            />
          }
          content={
            <React.Fragment>
              <LocationsBlock items={items} transactionType="Incoming Funds" />
              {expanded && (
                <Box
                  height={mobile ? "560px" : tablet ? "520px" : "550px"}
                  bgcolor={colors.primary.white}
                >
                  <Divider />
                  <Box height={30} />
                  <GeomapChart
                    showLegend
                    showTooltip
                    showZoomWidget
                    height={tablet ? "327px" : "500px"}
                    variant="explorer"
                    data={GeomapSampleData}
                    zoom={expanded && mobile ? 3 : 1}
                  />
                </Box>
              )}
            </React.Fragment>
          }
          expanded={props.expandedBlock === "locations"}
          variant="publisher"
        />
      </React.Fragment>
    );
  }, [props.expandedBlock]);

  const sdgs = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        shareLink="/explorer?e=sdgs"
        variant="publisher"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              12 activities
            </span>{" "}
            were contributing to the SDGs according to your published data.
          </React.Fragment>
        }
        content={
          <SDGChart
            data={SDGSampleData2}
            variant={tablet ? "horizontal" : "grid"}
          />
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, tablet]);

  const network = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Network"
        onExpand={() => props.setExpandedBlock("network")}
        variant="publisher"
        shareLink="/explorer?e=network"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              6 organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <NetworkChart id="data-hub-networks" data={NetworkSampleData} />
        }
        expanded={props.expandedBlock === "network"}
      />
    );
  }, [props.expandedBlock]);

  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        onExpand={() => props.setExpandedBlock("impact")}
        variant="publisher"
        shareLink="/explorer?e=impact"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              6 activities
            </span>{" "}
            susparssed their target results according to your available
            published results data.
          </React.Fragment>
        }
        content={
          props.expandedBlock === "impact" ? (
            <Table
              withSearch
              rows={IMPACT_CHART_ITEMS}
              headerFontSize="12px"
              columns={tablet ? IMPACT_COLUMNS_TABLET : IMPACT_COLUMNS}
              getRowHeight={() => (tablet ? "auto" : 40)}
            />
          ) : (
            <ImpactChart items={IMPACT_CHART_ITEMS} period="2023" />
          )
        }
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock, tablet]);

  const budget = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Budget"
        onExpand={() => props.setExpandedBlock("budget")}
        variant="publisher"
        shareLink="/explorer?e=budget"
        description={
          <React.Fragment>
            Budget by sectors show that{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Productive Sectors
            </span>{" "}
            were the most funded sectors of 2022.
          </React.Fragment>
        }
        content={
          <BarChart
            barWidth="40"
            yAxisLabelName="USD"
            xAxisLabelRotate={0}
            data={BarChartStackedSampleData}
          />
        }
        expanded={props.expandedBlock === "budget"}
      />
    );
  }, [props.expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (props.expandedBlock) {
      case "locations":
        view = locations;
        break;
      case "sdgs":
        view = sdgs;
        break;
      case "network":
        view = network;
        break;
      case "impact":
        view = impact;
        break;
      case "budget":
        view = budget;
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Box height={40} />
        {view}
      </React.Fragment>
    );
  }, [props.expandedBlock, locations, sdgs, network, impact, budget]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );

  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );

  const networkBlockHeight = React.useMemo(
    () => getBlockHeight("block-Network"),
    [network]
  );

  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  const budgetBlockHeight = React.useMemo(
    () => getBlockHeight("block-Budget"),
    [budget]
  );

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "40px",
          justifyContent: "space-between",
          [customBreakpointsMax.mobile]: {
            flexDirection: "column",
            gap: "20px",
            alignItems: "flex-start",
          },
        }}
      >
        <Typography
          variant="h4"
          fontSize={{
            xs: "24px",
            lg: "32px",
          }}
        >
          Unveil Your Impact: Insights from Your Data
        </Typography>
        <Switch
          label="Visualise Draft Files"
          info={
            <React.Fragment>
              <b>
                Your published items are visualised. Switch the toggle on to add
                your draft items to your visualisations.
              </b>
              <br />
              <br />
              Keep in mind that other users cannot have access to your draft
              items. Therefore, when switched on, only you can see these
              changes.
            </React.Fragment>
          }
        />
      </Box>
      <Grid container spacing={{ xs: "20px", md: "30px" }}>
        {props.expandedBlock && (
          <Grid item xs={12}>
            {expandedBlockView}
          </Grid>
        )}
        <Grid item xs={12} lg={5}>
          {overview}
        </Grid>
        <Grid item xs={12} lg={7}>
          {props.expandedBlock !== "locations" ? (
            locations
          ) : (
            <ChartPlaceholder height={locationsBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          {props.expandedBlock !== "sdgs" ? (
            sdgs
          ) : (
            <ChartPlaceholder height={sdgsBlockHeight} />
          )}
          <Box sx={{ height: 20 }} />
          <Box
            sx={{
              display: "none",
              columnGap: { xs: "12px", md: "20px" },
              [customBreakpointsMax.tablet]: {
                display: "flex",
              },
            }}
          >
            <CtaCommonBtn
              variant={"large"}
              label={mobile ? "AIDA Explorer" : "Your Favourites"}
              text={
                !mobile
                  ? "Introducing 'Your Bookmarks' - your personalized area for humanitarian insights."
                  : undefined
              }
              link="/"
              bgIcon={<ExplorerLargeCtaIcon />}
            />
            <CtaCommonBtn
              variant={"large"}
              label={mobile ? "AIDA Publisher" : "Publish Your Data"}
              text={
                !mobile
                  ? "Join the ranks of NGOs that are already benefiting from AIDA Publication Service."
                  : undefined
              }
              link="/"
              bgIcon={<PublisherLargeCtaIcon />}
              bgColor="green"
            />
          </Box>
          <Box
            sx={{
              gap: "20px",
              display: "flex",
              flexDirection: "row",
              [customBreakpointsMax.tablet]: {
                display: "none",
              },
            }}
          >
            <CtaCommonBtn
              label="Your Favourites"
              variant="medium"
              bgColor="green"
              link="/explorer/favourites"
              bgIcon={
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.508 21.5075C19.6326 20.0402 20.57 18.8327 22.0183 18.5464C24.0838 18.138 27.7851 17.6802 34 17.6802C40.2149 17.6802 43.9161 18.138 45.9817 18.5464C47.4299 18.8327 48.3673 20.0402 48.492 21.5075C48.6982 23.9358 48.96 28.3055 48.96 34.8382C48.96 40.8782 48.8432 44.5953 48.7042 46.8805C48.5749 49.0078 46.9472 49.6872 45.1391 48.5512L36.3922 43.0554C34.9303 42.1369 33.0696 42.1369 31.6077 43.0554L22.8608 48.5512C21.0528 49.6872 19.4251 49.0078 19.2957 46.8805C19.1567 44.5953 19.04 40.8782 19.04 34.8382C19.04 28.3055 19.3018 23.9358 19.508 21.5075Z"
                    fill={colors.shades.green[400]}
                  />
                </svg>
              }
            />
            <CtaCommonBtn
              label="Publish Your Data"
              variant="medium"
              bgColor="green"
              link="/publisher"
              bgIcon={
                <svg
                  width="72"
                  height="72"
                  viewBox="0 0 72 72"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M43.837 63.6728C20.8088 70.4542 6.66024 71.869 6.66024 71.869C6.66024 71.869 4.21177 72.6852 1.76303 70.2366C-0.685702 67.7879 0.13063 65.3395 0.13063 65.3395C0.13063 65.3395 1.54548 51.191 8.32678 28.1626C9.2913 24.8872 11.9494 22.4014 15.315 21.8258C19.7866 21.0611 26.8445 20.0939 36 19.636L40.9408 17.9996L54 31.059L52.3636 35.9996C51.9059 45.1551 50.9385 52.213 50.1738 56.6846C49.5982 60.0502 47.1124 62.7083 43.837 63.6728Z"
                    fill="#00726E"
                  />
                  <path
                    d="M36 19.6361C36.5951 12.4944 38.9144 5.28056 40.1483 1.88447C40.6015 0.637235 41.7919 -0.151689 43.1072 0.0242038C46.9093 0.532638 55.0473 2.45255 62.2972 9.70242C69.5471 16.9523 71.467 25.0904 71.9756 28.8925C72.1515 30.2078 71.3625 31.3983 70.1152 31.8516C66.7191 33.0854 59.5054 35.4046 52.3636 35.9997C52.3636 35.9997 50.7922 31.0691 45.8615 26.1384C40.9307 21.2075 36 19.6361 36 19.6361Z"
                    fill="#00726E"
                  />
                  <path
                    d="M0.594727 68.6791C4.60688 64.2482 13.6106 54.3243 21.3219 45.9669C21.182 45.4343 21.1074 44.8751 21.1074 44.2986C21.1074 40.6836 24.038 37.7532 27.6528 37.7532C31.2679 37.7532 34.1983 40.6836 34.1983 44.2986C34.1983 47.9135 31.2679 50.8441 27.6528 50.8441C27.0763 50.8441 26.5172 50.7695 25.9847 50.6296C17.6172 58.3501 7.67979 67.3659 3.25658 71.3711C2.77318 71.108 2.26833 70.7419 1.76348 70.2371C1.23598 69.7097 0.859997 69.1821 0.594727 68.6791Z"
                    fill="#004845"
                  />
                </svg>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={7}>
          {props.expandedBlock !== "network" ? (
            network
          ) : (
            <ChartPlaceholder height={networkBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12} lg={7}>
          {props.expandedBlock !== "impact" ? (
            impact
          ) : (
            <ChartPlaceholder height={impactBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          {props.expandedBlock !== "budget" ? (
            budget
          ) : (
            <ChartPlaceholder height={budgetBlockHeight} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
