import React from "react";
import get from "lodash/get";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useStoreState } from "app/state/store/hooks";
import { SimpleTable } from "app/components/simple-table";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export const DescriptionBlock: React.FC = () => {
  const { id } = useParams();
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const activity = useStoreState((state) =>
    get(state.ExplorerActivityPage, "data.data", {
      title: "",
      description: [
        {
          lang: "",
          text: "",
        },
      ],
      reportingOrg: {
        ref: "",
        name: "",
        type: "",
      },
      status: "",
      scope: "",
      dates: {
        startActual: "",
        startPlanned: "",
        endActual: "",
        endPlanned: "",
      },
      contact: {
        type: "",
        org: "",
        telephone: "",
        email: "",
        website: "",
        department: "",
        jobTitle: "",
        mailingAddress: "",
      },
    })
  );
  const loading = useStoreState((state) => state.ExplorerActivityPage.loading);

  return (
    <GenericPageBlockItem
      toolbarShare
      title="Overview"
      loading={loading}
      shareLink="/explorer/activities#block-Overview"
      description={
        <React.Fragment>
          {activity.title} is an{" "}
          <span
            style={{
              color: colors.primary.blue,
            }}
          >
            activity
          </span>{" "}
          published by{" "}
          <span
            style={{
              color: colors.primary.blue,
            }}
          >
            {activity.reportingOrg.name}.
          </span>
        </React.Fragment>
      }
      content={
        <React.Fragment>
          <Grid container spacing="20px">
            <Grid item xs={12} lg={6}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Activity Identifier
              </Typography>
              <Box height={10} />

              <SimpleTable
                columns={[
                  { headerName: "Activity Identifier", field: "identifier" },
                  { headerName: "Code", field: "code" },
                  { headerName: "Text", field: "text" },
                ]}
                rows={[
                  {
                    code: "-",
                    text: "-",
                    identifier: id,
                  },
                ]}
                variant={mobile ? "borderless" : "vertical"}
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Reporting Organisation
              </Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  { headerName: "Identifier", field: "identifier" },
                  { headerName: "Type", field: "type" },
                  { headerName: "Name", field: "name" },
                ]}
                rows={[
                  {
                    name: activity?.reportingOrg?.name,
                    type: activity?.reportingOrg?.type,
                    identifier: activity?.reportingOrg?.ref,
                  },
                ]}
                variant={mobile ? "borderless" : "vertical"}
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Description
              </Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  { headerName: "Language", field: "language" },
                  { headerName: "General", field: "general" },
                ]}
                rows={activity?.description.map((o: any) => ({
                  general: o.text,
                  language: o.lang,
                }))}
                variant={mobile ? "borderless" : "horizontal"}
                boxStyle={{
                  padding: "6px 10px",
                }}
                gridTemplateColumns={{
                  xs: "15% 85%",
                  lg: "7.5% 92.5%",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Activity Status & Scope
              </Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  {
                    headerName: "Activity Status",
                    field: "activityStatus",
                    renderCell: (row) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                          flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            width: "9px",
                            height: "9px",
                            borderRadius: "50%",
                            backgroundColor: get(
                              ACTIVITY_STATUS_COLORS,
                              row?.row?.activityStatus ?? "",
                              "grey"
                            ),
                          }}
                        />
                        <span>{row?.row?.activityStatus}</span>
                      </Box>
                    ),
                  },
                  { headerName: "Scope", field: "scope" },
                ]}
                rows={[
                  {
                    activityStatus: activity?.status,
                    scope: activity?.scope,
                  },
                ]}
                variant={mobile ? "borderless" : "horizontal"}
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Activity Dates
              </Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  {
                    headerName: "Planned Start Date",
                    field: "planned_start_date",
                  },
                  {
                    headerName: "Planned End Date",
                    field: "planned_end_date",
                  },
                  {
                    headerName: "Actual Start Date",
                    field: "actual_start_date",
                  },
                  {
                    headerName: "Actual End Date",
                    field: "actual_end_date",
                  },
                ]}
                rows={[
                  {
                    planned_start_date: activity?.dates?.startPlanned,
                    planned_end_date: activity?.dates?.endPlanned,
                    actual_start_date: activity?.dates?.startActual,
                    actual_end_date: activity?.dates?.endActual,
                  },
                ]}
                variant={mobile ? "borderless" : "horizontal"}
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography
                variant="h6"
                sx={{
                  [customBreakpointsMax.mobile]: {
                    fontSize: "14px",
                    lineHeight: "normal",
                  },
                }}
              >
                Contact Information
              </Typography>
              <Box height={10} />
              <Grid container spacing="20px">
                <Grid item xs={12} lg={6}>
                  <SimpleTable
                    columns={[
                      { headerName: "Type", field: "type" },
                      { headerName: "Telephone", field: "telephone" },
                      { headerName: "Email", field: "email" },
                      { headerName: "Website", field: "website" },
                    ]}
                    rows={[
                      {
                        type: activity?.contact?.type,
                        telephone: activity?.contact?.telephone,
                        email: activity?.contact?.email,
                        website: activity?.contact?.website,
                      },
                    ]}
                    variant={mobile ? "borderless" : "vertical"}
                    boxStyle={{
                      padding: "6px 10px",
                    }}
                    gridTemplateColumns="25% 75%"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SimpleTable
                    columns={[
                      {
                        headerName: "Organisation",
                        field: "organisation",
                      },
                      { headerName: "Department", field: "department" },
                      {
                        headerName: "Person & Position",
                        field: "person_position",
                      },
                      {
                        headerName: "Emailing Address",
                        field: "emailing_address",
                      },
                    ]}
                    rows={[
                      {
                        organisation: activity?.contact?.org,
                        department: activity?.contact?.department,
                        person_position: activity?.contact?.jobTitle,
                        emailing_address: activity?.contact?.mailingAddress,
                      },
                    ]}
                    variant={mobile ? "borderless" : "vertical"}
                    boxStyle={{
                      padding: "6px 10px",
                    }}
                    gridTemplateColumns="25% 75%"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      }
    />
  );
};
