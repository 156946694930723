import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { PlanCard } from "./components/card";
import { Features } from "./components/features";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import CheckYesIcon from "app/assets/vectors/jsx/CheckYesIcon";
import { CheckGoodIcon } from "app/assets/vectors/jsx/CheckGoodIcon";
import SelectedPlanBlock from "./components/selected-plan-block";
import { useMediaQuery } from "@mui/material";
import { CanonicalUrl } from "app/components/canonical-url";

const pricingOptions = [
  {
    title: "Free Publisher",
    price: "Free",
    description:
      "The perfect package to get you started with publishing your IATI data.",
    currency: "",
    duration: "",
    recommended: false,
    buttonText: "Start Publishing",
  },
  {
    title: "Standard",
    price: "1800",
    description: "The perfect package to get you started in your IATI journey.",
    currency: "€",
    duration: "year",
    recommended: true,
    buttonText: "Start Free Trial",
  },
  {
    title: "Premium",
    price: "4200",
    description: "The perfect package to get you started in your IATI journey.",
    currency: "€",
    duration: "year",
    recommended: false,
    buttonText: "Start Free Trial",
  },
];

export const Pricing: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = React.useState("");

  const mobile = useMediaQuery("(max-width: 767px)");

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/pricing" />
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            marginTop: "30px",
            [customBreakpointsMax.tablet]: {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              [customBreakpointsMax.tablet]: {
                gap: "20px",
              },
              [customBreakpointsMax.mobile]: {
                alignItems: "center",
                textAlign: "center",
              },
            }}
          >
            <Typography
              variant="h1"
              fontSize={{
                xs: "24px",
                md: "36px",
                lg: "40px",
              }}
              lineHeight="normal"
            >
              Platform for IATI Data: Validation, Publication, Visualization
            </Typography>
            <Typography
              variant="h5"
              fontSize={{
                xs: "10px",
                md: "14px",
              }}
              fontWeight={"400"}
              lineHeight={"20px"}
            >
              For Standard and Premium plans, the use of the different features
              will be enabled for a single unique IATI organisation identifier,
              up to 5 users for a minimum of 12 months.
            </Typography>
            <Typography
              variant="h5"
              fontSize={{
                xs: "10px",
                md: "14px",
              }}
              fontWeight={"400"}
              lineHeight={"20px"}
            >
              Looking for a custom plan?{" "}
              {mobile && (
                <>
                  <br /> <br />
                </>
              )}
              <a href="/pricing">Contact Us.</a>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              [customBreakpointsMax.tablet]: {
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                width: "100%",
                gap: "30px",
              },
              [customBreakpointsMax.mobile]: {
                gap: "5px",
              },
            }}
          >
            {pricingOptions.map((option) => (
              <PlanCard
                key={option.title}
                option={option}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />
            ))}
          </Box>
        </Box>
        <Box height={30} />
        {selectedPlan ? <SelectedPlanBlock /> : <Features />}
      </Box>
    </React.Fragment>
  );
};
