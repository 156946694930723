import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { formatNumber } from "app/utils/formatNumber";
import ArrowForward from "@mui/icons-material/ArrowForward";
import {
  GeomapTooltipLockedIcon,
  GeomapTooltipUnlockedIcon,
} from "app/assets/vectors/jsx/GeomapTooltipLock";

interface TooltipProps {
  label: string;
  value: number;
  isLocked: boolean;
  lockUnlock: () => void;
  variant?: "publisher" | "explorer";
  buttonLink: string;
  buttonText: string;
  subLabel: string;
  activityCount: number;
}

export const GeomapChartTooltip = React.memo(function TooltipMemoized(
  props: TooltipProps
) {
  return (
    <Box
      sx={{
        width: "294px",
        padding: "11.5px",
        borderRadius: "14px",
        boxShadow: colors.shadows.main,
        background: colors.primary.white,
        [customBreakpointsMax.tablet]: {
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
        [customBreakpointsMax.mobile]: {
          display: "block",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{props.label}</Typography>
          {props.isLocked ? (
            <IconButton
              aria-label="close"
              onClick={props.lockUnlock}
              sx={{
                padding: "0",
                [customBreakpointsMax.tablet]: {
                  display: "none",
                },
              }}
            >
              <GeomapTooltipLockedIcon variant={props.variant ?? "publisher"} />
            </IconButton>
          ) : (
            <IconButton
              aria-label="close"
              onClick={props.lockUnlock}
              sx={{
                padding: "0",
                [customBreakpointsMax.tablet]: {
                  display: "none",
                },
              }}
            >
              <GeomapTooltipUnlockedIcon
                variant={props.variant ?? "publisher"}
              />
            </IconButton>
          )}
        </Box>
        <Typography
          fontSize={{ xs: "12px", lg: "13.38px" }}
          sx={{
            marginTop: {
              xs: "5px",
              lg: "16.72px",
            },
          }}
        >
          {props.subLabel}
        </Typography>
        <Typography
          sx={{ marginTop: "5.57px" }}
          variant="h3"
          fontWeight={{
            xs: "700",
            lg: "400",
          }}
          fontSize={{
            xs: "24px",
            lg: "40px",
          }}
        >
          US$ {formatNumber(props.value, true)}
        </Typography>
        <Typography
          fontSize={{ xs: "12px", lg: "13.38px" }}
          sx={{ marginTop: "5.57px" }}
        >
          {props.activityCount} Activit{props.activityCount === 1 ? "y" : "ies"}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: "16.72px",
          justifyContent: "flex-end",
          [customBreakpointsMax.tablet]: {
            width: "max-content",
          },
          [customBreakpointsMax.mobile]: {
            width: "100%",
            marginTop: "5px",
          },
        }}
      >
        <Button
          sx={{
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "none",
            color: colors.primary.white,
            background:
              props.variant === "explorer"
                ? colors.primary.blue
                : colors.primary.green,
            padding: "6px 10px",
          }}
          variant="contained"
          endIcon={<ArrowForward />}
          component={Link}
          to={props.buttonLink}
        >
          {props.buttonText}
        </Button>
      </Box>
    </Box>
  );
});
