import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { formatNumber } from "app/utils/formatNumber";
import { PageHeader } from "app/components/page-header";
import { CanonicalUrl } from "app/components/canonical-url";
import { NetworkChart } from "app/components/charts/network";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { SECTOR_COLUMNS } from "app/pages/explorer/pages/sectors/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerSectorHeaderIcon.svg";

const box = (
  <Box
    height={{
      xs: "20px",
      md: "30px",
      lg: "40px",
    }}
  />
);

export const ExplorerSectors = () => {
  const mobile = useMediaQuery("(max-width: 767px)");

  const navigate = useNavigate();

  const [activeOption, setActiveOption] = React.useState("");

  const sectorOfTheMonth = useStoreState((state) =>
    get(state.ExplorerSectorOfTheMonth, "data.data", {
      code: "",
      name: "",
    })
  );
  const fetchSectorOfTheMonth = useStoreActions(
    (actions) => actions.ExplorerSectorOfTheMonth.fetch
  );
  const sectorOfTheMonthLoading = useStoreState(
    (state) => state.ExplorerSectorOfTheMonth.loading
  );
  const stats = useStoreState((state) =>
    get(state.ExplorerSectorStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      countries: [],
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerSectorStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerSectorStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerSectorsList, "data.data", {
      dac3: [],
      dac5: [],
    })
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerSectorsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerSectorsList.fetch
  );
  const partnersNetwork = useStoreState((state) =>
    get(state.ExplorerSectorPartners, "data.data", {
      data: {
        nodes: [],
        links: [],
        categories: [],
      },
      count: 0,
    })
  );
  const fetchPartnersNetwork = useStoreActions(
    (actions) => actions.ExplorerSectorPartners.fetch
  );
  const partnersNetworkLoading = useStoreState(
    (state) => state.ExplorerSectorPartners.loading
  );

  const sectorOfTheMonthRef = sectorOfTheMonth.code;
  const sectorOfTheMonthName = sectorOfTheMonth.name;

  const SECTORS = React.useMemo(() => {
    let items =
      activeOption === "DAC 3 Digit Sectors"
        ? get(list, "dac3", [])
        : get(list, "dac5", []);

    return items.map((item: any) => ({
      id: item.code,
      locations: item.locations,
      incomingFunds: item.incomingFunds
        ? formatNumber(item.incomingFunds, true)
        : "-",
      activities: item.activities,
      organisations: item.organisations,
      sector: item.name,
      name: item.name,
    }));
  }, [activeOption, list]);

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  React.useEffect(() => {
    fetchSectorOfTheMonth({});
  }, []);

  React.useEffect(() => {
    if (!sectorOfTheMonthLoading && sectorOfTheMonthRef !== "") {
      fetchStats({
        routeParams: {
          code: sectorOfTheMonthRef,
          year: defaultYear,
          transactionTypeCode: "1",
        },
      });
      fetchList({
        filterString: `startDate=${defaultYear}-01-01T00:00:01Z&endDate=${defaultYear}-12-31T23:59:59Z`,
      });
      fetchPartnersNetwork({
        routeParams: {
          code: sectorOfTheMonthRef,
        },
      });
    }
  }, [sectorOfTheMonthRef, sectorOfTheMonthLoading]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/explorer/sectors" />
      <Box>
        <PageHeader
          variant="explorer"
          title="AIDA Explorer: Discover Sectors"
          subtitle="Discover the core building blocks of global aid efforts with AIDA's sector-specific data. This dynamic feature allows you to explore the diverse humanitarian sectors driving positive change worldwide. "
          icon={<ExplorerOrganisationsHeaderIcon />}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight={"normal"}
        >
          Explore the Sector of the Month
        </Typography>
        {box}
        <ExploreListBlockItem
          title="SECTOR OF THE MONTH"
          description={sectorOfTheMonthName}
          loading={statsLoading}
          leftItems={[
            {
              title: "Overview",
              link: `/explorer/sectors/${sectorOfTheMonthRef}`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {formatNumber(stats.disbursement, true)}
                  </span>{" "}
                  was spent for
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {" "}
                    {stats.countries.length} recipient countries{" "}
                  </span>{" "}
                  in {defaultYear}.
                </React.Fragment>
              ),
            },
            {
              title: "Locations",
              link: `/explorer/sectors/${sectorOfTheMonthRef}?e=locations`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.countries
                      .slice(0, 4)
                      .map((c: any) => c.name)
                      .join(", ")}
                  </span>{" "}
                  were the largest recipient countries of {defaultYear}.
                </React.Fragment>
              ),
            },
            {
              title: "Results",
              link: `/explorer/sectors/${sectorOfTheMonthRef}?e=impact`,
              description: (
                <React.Fragment>
                  In {defaultYear}, available results show activities surpassed
                  about{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    4.8%
                  </span>{" "}
                  of their target results on average.
                </React.Fragment>
              ),
            },
            {
              title: "SUSTAINABLE DEVELOPMENT GOALS",
              link: `/explorer/sectors/${sectorOfTheMonthRef}?e=sdgs`,
              description: (
                <React.Fragment>
                  In {defaultYear},{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.sdgActivities} activities{" "}
                  </span>
                  were contributing to the SDGs.
                </React.Fragment>
              ),
            },
            {
              title: "FUNDING Analysis",
              link: `/explorer/sectors/${sectorOfTheMonthRef}?e=sectors`,
              description: (
                <React.Fragment>
                  Funding for {sectorOfTheMonthName} sector has been
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {" "}
                    increased 1.4%{" "}
                  </span>
                  in {defaultYear} compared to {parseInt(defaultYear, 10) - 1}{" "}
                  data.
                </React.Fragment>
              ),
            },
            {
              title: "ORGANISATIONS",
              link: `/explorer/sectors/${sectorOfTheMonthRef}?e=networks`,
              description: (
                <React.Fragment>
                  Top 10 organisations provides in total of
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {" "}
                    678,2 million{" "}
                  </span>
                  development aid in {defaultYear}. Which makes up the{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {" "}
                    85% of the total development aid.
                  </span>
                </React.Fragment>
              ),
            },
          ]}
          rightContent={{
            title: "NETWORKS",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {partnersNetwork.count} organisations
                </span>{" "}
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            ),
            loading: partnersNetworkLoading,
            content: (
              <NetworkChart
                roam
                zoom={2}
                id="sectors-network-chart"
                data={partnersNetwork.data}
                height={mobile ? "288px" : "400px"}
              />
            ),
          }}
          buttonText="See the Sector Page"
          buttonLink={`/explorer/sectors/${sectorOfTheMonthRef}`}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight={"normal"}
        >
          Explore All Sectors & Search
        </Typography>
        {box}
        <ExploreListAllBlock
          rows={SECTORS}
          assetType="Sector"
          loading={listLoading}
          columns={
            mobile
              ? [SECTOR_COLUMNS[0], SECTOR_COLUMNS[SECTOR_COLUMNS.length - 1]]
              : SECTOR_COLUMNS
          }
          activeExtraOption={activeOption}
          setActiveExtraOption={setActiveOption}
          extraOptions={["DAC 3 Digit Sectors", "DAC 5 Digit Sectors"]}
          onRowClick={(row) => navigate(`/explorer/sectors/${row.id}`)}
        />
      </Box>
    </React.Fragment>
  );
};
