import React from "react";

export const DragHandlerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      {...props}
    >
      <path
        d="M0.666667 1.83333C1.03486 1.83333 1.33333 1.53486 1.33333 1.16667C1.33333 0.798477 1.03486 0.5 0.666667 0.5C0.298477 0.5 0 0.798477 0 1.16667C0 1.53486 0.298477 1.83333 0.666667 1.83333Z"
        fill="#454545"
      />
      <path
        d="M5.99967 1.83333C6.36786 1.83333 6.66634 1.53486 6.66634 1.16667C6.66634 0.798477 6.36786 0.5 5.99967 0.5C5.63148 0.5 5.33301 0.798477 5.33301 1.16667C5.33301 1.53486 5.63148 1.83333 5.99967 1.83333Z"
        fill="#454545"
      />
      <path
        d="M0.666667 7.16683C1.03486 7.16683 1.33333 6.86835 1.33333 6.50016C1.33333 6.13197 1.03486 5.8335 0.666667 5.8335C0.298477 5.8335 0 6.13197 0 6.50016C0 6.86835 0.298477 7.16683 0.666667 7.16683Z"
        fill="#454545"
      />
      <path
        d="M5.99967 7.16683C6.36786 7.16683 6.66634 6.86835 6.66634 6.50016C6.66634 6.13197 6.36786 5.8335 5.99967 5.8335C5.63148 5.8335 5.33301 6.13197 5.33301 6.50016C5.33301 6.86835 5.63148 7.16683 5.99967 7.16683Z"
        fill="#454545"
      />
      <path
        d="M0.666667 12.4998C1.03486 12.4998 1.33333 12.2014 1.33333 11.8332C1.33333 11.465 1.03486 11.1665 0.666667 11.1665C0.298477 11.1665 0 11.465 0 11.8332C0 12.2014 0.298477 12.4998 0.666667 12.4998Z"
        fill="#454545"
      />
      <path
        d="M5.99967 12.4998C6.36786 12.4998 6.66634 12.2014 6.66634 11.8332C6.66634 11.465 6.36786 11.1665 5.99967 11.1665C5.63148 11.1665 5.33301 11.465 5.33301 11.8332C5.33301 12.2014 5.63148 12.4998 5.99967 12.4998Z"
        fill="#454545"
      />
    </svg>
  );
};
