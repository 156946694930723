import React from "react";
import { useSearchParams } from "react-router-dom";
import { ExplorerLanding } from "app/pages/home/explorer";
import { PublisherLanding } from "app/pages/home/publisher";
import { CanonicalUrl } from "app/components/canonical-url";

export const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const variant = searchParams.get("v");

  React.useEffect(() => {
    if (!variant || !["publisher", "explorer"].includes(variant)) {
      setSearchParams({ v: "explorer" });
    }
  }, [variant]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/" />
      {variant === "publisher" ? <PublisherLanding /> : <ExplorerLanding />}
    </React.Fragment>
  );
};
