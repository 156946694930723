import { Box, Button, Typography } from "@mui/material";
import { AccountProfileCard } from "app/components/account-profile-card";
import { customBreakpointsMax } from "app/theme";
import React from "react";

export const PricingPlanSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  return (
    <AccountProfileCard
      title="Pricing Plan"
      content={
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={"400"}
            fontSize={{
              xs: "12px",
              md: "14px",
              lg: "12px",
            }}
            lineHeight={"20px"}
            padding={{
              xs: "0",
              md: "20px",
            }}
            display={"grid"}
            gap={"20px"}
            gridTemplateColumns={"1fr 1fr 1fr"}
          >
            <span>
              <b>Standard</b> Pricing Plan
            </span>
            <span>
              <b>5</b> User Licenses
            </span>
            <span>
              <b>Active</b> Support
            </span>
          </Typography>
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
      hideSaveButton
      extraButtons={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              lineHeight: "normal",
              minWidth: "unset",
              [customBreakpointsMax.mobile]: {
                width: "100%",
              },
            }}
            variant="contained"
          >
            Contact Us
          </Button>
          <Button
            sx={{
              fontSize: "12px",
              lineHeight: "normal",
              minWidth: "unset",
              [customBreakpointsMax.mobile]: {
                width: "100%",
                padding: "6px 6px",
              },
            }}
            variant="contained"
          >
            See Plans & Pricing
          </Button>
        </Box>
      }
    />
  );
};
