import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { GenericTableColumn } from "app/components/generic-table/data";

export const SECTOR_COLUMNS: GenericTableColumn[] = [
  {
    field: "sector",
    headerName: "Sector",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box
        display="flex"
        columnGap="8px"
        component={Link}
        alignItems="center"
        to={`/explorer/sectors/${params.row.id}`}
      >
        <Typography
          fontSize="12px"
          variant="subtitle1"
          color={colors.primary.blue}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "incomingFunds",
    headerName: "Incoming Funds",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "activities",
    headerName: "Activities",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "locations",
    headerName: "Locations",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "organisations",
    headerName: "Organisations",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
];
