import React from "react";
import { useSearchParams } from "react-router-dom";
import { Hero } from "./components/hero";
import { Box } from "@mui/material";
import { ProductOffers } from "./components/product-offers";
import { PublisherBanner } from "./components/publisher-banner";
import { ExplorerBanner } from "./components/explorer-banner";
import { CanonicalUrl } from "app/components/canonical-url";

export const DataServices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const variant = searchParams.get("v");

  React.useEffect(() => {
    if (!variant || !["publisher", "explorer"].includes(variant)) {
      setSearchParams({ v: "explorer" });
    }
  }, [variant]);
  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/data-services" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "20px",
            md: "30px",
            lg: "50px",
          },
        }}
      >
        <Hero />
        <ProductOffers />
        <PublisherBanner />
        <ExplorerBanner />
      </Box>
    </React.Fragment>
  );
};
