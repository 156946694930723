import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { CanonicalUrl } from "app/components/canonical-url";
import { NetworkChart } from "app/components/charts/network";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { ORGANISATION_COLUMNS } from "app/pages/explorer/pages/organisations/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";

const box = (
  <Box
    height={{
      xs: "20px",
      md: "30px",
      lg: "40px",
    }}
  />
);

export const ExplorerOrganisations: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const navigate = useNavigate();

  const orgOfTheMonth = useStoreState((state) =>
    get(state.ExplorerOrganisationOfTheMonth, "data.data", {
      code: "",
      name: "",
    })
  );
  const fetchOrgOfTheMonth = useStoreActions(
    (actions) => actions.ExplorerOrganisationOfTheMonth.fetch
  );
  const orgOfTheMonthLoading = useStoreState(
    (state) => state.ExplorerOrganisationOfTheMonth.loading
  );
  const stats = useStoreState((state) =>
    get(state.ExplorerOrganisationStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      countries: [],
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerOrganisationStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerOrganisationStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerOrganisationsList, "data.data", [])
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerOrganisationsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerOrganisationsList.fetch
  );
  const partnersNetwork = useStoreState((state) =>
    get(state.ExplorerOrganisationPartners, "data.data", {
      data: [],
      count: 0,
    })
  );
  const fetchPartnersNetwork = useStoreActions(
    (actions) => actions.ExplorerOrganisationPartners.fetch
  );
  const partnersNetworkLoading = useStoreState(
    (state) => state.ExplorerOrganisationPartners.loading
  );

  const orgOfTheMonthRef = orgOfTheMonth.code.toUpperCase();
  const orgOfTheMonthName = orgOfTheMonth.name;

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  React.useEffect(() => {
    fetchOrgOfTheMonth({});
  }, []);

  React.useEffect(() => {
    if (!orgOfTheMonthLoading && orgOfTheMonthRef !== "") {
      fetchStats({
        routeParams: {
          code: orgOfTheMonthRef,
          year: defaultYear,
          transactionTypeCode: "4",
        },
      });
      fetchList({});
      fetchPartnersNetwork({
        routeParams: {
          code: orgOfTheMonthRef,
        },
      });
    }
  }, [orgOfTheMonthRef, orgOfTheMonthLoading]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/explorer/organisations" />
      <Box>
        <PageHeader
          variant="explorer"
          title="AIDA Explorer: Explore Publishing Organisations"
          subtitle="Get ready to dive into the heart of humanitarian aid with AIDA's organisation-specific data. This feature invites you to explore a network of dedicated organisations making a real difference in the world."
          icon={<ExplorerOrganisationsHeaderIcon />}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight="normal"
        >
          Explore the Organisation of the Month
        </Typography>
        {box}
        <ExploreListBlockItem
          title="ORGANISATION OF THE MONTH"
          description={orgOfTheMonthName}
          loading={statsLoading}
          leftItems={[
            {
              title: "Overview",
              link: `/explorer/organisations/${orgOfTheMonthRef}`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {partnersNetwork.count} organisations are in
                  </span>{" "}
                  was spent for{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.countries.length} recipient countries{" "}
                  </span>{" "}
                  in {defaultYear}.
                </React.Fragment>
              ),
            },
            {
              title: "Locations",
              link: `/explorer/organisations/${orgOfTheMonthRef}?e=locations`,
              description: (
                <React.Fragment>
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.countries
                      .map((c: any) => c.name)
                      .slice(0, 4)
                      .join(", ")}{" "}
                  </span>{" "}
                  were the largest recipient countries of {defaultYear}.
                </React.Fragment>
              ),
            },
            {
              title: "Results",
              link: `/explorer/organisations/${orgOfTheMonthRef}?e=impact`,
              description: (
                <React.Fragment>
                  In {defaultYear}, available results show activities surpassed
                  about{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    4.8%
                  </span>{" "}
                  of their target results on average.
                </React.Fragment>
              ),
            },
            {
              title: "SUSTAINABLE DEVELOPMENT GOALS",
              link: `/explorer/organisations/${orgOfTheMonthRef}?e=sdgs`,
              description: (
                <React.Fragment>
                  In {defaultYear},{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {stats.sdgActivities} activities{" "}
                  </span>
                  were contributing to the SDGs.
                </React.Fragment>
              ),
            },
            {
              title: "Sectors",
              link: `/explorer/organisations/${orgOfTheMonthRef}?e=sectors`,
              description: (
                <React.Fragment>
                  {orgOfTheMonthName} provided aid for{" "}
                  <span
                    style={{
                      color: colors.primary.blue,
                    }}
                  >
                    {" "}
                    2 sectors{" "}
                  </span>
                  in all years.
                </React.Fragment>
              ),
            },
          ]}
          rightContent={{
            title: "NETWORKS",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {partnersNetwork.count} organisations are in
                </span>{" "}
                collaboration with European Commission - Humanitarian Aid &
                Civil Protection.
              </React.Fragment>
            ),
            loading: partnersNetworkLoading,
            content: (
              <NetworkChart
                roam
                zoom={2}
                data={partnersNetwork.data}
                id="organisation-network-chart"
                height={mobile ? "288px" : tablet ? "400px" : "319px"}
              />
            ),
          }}
          buttonText="See the Organisation Page"
          buttonLink={`/explorer/organisations/${orgOfTheMonthRef}`}
        />
        {box}
        <Typography
          variant="h2"
          fontSize={{
            xs: "24px",
            lg: "36px",
          }}
          lineHeight={"normal"}
        >
          Explore All Publishing Organisations & Search
        </Typography>
        {box}
        <ExploreListAllBlock
          rows={list}
          loading={listLoading}
          assetType="Organisation"
          columns={
            mobile
              ? [
                  ORGANISATION_COLUMNS[0],
                  ORGANISATION_COLUMNS[ORGANISATION_COLUMNS.length - 1],
                ]
              : ORGANISATION_COLUMNS
          }
          onRowClick={(row) =>
            row.iatiIdentifier &&
            navigate(`/explorer/organisations/${row.iatiIdentifier}`)
          }
        />
      </Box>
    </React.Fragment>
  );
};
