import React from "react";
import axios from "axios";
import get from "lodash/get";
import cryptoJs from "crypto-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

const CONTENT_TYPE = "application/json";

interface IDataset {
  name: string;
  title: string;
  fileURL: string;
  iatiVersion: string;
  publisherId: string;
  publishStatus: boolean;
  activityCount: number;
  type: "activity" | "organisation";
}

export const usePublisherUtils = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string | null>(null);

  const intercomUserId = useStoreState((state) =>
    get(state.IntercomUser, "data.data.id", "")
  );
  const storedUser = useStoreState((state) => get(state.user, "data.data", {}));
  const fetchPublisherConvertedFiles = useStoreActions(
    (actions) => actions.PublisherConvertedFiles.authGetFetch
  );

  const email = get(storedUser, "email", "");
  const publisherId = get(storedUser, "app_metadata.publisherID", "");
  const registryAPIKey = get(storedUser, "app_metadata.registryApiKey", "");
  const orgRef = get(
    storedUser,
    "app_metadata.publisherInfo.publisher_iati_id",
    ""
  );

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  // create dataset in the IATI registry
  const createDataset = async (data: IDataset) => {
    try {
      setLoading(true);
      const t = await getAccessTokenSilentlyWithState();
      const encodedValues = cryptoJs.AES.encrypt(
        JSON.stringify({
          ...data,
          email,
          publisherId,
          apiKey: registryAPIKey,
        }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_API}/dataset`,
        { payload: encodedValues },
        {
          headers: {
            "Content-Type": CONTENT_TYPE,
            Authorization: `Bearer ${t}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error creating dataset", error);
      return null;
    }
  };

  // update dataset in the IATI registry
  const updateDataset = async (data: {
    id: string;
    iatiVersion?: string;
    publishStatus: boolean;
    activityCount?: number;
    type?: "activity" | "organisation";
  }) => {
    try {
      setLoading(true);
      const t = await getAccessTokenSilentlyWithState();
      const encodedValues = cryptoJs.AES.encrypt(
        JSON.stringify({
          ...data,
          email,
          apiKey: registryAPIKey,
        }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/dataset/${data.id}`,
        { payload: encodedValues },
        {
          headers: {
            "Content-Type": CONTENT_TYPE,
            Authorization: `Bearer ${t}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error updating dataset", error);
      return null;
    }
  };

  // update converted file status in ASA
  const updateFileStatus = async (data: {
    fileId: string;
    registryId: string;
    publishStatus: boolean;
  }) => {
    try {
      setLoading(true);
      const t = await getAccessTokenSilentlyWithState();
      const encodedValues = cryptoJs.AES.encrypt(
        JSON.stringify({ ...data, email, orgRef, intercomUserId }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      const response = await axios.patch(
        `${process.env.REACT_APP_ASA_API}/update-file-publish-status`,
        { payload: encodedValues },
        {
          headers: {
            "Content-Type": CONTENT_TYPE,
            Authorization: `Bearer ${t}`,
          },
        }
      );
      fetchPublisherConvertedFiles({ values: { orgRef, token: t } });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error updating file status", error);
      return null;
    }
  };

  // delete files in ASA
  const deleteFiles = async (fileIds: string[]) => {
    try {
      setLoading(true);
      const t = await getAccessTokenSilentlyWithState();
      const encodedValues = cryptoJs.AES.encrypt(
        JSON.stringify({
          orgRef,
          fileIds,
          token: t,
          apiKey: registryAPIKey,
        }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_ASA_API}/delete-files`,
        { payload: encodedValues },
        {
          headers: {
            "Content-Type": CONTENT_TYPE,
            Authorization: `Bearer ${t}`,
          },
        }
      );
      fetchPublisherConvertedFiles({ values: { orgRef, token: t } });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error deleting files", error);
      return null;
    }
  };

  // replace published file with edited version in ASA
  const replaceFileWithEditVersion = async (fileId: string) => {
    try {
      setLoading(true);
      const t = await getAccessTokenSilentlyWithState();
      const encodedValues = cryptoJs.AES.encrypt(
        JSON.stringify({
          orgRef,
          fileId,
          token: t,
          apiKey: registryAPIKey,
        }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_ASA_API}/replace-file-with-edited`,
        { payload: encodedValues },
        {
          headers: {
            "Content-Type": CONTENT_TYPE,
            Authorization: `Bearer ${t}`,
          },
        }
      );
      fetchPublisherConvertedFiles({ values: { orgRef, token: t } });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error replacing file with edited version", error);
      return null;
    }
  };

  return {
    loading,
    deleteFiles,
    createDataset,
    updateDataset,
    updateFileStatus,
    replaceFileWithEditVersion,
  };
};
