import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { LinearProgress } from "app/components/linear-progress";
import { ExploreListBlockItemProps } from "app/components/explore-list-block-Item/data";
import Skeleton from "@mui/material/Skeleton";

export const ExploreListBlockItem: React.FC<ExploreListBlockItemProps> = (
  props: ExploreListBlockItemProps
) => {
  return (
    <Box
      sx={{
        background: colors.primary.white,
        padding: "20px 30px",
        border: `1px solid ${colors.secondary.iconGray}`,
      }}
      data-cy="explore-list-block-item"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [customBreakpointsMax.mobile]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
          },
        }}
      >
        <Box>
          <Typography
            fontSize="12px"
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.title}
          </Typography>
          <Typography
            fontSize={{
              xs: "14px",
              md: "18px",
            }}
            component="div"
            fontWeight="700"
            color={colors.text.title}
            lineHeight={"normal"}
            sx={{
              display: "flex",
              columnGap: "6px",
            }}
            data-cy="explore-list-block-item-description"
          >
            {props.icon ?? null} {props.description}
          </Typography>
        </Box>
        {props.buttonText ? (
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "normal",
              flexShrink: 0,
              [customBreakpointsMax.mobile]: {
                width: "100%",
              },
            }}
            variant="contained"
            endIcon={<ArrowForward />}
            component={props.buttonLink ? Link : "button"}
            to={props.buttonLink}
          >
            {props.buttonText}
          </Button>
        ) : null}
      </Box>
      <Box height={20} />
      <Divider />
      <Box
        height={20}
        sx={{
          width: "100%",
          borderColor: colors.secondary.iconGray,
        }}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: `0.5px solid ${colors.secondary.iconGray}`,
            paddingRight: "20px",
            [customBreakpointsMax.mobile]: {
              borderRight: "none",
              paddingRight: "0",
            },
          }}
        >
          {props.leftItems?.map((item, index) => (
            <Box
              key={index}
              sx={{
                paddingBottom: "10px",
                "&:not(:last-child)": {
                  borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                },
                "&:not(:first-of-type)": {
                  paddingTop: "10px",
                },
                [customBreakpointsMax.mobile]: {
                  borderRight: "none",
                  paddingRight: "0",
                  borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
                  "&:last-child": {
                    marginBottom: "10px",
                  },
                },
                a: {
                  display: "block",
                  textDecoration: "none",
                  transition: "all 0.3s",
                  "&:hover": {
                    background: item.link
                      ? "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 22.05%, #E7E7E7 100%)"
                      : "transparent",
                  },
                },
              }}
            >
              {item.link ? (
                <Link to={item.link}>
                  <Typography
                    fontSize="12px"
                    marginBottom="5px"
                    textTransform="uppercase"
                    color={colors.text.title}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: colors.text.title,
                      justifyContent: "space-between",
                    }}
                  >
                    {props.loading ? (
                      <Skeleton
                        width="100%"
                        variant="text"
                        sx={{
                          fontSize: {
                            xs: "14px",
                            md: "18px",
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        fontSize={{
                          xs: "14px",
                          md: "18px",
                        }}
                        component="div"
                        fontWeight="700"
                        color={colors.text.title}
                        lineHeight={"normal"}
                      >
                        {item.description}
                      </Typography>
                    )}
                    <ArrowForward
                      htmlColor="#2E4063"
                      sx={{ marginTop: "-20px", marginRight: "11px" }}
                    />
                  </Box>
                </Link>
              ) : (
                <React.Fragment>
                  <Typography
                    fontSize="12px"
                    marginBottom="5px"
                    textTransform="uppercase"
                    color={colors.text.title}
                  >
                    {item.title}
                  </Typography>
                  {props.loading ? (
                    <Skeleton
                      width="100%"
                      variant="text"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          md: "18px",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      fontSize={{
                        xs: "14px",
                        md: "18px",
                      }}
                      component="div"
                      fontWeight="700"
                      color={colors.text.title}
                      lineHeight={"normal"}
                    >
                      {item.description}
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </Box>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingLeft: {
              xs: "0",
              md: "20px",
            },
          }}
        >
          <Typography
            fontSize="12px"
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.rightContent?.title}
          </Typography>
          {props.rightContent?.loading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: {
                  xs: "14px",
                  md: "18px",
                },
              }}
            />
          ) : (
            <Typography
              fontSize={{
                xs: "14px",
                md: "18px",
              }}
              component="div"
              fontWeight="700"
              color={colors.text.title}
              lineHeight={"normal"}
              marginBottom={"10px"}
            >
              {props.rightContent?.description}
            </Typography>
          )}
          {props.rightContent?.loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="calc(100% - 50px)"
            />
          ) : (
            props.rightContent?.content
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
