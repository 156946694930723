import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Astronaut } from "app/assets/vectors/Astronaut.svg";
import { SearchInput } from "app/components/search-input";
import { About } from "./components/about";
import { FAQS } from "./components/faqs";
import { Tutorials } from "./components/tutorials";
import { FurtherAssistance } from "./components/further-assistance";
import { ContactUs } from "./components/contact-us";
import { customBreakpointsMax } from "app/theme";
import { CanonicalUrl } from "app/components/canonical-url";

export const UserGuide = () => {
  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/user-guide" />
      <Box>
        <Box height={50} />

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            [customBreakpointsMax.tablet]: {
              "> svg": {
                width: "131px",
                height: "131px",
              },
            },
            [customBreakpointsMax.mobile]: {
              flexDirection: "column",
              "> svg": {
                width: "111px",
                height: "111px",
              },
            },
          }}
          data-cy="hero-container"
        >
          <Astronaut width="223" height="227" />
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box
              sx={{
                padding: "0 10px",
                [customBreakpointsMax.tablet]: {
                  padding: "0",
                },
                [customBreakpointsMax.mobile]: {
                  textAlign: "center",
                },
              }}
            >
              <Typography
                variant="h5"
                fontSize={"18px"}
                lineHeight={"24px"}
                fontWeight={"400"}
              >
                Welcome to User Guide of AIDA.
              </Typography>
              <Typography
                variant="h1"
                fontSize={{
                  xs: "36px",
                  lg: "40px",
                }}
                marginTop={"10px"}
                lineHeight={"normal"}
              >
                What can we help you find?
              </Typography>
            </Box>
            <Box
              height={{
                xs: "20px",
                lg: "30px",
              }}
            />
            <SearchInput
              showBorder
              type="text"
              placeholder="Search"
              padding="8px 8px"
            />
          </Box>
        </Box>
        <Box
          height={{
            xs: "40px",
            md: "70px",
            lg: "100px",
          }}
        />
        <About />
        <Box height={20} />
        <FAQS />
        <Box height={20} />
        <Tutorials />
        <Box
          height={{
            xs: "20px",
            md: "40px",
            lg: "100px",
          }}
        />
        <FurtherAssistance />
        <Box height={50} />
        <ContactUs />
      </Box>
    </React.Fragment>
  );
};
