import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { customBreakpointsMax } from "app/theme";

interface OverviewBlockProps {
  items: {
    name: string;
    value: string | React.ReactNode;
  }[];
  variant?: "vertical" | "grid";
}

export const OverviewBlock: React.FC<OverviewBlockProps> = (
  props: OverviewBlockProps
) => {
  const borderStyle = React.useMemo(() => {
    if (props.variant === "grid") {
      return {
        ":nth-of-type(odd)": {
          borderRight: "1px solid #E0E0E0",
        },
        [`:not(:last-of-type):not(:nth-of-type(${props.items.length - 1}))`]: {
          borderBottom: "1px solid #E0E0E0",
        },
      };
    }
    return {
      ":not(:last-child)": {
        borderRight: "1px solid #E0E0E0",
        [customBreakpointsMax.mobile]: {
          borderRight: "none",
          borderBottom: "1px solid #E0E0E0",
        },
      },
    };
  }, [props.variant, props.items]);

  const gridItemMd = React.useMemo(() => {
    if (props.variant === "grid") {
      return 6;
    }
    return 12 / props.items.length;
  }, [props.variant, props.items]);

  return (
    <Grid
      container
      spacing={{ xs: 1, md: 2 }}
      sx={{
        height: "100%",
        paddingTop: { xs: 0, md: "16px" },
        h6: {
          fontSize: "12px",
          fontWeight: "400",
        },
        h3: {
          fontSize: "24px",
          fontWeight: "700",
        },
        div: {
          gap: "10px",
          display: "flex",
          paddingBottom: {
            xs: "8px",
            md: "16px",
          },
          flexDirection: "column",
          ...borderStyle,
        },
        [customBreakpointsMax.mobile]: {
          h3: {
            fontSize: "18px",
          },
        },
      }}
      data-cy="overview-block"
    >
      {props.items.map((item) => (
        <Grid
          key={item.name}
          item
          xs={12}
          md={gridItemMd}
          justifyContent="center"
        >
          <Typography variant="h6">{item.name}</Typography>
          <Typography variant="h3">{item.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
