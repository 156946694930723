import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Popover,
  Popper,
  Typography,
} from "@mui/material";
import { colors } from "app/theme";
import { ReactComponent as DownloadIcon } from "app/assets/vectors/DownloadIcon.svg";
import React from "react";
import { SuccessIcon } from "app/assets/vectors/jsx/SuccessIcon";

const ButtonStyle = {
  display: "flex",
  alignItems: "center",
  columnGap: "5px",
  padding: "7px 10px",
};

export const DownloadButton = (props: { id: string; width?: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [selectedFormat, setSelectedFormat] = React.useState<string>("");
  const [downloaded, setDownloaded] = React.useState<boolean>(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownloaded(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? props.id : undefined;

  const formats = ["PNG", "PDF", "SVG"];

  return (
    <Box>
      <Button
        onClick={handleOpen}
        sx={{ ...ButtonStyle, width: props.width ?? "unset" }}
        variant="general"
        data-cy="download-button"
      >
        <DownloadIcon height={16} /> Download
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "16px",
            width: "272px",
            marginTop: "13px",
          }}
          data-cy="download-button-popover"
        >
          <Typography variant="button">Download</Typography>
          <Box height={16} />
          {downloaded ? (
            <Typography
              variant="body2"
              fontSize={"12px"}
              sx={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              You've Successfully Downloaded! <SuccessIcon />
            </Typography>
          ) : (
            <Grid display={"flex"} columnGap={"10px"} justifyContent={"center"}>
              {formats.map((format) => (
                <Box
                  key={format}
                  onClick={() => setSelectedFormat(format)}
                  sx={{
                    background: "#DFE3E5",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border:
                      selectedFormat === format
                        ? `1px solid ${colors.secondary.darkGrey}`
                        : "none",
                  }}
                >
                  <Typography variant="body2" fontSize={"12px"} color="#70777E">
                    {format}
                  </Typography>
                </Box>
              ))}
            </Grid>
          )}
          <Box height={16} />
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              disabled={!selectedFormat}
              variant="general"
              onClick={() => {
                if (downloaded) {
                  return handleClose();
                }
                setDownloaded(true);
              }}
            >
              {downloaded ? "Close" : "Download"}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
