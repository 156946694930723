import { enUS } from "date-fns/locale";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

export const shortDayLocale = {
  ...enUS,
  localize: {
    ...enUS.localize,
    day: (narrowDay: number) => "SMTWTFS"[narrowDay], // 'S' for Sunday, 'M' for Monday, etc.
  },
};

export interface DateRangePickerProps {
  yearOptions: number[];
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
}

export interface DateRangePickerCustomHeaderProps
  extends ReactDatePickerCustomHeaderProps {
  years: number[];
}
