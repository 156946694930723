import React from "react";

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5003 0.367188C9.70081 0.367188 9.05272 1.01527 9.05272 1.81473V9.05239H1.81473C1.01528 9.05239 0.367188 9.70048 0.367188 10.4999C0.367188 11.2994 1.01527 11.9475 1.81473 11.9475H9.05272V19.1853C9.05272 19.9847 9.70081 20.6328 10.5003 20.6328C11.2997 20.6328 11.9478 19.9847 11.9478 19.1853V11.9475H19.1853C19.9847 11.9475 20.6328 11.2994 20.6328 10.4999C20.6328 9.70048 19.9847 9.05239 19.1853 9.05239H11.9478V1.81473C11.9478 1.01528 11.2997 0.367188 10.5003 0.367188Z"
        fill={props.fill ?? "#ffffff"}
      />
    </svg>
  );
};

export const PlusIconDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50016 0.333344C5.13197 0.333344 4.8335 0.63182 4.8335 1.00001V4.33334H1.50016C1.13197 4.33334 0.833496 4.63182 0.833496 5.00001C0.833496 5.3682 1.13197 5.66668 1.50016 5.66668H4.8335V9.00001C4.8335 9.3682 5.13197 9.66668 5.50016 9.66668C5.86835 9.66668 6.16683 9.3682 6.16683 9.00001V5.66668H9.50016C9.86835 5.66668 10.1668 5.3682 10.1668 5.00001C10.1668 4.63182 9.86835 4.33334 9.50016 4.33334H6.16683V1.00001C6.16683 0.63182 5.86835 0.333344 5.50016 0.333344Z"
        fill="#454545"
      />
    </svg>
  );
};
