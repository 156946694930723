import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { YourFavouritesProps } from "app/pages/publisher/components/your-favourites/data";

export const YourFavourites: React.FC<YourFavouritesProps> = (
  props: YourFavouritesProps
) => {
  const exploreActivitiesButton = React.useMemo(() => {
    return (
      <Button
        sx={{
          padding: "0 5px",
          color: colors.primary.white,
          background: colors.primary.green,
          ":hover": {
            background: colors.shades.green[500],
          },
          ".MuiButton-endIcon": {
            marginLeft: "5px",
          },
        }}
        endIcon={<Add fontSize="small" />}
      >
        Explore Activities
      </Button>
    );
  }, []);

  const content = React.useMemo(() => {
    return (
      <Box
        sx={{
          gap: "28px",
          width: "100%",
          height: "100%",
          display: "flex",
          paddingBottom: "9px",
          flexDirection: "column",
          "> div": {
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            "> div": {
              gap: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            },
          },
          [customBreakpointsMax.tablet]: {
            flexDirection: "row",
            gap: "10px",
            "> div": {
              padding: "0 10px",
              "&:not(:first-of-type)": {
                borderLeft: `1px solid ${colors.secondary.iconGray}`,
              },
              "> h6": {
                fontWeight: "400",
              },
              "> div": {
                flexDirection: "column",
                button: {
                  fontSize: "12px",
                  width: "max-content",
                },
              },
            },
          },
          [customBreakpointsMax.mobile]: {
            flexDirection: "column",
            gap: "10px",
          },
        }}
      >
        <Box>
          <Typography variant="h6" fontSize="12px">
            Total Number of Favourites
          </Typography>
          <Box>
            <Typography variant="h3" fontSize="24px" fontWeight="700">
              {props.activeItems + props.plannedItems}
            </Typography>
            {props.activeItems + props.plannedItems === 0 &&
              exploreActivitiesButton}
          </Box>
        </Box>
        <Divider sx={{ borderColor: colors.secondary.iconGray }} />
        <Box>
          <Typography variant="h6" fontSize="12px">
            Active Favourite Activities
          </Typography>
          <Box>
            <Typography variant="h3" fontSize="24px" fontWeight="700">
              {props.activeItems}
            </Typography>
            {props.activeItems === 0 && exploreActivitiesButton}
          </Box>
        </Box>
        <Divider sx={{ borderColor: colors.secondary.iconGray }} />
        <Box>
          <Typography variant="h6" fontSize="12px">
            Planned Favourite Activities
          </Typography>
          <Box>
            <Typography variant="h3" fontSize="24px" fontWeight="700">
              {props.plannedItems}
            </Typography>
            {props.plannedItems === 0 && exploreActivitiesButton}
          </Box>
        </Box>
      </Box>
    );
  }, [props]);

  return (
    <GenericPageBlockItem
      title="Your favourites"
      description="You don't have any favourites yet."
      content={content}
      rightToolbar={
        <IconButton
          size="small"
          sx={{
            padding: "2px",
            background: colors.primary.green,
            ":hover": {
              background: colors.shades.green[200],
              "& svg": {
                color: colors.primary.green,
              },
            },
          }}
          data-cy="your-favourites-external-link"
          href="/explorer/favourites"
        >
          <ArrowOutward fontSize="small" htmlColor={colors.primary.white} />
        </IconButton>
      }
    />
  );
};
