import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import maxBy from "lodash/maxBy";
import filter from "lodash/filter";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import groupBy from "lodash/groupBy";
import Grid from "@mui/material/Grid";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { SDGChart } from "app/components/charts/sdg";
import { PieChart } from "app/components/charts/pie";
import { formatNumber } from "app/utils/formatNumber";
import { useStoreState } from "app/state/store/hooks";
import { LineChart } from "app/components/charts/line";
import { formatLocaleN } from "app/utils/formatLocale";
import { useNavigate, useParams } from "react-router-dom";
import { GeomapChart } from "app/components/charts/geomap";
import { ImpactChart } from "app/components/charts/impact";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { ChartsBlock } from "app/components/grid-blocks/charts";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import {
  IMPACT_COLUMNS,
  IMPACT_COLUMNS_TABLET,
} from "app/components/charts/impact/tableData";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { ImpactChartItemProps } from "app/components/charts/impact/data";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { getActivitiesColumns } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { InsightsBlockProps } from "app/pages/explorer/pages/locations/pages/location/components/insights-block/data";
import { useMediaQuery } from "@mui/material";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const { id } = useParams();
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");
  const navigate = useNavigate();

  const { favourites, onFavouriteButtonClick } = useFavouritesUtils();

  const location = props?.countries?.find(
    (location) => location.code.toLowerCase() === id?.toLowerCase()
  );

  const overviewStats: {
    name: string;
    value: number;
    activities: number;
    organisations: number;
    plannedActivities: number;
  } = useStoreState((state) =>
    get(state.ExplorerLocationPageOverviewStats.data, "data", {
      name: "",
      value: 0,
      activities: 0,
      organisations: 0,
      plannedActivities: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerLocationPageOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.value, true)}`,
      },
      { name: "Activities", value: overviewStats.activities },
      { name: "Planned Activities", value: overviewStats.plannedActivities },
      { name: "Organisations", value: overviewStats.organisations },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        height="100%"
        title="Overview"
        shareLink={`/explorer/locations/${id}#block-Overview`}
        loading={overviewStatsLoading}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.value, true)}
            </span>{" "}
            in {props.transactionType.label} for {location?.name} in{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <OverviewBlock items={items} variant={tablet ? "vertical" : "grid"} />
        }
      />
    );
  }, [
    location,
    props.period,
    overviewStats,
    overviewStatsLoading,
    props.transactionType,
    tablet,
  ]);

  const map = useStoreState((state) =>
    get(state.ExplorerLocationPageMap, "data.data", {
      data: [],
      geoData: [],
      coveragePercentage: 0,
    })
  );
  const mapLoading = useStoreState(
    (state) => state.ExplorerLocationPageMap.loading
  );
  const geospatialData = React.useMemo(() => {
    const expanded = props.expandedBlock === "geospatial-data";
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          loading={mapLoading}
          title="Geospatial Data"
          onExpand={() => props.setExpandedBlock("geospatial-data")}
          height={expanded ? (tablet ? "auto" : "900px") : "100%"}
          shareLink={`/explorer/locations/${id}?p=geospatial-data`}
          description={
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                {map.coveragePercentage.toFixed(2)}% of the activities
              </span>{" "}
              have provided exact locations.
            </React.Fragment>
          }
          content={
            <GeomapChart
              roam
              zoom={8}
              showScatter
              showZoomWidget
              scatterData={map.data}
              focusCountry={location?.name}
              data={filter(map.geoData, { iso2: id })}
              height={expanded && !tablet ? "750px" : "319px"}
            />
          }
          expanded={expanded}
        />
      </React.Fragment>
    );
  }, [props.expandedBlock, location, map, mapLoading, tablet]);

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerLocationPageSDGs, "data.items", {
      count: 0,
      data: [],
    })
  );
  const sdgsLoading = useStoreState(
    (state) => state.ExplorerLocationPageSDGs.loading
  );
  const sdgs = React.useMemo(() => {
    const items = sdgsData.data.map((item) => ({
      sdg: item.code,
      name: item.name,
      value: item.value,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        loading={sdgsLoading}
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        shareLink={`/explorer/locations/${id}?p=sdgs`}
        description={
          <React.Fragment>
            In {props.period},{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sdgsData.count} activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              {props.expandedBlock === "sdgs" ? null : (
                <>
                  Top six trending Sustainable Development Goals of{" "}
                  {props.period} in {location?.name}:
                </>
              )}
            </Typography>
            <Box height={28} />
            <SDGChart
              labelGroup="Activities"
              expanded={props.expandedBlock === "sdgs"}
              data={props.expandedBlock === "sdgs" ? items : items.slice(0, 6)}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, location, props.period, sdgsData, sdgsLoading]);

  const networksData: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
      category: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerLocationPageOrganisationsNetwork.data, "data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const networksLoading = useStoreState(
    (state) => state.ExplorerLocationPageOrganisationsNetwork.loading
  );
  const networks = React.useMemo(() => {
    const expanded = props.expandedBlock === "networks";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        loading={networksLoading}
        onExpand={() => props.setExpandedBlock("networks")}
        height={expanded ? (tablet ? "auto" : "900px") : "100%"}
        shareLink={`/explorer/locations/${id}?p=networks`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {networksData.nodes.length} organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              See the organisation network by their organisation type:
            </Typography>
            <NetworkChart
              id="networks-chart"
              data={networksData}
              height={expanded && !tablet ? "700px" : "450px"}
            />
          </React.Fragment>
        }
        expanded={expanded}
      />
    );
  }, [props.expandedBlock, networksData, networksLoading, tablet]);

  const budgetData: {
    bars: string[];
    series: string[];
    values: number[][];
  } = useStoreState((state) =>
    get(state.ExplorerLocationPageBudgetBySector.data, "data", {
      bars: [],
      series: [],
      values: [],
    })
  );
  const budgetLoading = useStoreState(
    (state) => state.ExplorerLocationPageBudgetBySector.loading
  );
  const topSector = React.useMemo(() => {
    const value = maxBy(
      budgetData.values,
      (value: number[]) => value[value.length - 1]
    );
    if (!value) return null;
    const index = budgetData.values.indexOf(value);
    return budgetData.series[index];
  }, [budgetData]);
  const formattedBudgetData = React.useMemo(() => {
    if (props.expandedBlock === "budget") {
      return budgetData;
    }
    return {
      series: budgetData.series,
      bars: budgetData.bars.slice(
        budgetData.bars.length - 5,
        budgetData.bars.length
      ),
      values: budgetData.values.map((v: number[]) =>
        v.slice(v.length - 5, v.length)
      ),
    };
  }, [props.expandedBlock, budgetData]);
  const budget = React.useMemo(() => {
    const expanded = props.expandedBlock === "budget";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Budget"
        loading={budgetLoading}
        onExpand={() => props.setExpandedBlock("budget")}
        height={expanded ? (tablet ? "auto" : "900px") : "100%"}
        shareLink={`/explorer/locations/${id}?p=budget`}
        description={
          <React.Fragment>
            Budget by sectors show that{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {topSector}
            </span>{" "}
            was the most funded sectors in {props.period}.
          </React.Fragment>
        }
        content={
          <BarChart
            barWidth={expanded && mobile ? "25px" : "40"}
            yAxisLabelName="USD"
            xAxisLabelRotate={0}
            data={formattedBudgetData}
            height={expanded && !tablet ? "750px" : "450px"}
          />
        }
        expanded={expanded}
      />
    );
  }, [
    topSector,
    props.period,
    budgetLoading,
    props.expandedBlock,
    formattedBudgetData,
    tablet,
    mobile,
  ]);

  const organisationsData: {
    id: string;
    name: string;
    count: number;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerLocationPageOrganisationsDonut.data, "data", [])
  );
  const organisationsLoading = useStoreState(
    (state) => state.ExplorerLocationPageOrganisationsDonut.loading
  );
  const organisations = React.useMemo(() => {
    const expanded = props.expandedBlock === "organisations";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        loading={organisationsLoading}
        onExpand={() => props.setExpandedBlock("organisations")}
        shareLink={`/explorer/locations/${id}?p=organisations`}
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {formatNumber(sumBy(organisationsData, "value"), true)}{" "}
              development aid
            </span>{" "}
            in {props.period}. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              85% of the total development aid.
            </span>
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half={!(expanded && tablet)}
            data={organisationsData}
            unit="USD"
            period={props.period}
            unitType="organisations"
            height={
              expanded
                ? mobile
                  ? "300px"
                  : "500px"
                : mobile
                ? "120px"
                : "350px"
            }
            showLabel={!(expanded && tablet)}
            showEmphasisLabel={expanded && tablet}
            labelPosition={expanded && tablet ? "center" : "outside"}
          />
        }
        expanded={expanded}
      />
    );
  }, [
    props.period,
    organisationsData,
    props.expandedBlock,
    organisationsLoading,
    mobile,
    tablet,
  ]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerLocationPageSectors.data, "data", [])
  );
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerLocationPageSectors.loading
  );
  const sectors = React.useMemo(() => {
    const Legend = (lprops: {
      percentageChange: number;
      year: string;
      value: string;
      name: string;
    }) => {
      return (
        <Box>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {lprops.name}
          </Typography>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={colors.secondary.blue}
                lineHeight={"normal"}
              >
                {lprops.value}
              </Typography>
              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                {lprops.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                fontSize="14px"
                color={
                  lprops.percentageChange > 0 ? "#2EA390" : colors.secondary.red
                }
              >
                {lprops.percentageChange > 0 && "+"}
                {lprops.percentageChange?.toFixed(2)}%
              </Typography>
              {lprops.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    const items: {
      name: string;
      value: number;
      prevValue: number;
      percentageChange: number;
      year: string;
    }[] = sectorsData.map((item) => ({
      name: item.name,
      value: item.value,
      prevValue: item.prevValue,
      percentageChange: item.changePercentage,
      year: props.period,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sectors"
        loading={sectorsLoading}
        onExpand={() => props.setExpandedBlock("sectors")}
        shareLink={`/explorer/locations/${id}?p=sectors`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sectorsData[0]?.name}
            </span>{" "}
            was the sector to have the highest percentage of growth for{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Sectors with significant funding rate changes for {props.period}:
            </Typography>
            <Box height={20} />
            <ChartsBlock
              gridCount={mobile ? 1 : tablet ? 2 : 4}
              items={items
                .slice(0, props.expandedBlock === "sectors" ? 8 : 4)
                .map((item, index) => (
                  <React.Fragment key={item.name}>
                    <LineChart
                      id={`sector-${index}`}
                      data={{
                        xAxisValues: [
                          (parseInt(item.year, 10) - 1).toString(),
                          item.year,
                        ],
                        series: [
                          {
                            name: "USD",
                            values: [item.prevValue, item.value],
                          },
                        ],
                      }}
                      yAxisLabelName="USD"
                      variant="signalLine"
                      height="250px"
                    />
                    <Box height={10} />
                    <Legend
                      {...item}
                      value={`${formatNumber(item.value, true)}`}
                    />
                  </React.Fragment>
                ))}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sectors"}
      />
    );
  }, [props.expandedBlock, sectorsData, sectorsLoading, tablet, mobile]);

  const impactData = useStoreState(
    (state) =>
      get(
        state.ExplorerLocationPageImpactResults,
        "data.data",
        []
      ) as ImpactChartItemProps[]
  );
  const impactLoading = useStoreState(
    (state) => state.ExplorerLocationPageImpactResults.loading
  );
  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        loading={impactLoading}
        onExpand={() => props.setExpandedBlock("impact")}
        shareLink={`/explorer/locations/${id}?p=impact`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {Object.keys(groupBy(impactData, "title")).length} activities
            </span>{" "}
            went above and beyond their target results in {props.period},
            showcasing noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          props.expandedBlock === "impact" ? (
            <Table
              withSearch
              rows={impactData}
              headerFontSize="12px"
              columns={tablet ? IMPACT_COLUMNS_TABLET : IMPACT_COLUMNS}
              getRowHeight={() => (tablet ? "auto" : 40)}
            />
          ) : (
            <ImpactChart items={impactData.slice(0, 4)} period={props.period} />
          )
        }
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock, impactData, impactLoading, props.period, tablet]);

  const activities = useStoreState((state) =>
    get(state.ExplorerLocationPageActivityTable, "data.data", [])
  );
  const activitiesCount = useStoreState((state) =>
    get(state.ExplorerLocationPageActivityTable, "data.count", 0)
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerLocationPageActivityTable.loading
  );
  const activitiesColumns = React.useMemo(() => {
    const cols = getActivitiesColumns(favourites, onFavouriteButtonClick);
    return cols;
  }, [favourites]);
  const recentActivities = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Recent Activities"
        altLoading={activitiesLoading}
        expanded={props.expandedBlock === "recent-activities"}
        shareLink={`/explorer/locations/${id}?p=recent-activities`}
        onExpand={() => props.setExpandedBlock("recent-activities")}
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {formatLocaleN(activitiesCount)} activities{" "}
            </span>
            taking place in {location?.name} in {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              rows={activities}
              autoHeight={false}
              headerFontSize="12px"
              columns={activitiesColumns}
              loading={activitiesLoading}
              loadMore={props.loadMoreActivities}
              hasMore={activitiesCount > activities.length}
              onRowClick={(row) =>
                row.id && navigate(`/explorer/activities/${row.id}`)
              }
            />
          </React.Fragment>
        }
      />
    );
  }, [
    location,
    activities,
    props.period,
    activitiesCount,
    activitiesColumns,
    activitiesLoading,
    props.expandedBlock,
    props.loadMoreActivities,
  ]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (props.expandedBlock) {
      case "geospatial-data":
        view = geospatialData;
        break;
      case "sdgs":
        view = sdgs;
        break;
      case "networks":
        view = networks;
        break;
      case "budget":
        view = budget;
        break;
      case "organisations":
        view = organisations;
        break;
      case "sectors":
        view = sectors;
        break;
      case "impact":
        view = impact;
        break;
      case "recent-activities":
        view = recentActivities;
        break;
      default:
        break;
    }

    return <React.Fragment>{view}</React.Fragment>;
  }, [
    props.expandedBlock,
    geospatialData,
    sdgs,
    networks,
    budget,
    organisations,
    sectors,
    impact,
    recentActivities,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const geospatialDataBlockHeight = React.useMemo(
    () => getBlockHeight("block-Geospatial Data"),
    [geospatialData]
  );
  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );
  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const budgetBlockHeight = React.useMemo(
    () => getBlockHeight("block-Budget"),
    [budget]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sectors"),
    [sectors]
  );
  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );
  const recentActivitiesBlockHeight = React.useMemo(
    () => getBlockHeight("block-Recent Activities"),
    [recentActivities]
  );

  return (
    <Grid container spacing={{ xs: "20px", md: "30px" }}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={props.expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} lg={5}>
        {overview}
      </Grid>
      <Grid item xs={12} lg={7}>
        {props.expandedBlock !== "geospatial-data" ? (
          geospatialData
        ) : (
          <ChartPlaceholder height={geospatialDataBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {props.expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              display: "none",
            },
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        {props.expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {props.expandedBlock !== "budget" ? (
          budget
        ) : (
          <ChartPlaceholder height={budgetBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={7}>
        {props.expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            [customBreakpointsMax.tablet]: {
              display: "none",
            },
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "recent-activities" ? (
          recentActivities
        ) : (
          <ChartPlaceholder height={recentActivitiesBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
