import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useHoverDirty, useMeasure } from "react-use";
import { ChartTooltip } from "app/components/charts/tooltip";
import { SDGChartProps } from "app/components/charts/sdg/data";
import { colors } from "app/theme";

const SDGChartItem: React.FC<{
  variant?: "grid" | "horizontal";
  expanded?: boolean;
  labelGroup?: string;
  data: SDGChartProps["data"][0];
}> = (props) => {
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);
  const [refContent, { width, height }] = useMeasure();

  return (
    <Grid
      ref={ref}
      sx={{
        cursor: "pointer",
        position: "relative",
        width: props.expanded
          ? { xs: "81px", md: "101px", lg: "131px" }
          : "100%",
        border: props.expanded
          ? `0.5px solid ${colors.secondary.iconGray}`
          : "none",
      }}
    >
      <Box
        ref={refContent}
        sx={{
          width: "100%",
          "> img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            display: "block",
          },
        }}
      >
        <img
          src={`/sdgs/en/${props.data.sdg}.png`}
          alt={`SDG ${props.data.sdg}`}
        />
      </Box>
      {props.expanded ? (
        <Box
          sx={{
            padding: { xs: "4px", md: "13px" },
            background: colors.secondary.lightGrey,
          }}
        >
          <Typography variant="body2" fontSize="12px">
            {props.labelGroup}
          </Typography>
          <Typography variant="h6">{props.data.value}</Typography>
        </Box>
      ) : null}
      {isHovering && (
        <ChartTooltip
          top={height / 2}
          left={(width / 3) * -1}
          title={`${props.data.value} ${props.labelGroup} for ${props.data.name}`}
          subtitle="This data is taking account of the activities that have reported their related SDGs."
        />
      )}
    </Grid>
  );
};

export const SDGChart: React.FC<SDGChartProps> = (props: SDGChartProps) => {
  return (
    <Box
      data-cy="sdg-chart"
      display={
        props.variant === "horizontal" || props.expanded ? "flex" : "grid"
      }
      gap={
        props.expanded
          ? { xs: "12px", md: "29px", lg: "20px" }
          : { xs: "5px", lg: "8px" }
      }
      flexWrap={
        props.variant === "horizontal" && !props.expanded ? "nowrap" : "wrap"
      }
      width={"100%"}
      gridTemplateColumns={"1fr 1fr 1fr"}
    >
      {props.data.map((item) => (
        <SDGChartItem key={item.sdg} {...props} data={item} />
      ))}
    </Box>
  );
};
