import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Sort from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Download from "@mui/icons-material/Download";
import { colors, customBreakpointsMax } from "app/theme";
import ControlPoint from "@mui/icons-material/ControlPoint";
import { SortByButton } from "app/components/sort-by-button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { SampleFiles } from "app/assets/vectors/jsx/SampleFiles";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { ROW_HEIGHT, TableProps } from "app/components/table/data";
import ViewColumnOutlined from "@mui/icons-material/ViewColumnOutlined";
import {
  GridRow,
  DataGrid,
  GridRowProps,
  GridToolbarProps,
  useGridApiContext,
  GridSlotsComponent,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const CustomGridToolbar = (props: GridToolbarProps) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        alignItems: {
          xs: "flex-start",
          md: "center",
        },
        padding: "0 0 10px 0",
        borderBottom: props.toolBarBorderBottom
          ? `0.5px solid rgba(224, 224, 224, 1)`
          : "none",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        {props.tableTitle && (
          <Typography variant="h4" fontSize={{ xs: "12px", md: "18px" }}>
            {props.tableTitle}
          </Typography>
        )}
        {props.showQuickFilter && (
          <GridToolbarQuickFilter
            data-cy="quick-filter"
            variant="outlined"
            sx={{
              width: { xs: "100%", md: "auto" },
              ".MuiInputBase-root": {
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                background: colors.secondary.lightGrey,
                border: `1px solid ${colors.secondary.iconGray}`,
              },
              ".MuiInputBase-input": {
                fontSize: "14px",
                textAlign: "start",
                color: colors.secondary.grayText,
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              ".MuiSvgIcon-root": {
                color: colors.secondary.grayText,
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "auto" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: "5px",
        }}
      >
        <Box
          sx={{
            gap: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {props.showAddFileButton && (
            <IconButton
              data-cy="table-add-button"
              onClick={() => props.onAddButtonClick()}
            >
              <ControlPoint fontSize="small" htmlColor={colors.text.title} />
            </IconButton>
          )}
          {props.showSampleFilesDownloadButton && (
            <IconButton
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_ASA_API}/file-templates/activity.zip`,
                  "_blank"
                );
              }}
            >
              <SampleFiles style={{ transform: "scale(0.8)" }} />
            </IconButton>
          )}
          {props.showDeleteButton && (
            <IconButton onClick={props.onDeleteButtonClick}>
              <DeleteOutline fontSize="small" />
            </IconButton>
          )}
          {props.showDownloadButton && (
            <IconButton onClick={props.onDownloadButtonClick}>
              <Download fontSize="small" />
            </IconButton>
          )}
          {props.showSearchButton && (
            <IconButton>
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {props.showColumnsButton && (
          <IconButton>
            <ViewColumnOutlined
              fontSize="small"
              htmlColor={colors.text.title}
            />
          </IconButton>
        )}
        {props.showFilterButton && (
          <IconButton onClick={() => apiRef.current.showFilterPanel()}>
            <Sort fontSize="small" htmlColor={colors.text.title} />
          </IconButton>
        )}
        {props.showPrintButton && (
          <IconButton onClick={() => {}}>
            <PrintOutlinedIcon
              fontSize="medium"
              htmlColor={colors.text.title}
            />
          </IconButton>
        )}
        {props.showCollapseButton && (
          <Button
            aria-describedby={"collapse"}
            variant="contained"
            onClick={() => props.onCollapseAllButtonClick()}
            sx={{
              fontSize: "12px",
              padding: "8px 10px",
              lineHeight: "normal",
              background: colors.secondary.lightGrayText,
            }}
          >
            Collapse All
          </Button>
        )}
        {props.showSortButton && <SortByButton id="sortBy" />}
      </Box>
    </GridToolbarContainer>
  );
};

const CustomGridRow = (props: GridRowProps) => {
  const detailPanelContent = React.useMemo(
    () => props?.getDetailPanelContent?.(props.row),
    [props.row, props?.getDetailPanelContent]
  );

  return (
    <React.Fragment key={props.row.id}>
      <GridRow
        {...props}
        rowHeight={
          detailPanelContent ? props.dimensions.rowHeight : props.rowHeight
        }
        key={props.row.id}
      >
        {props.children}
      </GridRow>
      {detailPanelContent ? (
        <React.Fragment>{detailPanelContent}</React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export const Table: React.FC<TableProps> = (props: TableProps) => {
  const slots: Partial<GridSlotsComponent> | undefined = React.useMemo(() => {
    let _slots: Partial<GridSlotsComponent> | undefined = {
      row: CustomGridRow,
      toolbar: CustomGridToolbar,
    };
    if (props.hideHeader) {
      _slots = {
        ..._slots,
        columnHeaders: () => null,
      };
    }
    return _slots;
  }, [props.withSearch]);

  const tableEl = React.useRef<any>();

  const scrollListener = () => {
    if (!tableEl.current) return;
    const el = document.querySelector(".MuiDataGrid-scrollbar");
    // @ts-ignore
    let bottom = el?.scrollHeight - el?.clientHeight;
    const distanceBottom = Math.round(bottom * 0.2);

    if (
      // @ts-ignore
      el?.scrollTop > bottom - distanceBottom &&
      props.hasMore &&
      !props.loading &&
      props.loadMore
    ) {
      props.loadMore();
    }
  };

  React.useLayoutEffect(() => {
    let tableRef = document.querySelector(".MuiDataGrid-scrollbar");
    setTimeout(() => {
      tableRef = document.querySelector(".MuiDataGrid-scrollbar");
      if (!tableRef) return;
      tableRef.addEventListener("scroll", scrollListener);
    }, 100);
    return () => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [tableEl.current]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
        ".MuiDataGrid-root": {
          borderRadius: "0px !important",
        },
      }}
    >
      <DataGrid
        data-cy="data-grid-table"
        hideFooter
        ref={tableEl}
        slots={slots}
        rows={props.rows}
        columns={props.columns}
        loading={props.loading}
        disableRowSelectionOnClick
        onRowClick={props.onRowClick}
        getRowHeight={props.getRowHeight}
        rowSelection={props.rowSelection}
        autoHeight={props.autoHeight ?? true}
        checkboxSelection={props.rowSelection}
        getRowClassName={props.getRowClassName}
        rowHeight={props.rowHeight ?? ROW_HEIGHT}
        columnHeaderHeight={props.rowHeight ?? ROW_HEIGHT}
        onRowSelectionModelChange={props.onRowSelectionModelChange}
        columnVisibilityModel={{
          id: false,
          registryId: false,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: props.withSearch,
            showFilterButton: props.withFilterButton,
            showAddFileButton: props.withAddFileButton,
            showColumnsButton: props.withColumnsButton,
            showSortButton: props.withSortButton,
            showCollapseButton: props.withCollapseButton,
            showDeleteButton: props.withDeleteButton,
            showSearchButton: props.withSearchButton,
            showDownloadButton: props.withDowloadButton,
            showPrintButton: props.withPrintButton,
            showSampleFilesDownloadButton: props.withSampleFilesDownloadButton,
            toolBarBorderBottom: props.toolBarBorderBottom,
            onAddButtonClick: props.onAddButtonClick,
            onCollapseAllButtonClick: props.onCollapseAllButtonClick,
            tableTitle: props.title,
            onDeleteButtonClick: props.onDeleteButtonClick,
            onDownloadButtonClick: props.onDownloadButtonClick,
          },
          row: {
            getDetailPanelContent: props.getDetailPanelContent,
          },
        }}
        sx={{
          borderStyle: "none",
          ".MuiDataGrid-row--borderBottom": {
            background: "transparent !important",
          },
          ".MuiDataGrid-main": {
            border: `1px solid ${colors.secondary.lightGrayText}`,
          },
          ".MuiDataGrid-scrollbar": {
            scrollbarWidth: "none",
          },
          ".MuiDataGrid-toolbarContainer": props.withSearch
            ? {
                "> div:first-of-type": {
                  width: "100%",
                  "> div": {
                    width: "100%",
                  },
                },
              }
            : {},
          ".MuiDataGrid-virtualScroller": {
            height: props.rows.length < 8 ? "auto" : "650px",
            overflowY: "scroll !important",
            scrollbarWidth: "thin",
          },
          ".MuiDataGrid-columnHeaders": {
            background: colors.secondary.lightGrey,
          },
          ".MuiDataGrid-columnHeader": {
            borderBottomStyle: "none !important",
            borderRight: `1px solid ${colors.secondary.lightGrayText}`,
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: props.headerFontSize ?? "12px",
            fontWeight: "700",
            color: colors.secondary.darkGrey,
            [customBreakpointsMax.tablet]: {
              fontSize: props.headerFontSize ?? "12px",
            },
          },
          ".MuiDataGrid-row": {
            maxHeight: "none !important",
            ":hover": {
              cursor: props.onRowClick ? "pointer" : "default",
              background: props.onRowClick
                ? colors.secondary.lightGrey
                : "transparent",
            },
            ".MuiDataGrid-cell": {
              ":last-of-type": {
                paddingRight: "20px",
              },
            },
          },
          ".MuiDataGrid-cell": {
            fontSize: "12px",
            minHeight: "38px",
            height: "auto",
            borderTop: `1px solid ${colors.secondary.lightGrayText}`,
            borderRight: `1px solid ${colors.secondary.lightGrayText}`,
            ":focus": {
              outline: "none",
            },
            ":active": {
              outline: "none",
            },
          },
          ".MuiCheckbox-root": {
            ".MuiSvgIcon-root": {
              width: "18px",
              height: "18px",
              fill: colors.secondary.darkGrey,
            },
          },
        }}
      />
    </Box>
  );
};
