import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { BookmarkIconButton } from "app/components/bookmark-button";

export const TRANSACTION_COLUMNS: GridColDef[] = [
  {
    headerName: "Transaction Type",
    field: "type",
    width: 180,
  },
  { headerName: "Date", field: "date", width: 150 },
  { headerName: "Description", field: "description", flex: 1 },
  {
    headerName: "Provider",
    field: "provider",
    headerAlign: "center",
    width: 150,
  },
  {
    headerName: "Receiver",
    field: "receiver",
    headerAlign: "center",
    width: 150,
  },
  {
    headerName: "Value",
    field: "value",
    headerAlign: "center",
    width: 150,
    renderCell: (params: any) => formatFinancialValue(params.value),
  },
];

export const TRANSACTION_TABLE_DATA = [
  {
    id: "1",
    type: "Disbursement",
    date: "2021-01-01",
    description: "EF XI-IATI-EC_NEAR-2020-PCC-413822-SCR.CTR.413822.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "3,000,000 EUR",
  },
  {
    id: "2",
    type: "Outgoing Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "3",
    type: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "4",
    type: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "5",
    type: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "6",
    type: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "7",
    type: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "8",
    type: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
];

export const RELATED_ACTIVITIES = [
  {
    id: "1",
    title: "ITS Establishement of Nagpur-Mumbai Super Communication Expressway",
    country: "Multiple Countries",
    status: "Pipeline/Identification",
    plannedStartDate: "30 June 2024",
    plannedEndDate: "30 December 2027",
  },
  {
    id: "2",
    title:
      "Competitiveness Improvement of Agriculture and Allied Sectors Project in the Union Territory of Jammu and Kashmir",
    country: "India",
    status: "Suspended",
    plannedStartDate: "30 June 2024",
    plannedEndDate: "30 December 2027",
  },
];

export const RELATED_ACTIVITY_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",
    renderCell: (params: any) => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BookmarkIconButton />
      </Box>
    ),
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "locations",
    headerName: "Location(s)",
    width: 150,
    renderCell: (params: any) => {
      if (params.value.length === 0) {
        return null;
      }
      const multi = params.value.length > 1;
      let name = params.value[0].name;
      let code = params.value[0].code.toLowerCase();
      if (multi) {
        name = "Multiple countries";
        code = "";
      }
      return (
        <Tooltip
          title={
            multi ? (
              <Box gap="5px" display="flex" flexDirection="column">
                {params.value.map((location: any) => (
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    columnGap={"5px"}
                    height={"100%"}
                  >
                    <CircleFlag
                      countryCode={location.code.toLowerCase()}
                      height="12"
                      width="12"
                    />
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={400}
                    >
                      {location.name}
                    </Typography>
                  </Grid>
                ))}
              </Box>
            ) : (
              ""
            )
          }
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            columnGap={"5px"}
            height={"100%"}
          >
            <CircleFlag countryCode={code} height="12" width="12" />
            <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
              {name}
            </Typography>
          </Grid>
        </Tooltip>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "startDate",
    headerName: "Planned Start Date",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "endDate",
    headerName: "Planned End Date",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];
