import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { NetworkChart } from "app/components/charts/network";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { SDGSampleData } from "app/components/charts/sdg/data";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { LandingPageWidget } from "app/components/landing-page-widget";
import { NetworkSampleData } from "app/components/charts/network/data";
import { BarChartStackedSampleData } from "app/components/charts/bar/data";
import { LandingPageBlockItem } from "app/components/landing-page-block-item";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import { SectorWidgetInfoText } from "app/pages/home/explorer/components/iati-in-numbers/data";

export const IatiDataInNumbers: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  const SectorWidgets = () => {
    const data = [
      {
        title: "Disaster prevention and preparedness",
        description: `Disbursement for ${defaultYear}`,
        valueText: "USD 721K",
        percentageChange: 23.01,
      },
      {
        title: "Development Food Assistance",
        description: `Disbursement for ${defaultYear}`,
        valueText: "USD 367K",
        percentageChange: 18.03,
      },
      {
        title: "Energy policy and administrative management",
        description: `Disbursement for ${defaultYear}`,
        valueText: "USD 1,156K",
        percentageChange: 15.03,
      },
      {
        title: "Employment creation",
        description: `Disbursement for ${defaultYear}`,
        valueText: "USD 239K",
        percentageChange: 6.08,
      },
    ];
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr",
            },
          }}
        >
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                borderBottom: {
                  xs: `0.5px solid ${colors.secondary.iconGray}`,
                  md: "none",
                  lg: `0.5px solid ${colors.secondary.iconGray}`,
                },
                borderRight: {
                  xs: `none`,
                  md: `0.5px solid ${colors.secondary.iconGray}`,
                  lg: `none`,
                },
                "&:last-of-type": {
                  borderBottom: "none",
                  borderRight: "none",
                },
                paddingBottom: "9.5px",
                marginBottom: "9.5px",
              }}
            >
              <LandingPageWidget
                title={item.title}
                description={item.description}
                valueText={item.valueText}
                percentageChange={item.percentageChange}
                infoText={<SectorWidgetInfoText />}
              />
            </Box>
          ))}
        </Box>
        <Box height={20} />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Typography
        variant="h3"
        fontSize={{
          xs: "24px",
          lg: "36px",
        }}
      >
        Publish your Data, Join the IATI Network
      </Typography>
      <Box height={10} />
      <Typography
        variant="body2"
        fontSize="12px"
        display={{
          xs: "none",
          lg: "block",
        }}
      >
        Collaboration is key in the world of international development. By
        publishing your data, you can easily share information with partners,
        stakeholders, and peer organizations worldwide. Foster collaborations
        that amplify your impact.
      </Typography>
      <Box height={20} />
      <Grid
        container
        spacing={{
          xs: "20px",
          lg: "30px",
        }}
      >
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <SDGChart
                labelGroup="Activities"
                data={SDGSampleData.slice(0, 9)}
              />
            }
            description={
              <React.Fragment>
                In {defaultYear}, available results show activities surpassed
                about
                <span style={{ color: colors.primary.blue }}> 4.8% </span> of
                their target results on average.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <BarChart
                barWidth={mobile ? "24px" : tablet ? "15px" : "30px"}
                height={mobile ? "232px" : tablet ? "200px" : "350px"}
                yAxisLabelName="USD"
                xAxisLabelRotate={0}
                data={BarChartStackedSampleData}
                xAxisLabelFontSize={mobile ? 8 : tablet ? 7 : 10}
                yAxisLabelFontSize={mobile ? 8 : tablet ? 7 : 10}
              />
            }
            title={`Funding gaps presented for the year of ${defaultYear} by sector.`}
            description={
              <React.Fragment>
                Funding gaps analysis by sectors shows that
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  Productive Sectors{" "}
                </span>{" "}
                of were the least funded sectors of {defaultYear}.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <NetworkChart
                id="landing-page-network-chart"
                data={NetworkSampleData}
                height={mobile ? "232px" : tablet ? "200px" : "310px"}
              />
            }
            title={`Donor network present for the year ${defaultYear}, separated by sectors.`}
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  1528 organisations
                </span>{" "}
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <Box
        sx={{
          display: "flex",
          columnGap: { xs: "12px", md: "20px" },
        }}
      >
        <CtaCommonBtn
          variant={"large"}
          label={
            mobile
              ? "AIDA Explorer"
              : tablet
              ? "Your Favourites"
              : "Explore Insights"
          }
          text={
            !mobile
              ? tablet
                ? "Introducing 'Your Bookmarks' - your personalized area for humanitarian insights."
                : "Unlock the Power of Data for Impactful Fundraising. Discover valuable insights and opportunities to elevate your fundraising efforts like never before."
              : undefined
          }
          link="/explorer"
          bgIcon={<ExplorerLargeCtaIcon />}
        />
        <CtaCommonBtn
          variant={"large"}
          label={
            mobile
              ? "AIDA Publisher"
              : tablet
              ? "Publish Your Data"
              : "Publish Your Information to IATI"
          }
          text={
            !mobile
              ? tablet
                ? "Join the ranks of NGOs that are already benefiting from AIDA Publication Service."
                : "Join the ranks of NGOs that are already benefiting from AIDA Publication Service. Embrace transparency, engage more donors, and make a difference that truly matters."
              : undefined
          }
          link="/publisher"
          bgIcon={<PublisherLargeCtaIcon />}
          bgColor="green"
        />
      </Box>
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <Grid
        container
        spacing={{
          xs: "20px",
          md: "30px",
        }}
        columns={131}
        flexDirection={{ xs: "column-reverse", lg: "row" }}
      >
        <Grid item xs={131} lg={40}>
          <LandingPageBlockItem
            content={<SectorWidgets />}
            title={`"Sectors with significant funding rate changes for ${defaultYear}."`}
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  Disaster Prevention and Preparedness
                </span>{" "}
                was the sector to have the highest percentage of growth for
                {defaultYear}.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={131} lg={91}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <Box height={20} />
                <PieChart
                  id="organisation-pie-chart"
                  half
                  donut
                  data={PieChartSampleData}
                  unit="USD"
                  height={mobile ? "130px" : tablet ? "300px" : "500px"}
                />
              </React.Fragment>
            }
            title="Sectors to which resources were allocated."
            description={
              <React.Fragment>
                Top 10 organisations provides in total of
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  678,2 million development aid{" "}
                </span>{" "}
                in {defaultYear}. Which makes up the
                <span style={{ color: colors.primary.blue }}>
                  {" "}
                  85% of the total development aid.{" "}
                </span>
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
