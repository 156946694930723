import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDebounce } from "react-use";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { ExplorerSearch } from "app/pages/explorer/components/search";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { PeriodSelectButton } from "app/components/period-select-button";
import { InsightsBlock } from "app/pages/explorer/components/insights-block";
import { emptySearchResults } from "app/pages/explorer/components/search/data";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { KeyProjectionsBlock } from "app/pages/explorer/components/key-projections-block";
import { ReactComponent as ExplorerHeaderIcon } from "app/assets/vectors/ExplorerHeader.svg";
import { customBreakpointsMax } from "app/theme";
import { useMediaQuery } from "@mui/material";
import { CanonicalUrl } from "app/components/canonical-url";

const box = (
  <Box
    height={{
      xs: "20px",
      md: "30px",
      lg: "40px",
    }}
  />
);

export const Explorer: React.FC = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  // component state
  const [search1, setSearch1] = React.useState("");
  const [transactionType1, setTransactionType1] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();
  const [selectedPeriod1, setSelectedPeriod1] = React.useState({
    start: new Date(`${defaultYear}-01-01`),
    end: new Date(`${defaultYear}-12-31`),
  });
  const [search2, setSearch2] = React.useState("");
  const [transactionType2, setTransactionType2] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod2, setSelectedPeriod2] = React.useState({
    start: new Date(
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`
    ),
    end: new Date("2050-12-31"),
  });
  const [searchActivitiesPage, setSearchActivitiesPage] = React.useState(1);

  // store actions
  const searchFetch = useStoreActions(
    (actions) => actions.ExplorerSearch.fetch
  );
  const overviewStatsFetch = useStoreActions(
    (actions) => actions.ExplorerOverviewStats.fetch
  );
  const locationsFetch = useStoreActions(
    (actions) => actions.ExplorerLocations.fetch
  );
  const sdgsFetch = useStoreActions((actions) => actions.ExplorerSDGs.fetch);
  const organisationsNetworkFetch = useStoreActions(
    (actions) => actions.ExplorerOrganisationsNetwork.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerBudgetBySector.fetch
  );
  const organisationsDonutFetch = useStoreActions(
    (actions) => actions.ExplorerOrganisationsDonut.fetch
  );
  const sectorsFetch = useStoreActions(
    (actions) => actions.ExplorerSectors.fetch
  );
  const impactFetch = useStoreActions(
    (actions) => actions.ExplorerImpactResults.fetch
  );
  const projectionsOverviewStatsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsOverviewStats.fetch
  );
  const projectionsLocationsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsLocations.fetch
  );
  const projectionsOrganisationsDonutFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsOrganisationsDonut.fetch
  );
  const projectionsSectorsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsSectors.fetch
  );

  // store state
  const searchResults = useStoreState((state) =>
    get(state.ExplorerSearch, "data.data", {
      activities: {
        count: 0,
        items: [],
        hasMore: false,
      },
      locations: {
        count: 0,
        items: [],
      },
      organisations: {
        count: 0,
        items: [],
      },
      sectors: {
        count: 0,
        items: [],
      },
    })
  );
  const search1Loading = useStoreState((state) => state.ExplorerSearch.loading);

  // component memoized values
  const period1Label = React.useMemo(() => {
    const startDate = selectedPeriod1.start.getDate();
    const endDate = selectedPeriod1.end.getDate();
    const startMonth = selectedPeriod1.start.getMonth() + 1;
    const endMonth = selectedPeriod1.end.getMonth() + 1;
    const startYear = selectedPeriod1.start.getFullYear();
    const endYear = selectedPeriod1.end.getFullYear();
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [selectedPeriod1]);

  const period1RouteParam = React.useMemo(() => {
    const startDate = selectedPeriod1.start.getDate();
    const endDate = selectedPeriod1.end.getDate();
    const startMonth = selectedPeriod1.start.getMonth() + 1;
    const endMonth = selectedPeriod1.end.getMonth() + 1;
    const startYear = selectedPeriod1.start.getFullYear();
    const endYear = selectedPeriod1.end.getFullYear();
    return `${startYear}-${startMonth < 10 ? "0" : ""}${startMonth}-${
      startDate < 10 ? "0" : ""
    }${startDate}|${endYear}-${endMonth < 10 ? "0" : ""}${endMonth}-${
      endDate < 10 ? "0" : ""
    }${endDate}`;
  }, [selectedPeriod1]);

  const period2Label = React.useMemo(() => {
    const startDate = selectedPeriod2.start.getDate();
    const endDate = selectedPeriod2.end.getDate();
    const startMonth = selectedPeriod2.start.getMonth() + 1;
    const endMonth = selectedPeriod2.end.getMonth() + 1;
    const startYear = selectedPeriod2.start.getFullYear();
    const endYear = selectedPeriod2.end.getFullYear();
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [selectedPeriod2]);

  const period2RouteParam = React.useMemo(() => {
    const startDate = selectedPeriod2.start.getDate();
    const endDate = selectedPeriod2.end.getDate();
    const startMonth = selectedPeriod2.start.getMonth() + 1;
    const endMonth = selectedPeriod2.end.getMonth() + 1;
    const startYear = selectedPeriod1.start.getFullYear();
    const endYear = selectedPeriod1.end.getFullYear();
    return `${startYear}-${startMonth < 10 ? "0" : ""}${startMonth}-${
      startDate < 10 ? "0" : ""
    }${startDate}|${endYear}-${endMonth < 10 ? "0" : ""}${endMonth}-${
      endDate < 10 ? "0" : ""
    }${endDate}`;
  }, [selectedPeriod2]);

  const search1Results = React.useMemo(() => {
    return {
      ...searchResults,
      activities: {
        ...searchResults.activities,
        hasMore:
          searchResults.activities.count >
          searchResults.activities.items.length,
      },
    };
  }, [searchResults]);

  const onLoadMoreSearchActivities = () => {
    if (search1Loading) return;
    searchFetch({
      addOnData: true,
      addOnDataKey: "activities",
      routeParams: { q: search1 },
      filterString: `page=${searchActivitiesPage + 1}`,
    });
    setSearchActivitiesPage((prev) => prev + 1);
  };

  // component effects
  useDebounce(
    () => {
      if (search1.length > 0) {
        searchFetch({ routeParams: { q: search1 } });
      }
    },
    250,
    [search1]
  );

  React.useEffect(() => {
    overviewStatsFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
    locationsFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
    organisationsDonutFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
    sdgsFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
  }, [period1RouteParam, transactionType1.code]);

  React.useEffect(() => {
    sectorsFetch({
      routeParams: {
        year: selectedPeriod1.start.getFullYear().toString(),
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
  }, [selectedPeriod1.start, transactionType1.code]);

  React.useEffect(() => {
    let fetchBudgetBySectorYears = [];
    const startYear = selectedPeriod1.start.getFullYear();
    const endYear = selectedPeriod1.end.getFullYear();
    if (startYear !== endYear) {
      fetchBudgetBySectorYears = [startYear.toString(), endYear.toString()];
    } else {
      fetchBudgetBySectorYears = [(startYear - 10).toString(), startYear];
    }
    fetchBudgetBySector({
      routeParams: {
        years: fetchBudgetBySectorYears.join("|"),
      },
    });
  }, [selectedPeriod1]);

  React.useEffect(() => {
    organisationsNetworkFetch({
      routeParams: {
        period: period1RouteParam,
      },
    });
    impactFetch({
      routeParams: {
        period: period1RouteParam,
      },
    });
  }, [period1RouteParam]);

  React.useEffect(() => {
    projectionsOverviewStatsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsLocationsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsOrganisationsDonutFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsSectorsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
  }, [period2RouteParam, transactionType2.code]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/explorer" />
      <Box>
        <PageHeader
          variant="explorer"
          title="AIDA Explorer: Discover Data Insights"
          subtitle="Discover insights, trends, and funding activities around the globe. Uncover valuable information to fuel your data-driven decisions."
          icon={<ExplorerHeaderIcon />}
        />
        {box}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              flexDirection: "column",
              gap: "20px",
            },
          }}
        >
          <Typography
            variant="h2"
            fontSize={{
              xs: "24px",
              lg: "32px",
            }}
          >
            Real-Time Insights: Aid Data
            {tablet ? " " : <br />} {period1Label}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "8px",
              [customBreakpointsMax.tablet]: {
                justifyContent: "space-between",
              },
              [customBreakpointsMax.mobile]: {
                display: "block",
              },
            }}
          >
            <ExplorerSearch
              searchValue={search1}
              results={search1Results}
              loading={search1Loading}
              setSearchValue={setSearch1}
              loadMore={onLoadMoreSearchActivities}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexWrap: "wrap",
                [customBreakpointsMax.mobile]: {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginTop: "10px",
                },
              }}
            >
              <TransactionTypeSelect
                value={transactionType1}
                setValue={setTransactionType1}
              />
              <PeriodSelectButton
                data={selectedPeriod1}
                setData={setSelectedPeriod1}
                width="100%"
              />
              <ShareButton id="share-button" width="100%" link="/explorer" />
              <DownloadButton id="download-button" width="100%" />
            </Box>
          </Grid>
        </Grid>
        {box}
        <InsightsBlock
          period={period1Label}
          transactionType={transactionType1}
        />
        {box}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              flexDirection: "column",
              gap: "20px",
            },
          }}
        >
          <Typography
            variant="h2"
            fontSize={{
              xs: "24px",
              lg: "32px",
            }}
          >
            The World in
            {tablet ? " " : <br />}
            {period2Label}:{tablet ? " " : <br />}
            Key Projections
          </Typography>
          <Grid
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "8px",
              [customBreakpointsMax.tablet]: {
                justifyContent: "space-between",
              },
              [customBreakpointsMax.mobile]: {
                display: "block",
              },
            }}
            id="key-projections-toolbar"
          >
            <ExplorerSearch
              loading={false}
              searchValue={search2}
              setSearchValue={setSearch2}
              results={emptySearchResults}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexWrap: "wrap",
                [customBreakpointsMax.mobile]: {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginTop: "10px",
                },
              }}
            >
              <TransactionTypeSelect
                value={transactionType2}
                setValue={setTransactionType2}
              />
              <PeriodSelectButton
                data={selectedPeriod2}
                setData={setSelectedPeriod2}
                width="100%"
              />
              {!tablet && (
                <React.Fragment>
                  <ShareButton
                    id="share-button"
                    link="/explorer#key-projections-toolbar"
                  />
                  <DownloadButton id="download-button" />
                </React.Fragment>
              )}
            </Box>
          </Grid>
        </Grid>
        {box}
        <KeyProjectionsBlock
          period={period2Label}
          transactionType={transactionType2}
        />
      </Box>
    </React.Fragment>
  );
};
