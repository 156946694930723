import { customBreakpointsMax } from "app/theme";

export interface CtaCommonBtnProps {
  label: string;
  text?: string;
  link?: string;
  onClick?: () => void;
  bgIcon?: React.ReactNode;
  bgColor?: "blue" | "green";
  variant: "footer" | "small" | "medium" | "large";
}

export const CtaCommonBtnVariantStyles = {
  footer: {
    width: {
      xs: "100%",
      md: "156px",
      lg: "205px",
    },
    height: {
      xs: "56px",
      md: "90px",
      lg: "68px",
    },
    fontSize: "14px",
    padding: {
      xs: "0 16px",
      lg: "0 14px",
    },
    icon: {
      top: {
        xs: "8px",
        md: "22px",
        lg: "13px",
      },
      right: {
        xs: "13px",
        md: "24px",
        lg: "14px",
      },
    },
    arrowIconButton: {
      top: {
        xs: "7px",
        md: "16px",
        lg: "6px",
      },
      right: {
        xs: "11px",
        md: "16px",
        lg: "7px",
      },
    },
  },
  small: {
    width: "180px",
    height: "120px",
    fontSize: "18px",
    padding: "0 20px",
    icon: {
      top: "24px",
      right: "25px",
    },
    arrowIconButton: {
      top: "20px",
      right: "20px",
    },
  },
  medium: {
    width: "245px",
    height: "120px",
    fontSize: "18px",
    padding: "0 20px",
    icon: {
      top: "25px",
      right: "25px",
    },
    arrowIconButton: {
      top: "20px",
      right: "25px",
    },
  },
  large: {
    width: "645px",
    height: { xs: "64px", md: "85px", lg: "129px" },
    fontSize: {
      xs: "14px",
      md: "24px",
      lg: "30px",
    },
    padding: {
      xs: "0 14px",
      md: "0 16px",
      lg: "0 20px",
    },
    icon: {
      top: { xs: "13px", md: "25px" },
      right: { xs: "13px", md: "24px" },
      svg: {
        width: { xs: "40px", lg: "auto" },
        height: { xs: "40px", lg: "auto" },
      },
    },
    arrowIconButton: {
      top: "20px",
      right: "19px",
    },
  },
};
