import React from "react";

const PublisherMediumCtaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.2247 42.4488C13.8725 46.9697 4.44016 47.9129 4.44016 47.9129C4.44016 47.9129 2.80785 48.4571 1.17536 46.8246C-0.457135 45.1922 0.0870865 43.5599 0.0870865 43.5599C0.0870865 43.5599 1.03032 34.1276 5.55119 18.7753C6.1942 16.5917 7.96625 14.9345 10.21 14.5508C13.191 14.041 17.8964 13.3961 24 13.0909L27.2939 12L36 20.7062L34.9091 24C34.604 30.1036 33.959 34.8089 33.4492 37.79C33.0654 40.0337 31.4082 41.8058 29.2247 42.4488Z"
        fill="#00726E"
      />
      <path
        d="M24 13.0909C24.3968 8.32976 25.9429 3.52054 26.7656 1.25648C27.0676 0.424986 27.8613 -0.100963 28.7381 0.0162986C31.2729 0.355255 36.6982 1.6352 41.5314 6.46845C46.3647 11.3017 47.6447 16.7271 47.9837 19.2618C48.101 20.1387 47.575 20.9324 46.7435 21.2345C44.4794 22.0571 39.6702 23.6032 34.9091 24C34.9091 24 33.8615 20.7129 30.5744 17.4257C27.2871 14.1385 24 13.0909 24 13.0909Z"
        fill="#004845"
      />
      <path
        d="M0.396484 45.786C3.07125 42.832 9.0737 36.2161 14.2146 30.6445C14.1213 30.2894 14.0716 29.9167 14.0716 29.5323C14.0716 27.1223 16.0253 25.1687 18.4352 25.1687C20.8453 25.1687 22.7989 27.1223 22.7989 29.5323C22.7989 31.9423 20.8453 33.896 18.4352 33.896C18.0509 33.896 17.6781 33.8462 17.3232 33.753C11.7448 38.9 5.11986 44.9105 2.17105 47.5807C1.84879 47.4052 1.51222 47.1612 1.17565 46.8247C0.823989 46.4731 0.573332 46.1213 0.396484 45.786Z"
        fill="#004845"
      />
    </svg>
  );
};

export default PublisherMediumCtaIcon;
