import React from "react";
import get from "lodash/get";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocalStorage, useSessionStorage } from "react-use";
import ProfilePicture from "app/assets/images/profile-picture.png";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { socialAuth, SocialAuthConnectionType } from "app/utils/socialAuth";
import { ReactComponent as GoogleIcon } from "app/assets/vectors/HeaderSignInViewGoogle.svg";
import { ReactComponent as LogOutIcon } from "app/assets/vectors/HeaderSignInViewLogOut.svg";
import { ReactComponent as LinkedinIcon } from "app/assets/vectors/HeaderSignInViewLinkedin.svg";
import { ReactComponent as MicrosoftIcon } from "app/assets/vectors/HeaderSignInViewMicrosoft.svg";
import { ReactComponent as MyAccountIcon } from "app/assets/vectors/HeaderSignInViewMyAccount.svg";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { useMediaQuery } from "@mui/material";

export const SignInMethods: {
  title: string;
  icon: React.ReactNode;
  type: SocialAuthConnectionType;
}[] = [
  {
    title: "Sign in with Google",
    icon: <GoogleIcon />,
    type: "google-oauth2",
  },
  {
    title: "Sign in with LinkedIn",
    icon: <LinkedinIcon />,
    type: "linkedin",
  },
  {
    title: "Sign in with Microsoft",
    icon: <MicrosoftIcon />,
    type: "windowslive",
  },
];

export const HeaderSignInView: React.FC = () => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  const location = useLocation();
  const { user, isAuthenticated, logout, isLoading } = useAuth0();

  const setActiveStep = useSessionStorage("publisher-active-step", -1)[1];
  const [_, setAuthRedirectData, clearAuthRedirectData] = useLocalStorage(
    "auth-redirect-data",
    {
      step: -1,
      pathname: "/",
    }
  );

  const loadingStoredUser = useStoreState((state) => state.user.loading);
  const clearStoredUser = useStoreActions((actions) => actions.user.clear);
  const storedUser = useStoreState((state) => get(state.user, "data.data", {}));
  const clearIntercomUser = useStoreActions(
    (actions) => actions.IntercomUser.clear
  );

  const handleSignInButtonClick = (type: SocialAuthConnectionType) => () => {
    setAuthRedirectData({ pathname: location.pathname, step: -1 });
    socialAuth(type);
  };

  const handleLogOutButtonClick = () => {
    clearStoredUser();
    clearIntercomUser();
    setActiveStep(-1);
    clearAuthRedirectData();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const view = React.useMemo(() => {
    if (isLoading || loadingStoredUser) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    if (isAuthenticated) {
      const profileImage = storedUser.picture ?? ProfilePicture;
      return (
        <React.Fragment>
          <Typography variant="h5" fontSize="14px" marginBottom="20px">
            Hello {user?.name?.split(" ")[0]}. You are signed in.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              [customBreakpointsMax.mobile]: {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
                "> img": {
                  width: "62px",
                  height: "62px",
                  borderRadius: "50%",
                  border: `1px solid ${colors.secondary.iconGray}`,
                },
                [customBreakpointsMax.mobile]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <img src={profileImage} alt="Profile" />
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",

                  "> div": {
                    gap: "5px",
                    display: "flex",
                    minWidth: "150px",
                    flexDirection: "column",
                  },
                  [customBreakpointsMax.mobile]: {
                    display: "none",
                  },
                }}
              >
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Organisation
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    {get(storedUser, "group", "-")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Plan
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    {get(storedUser, "app_metadata.pricingPlan.name", "-")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontSize="12px" fontWeight="400">
                    Publisher identifier
                  </Typography>
                  <Typography variant="h5" fontSize="14px">
                    {get(
                      storedUser,
                      "app_metadata.publisherInfo.publisher_iati_id",
                      "-"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                startIcon={<LogOutIcon />}
                onClick={handleLogOutButtonClick}
                sx={{
                  gap: "10px",
                  height: "32px",
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: "700",
                  borderRadius: "5px",
                  alignItems: "center",
                  textTransform: "none",
                  justifyContent: "center",
                  color: `${colors.primary.white} !important`,
                  background: colors.secondary.red,
                  ":hover": {
                    background: colors.secondary.darkRed,
                  },
                  "> .MuiButton-icon": {
                    margin: 0,
                  },
                  [customBreakpointsMax.mobile]: {
                    marginTop: "20px",
                  },
                }}
                data-cy="nav-log-out-button"
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          fontSize="14px"
          marginBottom={{
            xs: "20px",
            lg: "40px",
          }}
        >
          Quick Log in Actions
        </Typography>
        <Box
          sx={{
            gap: "30px",
            display: "flex",
            flexDirection: "row",
            [customBreakpointsMax.mobile]: {
              flexDirection: "column",
              alignItems: "center",
            },
            "> button": {
              gap: "10px",
              width: "190px",
              height: "32px",
              display: "flex",
              fontSize: "12px",
              fontWeight: "700",
              borderRadius: "5px",
              alignItems: "center",
              textTransform: "none",
              justifyContent: "center",
              color: colors.primary.white,
              background: colors.primary.blue,
              ":hover": {
                background: colors.shades.blue[400],
              },
            },
          }}
        >
          {SignInMethods.map((method) => (
            <Button
              startIcon={method.icon}
              key={method.title}
              onClick={handleSignInButtonClick(method.type)}
              data-cy="sign-in-method-button"
            >
              {method.title}
            </Button>
          ))}
        </Box>
      </React.Fragment>
    );
  }, [user, isLoading, isAuthenticated, loadingStoredUser, storedUser]);

  return (
    <Grid
      sx={{
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        display: "grid",
        gap: "20px",
        [customBreakpointsMax.tablet]: {
          display: "flex",
          flexDirection: "column-reverse",
          gap: "20px",
        },
      }}
    >
      <Grid
        gridColumn={{
          xs: "span 12",
          lg: "span 4",
        }}
        sx={{
          borderRight: "1px solid #A1AEBD",
          [customBreakpointsMax.tablet]: {
            borderRight: "none",
          },
        }}
      >
        {!tablet && (
          <Typography variant="h5" fontSize="14px" marginBottom="24px">
            See Your Account & Log in
          </Typography>
        )}
        <HeaderViewCommonBlockItem
          title="My Account"
          icon={<MyAccountIcon />}
          subtitle="Connect to your account & see your settings."
          link="/account-profile"
        />
      </Grid>
      <Grid
        gridColumn={{
          xs: "span 12",
          lg: "span 8",
        }}
      >
        {view}
      </Grid>
    </Grid>
  );
};
