import axios from "axios";

export function downloadFileFromSpaces(
  url: string,
  fileName: string,
  type: "xml" | "json",
  dataFileType: "activities" | "validation"
) {
  return axios
    .get(
      `${process.env.REACT_APP_ASA_API}/get-file-content?key=${dataFileType}/${
        url.split(`/${dataFileType}/`)[1]
      }`
    )
    .then((response) => {
      const file = new Blob([
        type === "json"
          ? JSON.stringify(JSON.parse(response.data.content))
          : response.data.content,
      ]);
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${fileName}.${type}`);
      link.click();
    })
    .catch((error) => {
      console.log(error);
    });
}
