import { Box, Typography, useMediaQuery } from "@mui/material";
import { LandingPageCarousel } from "app/components/carousel";
import { colors, customBreakpointsMax } from "app/theme";
import ISS from "app/assets/images/iss.png";
import TearFund from "app/assets/images/tearfund.png";
import React from "react";
import { QuoteLeft } from "app/assets/vectors/jsx/quoteLeft";

export const HearPublishers = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const items = [
    {
      text: "AIDA is a practical and easy to use tool to complete the donor reporting obligations, timely and accurately. It is refreshing to see how the tool has evolved to drive better and more efficient services to its customers, specially the validation functionality.",
      image: ISS,
      imageAlt: "iss",
      imageWidth: "195px",
      imageHeight: "75.528px",
      subtitle: (
        <span>
          <b> Thavan Rajoo</b> {mobile && <br />}Director of Finance as ISS
        </span>
      ),
    },
    {
      text: "Using AIDA as a publishing tool was seamless! With the right toolkit and AIDA Data Services, I published my data in just a week, meeting the donor deadline. Although understanding the IATI structure was challenging initially, helpdesk support provided valuable assistance. AIDA is recommended for its ease of use and efficient validation checks.",
      image: TearFund,
      imageAlt: "tearfund",
      imageWidth: "194.182px",
      imageHeight: "68.352px",
      subtitle: (
        <span>
          <b> Sander Venema</b> {mobile && <br />}Project Controller at Tearfund
          NL
        </span>
      ),
    },
  ];
  const QuoteTypography = ({ children }: { children: React.ReactNode }) => (
    <Typography
      variant="h5"
      textAlign={"center"}
      sx={{
        position: "relative",
        [customBreakpointsMax.tablet]: {
          svg: {
            width: "30px",
            height: "30px",
            path: {
              fill: colors.shades.blue[200],
            },
          },
        },
      }}
      fontSize={{
        xs: "14px",
        md: "18px",
        lg: "24px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "-30px",
          left: "-60px",
          [customBreakpointsMax.tablet]: {
            left: "-30px",
          },
        }}
      >
        <QuoteLeft />
      </Box>

      {children}
      <Box
        sx={{
          position: "absolute",
          bottom: "-30px",
          right: "-30px",
          transform: "rotate(180deg)",
          [customBreakpointsMax.tablet]: {
            right: "0px",
          },
        }}
      >
        <QuoteLeft />
      </Box>
    </Typography>
  );

  return (
    <React.Fragment>
      <Typography
        variant="h3"
        fontSize={{
          xs: "24px",
          lg: "36px",
        }}
      >
        Hear Publishers' Stories of Impact
      </Typography>
      <Box height={10} />
      <Typography variant="body2" fontSize={"12px"}>
        See testimonials from publishers who have successfully shared their
        data, along with their experiences.
      </Typography>
      <Box height={20} />

      <LandingPageCarousel>
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: "30px 80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "20px",
              "> img": {
                width: { xs: "131px", md: "195px" },
                height: "75.528px",
                objectFit: "contain",
              },
            }}
          >
            <img src={item.image} alt={item.imageAlt} />
            <QuoteTypography>{item.text}</QuoteTypography>
            <Typography
              variant="subtitle2"
              fontSize={{
                xs: "14px",
                md: "18px",
              }}
              textAlign={"center"}
            >
              {item.subtitle}
            </Typography>
          </Box>
        ))}
      </LandingPageCarousel>
    </React.Fragment>
  );
};
