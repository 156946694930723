import { Box, Icon, IconButton, Popover, Typography } from "@mui/material";
import { SettingsIcon } from "app/assets/vectors/jsx/SettingsIcon";
import { colors } from "app/theme";
import React from "react";

export const ConnectionOptionCard = ({
  option,
  setActiveSetting,
}: {
  option: {
    name: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    connected: boolean;
    fileAutomation: boolean;
    key: string;
  };
  setActiveSetting?: (value: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLElement | null
  >(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
    setAnchorEl(e.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = option.name;

  return (
    <Box
      sx={{
        border: `1px solid ${colors.secondary.iconGray}`,
        borderRadius: "5px",
        width: { xs: "100%", lg: "266px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "10px 20px",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {<option.icon />}
        <Box>
          <Typography variant="h5" lineHeight={"20px"} fontSize={"14px"}>
            {option.name}
          </Typography>
          <Typography
            variant="h5"
            lineHeight={"20px"}
            fontSize={"14px"}
            fontWeight={"400"}
          >
            Upload
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: colors.secondary.lightGrey,
          borderRadius: "5px",
          padding: "5px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              background: option.connected
                ? "#1DDA19"
                : colors.secondary.lightGrayText,
            }}
          ></Box>
          <Typography variant="h6" fontWeight={"400"} fontSize={"12px"}>
            {option.connected ? "Connected" : "Not Connected"}
          </Typography>
        </Box>
        <IconButton
          sx={{
            padding: "0px",
          }}
          onClick={handleOpen}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "10px",
            marginTop: "17px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            "> button": {
              padding: "5px 10px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "normal",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              width: "100%",
              textAlign: "left",
              "&:disabled": {
                color: colors.secondary.lightGrayText,
              },
            },
          }}
        >
          <Typography variant="h6" component={"button"}>
            {option.connected ? "Disconnect" : "Connect"}
          </Typography>
          <Typography
            variant="h6"
            component={"button"}
            disabled={!option.fileAutomation}
            onClick={() => setActiveSetting?.(`${option.key}-file-automation`)}
          >
            File Upload Automation
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
