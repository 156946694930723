import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { PageHeader } from "app/components/page-header";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { YourDataHub } from "app/pages/publisher/components/your-data-hub";
import { PublisherBlock } from "app/pages/publisher/components/publisher-block";
import { YourFavourites } from "app/pages/publisher/components/your-favourites";
import { ReactComponent as PublisherHeaderIcon } from "app/assets/vectors/PublisherHeader.svg";
import { ReactComponent as PublisherAIDAExplorer } from "app/assets/vectors/PublisherAIDAExplorer.svg";
import { customBreakpointsMax } from "app/theme";
import { useMediaQuery } from "@mui/material";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import { CanonicalUrl } from "app/components/canonical-url";

export const Publisher: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");
  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/publisher" />
      <Box
        sx={{
          gap: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageHeader
          variant="publisher"
          title="AIDA Publisher: Publish, Validate, Visualise Data"
          subtitle="Ready to share your impact with the world? Publish your data to contribute to a global network of meaningful insights."
          icon={<PublisherHeaderIcon />}
        />
        <PublisherBlock />
        <Grid
          container
          spacing={{ xs: "20px", md: "30px" }}
          sx={{
            [customBreakpointsMax.tablet]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              "> button": {
                width: "100%",
              },
            }}
          >
            {tablet ? (
              <Box
                sx={{
                  display: "none",
                  columnGap: { xs: "12px", md: "20px" },
                  [customBreakpointsMax.tablet]: {
                    display: "flex",
                  },
                }}
              >
                <CtaCommonBtn
                  variant={"large"}
                  label={mobile ? "AIDA Explorer" : "Your Favourites"}
                  text={
                    !mobile
                      ? "Introducing 'Your Bookmarks' - your personalized area for humanitarian insights."
                      : undefined
                  }
                  link="/"
                  bgIcon={<ExplorerLargeCtaIcon />}
                />
                <CtaCommonBtn
                  variant={"large"}
                  label={mobile ? "AIDA Publisher" : "Publish Your Data"}
                  text={
                    !mobile
                      ? "Join the ranks of NGOs that are already benefiting from AIDA Publication Service."
                      : undefined
                  }
                  link="/"
                  bgIcon={<PublisherLargeCtaIcon />}
                  bgColor="green"
                />
              </Box>
            ) : (
              <CtaCommonBtn
                bgColor="blue"
                link="/explorer"
                variant="medium"
                label="AIDA Explorer"
                bgIcon={<PublisherAIDAExplorer />}
              />
            )}
            <Box sx={{ height: "30px" }} />
            <YourFavourites activeItems={0} plannedItems={0} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <YourDataHub />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
