import { createStore, persist } from "easy-peasy";
import { StoreModel } from "app/state/api/interfaces";
// Explorer
import { IntercomUser, user } from "app/state/api/action-reducers/auth/user";
import { ExplorerSearch } from "app/state/api/action-reducers/explorer/search";
import PublisherConvertedFiles from "app/state/api/action-reducers/publisher/convertedFiles";
import PublisherConvertedFileActivities from "app/state/api/action-reducers/publisher/convertedFileActivities";
import PublisherConvertedFileValidationReport from "app/state/api/action-reducers/publisher/convertedFileValidationReport";
import {
  ExplorerHomeSDGs,
  ExplorerSDGs,
} from "app/state/api/action-reducers/explorer/sdgs";
import {
  ExplorerOverviewStats,
  ExplorerProjectionsOverviewStats,
} from "app/state/api/action-reducers/explorer/overview-stats";
import {
  ExplorerBudgetBySector,
  ExplorerHomeBudgetBySector,
} from "app/state/api/action-reducers/explorer/budget";
import {
  ExplorerActivityPage,
  ExplorerActivityPageOrganisations,
  ExplorerActivityPageRelatedActivities,
  ExplorerActivityPageResults,
  ExplorerActivityPageTransactions,
  ExplorerActivityPageTransactionsLineChart,
  ExplorerActivityTable,
  ExplorerHomeActivityTable,
} from "app/state/api/action-reducers/explorer/activities";
import {
  ExplorerLocations,
  ExplorerLocationMap,
  ExplorerLocationsList,
  ExplorerLocationStats,
  ExplorerProjectionsLocations,
  ExplorerLocationPageOverviewStats,
  ExplorerLocationPageMap,
  ExplorerLocationPageSDGs,
  ExplorerLocationPageOrganisationsNetwork,
  ExplorerLocationPageOrganisationsDonut,
  ExplorerLocationPageSectors,
  ExplorerLocationPageActivityTable,
  ExplorerLocationPageBudgetBySector,
  ExplorerLocationOfTheMonth,
} from "app/state/api/action-reducers/explorer/locations";
import {
  ExplorerSectors,
  ExplorerSectorsList,
  ExplorerSectorStats,
  ExplorerSectorPartners,
  ExplorerHomeSectors,
  ExplorerProjectionsSectors,
  ExplorerSectorPageOverviewStats,
  ExplorerSectorPageLocations,
  ExplorerSectorPageSDGs,
  ExplorerSectorPageSectorFunding,
  ExplorerSectorOrganisationsDonut,
  ExplorerSectorPageActivityTable,
  ExplorerSectorOrganisationsNetwork,
  ExplorerSectorOfTheMonth,
} from "app/state/api/action-reducers/explorer/sectors";
import {
  ExplorerOrganisationsList,
  ExplorerOrganisationStats,
  ExplorerOrganisationsDonut,
  ExplorerOrganisationsNetwork,
  ExplorerOrganisationPartners,
  ExplorerHomeOrganisationsNetwork,
  ExplorerHomeOrganisationsDonut,
  ExplorerProjectionsOrganisationsDonut,
  ExplorerOrganisationPageOverviewStats,
  ExplorerOrganisationPageLocations,
  ExplorerOrganisationPageSDGs,
  ExplorerOrganisationOrganisationsNetwork,
  ExplorerOrganisationSectors,
  ExplorerOrganisationPageActivityTable,
  ExplorerOrganisationPageDatasetTable,
  ExplorerOrganisationOfTheMonth,
} from "app/state/api/action-reducers/explorer/organisations";
import {
  ExplorerActivityStatusFilterOptions,
  ExplorerLocationFilterOptions,
  ExplorerPublisherFilterOptions,
  ExplorerSectorsFilterOptions,
  ExplorerYearFilterOptions,
} from "app/state/api/action-reducers/explorer/filter-options";
import {
  ExplorerImpactResults,
  ExplorerLocationPageImpactResults,
  ExplorerOrganisationPageImpactResults,
  ExplorerSectorPageImpactResults,
} from "app/state/api/action-reducers/explorer/results";
import {
  CreateBookmark,
  DeleteBookmark,
  GetBookmarks,
} from "app/state/api/action-reducers/auth/favourites";

const storeContent: StoreModel = {
  // Explorer
  ExplorerSearch: persist(ExplorerSearch),
  ExplorerOverviewStats: persist(ExplorerOverviewStats),
  ExplorerLocations: persist(ExplorerLocations),
  ExplorerOrganisationsDonut: persist(ExplorerOrganisationsDonut),
  ExplorerSectors: persist(ExplorerSectors),
  ExplorerSDGs: persist(ExplorerSDGs),
  ExplorerBudgetBySector: persist(ExplorerBudgetBySector),
  ExplorerOrganisationsNetwork: persist(ExplorerOrganisationsNetwork),
  ExplorerImpactResults: persist(ExplorerImpactResults),
  ExplorerLocationOfTheMonth: persist(ExplorerLocationOfTheMonth),
  ExplorerLocationStats: persist(ExplorerLocationStats),
  ExplorerLocationsList: persist(ExplorerLocationsList),
  ExplorerLocationMap: persist(ExplorerLocationMap),
  ExplorerOrganisationOfTheMonth: persist(ExplorerOrganisationOfTheMonth),
  ExplorerOrganisationStats: persist(ExplorerOrganisationStats),
  ExplorerOrganisationsList: persist(ExplorerOrganisationsList),
  ExplorerOrganisationPartners: persist(ExplorerOrganisationPartners),
  ExplorerSectorPartners: persist(ExplorerSectorPartners),
  ExplorerSectorOfTheMonth: persist(ExplorerSectorOfTheMonth),
  ExplorerSectorStats: persist(ExplorerSectorStats),
  ExplorerSectorsList: persist(ExplorerSectorsList),
  ExplorerActivityTable: ExplorerActivityTable,
  ExplorerLocationFilterOptions: persist(ExplorerLocationFilterOptions),
  ExplorerPublisherFilterOptions: persist(ExplorerPublisherFilterOptions),
  ExplorerSectorsFilterOptions: persist(ExplorerSectorsFilterOptions),
  ExplorerActivityStatusFilterOptions: persist(
    ExplorerActivityStatusFilterOptions
  ),
  ExplorerYearFilterOptions: persist(ExplorerYearFilterOptions),
  // Explorer Projections
  ExplorerProjectionsOverviewStats: persist(ExplorerProjectionsOverviewStats),
  ExplorerProjectionsLocations: persist(ExplorerProjectionsLocations),
  ExplorerProjectionsOrganisationsDonut: persist(
    ExplorerProjectionsOrganisationsDonut
  ),
  ExplorerProjectionsSectors: persist(ExplorerProjectionsSectors),
  // Explorer Home
  ExplorerHomeSDGs: persist(ExplorerHomeSDGs),
  ExplorerHomeBudgetBySector: persist(ExplorerHomeBudgetBySector),
  ExplorerHomeOrganisationsNetwork: persist(ExplorerHomeOrganisationsNetwork),
  ExplorerHomeSectors: persist(ExplorerHomeSectors),
  ExplorerHomeOrganisationsDonut: persist(ExplorerHomeOrganisationsDonut),
  ExplorerHomeActivityTable: persist(ExplorerHomeActivityTable),
  // Explorer Location Page
  ExplorerLocationPageOverviewStats: persist(ExplorerLocationPageOverviewStats),
  ExplorerLocationPageMap: persist(ExplorerLocationPageMap),
  ExplorerLocationPageSDGs: persist(ExplorerLocationPageSDGs),
  ExplorerLocationPageOrganisationsNetwork: persist(
    ExplorerLocationPageOrganisationsNetwork
  ),
  ExplorerLocationPageOrganisationsDonut: persist(
    ExplorerLocationPageOrganisationsDonut
  ),
  ExplorerLocationPageBudgetBySector: persist(
    ExplorerLocationPageBudgetBySector
  ),
  ExplorerLocationPageSectors: persist(ExplorerLocationPageSectors),
  ExplorerLocationPageImpactResults: persist(ExplorerLocationPageImpactResults),
  ExplorerLocationPageActivityTable: persist(ExplorerLocationPageActivityTable),
  // Explorer Organisation Page
  ExplorerOrganisationPageOverviewStats: persist(
    ExplorerOrganisationPageOverviewStats
  ),
  ExplorerOrganisationPageLocations: persist(ExplorerOrganisationPageLocations),
  ExplorerOrganisationPageSDGs: persist(ExplorerOrganisationPageSDGs),
  ExplorerOrganisationOrganisationsNetwork: persist(
    ExplorerOrganisationOrganisationsNetwork
  ),
  ExplorerOrganisationSectors: persist(ExplorerOrganisationSectors),
  ExplorerOrganisationPageImpactResults: persist(
    ExplorerOrganisationPageImpactResults
  ),
  ExplorerOrganisationPageActivityTable: persist(
    ExplorerOrganisationPageActivityTable
  ),
  ExplorerOrganisationPageDatasetTable: persist(
    ExplorerOrganisationPageDatasetTable
  ),
  // Explorer Sector Page
  ExplorerSectorPageOverviewStats: persist(ExplorerSectorPageOverviewStats),
  ExplorerSectorPageLocations: persist(ExplorerSectorPageLocations),
  ExplorerSectorPageSDGs: persist(ExplorerSectorPageSDGs),
  ExplorerSectorOrganisationsNetwork: persist(
    ExplorerSectorOrganisationsNetwork
  ),
  ExplorerSectorPageSectorFunding: persist(ExplorerSectorPageSectorFunding),
  ExplorerSectorOrganisationsDonut: persist(ExplorerSectorOrganisationsDonut),
  ExplorerSectorPageImpactResults: persist(ExplorerSectorPageImpactResults),
  ExplorerSectorPageActivityTable: persist(ExplorerSectorPageActivityTable),
  // Explorer Activity Page
  ExplorerActivityPage: persist(ExplorerActivityPage),
  ExplorerActivityPageTransactions: persist(ExplorerActivityPageTransactions),
  ExplorerActivityPageTransactionsLineChart: persist(
    ExplorerActivityPageTransactionsLineChart
  ),
  ExplorerActivityPageOrganisations: persist(ExplorerActivityPageOrganisations),
  ExplorerActivityPageRelatedActivities: persist(
    ExplorerActivityPageRelatedActivities
  ),
  ExplorerActivityPageResults: persist(ExplorerActivityPageResults),
  // Publisher
  PublisherConvertedFiles: persist(PublisherConvertedFiles),
  PublisherConvertedFileActivities: persist(PublisherConvertedFileActivities),
  PublisherConvertedFileValidationReport: persist(
    PublisherConvertedFileValidationReport
  ),
  // Auth
  user: persist(user, { storage: "localStorage" }),
  IntercomUser: persist(IntercomUser, { storage: "localStorage" }),
  GetBookmarks: persist(GetBookmarks),
  CreateBookmark: persist(CreateBookmark),
  DeleteBookmark: persist(DeleteBookmark),
};

export const store = createStore(storeContent);
