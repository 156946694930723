export interface PeriodSelectButtonProps {
  data?: { start: Date; end: Date };
  setData: (data: { start: Date; end: Date }) => void;
  width?: string;
  buttonLabelFullPeriodString?: boolean;
}

export const ButtonStyle = {
  display: "flex",
  columnGap: "5px",
  padding: "7px 10px",
  alignItems: "center",
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day < 10 ? "0" : ""}${day} ${months[month]} ${year}`;
};

export const years = Array.from(
  { length: 2100 - 1950 + 1 },
  (_, i) => 1950 + i
);
