import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import {
  CtaCommonBtnProps,
  CtaCommonBtnVariantStyles,
} from "app/components/cta-common-btn/data";

export const CtaCommonBtn: React.FC<CtaCommonBtnProps> = (
  props: CtaCommonBtnProps
) => {
  const navigate = useNavigate();

  const variantStyles = React.useMemo(() => {
    return CtaCommonBtnVariantStyles[props.variant];
  }, [props.variant]);

  const handleClick = React.useCallback(() => {
    if (props.link) {
      navigate(props.link);
    }
  }, [props.link]);

  return (
    <ButtonBase
      sx={{
        ...variantStyles,
        gap: { xs: "5px", lg: "15px" },
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-start",
        background: colors.primary[props.bgColor ?? "blue"],
        ":hover": {
          background: colors.shades[props.bgColor ?? "blue"][400],
          "> svg": {
            background: colors.shades[props.bgColor ?? "blue"][500],
          },
          "> div > svg": {
            path: {
              fill: colors.shades[props.bgColor ?? "blue"][500],
            },
          },
        },
      }}
      onClick={handleClick}
      data-cy="cta-common-btn"
    >
      <Typography
        zIndex={1}
        fontWeight="700"
        textAlign="start"
        maxWidth={{
          xs: "calc(100% - 20px)",
          md: "calc(100% - 40px)",
          lg: "calc(100% - 60px)",
        }}
        color={colors.primary.white}
        fontSize={variantStyles.fontSize}
      >
        {props.label}
      </Typography>
      {props.text && (
        <Typography
          zIndex={1}
          fontSize={{ md: "10px", lg: "12px" }}
          fontWeight="700"
          textAlign="start"
          color={colors.primary.white}
        >
          {props.text}
        </Typography>
      )}
      {props.bgIcon && (
        <Box
          sx={{
            ...variantStyles.icon,
            zIndex: 0,
            position: "absolute",
            color: colors.primary.white,
          }}
        >
          {props.bgIcon}
        </Box>
      )}
      <ArrowOutward
        htmlColor={colors.primary.white}
        fontSize="small"
        sx={{
          ...variantStyles.arrowIconButton,
          zIndex: 1,
          padding: 0,
          borderRadius: "50%",
          position: "absolute",
          background: colors.shades[props.bgColor ?? "blue"][200],
          ":hover": {
            background: colors.shades[props.bgColor ?? "blue"][500],
          },
        }}
      />
    </ButtonBase>
  );
};
