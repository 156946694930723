import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { AccountProfileCard } from "app/components/account-profile-card";
import { SelectField } from "app/components/select-field";
import { colors } from "app/theme";
import React from "react";

export const ChangeBillingInformationSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [data, setData] = React.useState({
    name: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <AccountProfileCard
      title="Change Billing Information"
      content={
        <Grid
          container
          spacing={{ xs: "20", md: "30px" }}
          sx={{
            input: {
              width: "100%",
              height: "32px",
              fontSize: "12px",
              padding: "10px 20px",
              appearance: "none",
              "-webkit-appearance": "none",
              borderRadius: "5px",
              color: colors.text.title,
              background: colors.secondary.lightGrey,
              border: `1px solid ${colors.secondary.iconGray}`,
              ":focus": {
                outline: "none",
              },
            },
            label: {
              display: "block",
              lineHeight: "20px",
              marginBottom: "10px",
            },
          }}
        >
          <Grid item xs={12} md={6} lg={3}>
            <input
              type="text"
              placeholder="Jane Doe"
              name="name"
              id="name"
              value={data.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={9}>
            {" "}
            <input
              type="text"
              placeholder="Billing Address Lorem Ipsum"
              name="startTime"
              id="start-time"
              value={data.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={7} md={6} lg={2.5}>
            <input
              type="text"
              placeholder="X City"
              name="city"
              id="city"
              value={data.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={5} md={3.5} lg={2}>
            <input
              type="text"
              placeholder="YYYY Zip Code"
              name="zipCode"
              id="zipCode"
              value={data.zipCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <SelectField
              id="country"
              value={data.country}
              onChange={handleChange}
              name="country"
              options={[]}
              width="100%"
              variant="outlined"
              height="32px"
              placeholder="Z Country"
              fontWeight="400"
            />
          </Grid>
        </Grid>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("payment-details")}
    />
  );
};
