import React from "react";
import get from "lodash/get";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Stepper } from "app/components/stepper";
import Typography from "@mui/material/Typography";
import { useStoreState } from "app/state/store/hooks";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { usePublisherUtils } from "app/hooks/usePublisherUtils";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { PublisherBlockLayoutProps } from "app/pages/publisher/components/publisher-block/data";
import { PublisherErrorMessageModal } from "app/pages/publisher/components/publisher-block/data";
import { PublisherBlockUploadView } from "app/pages/publisher/components/publisher-block/views/upload";
import { PublisherBlockPublishView } from "app/pages/publisher/components/publisher-block/views/publish";
import { PublisherBlockValidateView } from "app/pages/publisher/components/publisher-block/views/validate";
import { PublisherBlockPreVizPopup } from "app/pages/publisher/components/publisher-block/views/preVizPopup";
import { PublisherBlockConnectToIATIView } from "app/pages/publisher/components/publisher-block/views/connectToIATI";
import { PublisherBlockValidityReportPopup } from "app/pages/publisher/components/publisher-block/views/validityReportPopup";
import { ReactComponent as PublisherAstronautMobile } from "app/assets/vectors/PublisherAstronautMobile.svg";
import { useMediaQuery } from "@mui/material";

export const PublisherBlockLayout: React.FC<PublisherBlockLayoutProps> = (
  props: PublisherBlockLayoutProps
) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const mobile = useMediaQuery("(max-width: 767px)");

  const { loading, replaceFileWithEditVersion } = usePublisherUtils();

  const loadingPublisherConvertedFiles = useStoreState(
    (state) => state.PublisherConvertedFiles.loading
  );
  const loadingPublisherConvertedFileActivities = useStoreState(
    (state) => state.PublisherConvertedFileActivities.loading
  );
  const dataPublisherConvertedFileValidationReportDate = useStoreState(
    (state) =>
      get(state.PublisherConvertedFileValidationReport, "data.date", "")
  );

  const buttons = React.useMemo(() => {
    if (
      props.activeStep === 0 ||
      props.activeStep === 1 ||
      props.activeStep === 2
    ) {
      return (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            height: "33px",
            display: "flex",
            padding: "0 15px",
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "flex-end",
            "> button": {
              padding: "2px 10px",
              color: colors.primary.white,
              background: colors.primary.green,
              ":hover": {
                background: colors.shades.green[500],
              },
              ":disabled": {
                color: colors.primary.white,
                background: colors.secondary.lightGrayText,
              },
            },
            [customBreakpointsMax.tablet]: {
              height: "auto",
              marginTop: "50px",
              button: {
                fontSize: "12px",
              },
            },
          }}
        >
          {props.activeStep === 1 && isAuthenticated && (
            <Box>
              <Tooltip
                placement="top"
                title={
                  <Box
                    sx={{
                      gap: "8px",
                      width: "230px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography fontSize="10px" fontWeight="700">
                      See the preview of your data visualisation.
                    </Typography>
                    <Typography fontSize="10px">
                      Proceed with the publishing steps to interact with and
                      expand the charts.
                    </Typography>
                  </Box>
                }
              >
                <Button
                  disableRipple
                  onClick={props.handlePreVizPopupOpenChange(true)}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: colors.text.title,
                    textDecoration: "underline",
                    ":hover": {
                      background: "transparent",
                      textDecoration: "underline",
                    },
                  }}
                >
                  See Pre-Visualisations of Your Data
                </Button>
              </Tooltip>
            </Box>
          )}
          <Button
            onClick={props.onBackClick}
            disabled={!props.isBackActive}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Tooltip
            open={props.nextButtonPopupOpen && props.activeStep === 2}
            placement="top"
            title={
              <Box
                sx={{
                  gap: "8px",
                  width: "230px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize="10px" fontWeight="700">
                  Sign Up & Register to Continue!
                </Typography>
                <Typography fontSize="10px">
                  Dear user, your credentials are needed to complete the upload
                  process, so that AIDA can match your data files with your
                  organisation!
                </Typography>
              </Box>
            }
          >
            <Button
              onClick={props.onNextClick}
              disabled={!props.isNextActive}
              endIcon={<ArrowForward />}
              data-cy="next-button"
            >
              Next
            </Button>
          </Tooltip>
        </Box>
      );
    }
    if (props.activeStep === 3) {
      return (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            height: "33px",
            display: "flex",
            padding: "0 15px",
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "flex-end",
            "> button": {
              padding: "2px 10px",
              color: colors.primary.white,
              background: colors.primary.green,
              ":hover": {
                background: colors.shades.green[500],
              },
              ":disabled": {
                color: colors.primary.white,
                background: colors.secondary.lightGrayText,
              },
            },
          }}
        >
          <Button
            onClick={props.onBackClick}
            disabled={!props.isBackActive}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Button
            disabled={!props.isNextActive}
            endIcon={<ArrowForward />}
            onClick={() => {
              props.setActiveStep(-1);
              props.clear();
              navigate("/data-hub");
            }}
          >
            Your Data Hub
          </Button>
        </Box>
      );
    }
  }, [
    props.activeStep,
    props.isBackActive,
    props.isNextActive,
    props.nextButtonPopupOpen,
    props.filename,
  ]);

  const steps = React.useMemo(() => {
    const result = [
      {
        title: "Connect Your Data",
        description:
          "Connect your files and convert to IATI. Upload your data through online data clouds, or manually.",
      },
      {
        title: "Validate & Visualise Your Data",
        description:
          "Validate & Visualise the converted files. See your validity report, browse the visualisations of your uploaded files before publishing.",
      },
      {
        title: "Connect to IATI Registry",
        description:
          "Connect to IATI registry to streamline your publishing process.",
        info: "This step is a one time process only! Register once to remove this step from your publishing flow.",
      },
      {
        title: "Publish to the IATI Registry",
        description:
          "Publish to the IATI registry and share your impact to the world.",
      },
    ];
    if (!isAuthenticated) {
      return result;
    }
    return [result[0], result[1], result[3]];
  }, [isAuthenticated]);

  const description = React.useMemo(() => {
    if (props.activeStep > -1) {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBack fontSize="small" />}
            onClick={props.onToolbarButtonClick}
            sx={{
              top: "10px",
              right: "10px",
              fontSize: "12px",
              textWrap: "nowrap",
              padding: "3px 10px",
              position: "absolute",
            }}
          >
            Back to Dashboard
          </Button>
          <Box marginTop="10px">
            <Stepper
              steps={[
                "connect your files",
                "validate & visualise",
                "connect to iati",
                "publish to iati registry",
                "",
              ]}
              activeStep={props.activeStep === 3 ? 4 : props.activeStep}
            />
          </Box>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "none",
            [customBreakpointsMax.mobile]: {
              background: colors.secondary.lightGrey,
              padding: "15px",
              display: "flex",
              gap: "30px",
              alignItems: "center",
              "> svg": {
                flexShrink: 0,
              },
            },
          }}
        >
          <PublisherAstronautMobile />

          <Typography
            sx={{
              fontSize: "10px",
            }}
          >
            Publishing function is usable for{" "}
            <b> desktop and tablet users only.</b> Log in from your desktop to
            publish your data.
          </Typography>
        </Box>
        You haven't publish{" "}
        <span
          style={{
            color: colors.primary.green,
          }}
        >
          any IATI files yet
        </span>
        . Start publishing today and visualise your impact.
        {props.activeStep === -1 ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onToolbarButtonClick}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{
              fontSize: "12px",
              textWrap: "nowrap",
              padding: "3px 10px",
              flexShrink: 0,
              display: "none",
              marginTop: "20px",
              width: "100%",
              [customBreakpointsMax.mobile]: {
                display: "flex",
              },
            }}
            disabled={mobile}
          >
            Start Publishing
          </Button>
        ) : null}
      </React.Fragment>
    );
  }, [props.activeStep, mobile]);

  const view = React.useMemo(() => {
    if (props.activeStep > -1) {
      switch (props.activeStep) {
        case 0:
          return (
            <React.Fragment>
              <PublisherBlockUploadView
                filename={props.filename ?? ""}
                uploading={props.uploading}
                setFilename={props.setFilename}
                message={props.uploadViewMessage}
                selectedFiles={props.selectedFiles}
                rejectedFiles={props.rejectedFiles}
                setSelectedFiles={props.setSelectedFiles}
                setRejectedFiles={props.setRejectedFiles}
              />
              {buttons}
            </React.Fragment>
          );
        case 1:
          return (
            <React.Fragment>
              <PublisherBlockValidateView
                activities={props.convertedFileActivities}
                loading={loadingPublisherConvertedFileActivities}
                seeTheReportAction={props.handleValidityReportOpenChange}
              />
              {buttons}
            </React.Fragment>
          );
        case 2:
          return (
            <React.Fragment>
              <PublisherBlockConnectToIATIView
                setNextButtonPopupOpen={props.setNextButtonPopupOpen}
              />
              {buttons}
            </React.Fragment>
          );
        case 3:
          return (
            <React.Fragment>
              <PublisherBlockPublishView
                files={props.convertedFiles}
                loading={loadingPublisherConvertedFiles || loading}
                replaceFileWithEditVersion={replaceFileWithEditVersion}
                changePublishStatus={
                  props.handleConvertedFilePublishStatusChange
                }
              />
              {buttons}
            </React.Fragment>
          );
        default:
          return null;
      }
    }
    return (
      <Box
        sx={{
          width: "100%",
          height: "450px",
          display: "flex",
          padding: "15px",
          flexDirection: "column",
          background: colors.secondary.lightGrey,
          [customBreakpointsMax.tablet]: {
            height: "auto",
            gap: "20px",
          },
          [customBreakpointsMax.mobile]: {
            gap: "30px",
          },
        }}
      >
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <svg width="34" height="34" viewBox="0 0 34 34">
            <path
              d="M17.0007 31.1654C24.8247 31.1654 31.1673 24.8227 31.1673 16.9987C31.1673 9.17466 24.8247 2.83203 17.0007 2.83203C9.17662 2.83203 2.83398 9.17466 2.83398 16.9987C2.83398 24.8227 9.17662 31.1654 17.0007 31.1654Z"
              fill="#66BCB9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.2216 11.015C24.7969 11.4604 24.9646 12.3123 24.5988 12.969C21.7608 18.0634 19.1784 21.453 17.6524 23.2674C16.8316 24.2433 15.5119 24.3685 14.5812 23.517C13.2525 22.3013 11.2997 20.2375 9.4309 17.2313C9.07871 16.6648 9.155 15.9094 9.59388 15.4195L10.7191 14.1636C11.243 13.5789 12.1041 13.5628 12.6325 14.1427C14.4931 16.1852 15.9362 18.0372 15.9362 18.0372C15.9362 18.0372 18.0568 14.5971 21.3497 10.4261C21.8048 9.84961 22.59 9.75214 23.1593 10.1928L24.2216 11.015Z"
              fill="#004845"
            />
          </svg>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              fontSize={{
                xs: "12px",
                md: "14px",
              }}
              fontWeight="700"
            >
              Connect and transform your data faster than ever!
            </Typography>
            <Typography
              variant="h5"
              fontSize={{
                xs: "10px",
                md: "14px",
              }}
              fontWeight="400"
            >
              Follow the streamlined {steps.length}-step upload process to share
              your impact with ease.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0 70px",
            alignItems: "center",
            justifyContent: "center",
            [customBreakpointsMax.tablet]: {
              padding: "0",
            },
          }}
        >
          <Grid
            container
            rowSpacing={{
              xs: 2,
              lg: 6,
            }}
            columnSpacing={{
              xs: 2,
              lg: 4,
            }}
          >
            {steps.map((step, index) => (
              <Grid key={step.title} item xs={12} sm={isAuthenticated ? 12 : 6}>
                <Box
                  sx={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      fontSize: "32px",
                      minWidth: "44px",
                      maxWidth: "44px",
                      minHeight: "44px",
                      maxHeight: "44px",
                      fontWeight: "700",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      color: colors.primary.white,
                      background: colors.shades.green[400],
                      [customBreakpointsMax.tablet]: {
                        minWidth: "37px",
                        maxWidth: "37px",
                        minHeight: "37px",
                        maxHeight: "37px",
                        fontSize: "28px",
                      },
                      [customBreakpointsMax.tablet]: {
                        minWidth: "20px",
                        maxWidth: "20px",
                        minHeight: "20px",
                        maxHeight: "20px",
                        fontSize: "18px",
                      },
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontSize={{
                        xs: "14px",
                        lg: "18px",
                      }}
                      fontWeight="700"
                    >
                      {step.title}
                    </Typography>
                    <Typography
                      variant="h5"
                      flexWrap="wrap"
                      fontSize={{
                        xs: "12px",
                        lg: "14px",
                      }}
                      fontWeight="400"
                      sx={{
                        [customBreakpointsMax.mobile]: {
                          display: "none",
                        },
                      }}
                    >
                      {step.description}
                    </Typography>
                    {!mobile && step.info && (
                      <Typography
                        variant="h5"
                        padding="5px"
                        flexWrap="wrap"
                        fontSize={{
                          xs: "10px",
                          lg: "12px",
                        }}
                        fontWeight="400"
                        fontStyle="italic"
                        color={colors.text.title}
                        border={`1px solid ${colors.shades.green[400]}`}
                        sx={{
                          marginTop: "5px",
                          borderRadius: "5px",
                          background: colors.shades.green[100],
                        }}
                      >
                        {step.info}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  }, [
    steps,
    loading,
    props.activeStep,
    buttons,
    props.filename,
    isAuthenticated,
    props.uploadViewMessage,
    props.convertedFiles,
    props.convertedFileActivities,
    isAuthenticated,
    props.filename,
    props.selectedFiles,
    props.rejectedFiles,
    props.uploading,
    loadingPublisherConvertedFiles,
    loadingPublisherConvertedFileActivities,
    mobile,
  ]);

  return (
    <React.Fragment>
      <GenericPageBlockItem
        title="Publisher"
        description={description}
        rightToolbar={
          props.activeStep === -1 ? (
            <Button
              color="secondary"
              variant="contained"
              onClick={props.onToolbarButtonClick}
              endIcon={<ArrowForward fontSize="small" />}
              sx={{
                fontSize: "12px",
                textWrap: "nowrap",
                padding: "3px 10px",
                flexShrink: 0,
                [customBreakpointsMax.mobile]: {
                  display: "none",
                },
              }}
              data-cy="publish-button"
            >
              Start Publishing
            </Button>
          ) : null
        }
        content={view}
      />
      <PublisherBlockPreVizPopup
        open={props.preVizPopupOpen}
        onClose={props.handlePreVizPopupOpenChange(false)}
      />
      <PublisherBlockValidityReportPopup
        fileURL={props.fileURL}
        items={props.reportData.items}
        fileName={props.filename ?? ""}
        errors={props.reportData.errors}
        value={props.validityReportPopupOpen}
        open={Boolean(props.validityReportPopupOpen)}
        title={get(props.reportData, "items[0].name", "")}
        onClose={() => props.handleValidityReportOpenChange(null)}
        date={dataPublisherConvertedFileValidationReportDate}
      />
      <PublisherErrorMessageModal {...props.modal} close={props.resetModal} />
    </React.Fragment>
  );
};
