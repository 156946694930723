import { GenericTableColumn } from "app/components/generic-table/data";

export interface ExploreListAllBlockProps {
  rows: any[];
  loading?: boolean;
  extraOptions?: string[];
  activeExtraOption?: string;
  columns: GenericTableColumn[];
  setActiveExtraOption?: (option: string) => void;
  onRowClick?: (row: any) => void;
  assetType: "Sector" | "Location" | "Organisation";
}

export const OPTIONS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "All",
];
