import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { AssistanceLaptop } from "app/assets/vectors/jsx/AssistanceLaptop";
import { customBreakpointsMax } from "app/theme";
import React from "react";

export const FurtherAssistance = () => {
  return (
    <Box>
      <Divider sx={{ margin: "5px 0" }} />
      <Box
        sx={{
          padding: "36px 0",
          display: "flex",
          gap: "50px",
          alignItems: "center",
          [customBreakpointsMax.tablet]: {
            gap: "31px",
            "> svg": {
              width: "223px",
              height: "116px",
            },
          },
          [customBreakpointsMax.mobile]: {
            flexDirection: "column-reverse",
            gap: "20px",
            "> svg": {
              width: "183px",
              height: "95px",
            },
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography
            variant="h1"
            fontSize={{
              xs: "24px",
              lg: "40px",
            }}
            lineHeight={"normal"}
          >
            Need Further Assistance?
          </Typography>
          <Typography
            marginTop={"10px"}
            variant="h4"
            fontSize={{
              xs: "14px",
              lg: "18px",
            }}
            lineHeight={"24px"}
            fontWeight={"400"}
          >
            Hi there, stuck somewhere? Our team of experts is here to help you.
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "30px",
              marginTop: "30px",
              "> button": {
                fontSize: "18px",
                padding: "10px 15px",
                lineHeight: "normal",
              },
              [customBreakpointsMax.mobile]: {
                flexDirection: "column",
                gap: "20px",
              },
            }}
          >
            <Button variant="contained" endIcon={<ArrowForward />}>
              Talk to an IATI expert
            </Button>{" "}
            <Button variant="contained" endIcon={<ArrowForward />}>
              Contact Us
            </Button>
          </Box>
        </Box>
        <AssistanceLaptop />
      </Box>
      <Divider sx={{ margin: "5px 0" }} />
    </Box>
  );
};
