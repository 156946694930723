import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import {
  BookmarkCheckIcon,
  BookmarkIcon,
  BookmarkMinusIcon,
  BookmarkPlusIcon,
} from "app/assets/vectors/jsx/Bookmark";

export const BookmarkIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      sx={{
        svg: {
          ":first-of-type": {
            display: "block",
          },
          ":last-of-type": {
            display: "none",
          },
        },
        ":hover": {
          svg: {
            ":first-of-type": {
              display: "none",
            },
            ":last-of-type": {
              display: "block",
            },
          },
        },
        ...props.sx,
      }}
      {...props}
    >
      <BookmarkIcon />
      <BookmarkPlusIcon />
    </IconButton>
  );
};

export const BookmarkCheckedIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      sx={{
        svg: {
          ":first-of-type": {
            display: "block",
          },
          ":last-of-type": {
            display: "none",
          },
        },
        ":hover": {
          svg: {
            ":first-of-type": {
              display: "none",
            },
            ":last-of-type": {
              display: "block",
            },
          },
        },
        ...props.sx,
      }}
      {...props}
    >
      <BookmarkCheckIcon />
      <BookmarkMinusIcon />
    </IconButton>
  );
};
