import React from "react";
import axios from "axios";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Edit from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import { formatDate } from "app/utils/formatDate";
import FormControl from "@mui/material/FormControl";
import Download from "@mui/icons-material/Download";
import { downloadFile } from "app/utils/downloadFile";
import Delete from "@mui/icons-material/DeleteOutline";
import ChevronRight from "@mui/icons-material/ChevronRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  GridCallbackDetails,
  GridColDef,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

export interface TableProps {
  rows: Array<object>;
  columns: GridColDef[];
  withSearch?: boolean;
  withAddFileButton?: boolean;
  withColumnsButton?: boolean;
  withFilterButton?: boolean;
  withDeleteButton?: boolean;
  withSearchButton?: boolean;
  headerFontSize?: string;
  withSortButton?: boolean;
  withCollapseButton?: boolean;
  withDowloadButton?: boolean;
  withPrintButton?: boolean;
  withSampleFilesDownloadButton?: boolean;
  getDetailPanelContent?: (row: any) => React.ReactNode;
  autoHeight?: boolean;
  getRowHeight?: (params: GridRowHeightParams) => number | "auto";
  hideHeader?: boolean;
  title?: string | React.ReactNode;
  toolBarBorderBottom?: boolean;
  onAddButtonClick?: () => void;
  onCollapseAllButtonClick?: () => void;
  onRowClick?: (params: any) => void;
  loadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
  rowHeight?: number;
  getRowClassName?: (params: GridRowClassNameParams<any>) => string;
  rowSelection?: boolean;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  onDeleteButtonClick?: () => void;
  onDownloadButtonClick?: () => void;
}

export const ROW_HEIGHT = 38;

export const GET_CONVERTED_FILE_ACTIVITIES_COLUMNS = (
  seeTheReportAction: (value: string) => void
) => {
  const onSeeTheReportClick = (value: string) => () => {
    seeTheReportAction(value);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => formatDate(params.value as string),
    },
    {
      field: "title",
      headerName: "Activity Title",
      width: 700,
      flex: 1,
    },
    {
      field: "type",
      headerName: "File Type",
      width: 100,
    },
    {
      field: "status",
      headerName: "Validity Status",
      width: 120,
      display: "flex",
      cellClassName: "validity-status-cell",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              fontSize: "12px",
              padding: "0 10px",
              alignItems: "center",
              cursor: params.value === "Valid" ? "default" : "pointer",
              backgroundColor: params.value === "Valid" ? "#E9F5F4" : "#FCEFEF",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "validityReportId",
      headerName: "Validity Report",
      width: 150,
      display: "flex",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.status === "Valid") {
          return "N/A";
        }
        return (
          <Button
            onClick={onSeeTheReportClick(params.value as string)}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: colors.text.title,
              textDecoration: "underline",
              padding: 0,
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            See the Report
          </Button>
        );
      },
    },
  ];

  return columns;
};

export interface ConvertedFileActivity {
  id: string;
  date: string;
  title: string;
  type: string;
  status: string;
  validityReportId: string;
}

export const CONVERTED_FILE_ACTIVITIES_ROWS: ConvertedFileActivity[] = [
  {
    id: "1",
    date: "2021-09-01",
    title: "Activity 1",
    type: "Activity",
    status: "Valid",
    validityReportId: "1",
  },
  {
    id: "2",
    date: "2021-09-02",
    title: "Activity 2",
    type: "Activity",
    status: "Invalid",
    validityReportId: "2",
  },
];

const downloadXMLFile = (url: string, name: string) => {
  axios
    .get(url)
    .then((response) => {
      downloadFile(name, "xml", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const ExpandComp = (props: { onClick: () => void }) => {
  const [expanded, setExpanded] = React.useState(true);

  const onExpandClick = () => {
    setExpanded(!expanded);
    props.onClick();
  };

  return (
    <React.Fragment>
      <ChevronRight
        fontSize="small"
        onClick={onExpandClick}
        htmlColor={colors.secondary.darkGrey}
        sx={{
          left: "0",
          top: "9px",
          cursor: "pointer",
          position: "absolute",
          transform: expanded ? "rotate(90deg)" : "rotate(0)",
        }}
      />
      <Box sx={{ width: "25px" }} />
    </React.Fragment>
  );
};

export const GET_CONVERTED_FILES_COLUMNS = (
  changePublishStatus: (
    fileId: string,
    value: boolean,
    isValid: boolean,
    registryFileId?: string,
    isAdmin?: boolean
  ) => void,
  showValidationReport: (fileId: string) => void,
  isAdmin: boolean,
  hideActions?: boolean,
  deleteFiles?: (fileIds: string[]) => void,
  editFile?: (fileId: string) => void,
  replaceFileWithEditVersion?: (fileId: string) => void,
  onExpand?: (row: any) => void
) => {
  const onEditFile = (id: string) => () => {
    if (editFile) editFile(id);
  };

  const onDeleteFile = (id: string) => () => {
    if (deleteFiles) deleteFiles([id]);
  };

  const onDownloadXMLBtnClick = (title: string, url: string) => () => {
    downloadXMLFile(url, title);
  };

  const onReplace = (fileId: string) => () => {
    if (replaceFileWithEditVersion) replaceFileWithEditVersion(fileId);
  };

  const onChangePublishStatus =
    (
      fileId: string,
      value: boolean,
      isValid: boolean,
      registryFileId?: string
    ) =>
    () => {
      changePublishStatus(fileId, value, isValid, registryFileId, isAdmin);
    };

  let columns: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "title",
      headerName: "File Name",
      width: 650,
      flex: 1,
      display: "flex",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {params.row.editVersion && onExpand && (
              <ExpandComp onClick={() => onExpand(params.row)} />
            )}
            <Link
              target="_blank"
              href={params.row.url as string}
              sx={{
                width: "100%",
                height: "100%",
                padding: "10px 0",
                textDecoration: "none",
                color: colors.text.title,
              }}
            >
              {params.value}
            </Link>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "File Type",
      width: 100,
    },
    {
      field: "status",
      headerName: "Validity Status",
      width: 120,
      display: "flex",
      cellClassName: "validity-status-cell",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              fontSize: "12px",
              padding: "0 10px",
              alignItems: "center",
              cursor: params.value === "Valid" ? "default" : "pointer",
              backgroundColor: params.value === "Valid" ? "#E9F5F4" : "#FCEFEF",
            }}
            onClick={() =>
              params.value !== "Valid" && showValidationReport(params.row.id)
            }
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "isPublished",
      headerName: "Publishing Status",
      width: 150,
      display: "flex",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.rowClassName === "table-row-expanded" &&
          params.row.originalFileId
        ) {
          return (
            <button
              onClick={onReplace(params.row.originalFileId)}
              style={{
                width: "100%",
                height: "22px",
                fontSize: "12px",
                fontWeight: "700",
                cursor: "pointer",
                textAlign: "start",
                borderStyle: "none",
                borderRadius: "5px",
                color: colors.primary.white,
                background: colors.primary.green,
              }}
            >
              Replace
            </button>
          );
        }
        return (
          <FormControl
            fullWidth
            sx={{
              ".MuiInputBase-root": {
                width: "100%",
                height: "22px",
                borderRadius: "5px",
                background: params.value ? colors.shades.blue[300] : "#FFF377",
              },
              ".MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "700",
                textAlign: "start",
                paddingLeft: "8px",
                paddingRight: "8px",
                color: params.value ? colors.primary.white : "#6F5C2A",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              ".MuiSelect-icon": {
                color: params.value ? colors.primary.white : "#6F5C2A",
              },
              ".MuiMenuItem-root": {
                fontSize: "12px",
                fontWeight: "700",
              },
            }}
          >
            <Select
              value={params.value ? 1 : 0}
              IconComponent={KeyboardArrowDown}
              onChange={onChangePublishStatus(
                params.rowNode.id.toString(),
                !(params.value as boolean),
                params.row.status === "Valid",
                params.row.registryId as string
              )}
            >
              <MenuItem value={1}>Published</MenuItem>
              <MenuItem value={0}>Draft</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => formatDate(params.value as string),
    },
    {
      field: "url",
      headerName: "Actions",
      width: 140,
      display: "flex",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const showDeleteButton = isAdmin || !params.row.registryId;
        return (
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              "a, button": {
                fontSize: "12px",
                cursor: "pointer",
                appearance: "none",
                borderStyle: "none",
                color: colors.text.title,
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            {editFile && (
              <button onClick={onEditFile(params.row.id)}>
                <Edit fontSize="small" htmlColor={colors.secondary.darkGrey} />
              </button>
            )}
            {showDeleteButton && deleteFiles && (
              <button onClick={onDeleteFile(params.row.id)}>
                <Delete
                  fontSize="small"
                  htmlColor={colors.secondary.darkGrey}
                />
              </button>
            )}
            <button
              onClick={onDownloadXMLBtnClick(params.row.title, params.value)}
            >
              <Download
                fontSize="small"
                htmlColor={colors.secondary.darkGrey}
              />
            </button>
          </Box>
        );
      },
    },
    {
      field: "registryId",
    },
  ];

  if (hideActions) {
    columns = columns.filter((column) => column.field !== "url");
  }

  return columns;
};

export interface ConvertedFile {
  id: string;
  date: string;
  title: string;
  type: string;
  status: string;
  isPublished: boolean;
  activityCount: number;
  url: string;
  iatiVersion: string;
  rowClassName?: string;
  originalFileId?: string;
  editVersion?: {
    count: number;
    createdDate: string;
    fileName: string;
    iatiVersion: string;
    id: string;
    path: string;
    published: boolean;
    type: string;
    validation: {
      hasErrorsOrWarnings: boolean;
      isValid: boolean;
      path: string;
    };
  };
}

export const CONVERTED_FILES_ROWS: ConvertedFile[] = [
  {
    id: "1",
    date: "2021-09-01",
    title: "ITS Establishement of Nagpur-Mumbai Super Communication Expressway",
    type: "Activity",
    status: "Invalid",
    isPublished: false,
    activityCount: 1,
    url: "https://files.aida.tools/activties/1",
    iatiVersion: "2.03",
  },
  {
    id: "2",
    date: "2021-09-02",
    title:
      "Competitiveness Improvement of Agriculture and Allied Sectors Project in the Union Territory of Jammu and Kashmir",
    type: "Activity",
    status: "Valid",
    isPublished: false,
    activityCount: 1,
    url: "https://files.aida.tools/activties/2",
    iatiVersion: "2.03",
  },
];
