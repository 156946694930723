export function formatNumber(number: number, abr: boolean = false): string {
  let n = number;
  if (number === 0) return "0";
  if (number < 0) n = number * -1;
  const suffixes = ["", "thousand", "million", "billion", "trillion"];
  const suffixesAbr = ["", "K", "mln", "bln", "trln"];
  let tier = Math.floor(Math.log10(n) / 3);

  if (tier === 0) return n.toString(); // if the number is less than 1000, return it as is

  const suffix = abr ? suffixesAbr[tier] : suffixes[tier];
  const scaled = n / Math.pow(10, tier * 3);

  // To handle decimals and round to a certain decimal place
  const formatted = scaled.toFixed(1).replace(/\.0$/, "");

  return `${number < 0 ? "-" : ""}${formatted} ${suffix}`;
}
