export interface GeomapChartProps {
  data: { name: string; value: number; iso2?: string }[];
  height?: string;
  showTooltip?: boolean;
  showLegend?: boolean;
  focusCountry?: string;
  zoom?: number;
  variant?: "publisher" | "explorer";
  roam?: boolean;
  showZoomWidget?: boolean;
  showScatter?: boolean;
  scatterData?: {
    lat: number;
    lng: number;
    // name: string;
    activityId: string;
    activityTitle: string;
  }[];
}

// Iso2 here is incorrect, for testing purposes, we will use the iso3 code
export const GeomapSampleData = [
  {
    value: 41128771,
    name: "Afghanistan",
    full_name: "Afghanistan",
    iso2: "AFG",
    iso3: "AFG",
  },
  {
    value: 2842321,
    name: "Albania",
    full_name: "Albania",
    iso2: "ALB",
    iso3: "ALB",
  },
  {
    value: 44903225,
    name: "Algeria",
    full_name: "Algeria",
    iso2: "DZA",
    iso3: "DZA",
  },
  {
    value: 44273,
    name: "American Samoa",
    full_name: "American Samoa",
    iso2: "ASM",
    iso3: "ASM",
  },
  {
    value: 79824,
    name: "Andorra",
    full_name: "Andorra",
    iso2: "AND",
    iso3: "AND",
  },
  {
    value: 35588987,
    name: "Angola",
    full_name: "Angola",
    iso2: "AGO",
    iso3: "AGO",
  },
  {
    value: 15857,
    name: "Anguilla",
    full_name: "Anguilla",
    iso2: "AIA",
    iso3: "AIA",
  },
  {
    value: 93763,
    name: "Antigua and Barbuda",
    full_name: "Antigua and Barbuda",
    iso2: "ATG",
    iso3: "ATG",
  },
  {
    value: 45510318,
    name: "Argentina",
    full_name: "Argentina",
    iso2: "ARG",
    iso3: "ARG",
  },
  {
    value: 2780469,
    name: "Armenia",
    full_name: "Armenia",
    iso2: "ARM",
    iso3: "ARM",
  },
  {
    value: 106445,
    name: "Aruba",
    full_name: "Aruba",
    iso2: "ABW",
    iso3: "ABW",
  },
  {
    value: 26177413,
    name: "Australia",
    full_name: "Australia",
    iso2: "AUS",
    iso3: "AUS",
  },
  {
    value: 8939617,
    name: "Austria",
    full_name: "Austria",
    iso2: "AUT",
    iso3: "AUT",
  },
  {
    value: 10358074,
    name: "Azerbaijan",
    full_name: "Azerbaijan",
    iso2: "AZE",
    iso3: "AZE",
  },
  {
    value: 409984,
    name: "Bahamas",
    full_name: "Bahamas",
    iso2: "BHS",
    iso3: "BHS",
  },
  {
    value: 1472233,
    name: "Bahrain",
    full_name: "Bahrain",
    iso2: "BHR",
    iso3: "BHR",
  },
  {
    value: 171186372,
    name: "Bangladesh",
    full_name: "Bangladesh",
    iso2: "BGD",
    iso3: "BGD",
  },
  {
    value: 281635,
    name: "Barbados",
    full_name: "Barbados",
    iso2: "BRB",
    iso3: "BRB",
  },
  {
    value: 9534954,
    name: "Belarus",
    full_name: "Belarus",
    iso2: "BLR",
    iso3: "BLR",
  },
  {
    value: 11655930,
    name: "Belgium",
    full_name: "Belgium",
    iso2: "BEL",
    iso3: "BEL",
  },
  {
    value: 405272,
    name: "Belize",
    full_name: "Belize",
    iso2: "BLZ",
    iso3: "BLZ",
  },
  {
    value: 13352864,
    name: "Benin",
    full_name: "Benin",
    iso2: "BEN",
    iso3: "BEN",
  },
  {
    value: 64184,
    name: "Bermuda",
    full_name: "Bermuda",
    iso2: "BMU",
    iso3: "BMU",
  },
  {
    value: 782455,
    name: "Bhutan",
    full_name: "Bhutan",
    iso2: "BTN",
    iso3: "BTN",
  },
  {
    value: 12224110,
    name: "Bolivia",
    full_name: "Bolivia",
    iso2: "BOL",
    iso3: "BOL",
  },
  {
    value: 3233526,
    name: "Bosnia and Herzegovina",
    full_name: "Bosnia and Herzegovina",
    iso2: "BIH",
    iso3: "BIH",
  },
  {
    value: 2630296,
    name: "Botswana",
    full_name: "Botswana",
    iso2: "BWA",
    iso3: "BWA",
  },
  {
    value: 215313498,
    name: "Brazil",
    full_name: "Brazil",
    iso2: "BRA",
    iso3: "BRA",
  },
  {
    value: 31305,
    name: "British Virgin Islands",
    full_name: "British Virgin Islands",
    iso2: "VGB",
    iso3: "VGB",
  },
  {
    value: 449002,
    name: "Brunei",
    full_name: "Brunei",
    iso2: "BRN",
    iso3: "BRN",
  },
  {
    value: 6781953,
    name: "Bulgaria",
    full_name: "Bulgaria",
    iso2: "BGR",
    iso3: "BGR",
  },
  {
    value: 22673762,
    name: "Burkina Faso",
    full_name: "Burkina Faso",
    iso2: "BFA",
    iso3: "BFA",
  },
  {
    value: 12889576,
    name: "Burundi",
    full_name: "Burundi",
    iso2: "BDI",
    iso3: "BDI",
  },
  {
    value: 16767842,
    name: "Cambodia",
    full_name: "Cambodia",
    iso2: "KHM",
    iso3: "KHM",
  },
  {
    value: 27914536,
    name: "Cameroon",
    full_name: "Cameroon",
    iso2: "CMR",
    iso3: "CMR",
  },
  {
    value: 38454327,
    name: "Canada",
    full_name: "Canada",
    iso2: "CAN",
    iso3: "CAN",
  },
  {
    value: 593149,
    name: "Cape Verde",
    full_name: "Cape Verde",
    iso2: "CPV",
    iso3: "CPV",
  },
  {
    value: 68706,
    name: "Cayman Islands",
    full_name: "Cayman Islands",
    iso2: "CYM",
    iso3: "CYM",
  },
  {
    value: 5579144,
    name: "Central African Republic",
    full_name: "Central African Republic",
    iso2: "CAF",
    iso3: "CAF",
  },
  {
    value: 17723315,
    name: "Chad",
    full_name: "Chad",
    iso2: "TCD",
    iso3: "TCD",
  },
  {
    value: 19603733,
    name: "Chile",
    full_name: "Chile",
    iso2: "CHL",
    iso3: "CHL",
  },
  {
    value: 142588731,
    name: "China",
    full_name: "China",
    iso2: "CHN",
    iso3: "CHN",
  },
  {
    value: 51874024,
    name: "Colombia",
    full_name: "Colombia",
    iso2: "COL",
    iso3: "COL",
  },
  {
    value: 836774,
    name: "Comoros",
    full_name: "Comoros",
    iso2: "COM",
    iso3: "COM",
  },
  {
    value: 17011,
    name: "Cook Islands",
    full_name: "Cook Islands",
    iso2: "COK",
    iso3: "COK",
  },
  {
    value: 5180829,
    name: "Costa Rica",
    full_name: "Costa Rica",
    iso2: "CRI",
    iso3: "CRI",
  },
  {
    value: 4030358,
    name: "Croatia",
    full_name: "Croatia",
    iso2: "HRV",
    iso3: "HRV",
  },
  {
    value: 11212191,
    name: "Cuba",
    full_name: "Cuba",
    iso2: "CUB",
    iso3: "CUB",
  },
  {
    value: 191163,
    name: "Curacao",
    full_name: "Curacao",
    iso2: "CUW",
    iso3: "CUW",
  },
  {
    value: 1251488,
    name: "Cyprus",
    full_name: "Cyprus",
    iso2: "CYP",
    iso3: "CYP",
  },
  {
    value: 10493986,
    name: "Czech Republic",
    full_name: "Czech Republic",
    iso2: "CZE",
    iso3: "CZE",
  },
  {
    value: 5882261,
    name: "Denmark",
    full_name: "Denmark",
    iso2: "DNK",
    iso3: "DNK",
  },
  {
    value: 1120849,
    name: "Djibouti",
    full_name: "Djibouti",
    iso2: "DJI",
    iso3: "DJI",
  },
  {
    value: 72737,
    name: "Dominica",
    full_name: "Dominica",
    iso2: "DMA",
    iso3: "DMA",
  },
  {
    value: 11228821,
    name: "Dominican Republic",
    full_name: "Dominican Republic",
    iso2: "DOM",
    iso3: "DOM",
  },
  {
    value: 99010212,
    name: "DR Congo",
    full_name: "DR Congo",
    iso2: "COD",
    iso3: "COD",
  },
  {
    value: 18001000,
    name: "Ecuador",
    full_name: "Ecuador",
    iso2: "ECU",
    iso3: "ECU",
  },
  {
    value: 110990103,
    name: "Egypt",
    full_name: "Egypt",
    iso2: "EGY",
    iso3: "EGY",
  },
  {
    value: 6336392,
    name: "El Salvador",
    full_name: "El Salvador",
    iso2: "SLV",
    iso3: "SLV",
  },
  {
    value: 1674908,
    name: "Equatorial Guinea",
    full_name: "Equatorial Guinea",
    iso2: "GNQ",
    iso3: "GNQ",
  },
  {
    value: 3684032,
    name: "Eritrea",
    full_name: "Eritrea",
    iso2: "ERI",
    iso3: "ERI",
  },
  {
    value: 1326062,
    name: "Estonia",
    full_name: "Estonia",
    iso2: "EST",
    iso3: "EST",
  },
  {
    value: 1201670,
    name: "Eswatini",
    full_name: "Eswatini",
    iso2: "SWZ",
    iso3: "SWZ",
  },
  {
    value: 123379924,
    name: "Ethiopia",
    full_name: "Ethiopia",
    iso2: "ETH",
    iso3: "ETH",
  },
  {
    value: 3780,
    name: "Falkland Islands",
    full_name: "Falkland Islands",
    iso2: "FLK",
    iso3: "FLK",
  },
  {
    value: 53090,
    name: "Faroe Islands",
    full_name: "Faroe Islands",
    iso2: "FRO",
    iso3: "FRO",
  },
  {
    value: 929766,
    name: "Fiji",
    full_name: "Fiji",
    iso2: "FJI",
    iso3: "FJI",
  },
  {
    value: 5540745,
    name: "Finland",
    full_name: "Finland",
    iso2: "FIN",
    iso3: "FIN",
  },
  {
    value: 64626628,
    name: "France",
    full_name: "France",
    iso2: "FRA",
    iso3: "FRA",
  },
  {
    value: 304557,
    name: "French Guiana",
    full_name: "French Guiana",
    iso2: "GUF",
    iso3: "GUF",
  },
  {
    value: 306279,
    name: "French Polynesia",
    full_name: "French Polynesia",
    iso2: "PYF",
    iso3: "PYF",
  },
  {
    value: 2388992,
    name: "Gabon",
    full_name: "Gabon",
    iso2: "GAB",
    iso3: "GAB",
  },
  {
    value: 2705992,
    name: "Gambia",
    full_name: "Gambia",
    iso2: "GMB",
    iso3: "GMB",
  },
  {
    value: 3744385,
    name: "Georgia",
    full_name: "Georgia",
    iso2: "GEO",
    iso3: "GEO",
  },
  {
    value: 83369843,
    name: "Germany",
    full_name: "Germany",
    iso2: "DEU",
    iso3: "DEU",
  },
  {
    value: 33475870,
    name: "Ghana",
    full_name: "Ghana",
    iso2: "GHA",
    iso3: "GHA",
  },
  {
    value: 32649,
    name: "Gibraltar",
    full_name: "Gibraltar",
    iso2: "GIB",
    iso3: "GIB",
  },
  {
    value: 10384971,
    name: "Greece",
    full_name: "Greece",
    iso2: "GRC",
    iso3: "GRC",
  },
  {
    value: 56466,
    name: "Greenland",
    full_name: "Greenland",
    iso2: "GRL",
    iso3: "GRL",
  },
  {
    value: 125438,
    name: "Grenada",
    full_name: "Grenada",
    iso2: "GRD",
    iso3: "GRD",
  },
  {
    value: 395752,
    name: "Guadeloupe",
    full_name: "Guadeloupe",
    iso2: "GLP",
    iso3: "GLP",
  },
  {
    value: 171774,
    name: "Guam",
    full_name: "Guam",
    iso2: "GUM",
    iso3: "GUM",
  },
  {
    value: 17843908,
    name: "Guatemala",
    full_name: "Guatemala",
    iso2: "GTM",
    iso3: "GTM",
  },
  {
    value: 63301,
    name: "Guernsey",
    full_name: "Guernsey",
    iso2: "GGY",
    iso3: "GGY",
  },
  {
    value: 13859341,
    name: "Guinea",
    full_name: "Guinea",
    iso2: "GIN",
    iso3: "GIN",
  },
  {
    value: 2105566,
    name: "Guinea-Bissau",
    full_name: "Guinea-Bissau",
    iso2: "GNB",
    iso3: "GNB",
  },
  {
    value: 808726,
    name: "Guyana",
    full_name: "Guyana",
    iso2: "GUY",
    iso3: "GUY",
  },
  {
    value: 11584996,
    name: "Haiti",
    full_name: "Haiti",
    iso2: "HTI",
    iso3: "HTI",
  },
  {
    value: 10432860,
    name: "Honduras",
    full_name: "Honduras",
    iso2: "HND",
    iso3: "HND",
  },
  {
    value: 7488865,
    name: "Hong Kong",
    full_name: "Hong Kong",
    iso2: "HKG",
    iso3: "HKG",
  },
  {
    value: 9967308,
    name: "Hungary",
    full_name: "Hungary",
    iso2: "HUN",
    iso3: "HUN",
  },
  {
    value: 372899,
    name: "Iceland",
    full_name: "Iceland",
    iso2: "ISL",
    iso3: "ISL",
  },
  {
    value: 235824862,
    name: "India",
    full_name: "India",
    iso2: "IND",
    iso3: "IND",
  },
  {
    value: 275501339,
    name: "Indonesia",
    full_name: "Indonesia",
    iso2: "IDN",
    iso3: "IDN",
  },
  {
    value: 88550570,
    name: "Iran",
    full_name: "Iran",
    iso2: "IRN",
    iso3: "IRN",
  },
  {
    value: 44496122,
    name: "Iraq",
    full_name: "Iraq",
    iso2: "IRQ",
    iso3: "IRQ",
  },
  {
    value: 5023109,
    name: "Ireland",
    full_name: "Ireland",
    iso2: "IRL",
    iso3: "IRL",
  },
  {
    value: 84519,
    name: "Isle of Man",
    full_name: "Isle of Man",
    iso2: "IMN",
    iso3: "IMN",
  },
  {
    value: 9038309,
    name: "Israel",
    full_name: "Israel",
    iso2: "ISR",
    iso3: "ISR",
  },
  {
    value: 59037474,
    name: "Italy",
    full_name: "Italy",
    iso2: "ITA",
    iso3: "ITA",
  },
  {
    value: 28160542,
    name: "Ivory Coast",
    full_name: "Ivory Coast",
    iso2: "CIV",
    iso3: "CIV",
  },
  {
    value: 2827377,
    name: "Jamaica",
    full_name: "Jamaica",
    iso2: "JAM",
    iso3: "JAM",
  },
  {
    value: 123951692,
    name: "Japan",
    full_name: "Japan",
    iso2: "JPN",
    iso3: "JPN",
  },
  {
    value: 110778,
    name: "Jersey",
    full_name: "Jersey",
    iso2: "JEY",
    iso3: "JEY",
  },
  {
    value: 11285869,
    name: "Jordan",
    full_name: "Jordan",
    iso2: "JOR",
    iso3: "JOR",
  },
  {
    value: 19397998,
    name: "Kazakhstan",
    full_name: "Kazakhstan",
    iso2: "KAZ",
    iso3: "KAZ",
  },
  {
    value: 54027487,
    name: "Kenya",
    full_name: "Kenya",
    iso2: "KEN",
    iso3: "KEN",
  },
  {
    value: 131232,
    name: "Kiribati",
    full_name: "Kiribati",
    iso2: "KIR",
    iso3: "KIR",
  },
  {
    value: 4268873,
    name: "Kuwait",
    full_name: "Kuwait",
    iso2: "KWT",
    iso3: "KWT",
  },
  {
    value: 6630623,
    name: "Kyrgyzstan",
    full_name: "Kyrgyzstan",
    iso2: "KGZ",
    iso3: "KGZ",
  },
  {
    value: 7529475,
    name: "Laos",
    full_name: "Laos",
    iso2: "LAO",
    iso3: "LAO",
  },
  {
    value: 1850651,
    name: "Latvia",
    full_name: "Latvia",
    iso2: "LVA",
    iso3: "LVA",
  },
  {
    value: 5489739,
    name: "Lebanon",
    full_name: "Lebanon",
    iso2: "LBN",
    iso3: "LBN",
  },
  {
    value: 2305825,
    name: "Lesotho",
    full_name: "Lesotho",
    iso2: "LSO",
    iso3: "LSO",
  },
  {
    value: 5302681,
    name: "Liberia",
    full_name: "Liberia",
    iso2: "LBR",
    iso3: "LBR",
  },
  {
    value: 6812341,
    name: "Libya",
    full_name: "Libya",
    iso2: "LBY",
    iso3: "LBY",
  },
  {
    value: 39327,
    name: "Liechtenstein",
    full_name: "Liechtenstein",
    iso2: "LIE",
    iso3: "LIE",
  },
  {
    value: 2750055,
    name: "Lithuania",
    full_name: "Lithuania",
    iso2: "LTU",
    iso3: "LTU",
  },
  {
    value: 647599,
    name: "Luxembourg",
    full_name: "Luxembourg",
    iso2: "LUX",
    iso3: "LUX",
  },
  {
    value: 695168,
    name: "Macau",
    full_name: "Macau",
    iso2: "MAC",
    iso3: "MAC",
  },
  {
    value: 29611714,
    name: "Madagascar",
    full_name: "Madagascar",
    iso2: "MDG",
    iso3: "MDG",
  },
  {
    value: 20405317,
    name: "Malawi",
    full_name: "Malawi",
    iso2: "MWI",
    iso3: "MWI",
  },
  {
    value: 33938221,
    name: "Malaysia",
    full_name: "Malaysia",
    iso2: "MYS",
    iso3: "MYS",
  },
  {
    value: 523787,
    name: "Maldives",
    full_name: "Maldives",
    iso2: "MDV",
    iso3: "MDV",
  },
  {
    value: 22593590,
    name: "Mali",
    full_name: "Mali",
    iso2: "MLI",
    iso3: "MLI",
  },
  {
    value: 533286,
    name: "Malta",
    full_name: "Malta",
    iso2: "MLT",
    iso3: "MLT",
  },
  {
    value: 41569,
    name: "Marshall Islands",
    full_name: "Marshall Islands",
    iso2: "MHL",
    iso3: "MHL",
  },
  {
    value: 367507,
    name: "Martinique",
    full_name: "Martinique",
    iso2: "MTQ",
    iso3: "MTQ",
  },
  {
    value: 4736139,
    name: "Mauritania",
    full_name: "Mauritania",
    iso2: "MRT",
    iso3: "MRT",
  },
  {
    value: 1299469,
    name: "Mauritius",
    full_name: "Mauritius",
    iso2: "MUS",
    iso3: "MUS",
  },
  {
    value: 326101,
    name: "Mayotte",
    full_name: "Mayotte",
    iso2: "MYT",
    iso3: "MYT",
  },
  {
    value: 127504125,
    name: "Mexico",
    full_name: "Mexico",
    iso2: "MEX",
    iso3: "MEX",
  },
  {
    value: 114164,
    name: "Micronesia",
    full_name: "Micronesia",
    iso2: "FSM",
    iso3: "FSM",
  },
  {
    value: 3272996,
    name: "Moldova",
    full_name: "Moldova",
    iso2: "MDA",
    iso3: "MDA",
  },
  {
    value: 36469,
    name: "Monaco",
    full_name: "Monaco",
    iso2: "MCO",
    iso3: "MCO",
  },
  {
    value: 3398366,
    name: "Mongolia",
    full_name: "Mongolia",
    iso2: "MNG",
    iso3: "MNG",
  },
  {
    value: 627082,
    name: "Montenegro",
    full_name: "Montenegro",
    iso2: "MNE",
    iso3: "MNE",
  },
  {
    value: 4390,
    name: "Montserrat",
    full_name: "Montserrat",
    iso2: "MSR",
    iso3: "MSR",
  },
  {
    value: 37457971,
    name: "Morocco",
    full_name: "Morocco",
    iso2: "MAR",
    iso3: "MAR",
  },
  {
    value: 32969517,
    name: "Mozambique",
    full_name: "Mozambique",
    iso2: "MOZ",
    iso3: "MOZ",
  },
  {
    value: 54179306,
    name: "Myanmar",
    full_name: "Myanmar",
    iso2: "MMR",
    iso3: "MMR",
  },
  {
    value: 2567012,
    name: "Namibia",
    full_name: "Namibia",
    iso2: "NAM",
    iso3: "NAM",
  },
  {
    value: 12668,
    name: "Nauru",
    full_name: "Nauru",
    iso2: "NRU",
    iso3: "NRU",
  },
  {
    value: 30547580,
    name: "Nepal",
    full_name: "Nepal",
    iso2: "NPL",
    iso3: "NPL",
  },
  {
    value: 17564014,
    name: "Netherlands",
    full_name: "Netherlands",
    iso2: "NLD",
    iso3: "NLD",
  },
  {
    value: 289950,
    name: "New Caledonia",
    full_name: "New Caledonia",
    iso2: "NCL",
    iso3: "NCL",
  },
  {
    value: 5185288,
    name: "New Zealand",
    full_name: "New Zealand",
    iso2: "NZL",
    iso3: "NZL",
  },
  {
    value: 6948392,
    name: "Nicaragua",
    full_name: "Nicaragua",
    iso2: "NIC",
    iso3: "NIC",
  },
  {
    value: 26207977,
    name: "Niger",
    full_name: "Niger",
    iso2: "NER",
    iso3: "NER",
  },
  {
    value: 218541212,
    name: "Nigeria",
    full_name: "Nigeria",
    iso2: "NGA",
    iso3: "NGA",
  },
  {
    value: 1934,
    name: "Niue",
    full_name: "Niue",
    iso2: "NIU",
    iso3: "NIU",
  },
  {
    value: 26069416,
    name: "North Korea",
    full_name: "North Korea",
    iso2: "PRK",
    iso3: "PRK",
  },
  {
    value: 2093599,
    name: "North Macedonia",
    full_name: "North Macedonia",
    iso2: "MKD",
    iso3: "MKD",
  },
  {
    value: 49551,
    name: "Northern Mariana Islands",
    full_name: "Northern Mariana Islands",
    iso2: "NFK",
    iso3: "NFK",
  },
  {
    value: 5434319,
    name: "Norway",
    full_name: "Norway",
    iso2: "NOR",
    iso3: "NOR",
  },
  {
    value: 4576298,
    name: "Oman",
    full_name: "Oman",
    iso2: "OMN",
    iso3: "OMN",
  },
  {
    value: 235824862,
    name: "Pakistan",
    full_name: "Pakistan",
    iso2: "PAK",
    iso3: "PAK",
  },
  {
    value: 18055,
    name: "Palau",
    full_name: "Palau",
    iso2: "PLW",
    iso3: "PLW",
  },
  {
    value: 5250072,
    name: "Palestine",
    full_name: "Palestine",
    iso2: "PSE",
    iso3: "PSE",
  },
  {
    value: 4408581,
    name: "Panama",
    full_name: "Panama",
    iso2: "PAN",
    iso3: "PAN",
  },
  {
    value: 10142619,
    name: "Papua New Guinea",
    full_name: "Papua New Guinea",
    iso2: "PNG",
    iso3: "PNG",
  },
  {
    value: 6780744,
    name: "Paraguay",
    full_name: "Paraguay",
    iso2: "PRY",
    iso3: "PRY",
  },
  {
    value: 34049588,
    name: "Peru",
    full_name: "Peru",
    iso2: "PER",
    iso3: "PER",
  },
  {
    value: 115559009,
    name: "Philippines",
    full_name: "Philippines",
    iso2: "PHL",
    iso3: "PHL",
  },
  {
    value: 39857145,
    name: "Poland",
    full_name: "Poland",
    iso2: "POL",
    iso3: "POL",
  },
  {
    value: 10270865,
    name: "Portugal",
    full_name: "Portugal",
    iso2: "PRT",
    iso3: "PRT",
  },
  {
    value: 3252407,
    name: "Puerto Rico",
    full_name: "Puerto Rico",
    iso2: "PRI",
    iso3: "PRI",
  },
  {
    value: 2695122,
    name: "Qatar",
    full_name: "Qatar",
    iso2: "QAT",
    iso3: "QAT",
  },
  {
    value: 5970424,
    name: "Republic of the Congo",
    full_name: "Republic of the Congo",
    iso2: "COG",
    iso3: "COG",
  },
  {
    value: 974052,
    name: "Reunion",
    full_name: "Reunion",
    iso2: "REU",
    iso3: "REU",
  },
  {
    value: 19659267,
    name: "Romania",
    full_name: "Romania",
    iso2: "ROU",
    iso3: "ROU",
  },
  {
    value: 14471331,
    name: "Russia",
    full_name: "Russia",
    iso2: "RUS",
    iso3: "RUS",
  },
  {
    value: 13776698,
    name: "Rwanda",
    full_name: "Rwanda",
    iso2: "RWA",
    iso3: "RWA",
  },
  {
    value: 10967,
    name: "Saint Barthelemy",
    full_name: "Saint Barthelemy",
    iso2: "BLM",
    iso3: "BLM",
  },
  {
    value: 47657,
    name: "Saint Kitts and Nevis",
    full_name: "Saint Kitts and Nevis",
    iso2: "KNA",
    iso3: "KNA",
  },
  {
    value: 179857,
    name: "Saint Lucia",
    full_name: "Saint Lucia",
    iso2: "LCA",
    iso3: "LCA",
  },
  {
    value: 31791,
    name: "Saint Martin",
    full_name: "Saint Martin",
    iso2: "MAF",
    iso3: "MAF",
  },
  {
    value: 5862,
    name: "Saint Pierre and Miquelon",
    full_name: "Saint Pierre and Miquelon",
    iso2: "SPM",
    iso3: "SPM",
  },
  {
    value: 103948,
    name: "Saint Vincent and the Grenadines",
    full_name: "Saint Vincent and the Grenadines",
    iso2: "VCT",
    iso3: "VCT",
  },
  {
    value: 222382,
    name: "Samoa",
    full_name: "Samoa",
    iso2: "WSM",
    iso3: "WSM",
  },
  {
    value: 33660,
    name: "San Marino",
    full_name: "San Marino",
    iso2: "SMR",
    iso3: "SMR",
  },
  {
    value: 227380,
    name: "Sao Tome and Principe",
    full_name: "Sao Tome and Principe",
    iso2: "STP",
    iso3: "STP",
  },
  {
    value: 36408820,
    name: "Saudi Arabia",
    full_name: "Saudi Arabia",
    iso2: "SAU",
    iso3: "SAU",
  },
  {
    value: 17316449,
    name: "Senegal",
    full_name: "Senegal",
    iso2: "SEN",
    iso3: "SEN",
  },
  {
    value: 7221365,
    name: "Serbia",
    full_name: "Serbia",
    iso2: "SRB",
    iso3: "SRB",
  },
  {
    value: 107118,
    name: "Seychelles",
    full_name: "Seychelles",
    iso2: "SYC",
    iso3: "SYC",
  },
  {
    value: 8605718,
    name: "Sierra Leone",
    full_name: "Sierra Leone",
    iso2: "SLE",
    iso3: "SLE",
  },
  {
    value: 5975689,
    name: "Singapore",
    full_name: "Singapore",
    iso2: "SGP",
    iso3: "SGP",
  },
  {
    value: 44175,
    name: "Sint Maarten",
    full_name: "Sint Maarten",
    iso2: "SXM",
    iso3: "SXM",
  },
  {
    value: 5643453,
    name: "Slovakia",
    full_name: "Slovakia",
    iso2: "SVK",
    iso3: "SVK",
  },
  {
    value: 2119844,
    name: "Slovenia",
    full_name: "Slovenia",
    iso2: "SVN",
    iso3: "SVN",
  },
  {
    value: 724273,
    name: "Solomon Islands",
    full_name: "Solomon Islands",
    iso2: "SLB",
    iso3: "SLB",
  },
  {
    value: 17597511,
    name: "Somalia",
    full_name: "Somalia",
    iso2: "SOM",
    iso3: "SOM",
  },
  {
    value: 59893885,
    name: "South Africa",
    full_name: "South Africa",
    iso2: "ZAF",
    iso3: "ZAF",
  },
  {
    value: 51815810,
    name: "South Korea",
    full_name: "South Korea",
    iso2: "KOR",
    iso3: "KOR",
  },
  {
    value: 10913164,
    name: "South Sudan",
    full_name: "South Sudan",
    iso2: "SSD",
    iso3: "SSD",
  },
  {
    value: 47558630,
    name: "Spain",
    full_name: "Spain",
    iso2: "ESP",
    iso3: "ESP",
  },
  {
    value: 21832143,
    name: "Sri Lanka",
    full_name: "Sri Lanka",
    iso2: "LKA",
    iso3: "LKA",
  },
  {
    value: 46874204,
    name: "Sudan",
    full_name: "Sudan",
    iso2: "SDN",
    iso3: "SDN",
  },
  {
    value: 618040,
    name: "Suriname",
    full_name: "Suriname",
    iso2: "SUR",
    iso3: "SUR",
  },
  {
    value: 10549347,
    name: "Sweden",
    full_name: "Sweden",
    iso2: "SWE",
    iso3: "SWE",
  },
  {
    value: 8740472,
    name: "Switzerland",
    full_name: "Switzerland",
    iso2: "CHE",
    iso3: "CHE",
  },
  {
    value: 22125249,
    name: "Syrian Arab Republic",
    full_name: "Syrian Arab Republic",
    iso2: "SYR",
    iso3: "SYR",
  },
  {
    value: 23893394,
    name: "Taiwan",
    full_name: "Taiwan",
    iso2: "TWN",
    iso3: "TWN",
  },
  {
    value: 9952787,
    name: "Tajikistan",
    full_name: "Tajikistan",
    iso2: "TJK",
    iso3: "TJK",
  },
  {
    value: 65497748,
    name: "Tanzania",
    full_name: "Tanzania",
    iso2: "TZA",
    iso3: "TZA",
  },
  {
    value: 71697030,
    name: "Thailand",
    full_name: "Thailand",
    iso2: "THA",
    iso3: "THA",
  },
  {
    value: 1341296,
    name: "Timor-Leste",
    full_name: "Timor-Leste",
    iso2: "TLS",
    iso3: "TLS",
  },
  {
    value: 8848699,
    name: "Togo",
    full_name: "Togo",
    iso2: "TGO",
    iso3: "TGO",
  },
  {
    value: 1871,
    name: "Tokelau",
    full_name: "Tokelau",
    iso2: "TKL",
    iso3: "TKL",
  },
  {
    value: 106858,
    name: "Tonga",
    full_name: "Tonga",
    iso2: "TON",
    iso3: "TON",
  },
  {
    value: 1531044,
    name: "Trinidad and Tobago",
    full_name: "Trinidad and Tobago",
    iso2: "TTO",
    iso3: "TTO",
  },
  {
    value: 12356117,
    name: "Tunisia",
    full_name: "Tunisia",
    iso2: "TUN",
    iso3: "TUN",
  },
  {
    value: 85341241,
    name: "Turkey",
    full_name: "Turkey",
    iso2: "TUR",
    iso3: "TUR",
  },
  {
    value: 6430770,
    name: "Turkmenistan",
    full_name: "Turkmenistan",
    iso2: "TKM",
    iso3: "TKM",
  },
  {
    value: 45703,
    name: "Turks and Caicos Islands",
    full_name: "Turks and Caicos Islands",
    iso2: "TCA",
    iso3: "TCA",
  },
  {
    value: 11312,
    name: "Tuvalu",
    full_name: "Tuvalu",
    iso2: "TUV",
    iso3: "TUV",
  },
  {
    value: 47249585,
    name: "Uganda",
    full_name: "Uganda",
    iso2: "UGA",
    iso3: "UGA",
  },
  {
    value: 39701739,
    name: "Ukraine",
    full_name: "Ukraine",
    iso2: "UKR",
    iso3: "UKR",
  },
  {
    value: 9441129,
    name: "United Arab Emirates",
    full_name: "United Arab Emirates",
    iso2: "ARE",
    iso3: "ARE",
  },
  {
    value: 67508936,
    name: "United Kingdom",
    full_name: "United Kingdom",
    iso2: "GBR",
    iso3: "GBR",
  },
  {
    value: 338289857,
    name: "United States",
    full_name: "United States",
    iso2: "USA",
    iso3: "USA",
  },
  {
    value: 99465,
    name: "United States Virgin Islands",
    full_name: "United States Virgin Islands",
    iso2: "VIR",
    iso3: "VIR",
  },
  {
    value: 3422794,
    name: "Uruguay",
    full_name: "Uruguay",
    iso2: "URY",
    iso3: "URY",
  },
  {
    value: 34627652,
    name: "Uzbekistan",
    full_name: "Uzbekistan",
    iso2: "UZB",
    iso3: "UZB",
  },
  {
    value: 326740,
    name: "Vanuatu",
    full_name: "Vanuatu",
    iso2: "VUT",
    iso3: "VUT",
  },
  {
    value: 510,
    name: "Vatican City",
    full_name: "Vatican City",
    iso2: "VAT",
    iso3: "VAT",
  },
  {
    value: 28301696,
    name: "Venezuela",
    full_name: "Venezuela",
    iso2: "VEN",
    iso3: "VEN",
  },
  {
    value: 98186856,
    name: "Vietnam",
    full_name: "Vietnam",
    iso2: "VNM",
    iso3: "VNM",
  },
  {
    value: 11572,
    name: "Wallis and Futuna",
    full_name: "Wallis and Futuna",
    iso2: "WLF",
    iso3: "WLF",
  },
  {
    value: 575986,
    name: "Western Sahara",
    full_name: "Western Sahara",
    iso2: "ESH",
    iso3: "ESH",
  },
  {
    value: 33696614,
    name: "Yemen",
    full_name: "Yemen",
    iso2: "YEM",
    iso3: "YEM",
  },
  {
    value: 20017675,
    name: "Zambia",
    full_name: "Zambia",
    iso2: "ZMB",
    iso3: "ZMB",
  },
  {
    value: 16320537,
    name: "Zimbabwe",
    full_name: "Zimbabwe",
    iso2: "ZWE",
    iso3: "ZWE",
  },
];
