import React from "react";

const FullScreenIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 9.28571V2H9.28571"
        stroke="#454545"
        strokeWidth="2.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9976 11.7139V18.9996H11.7119"
        stroke="#454545"
        strokeWidth="2.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2L19 19"
        stroke="#454545"
        strokeWidth="2.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullScreenIcon;

export const ExitFullScreenIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="46"
      height="47"
      viewBox="6 6 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75488 25.271H19.7282V35.2443"
        stroke="#454545"
        strokeWidth="2.41777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3503 18.6222H26.377V8.64893"
        stroke="#454545"
        strokeWidth="2.41777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.377 18.6223L38.0125 6.98682"
        stroke="#454545"
        strokeWidth="2.41777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.09277 36.9065L19.7283 25.271"
        stroke="#454545"
        strokeWidth="2.41777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
