import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import Divider from "@mui/material/Divider";
import {
  shortDayLocale,
  DateRangePickerProps,
  DateRangePickerCustomHeaderProps,
} from "app/components/date-range-picker/data";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const DateRangePickerCustomHeader: React.FC<
  DateRangePickerCustomHeaderProps
> = (props) => {
  const { years, date, changeYear, changeMonth } = props;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <select
        value={months[date.getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
      </select>
      <select
        value={date.getFullYear()}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  return (
    <Box
      sx={{
        gap: "10px",
        display: "flex",
        padding: "12px",
        borderRadius: "5px",
        width: "fit-content",
        background: colors.secondary.lightGrey,
        border: `1px solid ${colors.secondary.iconGray}`,
      }}
    >
      <Box data-cy="date-range-picker-start-date">
        <DatePicker
          inline
          highlightDates={[]}
          selectsStart
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={shortDayLocale}
          endDate={props.endDate}
          maxDate={props.endDate}
          disabledKeyboardNavigation
          selected={props.startDate}
          startDate={props.startDate}
          onChange={(date) => date && props.setStartDate(date)}
          onYearChange={(date) => date && props.setStartDate(date)}
          onMonthChange={(date) => date && props.setStartDate(date)}
          renderCustomHeader={(hProps) => (
            <DateRangePickerCustomHeader
              {...hProps}
              years={props.yearOptions}
            />
          )}
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box data-cy="date-range-picker-end-date">
        <DatePicker
          inline
          selectsEnd
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={shortDayLocale}
          endDate={props.endDate}
          selected={props.endDate}
          minDate={props.startDate}
          disabledKeyboardNavigation
          startDate={props.startDate}
          onChange={(date) => date && props.setEndDate(date)}
          onYearChange={(date) => date && props.setEndDate(date)}
          onMonthChange={(date) => date && props.setEndDate(date)}
          renderCustomHeader={(hProps) => (
            <DateRangePickerCustomHeader
              {...hProps}
              years={props.yearOptions}
            />
          )}
        />
      </Box>
    </Box>
  );
};
