import React from "react";
import { Banner } from "app/components/banner";
import PublisherHeroImage from "app/assets/images/data-services-publisher-hero-image.png";
import { ReactComponent as DataServicePublisherDataVisualisation } from "app/assets/vectors/DataServicePublisherDataVisualisation.svg";
import { ReactComponent as DataServicePublisherUpload } from "app/assets/vectors/DataServicePublisherUpload.svg";
import { ReactComponent as DataServicePublisherValidation } from "app/assets/vectors/DataServicePublisherValidation.svg";
import { ReactComponent as DataServicePublisherInterface } from "app/assets/vectors/DataServicePublisherInterface.svg";
import { Box } from "@mui/material";
import { DataServiceCard } from "app/components/data-service-card";
import { customBreakpointsMax } from "app/theme";

export const PublisherBanner = () => {
  return (
    <React.Fragment>
      <Banner
        image={PublisherHeroImage}
        title="AIDA Publisher: Simplifying Data Sharing for Impactful Insights"
        subtitle="Empower your data impact with AIDA Publisher. Streamline your publishing process, from IATI registry connection to insightful visualizations. Unleash the potential of your data, meet donor deadlines effortlessly, and benefit from a toolkit designed for efficiency. Explore the power of AIDA Publisher for seamless data publishing that resonates with impact."
        linkText="See AIDA Publisher"
        variant="publisher"
        linkUrl="/publisher"
      />

      <Box
        sx={{
          gap: "30px",
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          [customBreakpointsMax.tablet]: {
            gap: "20px",
            gridTemplateColumns: "1fr 1fr",
          },
          [customBreakpointsMax.mobile]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataServiceCard
          image={<DataServicePublisherDataVisualisation />}
          title="Data Visualizations"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Publisher"
          linkUrl="/publisher"
          variant="publisher"
        />
        <DataServiceCard
          image={<DataServicePublisherUpload />}
          title="Seamless Data Upload"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Publisher"
          linkUrl="/publisher"
          variant="publisher"
        />
        <DataServiceCard
          image={<DataServicePublisherValidation />}
          title="Real-time Validation"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Publisher"
          linkUrl="/publisher"
          variant="publisher"
        />
        <DataServiceCard
          image={<DataServicePublisherInterface />}
          title="User-Friendly Interface"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Publisher"
          linkUrl="/publisher"
          variant="publisher"
        />
      </Box>
    </React.Fragment>
  );
};
