import React from "react";

export const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="3"
      viewBox="0 0 21 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.367188"
        y="0.0522461"
        width="20.2656"
        height="2.89509"
        rx="1.44754"
        fill={props.fill ?? "white"}
      />
    </svg>
  );
};

export const MinusIconDark = () => {
  return (
    <svg
      width="11"
      height="2"
      viewBox="0 0 11 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.833496"
        y="0.333328"
        width="9.33333"
        height="1.33333"
        rx="0.666667"
        fill="#454545"
      />
    </svg>
  );
};
