import { Avatar, Box, Typography } from "@mui/material";
import { GridCellParams, GridColDef, GridTreeNode } from "@mui/x-data-grid";
import { SelectField } from "app/components/select-field";
import { customBreakpointsMax } from "app/theme";

const RoleSelect = ({
  params,
}: {
  params: GridCellParams<any, any, any, GridTreeNode>;
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [customBreakpointsMax.mobile]: {
          alignItems: "flex-end",
          justifyContent: "flex-end",
        },
      }}
    >
      {params.row.currentUser ? (
        <Typography variant="h5" fontSize={"14px"}>
          {params.row.role}
        </Typography>
      ) : (
        <Box>
          <SelectField
            id="roles"
            onChange={() => {}}
            value={params.row.role}
            options={[
              { label: "Admin", value: "Admin" },
              { label: "Member", value: "Member" },
            ]}
            width="100%"
            fontSize="14px"
          />
        </Box>
      )}
    </Box>
  );
};

export const OrganisationmemberColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "",
    renderCell: (params) => (
      <Box
        sx={{
          padding: "10px 0",
          [customBreakpointsMax.mobile]: {
            display: "flex",

            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"12px"}>
          <Avatar
            sx={{
              width: 48,
              height: 48,
              [customBreakpointsMax.mobile]: {
                display: "none",
              },
            }}
            src={params.row.image}
          />
          <Box>
            <Typography variant="h5" fontSize={"14px"}>
              {params.value}
            </Typography>
            <Typography variant="h5" fontSize={"14px"} fontWeight={"400"}>
              {params.row.email}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "none",
            [customBreakpointsMax.mobile]: {
              display: "block",
            },
          }}
        >
          <RoleSelect params={params} />
        </Box>
      </Box>
    ),
    flex: 1,
  },
  {
    field: "role",
    headerName: "",
    width: 120,
    renderCell: (params) => <RoleSelect params={params} />,
  },
];

export const OrganisationmemberData = [
  {
    id: 1,
    name: "Annette Black",
    email: "Annette Black@zimmerman.team",
    role: "Admin",
    image: "https://picsum.photos/id/1/48/48",
    currentUser: true,
  },
  {
    id: 2,
    name: "Phelim Reachtain",
    email: "Phelim@zimmerman.team",
    role: "Admin",
    image: "https://picsum.photos/id/1/48/48",
    currentUser: false,
  },
  {
    id: 3,
    name: "Dálach Abbadelli",
    email: "Dálach@zimmerman.team",
    role: "Admin",
    image: "https://picsum.photos/id/1/48/48",
    currentUser: false,
  },
  {
    id: 4,
    name: "Karita Alexandersson",
    email: "Karita@zimmerman.team",
    role: "Member",
    image: "https://picsum.photos/id/1/48/48",
    currentUser: false,
  },
  {
    id: 5,
    name: "Ethna Markey",
    email: "Ethna@zimmerman.team",
    role: "Member",
    image: "https://picsum.photos/id/1/48/48",
    currentUser: false,
  },
];
