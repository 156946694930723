import React from "react";
import axios from "axios";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import { customBreakpointsMax } from "app/theme";
import { PageHeader } from "app/components/page-header";
import { ShareButton } from "app/components/share-button";
import { useNavigate, useParams } from "react-router-dom";
import { CanonicalUrl } from "app/components/canonical-url";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { DownloadButton } from "app/components/download-button";
import { AutoCompleteField } from "app/components/autocomplete-field";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { PeriodSelectButton } from "app/components/period-select-button";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { InsightsBlock } from "app/pages/explorer/pages/locations/pages/location/components/insights-block";

export const ExplorerLocation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    start: new Date(`${defaultYear}-01-01`),
    end: new Date(`${defaultYear}-12-31`),
  });
  const [proceed, setProceed] = React.useState(false);

  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: [
      "geospatial-data",
      "sdgs",
      "networks",
      "budget",
      "organisations",
      "sectors",
      "impact",
      "recent-activities",
    ],
  });

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerLocationPageOverviewStats.fetch
  );
  const fetchMap = useStoreActions(
    (actions) => actions.ExplorerLocationPageMap.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerLocationPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerLocationPageOrganisationsNetwork.fetch
  );
  const fetchOrganisationsDonut = useStoreActions(
    (actions) => actions.ExplorerLocationPageOrganisationsDonut.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerLocationPageBudgetBySector.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerLocationPageSectors.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerLocationPageImpactResults.fetch
  );
  const fetchActivities = useStoreActions(
    (actions) => actions.ExplorerLocationPageActivityTable.fetch
  );
  const loadingActivities = useStoreState(
    (state) => state.ExplorerLocationPageActivityTable.loading
  );

  const locationList = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );

  const getLocationBestSuitedTransactionTypeForSelectedPeriod = async (
    period: string,
    id: string
  ) => {
    return axios
      .get(
        `${process.env.REACT_APP_API}/explorer/general/transaction-type-availability/${period}/?country=${id}`
      )
      .then((response) => {
        const typeCode = get(response, "data.type", 0);
        if (typeCode > 0) {
          const type = TRANSACTION_TYPES.find((t) => t.code === typeCode);
          if (type) {
            setTransactionType(type);
          }
          setProceed(true);
        } else {
          const year = parseInt(period.slice(0, 4), 10) - 1;
          setSelectedPeriod({
            start: new Date(`${year}-01-01`),
            end: new Date(`${year}-12-31`),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLocationChange = (
    value: {
      label: string;
      value: string;
    } | null
  ) => {
    if (!value) return;
    navigate(`/explorer/locations/${value.value}`);
  };

  const onLoadMoreActivities = () => {
    if (loadingActivities) return;
    setActivitiesPage((prev) => {
      fetchActivities({
        addOnData: true,
        filterString: `country=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const countries: {
    code: string;
    name: string;
  }[] = React.useMemo(() => {
    return locationList.map((location: { value: string; label: string }) => ({
      code: location.value,
      name: location.label,
    }));
  }, [locationList]);

  const periodLabel = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    return `${startYear}-${startMonth < 10 ? "0" : ""}${startMonth}-${
      startDate < 10 ? "0" : ""
    }${startDate}|${endYear}-${endMonth < 10 ? "0" : ""}${endMonth}-${
      endDate < 10 ? "0" : ""
    }${endDate}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    setProceed(false);
  }, [id]);

  React.useEffect(() => {
    if (id && !proceed) {
      getLocationBestSuitedTransactionTypeForSelectedPeriod(
        periodRouteParam,
        id
      );
    }
  }, [id, periodRouteParam, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchMap({
        routeParams: { code: id },
        filterString: `startDate=${selectedPeriod.start.toISOString()}&endDate=${selectedPeriod.end.toISOString()}&transactionTypeCode=${
          transactionType.code
        }`,
      });
      fetchOrganisationsDonut({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchSDGs({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      let fetchBudgetBySectorYears = [];
      const startYear = selectedPeriod.start.getFullYear();
      const endYear = selectedPeriod.end.getFullYear();
      if (startYear !== endYear) {
        fetchBudgetBySectorYears = [startYear, endYear];
      } else {
        fetchBudgetBySectorYears = [(startYear - 10).toString(), startYear];
      }
      fetchBudgetBySector({
        routeParams: {
          years: fetchBudgetBySectorYears.join("|"),
        },
      });
    }
  }, [id, selectedPeriod.start, selectedPeriod.end, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOrganisationsNetwork({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivities({
        filterString: `country=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchSectors({
        filterString: `country=${id}`,
        routeParams: {
          year: selectedPeriod.start.getFullYear().toString(),
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod.start, transactionType.code, proceed]);

  const location = React.useMemo(() => {
    return countries.find(
      (location) => location.code.toLowerCase() === id?.toLowerCase()
    );
  }, [countries, id]);

  const flagSize = mobile ? 30 : tablet ? 50 : 60;

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/explorer/locations/${id}`} />
      <Box>
        <PageHeader
          variant="explorer"
          title={location?.name!}
          subtitle={`Showcasing country-specific data of ${location?.name}.`}
          icon={
            <Box
              sx={{
                marginRight: { xs: "0", lg: "20px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircleFlag
                countryCode={id?.toLowerCase()!}
                width={flagSize}
                height={flagSize}
              />
            </Box>
          }
        />
        <Box height={30} />
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              justifyContent: "space-between",
            },
            [customBreakpointsMax.mobile]: {
              display: "block",
            },
          }}
        >
          <AutoCompleteField
            id="location-autocomplete"
            options={countries.map((s) => ({
              label: s.name,
              value: s.code,
            }))}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component={"li"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  {...optionProps}
                >
                  <CircleFlag
                    width={12}
                    height={12}
                    countryCode={option.value.toLowerCase()}
                  />
                  {option.label}
                </Box>
              );
            }}
            value={{ label: location?.name!, value: location?.code! }}
            onChange={handleLocationChange}
            variant="outlined"
            width={{
              xs: "100%",
              md: "300px",
            }}
          />

          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
              [customBreakpointsMax.mobile]: {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "10px",
              },
            }}
          >
            <TransactionTypeSelect
              value={transactionType}
              setValue={setTransactionType}
            />
            <PeriodSelectButton
              data={selectedPeriod}
              setData={setSelectedPeriod}
              width="100%"
            />
            <ShareButton
              id="share-button"
              width="100%"
              link={`/explorer/locations/${id}`}
            />
            <DownloadButton id="download-button" width="100%" />
          </Grid>
        </Grid>
        <Box height={30} />
        <InsightsBlock
          period={periodLabel}
          countries={countries}
          expandedBlock={expandedBlock}
          transactionType={transactionType}
          loadMoreActivities={onLoadMoreActivities}
          setExpandedBlock={handleSetExpandedBlock}
        />
      </Box>
    </React.Fragment>
  );
};
