import React from "react";
import axios from "axios";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import cloneDeep from "lodash/cloneDeep";
import { Table } from "app/components/table";
import { formatLocaleN } from "app/utils/formatLocale";
import { PageHeader } from "app/components/page-header";
import { colors, customBreakpointsMax } from "app/theme";
import { useNavigate, useParams } from "react-router-dom";
import { ShareButton } from "app/components/share-button";
import { CanonicalUrl } from "app/components/canonical-url";
import { InsightsBlock } from "./components/insights-block";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { DownloadButton } from "app/components/download-button";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { AutoCompleteField } from "app/components/autocomplete-field";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { PeriodSelectButton } from "app/components/period-select-button";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { getDatasetColumns } from "app/pages/explorer/pages/organisations/pages/organisation/tableData";
import { getActivitiesColumns } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";
import { DetailPanelContent } from "app/pages/explorer/pages/organisations/pages/organisation/components/detail-panel-content";

const box = (
  <Box
    height={{
      xs: "20px",
      md: "30px",
      lg: "40px",
    }}
  />
);

export const ExplorerOrganisation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { favourites, onFavouriteButtonClick } = useFavouritesUtils();

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageOverviewStats.fetch
  );
  const fetchLocations = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageLocations.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerOrganisationOrganisationsNetwork.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerOrganisationSectors.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageImpactResults.fetch
  );
  const fetchActivityTable = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageActivityTable.fetch
  );
  const fetchDatasetTable = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageDatasetTable.fetch
  );

  const orgName = useStoreState((state) =>
    get(state.ExplorerOrganisationPageOverviewStats, "data.data.name", "")
  );
  const organisationFilterOptions = useStoreState((state) =>
    get(state.ExplorerPublisherFilterOptions, "data.data.publishers", [])
  );
  const activities = useStoreState((state) =>
    get(state.ExplorerOrganisationPageActivityTable, "data.data", [])
  );
  const activitiesCount = useStoreState((state) =>
    get(state.ExplorerOrganisationPageActivityTable, "data.count", 0)
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageActivityTable.loading
  );
  const datasets = useStoreState((state) =>
    get(state.ExplorerOrganisationPageDatasetTable, "data.data", [])
  );
  const datasetsLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageDatasetTable.loading
  );

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    start: new Date(`${defaultYear}-01-01`),
    end: new Date(`${defaultYear}-12-31`),
  });
  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: ["locations", "results", "sdgs", "networks", "sectors", "impact"],
  });
  const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>([]);
  const [proceed, setProceed] = React.useState(false);

  const getOrgBestSuitedTransactionTypeForSelectedPeriod = async (
    period: string,
    id: string
  ) => {
    return axios
      .get(
        `${process.env.REACT_APP_API}/explorer/general/transaction-type-availability/${period}/?publisher=${id}`
      )
      .then((response) => {
        const typeCode = get(response, "data.type", 0);
        if (typeCode > 0) {
          const type = TRANSACTION_TYPES.find((t) => t.code === typeCode);
          if (type) {
            setTransactionType(type);
          }
          setProceed(true);
        } else {
          const year = parseInt(period.slice(0, 4), 10) - 1;
          setSelectedPeriod({
            start: new Date(`${year}-01-01`),
            end: new Date(`${year}-12-31`),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleExpandRow = (rowId: string, field: string) => {
    const newExpandedRowIds = cloneDeep(expandedRowIds);
    const index = newExpandedRowIds.indexOf(`${rowId}.${field}`);
    if (index === -1) {
      newExpandedRowIds.push(`${rowId}.${field}`);
    } else {
      newExpandedRowIds.splice(index, 1);
    }
    setExpandedRowIds(newExpandedRowIds);
  };

  const detailPanelContent = React.useCallback(
    (row: any) => {
      return (
        <DetailPanelContent
          row={row}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
        />
      );
    },
    [expandedRowIds]
  );

  const handleOrganisationChange = (
    value: {
      label: string;
      value: string;
    } | null
  ) => {
    if (value) {
      navigate(`/explorer/organisations/${value.value}`);
    }
  };

  const onLoadMoreActivities = () => {
    if (activitiesLoading) return;
    setActivitiesPage((prev) => {
      fetchActivityTable({
        addOnData: true,
        filterString: `publisher=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const periodLabel = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    return `${startYear}-${startMonth < 10 ? "0" : ""}${startMonth}-${
      startDate < 10 ? "0" : ""
    }${startDate}|${endYear}-${endMonth < 10 ? "0" : ""}${endMonth}-${
      endDate < 10 ? "0" : ""
    }${endDate}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    setProceed(false);
  }, [id]);

  React.useEffect(() => {
    if (id && !proceed) {
      getOrgBestSuitedTransactionTypeForSelectedPeriod(periodRouteParam, id);
    }
  }, [id, periodRouteParam, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchLocations({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchSectors({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchSDGs({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOrganisationsNetwork({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivityTable({
        filterString: `publisher=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchDatasetTable({
        routeParams: {
          code: id,
        },
      });
    }
  }, [id, proceed]);

  const organisation = React.useMemo(() => {
    return organisationFilterOptions.find((o: any) => o.value === id);
  }, [id, organisationFilterOptions]);

  const activitiesColumns = React.useMemo(() => {
    const cols = getActivitiesColumns(favourites, onFavouriteButtonClick);
    return cols;
  }, [favourites]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/explorer/organisations/${id}`} />
      <Box>
        <PageHeader
          title={orgName}
          variant="explorer"
          subtitle={`Showcasing organisation-specific data of ${orgName}.`}
          icon={<ExplorerOrganisationsHeaderIcon />}
        />
        {box}
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              justifyContent: "space-between",
            },
            [customBreakpointsMax.mobile]: {
              display: "block",
            },
          }}
        >
          <AutoCompleteField
            id="organisation-autocomplete"
            options={organisationFilterOptions}
            value={organisation}
            onChange={handleOrganisationChange}
            variant="outlined"
            width={{
              xs: "100%",
              md: "300px",
            }}
          />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
              [customBreakpointsMax.mobile]: {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "10px",
              },
            }}
          >
            <TransactionTypeSelect
              value={transactionType}
              setValue={setTransactionType}
            />
            <PeriodSelectButton
              data={selectedPeriod}
              setData={setSelectedPeriod}
              width="100%"
            />
            <ShareButton
              id="share-button"
              width="100%"
              link={`/explorer/organisations/${id}`}
            />
            <DownloadButton id="download-button" width="100%" />
          </Grid>
        </Grid>
        {box}
        <InsightsBlock
          period={periodLabel}
          organisation={orgName}
          expandedBlock={expandedBlock}
          transactionType={transactionType}
          loadMoreActivities={onLoadMoreActivities}
          setExpandedBlock={handleSetExpandedBlock}
        />
        {box}
        <GenericPageBlockItem
          title="Recent Activities"
          loading={activitiesLoading}
          shareLink={`/explorer/organisations/${id}#block-Recent%20Activities`}
          description={
            <React.Fragment>
              Currently{" "}
              <span style={{ color: colors.primary.blue }}>
                {formatLocaleN(activitiesCount)} activities{" "}
              </span>
              taking place for {orgName} in {periodLabel}.
            </React.Fragment>
          }
          content={
            <React.Fragment>
              <Table
                withSearch
                withSortButton
                rows={activities}
                autoHeight={false}
                headerFontSize="12px"
                columns={activitiesColumns}
                loadMore={onLoadMoreActivities}
                hasMore={activitiesCount > activities.length}
                onRowClick={(row) =>
                  row.id && navigate(`/explorer/activities/${row.id}`)
                }
              />
            </React.Fragment>
          }
          toolbarShare
        />
        {box}
        <GenericPageBlockItem
          title="IATI CLOUD DATA / ORGANISATION DATASETS"
          loading={datasetsLoading}
          shareLink={`/explorer/organisations/${id}#block-IATI CLOUD DATA / ORGANISATION DATASETS`}
          description={
            <React.Fragment>
              Currently{" "}
              <span style={{ color: colors.primary.blue }}>
                {datasets.length} datasets
              </span>{" "}
              found for {orgName} in the IATI registry.
            </React.Fragment>
          }
          content={
            <React.Fragment>
              <Table
                withSearch
                withSortButton
                withCollapseButton
                rows={datasets}
                columns={getDatasetColumns(handleExpandRow, expandedRowIds)}
                headerFontSize="12px"
                getDetailPanelContent={detailPanelContent}
                onCollapseAllButtonClick={() => setExpandedRowIds([])}
                getRowHeight={() => "auto"}
                rowHeight={40}
              />
            </React.Fragment>
          }
          toolbarShare
        />
      </Box>
    </React.Fragment>
  );
};
