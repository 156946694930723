import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Collapse from "@mui/material/Collapse";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "app/assets/vectors/jsx/MenuIcon";
import { NavLink, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SkipToMainButton } from "app/components/skip-to-main-btn";
import { HeaderButtons } from "app/components/header/sub-components/buttons";
import { headerButtonsItems } from "app/components/header/sub-components/buttons/data";
import { HeaderExpandedView } from "app/components/header/sub-components/expanded-view";
import { ReactComponent as HeaderToolbarLogo } from "app/assets/vectors/HeaderToolbarLogo.svg";

export const Header: React.FC = () => {
  const { hash, pathname, search } = useLocation();
  const mobile = useMediaQuery("(max-width: 767px)");

  const [activeButton, setActiveButton] = React.useState<string>("");

  const [mobileMenu, setMobileMenu] = React.useState<boolean>(false);

  const handleButtonClick = (label: string) => {
    setActiveButton(label === activeButton ? "" : label);
  };

  React.useEffect(() => {
    setActiveButton("");
    setMobileMenu(false);
    setTimeout(() => {
      if (!hash && search.indexOf("e") === -1) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 100);
  }, [pathname]);

  return (
    <Box sx={{ zIndex: 1000, flexGrow: 1, top: 0, position: "sticky" }}>
      <AppBar position="static">
        <Container maxWidth="lg" disableGutters>
          <ClickAwayListener onClickAway={() => handleButtonClick("")}>
            <Toolbar
              sx={{
                display: "block",
                position: "unset",
                padding: "10px 40px",
                background: colors.secondary.lightGrey,
              }}
              data-cy="navbar"
            >
              <Box
                sx={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SkipToMainButton />
                <NavLink
                  to="/"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <HeaderToolbarLogo />
                </NavLink>

                {mobile ? (
                  <IconButton onClick={() => setMobileMenu(!mobileMenu)}>
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <HeaderButtons
                    buttons={headerButtonsItems}
                    activeButton={activeButton}
                    handleButtonClick={handleButtonClick}
                  />
                )}
              </Box>
              {activeButton && !mobile && (
                <HeaderExpandedView
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
              )}
              {mobile && (
                <Collapse
                  sx={{
                    width: "100vw",
                    position: "absolute",
                    left: 0,
                  }}
                  in={mobileMenu}
                >
                  <HeaderButtons
                    buttons={headerButtonsItems}
                    activeButton={activeButton}
                    handleButtonClick={handleButtonClick}
                  />
                </Collapse>
              )}
            </Toolbar>
          </ClickAwayListener>
        </Container>
      </AppBar>
    </Box>
  );
};
