import React from "react";

export const FavouriteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.4995 20.125V37.375M43.1245 28.75H25.8745M54.6245 60.375L34.4995 48.875L14.3745 60.375V14.375C14.3745 12.85 14.9803 11.3875 16.0586 10.3091C17.1369 9.2308 18.5995 8.625 20.1245 8.625H48.8745C50.3994 8.625 51.862 9.2308 52.9403 10.3091C54.0186 11.3875 54.6245 12.85 54.6245 14.375V60.375Z"
        stroke="#0C162A"
        stroke-width="2.76"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FavouriteIconSelected = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.5 3H7.5C6.4 3 5.51 3.9 5.51 5L5.5 21L12.5 18L19.5 21V5C19.5 3.9 18.6 3 17.5 3Z"
        fill="#ED6060"
      />
    </svg>
  );
};
