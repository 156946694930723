import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Collapse, SelectChangeEvent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { formatLocaleN } from "app/utils/formatLocale";
import { SearchInput } from "app/components/search-input";
import { PlusIcon } from "app/assets/vectors/jsx/PlusIcon";
import { ResetIcon } from "app/assets/vectors/jsx/ResetIcon";
import { MinusIcon } from "app/assets/vectors/jsx/MinusIcon";
import { useStoreState } from "app/state/store/hooks";
import get from "lodash/get";
import { AutoCompleteField, IOption } from "app/components/autocomplete-field";
import { PeriodSelectButton } from "app/components/period-select-button";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";

const ResetButtonStyle = {
  background: colors.shades.blue[100],
  padding: "6px 12px",
  lineHeight: "20px",
  fontWeight: 400,
  color: colors.text.body,
  borderRadius: "5px",
  fontSize: "14px",
  [customBreakpointsMax.mobile]: {
    fontSize: "12px",
    padding: "4px 6px",
  },
};

const inputStyle = {
  border: `1px solid ${colors.secondary.iconGray}`,
  borderRadius: "5px",
  background: colors.secondary.lightGrey,
  fontWeight: "12px",
  color: colors.text.title,
  height: "40px",
  padding: "0px 20px",
  outline: "none",
  width: "100%",
  "::placeholder": {
    color: colors.secondary.iconGray,
  },
};

const labelStyle = {
  fontSize: {
    xs: "12px",
    md: "14px",
  },
  lineHeight: {
    xs: "normal",
    md: "20px",
  },
};

interface FilterBlockProps {
  count: number;
  search: string;
  searchBtnEnabled: boolean;
  onSearchBtnClick: () => void;
  setSearch: (search: string) => void;
  selectedFilters: {
    location: string[];
    organisation: string[];
    sector: string[];
    period: string;
    activityStatus: string[];
    budgetMin: string;
    budgetMax: string;
  };
  resetFilters: () => void;
  onFilterChange: (
    value: string | string[],
    type: string,
    action: "add" | "remove"
  ) => void;
}

export const FilterBlock: React.FC<FilterBlockProps> = (
  props: FilterBlockProps
) => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState<
    | {
        start: Date;
        end: Date;
      }
    | undefined
  >(undefined);

  const locationFilterOptions = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );
  const publisherFilterOptions = useStoreState((state) =>
    get(state.ExplorerPublisherFilterOptions, "data.data.publishers", [])
  );
  const sectorsFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );
  const activityStatusFilterOptions = useStoreState((state) =>
    get(state.ExplorerActivityStatusFilterOptions, "data.data.statuses", [])
  );

  const handleChange = (e: SelectChangeEvent) => {
    props.onFilterChange(e.target.value, e.target.name, "add");
  };

  const handleAutoCompleteInputChange = (
    option: IOption | IOption[] | null,
    key: string
  ) => {
    if (option) {
      if (Array.isArray(option)) {
        props.onFilterChange(
          option.map((opt) => opt.value),
          key,
          "add"
        );
        return;
      }
      props.onFilterChange(option.value, key, "add");
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearch(e.target.value);
  };

  const handleSearchInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      props.onSearchBtnClick();
    }
  };

  const handlePeriodFilterArrowClick = () => {
    const btn = document.getElementById("period-select-button");
    if (btn) {
      btn.click();
    }
  };

  const handlePeriodChange = (data: { start: Date; end: Date }) => {
    setSelectedPeriod(data);
    props.onFilterChange(
      `${data.start.toISOString().split("T")[0]}|${
        data.end.toISOString().split("T")[0]
      }`,
      "period",
      "add"
    );
  };

  const handleResetFilters = () => {
    props.resetFilters();
    setSelectedPeriod(undefined);
  };

  const resetFiltersBtnDisabled = React.useMemo(() => {
    return (
      props.search.length === 0 &&
      !Object.values(props.selectedFilters).some((filter) => filter.length > 0)
    );
  }, [props.selectedFilters, props.search]);

  const appliedFiltersCount = React.useMemo(() => {
    let count = 0;
    Object.values(props.selectedFilters).forEach((filter) => {
      if (Array.isArray(filter)) {
        count += filter.length;
      } else if (filter) {
        count++;
      }
    });
    return count > 0 ? `${count} filter${count > 1 ? "s" : ""} applied` : null;
  }, [props.selectedFilters]);

  return (
    <Box
      sx={{
        padding: "20px 30px",
        background: colors.primary.white,
      }}
      data-cy="filter-block"
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          [customBreakpointsMax.mobile]: {
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: {
              xs: "12px",
              md: "14px",
              lg: "18px",
            },
            lineHeight: "24px",
          }}
        >
          Search through {formatLocaleN(props.count)} activities.
        </Typography>
        <Grid
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "20px",
          }}
        >
          <Box sx={ResetButtonStyle}>
            {appliedFiltersCount ?? "No filters added yet"}
          </Box>
          <Button
            sx={{
              ...ResetButtonStyle,
              "&:hover": {
                background: colors.shades.blue[200],
              },
              ".MuiButton-icon": {
                transform: "scale(0.7)",
              },
            }}
            onClick={handleResetFilters}
            disabled={resetFiltersBtnDisabled}
            endIcon={
              <ResetIcon
                style={{ opacity: resetFiltersBtnDisabled ? "0.26" : "1" }}
              />
            }
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
      <Box height={20} />
      <Box
        sx={{
          gap: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SearchInput
          showBorder
          placeholder="Search"
          value={props.search}
          style={{ width: "100%" }}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
        />
        <Button
          variant="contained"
          sx={{
            minWidth: "130px",
            padding: "4px 10px",
            background: filtersOpen ? colors.shades.blue[400] : "auto",
            ".MuiButton-icon": {
              transform: "scale(0.7)",
            },
          }}
          endIcon={filtersOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Add Filters
        </Button>
      </Box>
      <Collapse in={filtersOpen} sx={{ marginTop: "20px" }}>
        <Grid
          sx={{
            overflow: "hidden",
            transition: "all 0.3s ease-in-out",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            display: "grid",
            gap: "20px",
            [customBreakpointsMax.tablet]: {
              gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
            },
            [customBreakpointsMax.mobile]: {
              gap: "15px",
            },
          }}
          data-cy="filter-block-filters"
        >
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Location
            </Typography>
            <AutoCompleteField
              multiple
              variant="outlined"
              id="location"
              name="location"
              value={props.selectedFilters.location.map((loc) => ({
                label: locationFilterOptions.find(
                  (option: any) => option.value === loc
                )?.label,
                value: loc,
              }))}
              onChange={(option) =>
                handleAutoCompleteInputChange(option, "location")
              }
              fontWeight="400"
              height="40px"
              placeholder="Select one or multiple options"
              options={locationFilterOptions}
            />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Organisations
            </Typography>
            <AutoCompleteField
              multiple
              variant="outlined"
              id="organisations"
              name="organisation"
              value={props.selectedFilters.organisation.map((org) => ({
                label: publisherFilterOptions.find(
                  (option: any) => option.value === org
                )?.label,
                value: org,
              }))}
              onChange={(option) =>
                handleAutoCompleteInputChange(option, "organisation")
              }
              fontWeight="400"
              height="40px"
              placeholder="Select one or multiple options"
              options={publisherFilterOptions}
            />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Sectors
            </Typography>
            <AutoCompleteField
              multiple
              variant="outlined"
              id="sectors"
              name="sector"
              value={props.selectedFilters.sector.map((sec) => ({
                label: sectorsFilterOptions.find(
                  (option: any) => option.value === sec
                )?.label,
                value: sec,
              }))}
              onChange={(option) =>
                handleAutoCompleteInputChange(option, "sector")
              }
              height="40px"
              placeholder="Select one or multiple options"
              fontWeight="400"
              options={sectorsFilterOptions}
            />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Period
            </Typography>
            <Grid
              display="flex"
              columnGap="10px"
              sx={{
                width: "100%",
                position: "relative",
                "> div": {
                  width: "100%",
                  "> button": {
                    ...inputStyle,
                    fontSize: "12px",
                    fontWeight: "400",
                    justifyContent: "flex-start",
                    color: selectedPeriod
                      ? colors.text.title
                      : colors.secondary.iconGray,
                    ":hover": {
                      background: inputStyle.background,
                    },
                    "> svg": {
                      display: "none",
                    },
                  },
                },
              }}
            >
              <PeriodSelectButton
                width="100%"
                data={selectedPeriod}
                buttonLabelFullPeriodString
                setData={handlePeriodChange}
              />
              <Box
                component="span"
                sx={{
                  top: "10px",
                  right: "10px",
                  position: "absolute",
                }}
                onClick={handlePeriodFilterArrowClick}
              >
                <ArrowDown stroke={colors.text.title} />
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Activity Status
            </Typography>
            <AutoCompleteField
              width={{
                xs: "100%",
                lg: "100%",
              }}
              multiple
              variant="outlined"
              id="activity-status"
              name="activityStatus"
              value={props.selectedFilters.activityStatus.map((status) => ({
                label: activityStatusFilterOptions.find(
                  (option: any) => option.value === status
                )?.label,
                value: status,
              }))}
              onChange={(option) =>
                handleAutoCompleteInputChange(option, "activityStatus")
              }
              height="40px"
              placeholder="Select one or multiple options"
              fontWeight="400"
              options={activityStatusFilterOptions}
            />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" marginBottom="10px" sx={labelStyle}>
              Total Budget
            </Typography>
            <Grid
              display="flex"
              columnGap="10px"
              sx={{
                "> input": inputStyle,
                width: "100%",
              }}
            >
              <input
                min={0}
                type="number"
                name="budgetMin"
                placeholder="Minimum"
                onChange={handleChange}
                value={props.selectedFilters.budgetMin}
              />
              <input
                min={0}
                type="number"
                name="budgetMax"
                placeholder="Maximum"
                onChange={handleChange}
                value={props.selectedFilters.budgetMax}
              />
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      <Button
        variant="contained"
        onClick={props.onSearchBtnClick}
        disabled={!props.searchBtnEnabled}
        sx={{
          margin: "0 auto",
          display: "block",
          minWidth: "130px",
          padding: "4px 10px",
          marginTop: filtersOpen ? "20px" : 0,
        }}
      >
        Search
      </Button>
    </Box>
  );
};
