import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { colors } from "app/theme";
import React from "react";
import { Link } from "react-router-dom";
import { DataServiceCardProps } from "./data";

export const DataServiceCard = (props: DataServiceCardProps) => {
  const color =
    props.variant === "publisher" ? colors.primary.green : colors.primary.blue;
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: {
          xs: "20px",
          md: "30px",
        },
        background: colors.primary.white,
      }}
      data-cy="data-service-card"
    >
      <Box>{props.image}</Box>
      <Typography
        variant="h6"
        sx={{
          background: color,
          padding: "6px 12px",
          borderRadius: "29px",
          fontSize: "12px",
          lineHeight: "normal",
          color: colors.primary.white,
        }}
      >
        {props.title}
      </Typography>

      <Typography
        variant="h6"
        fontSize={"12px"}
        lineHeight={"normal"}
        fontWeight={"400"}
      >
        {props.text}
      </Typography>
      <Box
        sx={{
          width: "100%",
          "> a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            columnGap: "6px",
            justifyContent: "flex-end",
          },
        }}
      >
        <Link to={props.linkUrl || ""}>
          <Typography
            variant="h6"
            lineHeight={"normal"}
            fontSize={"12px"}
            color={colors.secondary.red}
          >
            {props.linkText}
          </Typography>

          <ArrowForwardIos
            sx={{
              width: "10px",
              height: "10px",
              color: colors.secondary.red,
            }}
          />
        </Link>
      </Box>
    </Box>
  );
};
