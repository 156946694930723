import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { DateRangePicker } from "app/components/date-range-picker";
import {
  ButtonStyle,
  formatDate,
  PeriodSelectButtonProps,
  years,
} from "app/components/period-select-button/data";
import { colors } from "app/theme";
import Tooltip from "@mui/material/Tooltip";

export const PeriodSelectButton = (props: PeriodSelectButtonProps) => {
  const [data, setData] = React.useState(
    props.data ?? { start: new Date(), end: new Date() }
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (date: Date) => {
    setData({ start: date, end: data.end });
  };

  const handleEndDateChange = (date: Date) => {
    setData({ start: data.start, end: date });
  };

  const handleReset = () => {
    setData(props.data ?? { start: new Date(), end: new Date() });
  };

  const handleApply = () => {
    props.setData(data);
    handleClose();
  };

  const handleCancel = () => {
    handleReset();
    handleClose();
  };

  const buttonLabel = React.useMemo(() => {
    if (props.buttonLabelFullPeriodString) {
      if (!props.data) return "Select period";
      return `${formatDate(data.start)} - ${formatDate(data.end)}`;
    }
    const startDate = data.start.getDate();
    const endDate = data.end.getDate();
    const startYear = data.start.getFullYear();
    const endYear = data.end.getFullYear();
    const startMonth = data.start.getMonth() + 1;
    const endMonth = data.end.getMonth() + 1;
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [data, props.data, props.buttonLabelFullPeriodString]);

  React.useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  const applyBtnEnabled = React.useMemo(() => {
    return data.start <= data.end;
  }, [data]);

  const open = Boolean(anchorEl);
  const id = open ? "year-select-popup" : undefined;

  return (
    <Box>
      <Button
        id="period-select-button"
        aria-describedby={id}
        variant="general"
        sx={{ ...ButtonStyle, width: props.width ?? "unset" }}
        onClick={handleOpen}
        data-cy="period-select-button"
      >
        {buttonLabel}
        <ArrowDown transform={`rotate(${open ? "180" : "0"})`} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            borderRadius: "5px",
            background: colors.secondary.lightGrey,
            border: `1px solid ${colors.secondary.iconGray}`,
            "> div": {
              borderStyle: "none",
            },
          },
        }}
        data-cy="period-select-popup"
      >
        <DateRangePicker
          startDate={data.start}
          endDate={data.end}
          setStartDate={handleStartDateChange}
          setEndDate={handleEndDateChange}
          yearOptions={years}
        />
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            justifyContent: "right",
            padding: "4px 16px 16px 16px",
            button: {
              fontSize: "12px",
              lineHeight: "1.15",
              padding: "6px 10px",
            },
          }}
        >
          <Button onClick={handleReset} variant="contained">
            Reset
          </Button>
          <Button onClick={handleCancel} variant="contained">
            Cancel
          </Button>
          <Tooltip
            arrow
            title={
              applyBtnEnabled ? undefined : "Start date must be before end date"
            }
            placement="top"
          >
            <Box>
              <Button
                variant="contained"
                onClick={handleApply}
                disabled={!applyBtnEnabled}
              >
                Apply
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Popover>
    </Box>
  );
};
