import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import maxBy from "lodash/maxBy";
import Box from "@mui/material/Box";
import groupBy from "lodash/groupBy";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Table } from "app/components/table";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { formatNumber } from "app/utils/formatNumber";
import { useStoreState } from "app/state/store/hooks";
import { LineChart } from "app/components/charts/line";
import { colors, customBreakpointsMax } from "app/theme";
import { GeomapChart } from "app/components/charts/geomap";
import { ImpactChart } from "app/components/charts/impact";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { ChartsBlock } from "app/components/grid-blocks/charts";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { YearSelectWidget } from "app/components/year-select-widget";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { ImpactChartItemProps } from "app/components/charts/impact/data";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import { InsightsBlockProps } from "app/pages/explorer/components/insights-block/data";
import { GenericPageBlockItemLocationDescription } from "app/components/generic-page-block-item/data";
import {
  IMPACT_COLUMNS,
  IMPACT_COLUMNS_TABLET,
} from "app/components/charts/impact/tableData";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(max-width: 1199px)");

  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: [
      "locations",
      "sdgs",
      "networks",
      "sectors",
      "impact",
      "budget",
      "organisations",
    ],
  });

  const locationsFilterOptions = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );
  const sectorsFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );

  const overviewStats: {
    total: number;
    activityCount: number;
    orgCount: number;
    countryCount: number;
  } = useStoreState((state) =>
    get(state.ExplorerOverviewStats.data, "data", {
      total: 0,
      activityCount: 0,
      orgCount: 0,
      countryCount: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.total, true)}`,
      },
      { name: "Activities", value: overviewStats.activityCount?.toString() },
      { name: "Organisations", value: overviewStats.orgCount?.toString() },
      {
        name: "Recipient Countries",
        value: overviewStats.countryCount?.toString(),
      },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="Overview"
        loading={overviewStatsLoading}
        shareLink="/explorer#block-Overview"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.total, true)}
            </span>{" "}
            was spent for{" "}
            <Link to="/explorer/locations">
              {overviewStats.countryCount} recipient countries
            </Link>{" "}
            in {props.period}.
          </React.Fragment>
        }
        content={
          <OverviewBlock items={items} variant={tablet ? "vertical" : "grid"} />
        }
      />
    );
  }, [
    overviewStats,
    props.period,
    overviewStatsLoading,
    props.transactionType,
    tablet,
  ]);

  const locationsData: {
    stats: {
      code: string;
      name: string;
      value: number;
      count: number;
    }[];
    geo_data: {
      name: string;
      iso2: string;
      value: number;
      full_name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerLocations.data, "data", {
      stats: [],
      geo_data: [],
    })
  );
  const locationsLoading = useStoreState(
    (state) => state.ExplorerLocations.loading
  );
  const locations = React.useMemo(() => {
    const items = locationsData.stats?.map((item) => ({
      code: item.code.toLowerCase(),
      name: item.name,
      funds: `USD ${formatNumber(item.value, true)}`,
      activities: item.count,
    }));
    const expanded = expandedBlock === "locations";

    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          loading={locationsLoading}
          onExpand={() => handleSetExpandedBlock("locations")}
          height={expanded ? (tablet ? "auto" : "900px") : "100%"}
          shareLink="/explorer?e=locations"
          description={
            <GenericPageBlockItemLocationDescription
              period={props.period}
              items={
                locationsData.stats?.map((item) => {
                  const fLocation = find(locationsFilterOptions, {
                    label: item.name,
                  });
                  return {
                    label: item.name,
                    link: fLocation
                      ? `/explorer/locations/${fLocation.value}`
                      : "",
                  };
                }) ?? []
              }
            />
          }
          content={
            <React.Fragment>
              <LocationsBlock
                items={items}
                transactionType={props.transactionType.label}
              />
              {expanded && (
                <Box
                  bgcolor={colors.primary.white}
                  height={mobile ? "560px" : tablet ? "520px" : "600px"}
                >
                  <Divider />
                  <Box height={30} />
                  <GeomapChart
                    roam
                    showLegend
                    showTooltip
                    showZoomWidget
                    variant="explorer"
                    data={locationsData.geo_data}
                    zoom={expanded && mobile ? 3 : 1}
                    height={tablet ? "327px" : "550px"}
                  />
                </Box>
              )}
            </React.Fragment>
          }
          expanded={expanded}
        />
      </React.Fragment>
    );
  }, [
    props.period,
    expandedBlock,
    locationsData,
    locationsLoading,
    props.transactionType,
    mobile,
    tablet,
  ]);

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerSDGs, "data.items", {
      count: 0,
      data: [],
    })
  );
  const sdgsLoading = useStoreState((state) => state.ExplorerSDGs.loading);
  const sdgs = React.useMemo(() => {
    const items = sdgsData.data.map((item) => ({
      sdg: item.code,
      name: item.name,
      value: item.value,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        loading={sdgsLoading}
        title="Sustainable Development Goals"
        onExpand={() => handleSetExpandedBlock("sdgs")}
        shareLink="/explorer?e=sdgs"
        description={
          <React.Fragment>
            In {props.period},{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sdgsData.count} activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Top six trending Sustainable Development Goals of {props.period}:
            </Typography>
            <Box height={28} />
            <SDGChart
              labelGroup="Activities"
              expanded={expandedBlock === "sdgs"}
              data={expandedBlock === "sdgs" ? items : items.slice(0, 6)}
              variant={tablet ? "horizontal" : "grid"}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "sdgs"}
      />
    );
  }, [sdgsData, sdgsLoading, props.period, expandedBlock, tablet]);

  const networksData: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
      category: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerOrganisationsNetwork.data, "data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const networksLoading = useStoreState(
    (state) => state.ExplorerOrganisationsNetwork.loading
  );
  const networks = React.useMemo(() => {
    const expanded = expandedBlock === "networks";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        loading={networksLoading}
        onExpand={() => handleSetExpandedBlock("networks")}
        height={expanded ? (tablet ? "auto" : "900px") : "100%"}
        shareLink="/explorer?e=networks"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {networksData.nodes?.length} organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              See the organisation network by their organisation type:
            </Typography>
            <NetworkChart
              roam
              id="networks-chart"
              data={networksData}
              showZoomWidget={expanded}
              height={expanded && !tablet ? "700px" : "450px"}
            />
          </React.Fragment>
        }
        expanded={expanded}
      />
    );
  }, [expandedBlock, networksData, networksLoading]);

  const budgetData = useStoreState((state) =>
    get(state.ExplorerBudgetBySector, "data.data", {
      bars: [],
      series: [],
      values: [],
    })
  );
  const budgetLoading = useStoreState(
    (state) => state.ExplorerBudgetBySector.loading
  );
  const topSector: {
    name: string;
    link?: string;
  } | null = React.useMemo(() => {
    const value = maxBy(
      budgetData.values,
      (value: number[]) => value[value.length - 1]
    );
    if (!value) return null;
    const index = budgetData.values.indexOf(value);
    const label = budgetData.series[index];
    const fSector = find(sectorsFilterOptions, { label });
    return {
      name: fSector ? fSector.label : label,
      link: fSector ? `/explorer/sectors/${fSector.value}` : undefined,
    };
  }, [budgetData, sectorsFilterOptions]);
  const formattedBudgetData = React.useMemo(() => {
    if (expandedBlock === "budget") {
      return budgetData;
    }
    return {
      series: budgetData.series,
      bars: budgetData.bars.slice(
        budgetData.bars.length - 5,
        budgetData.bars.length
      ),
      values: budgetData.values.map((v: number[]) =>
        v.slice(v.length - 5, v.length)
      ),
    };
  }, [expandedBlock, budgetData]);
  const budget = React.useMemo(() => {
    const expanded = expandedBlock === "budget";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Budget"
        loading={budgetLoading}
        onExpand={() => handleSetExpandedBlock("budget")}
        height={expanded ? (tablet ? "auto" : "900px") : "100%"}
        shareLink="/explorer?e=budget"
        description={
          <React.Fragment>
            Budget by sectors show that{" "}
            {topSector?.link ? (
              <Link to={topSector.link}>{topSector.name}</Link>
            ) : (
              topSector?.name
            )}{" "}
            was the most funded sectors of {props.period}.
          </React.Fragment>
        }
        content={
          <BarChart
            barWidth={expanded && mobile ? "25px" : "40"}
            yAxisLabelName="USD"
            xAxisLabelRotate={0}
            data={formattedBudgetData}
            height={expanded && !tablet ? "750px" : "450px"}
          />
        }
        expanded={expanded}
      />
    );
  }, [
    topSector,
    props.period,
    expandedBlock,
    budgetLoading,
    formattedBudgetData,
    tablet,
    mobile,
  ]);

  const organisationsData: {
    id: string;
    name: string;
    count: number;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerOrganisationsDonut.data, "data", [])
  );
  const organisationsLoading = useStoreState(
    (state) => state.ExplorerOrganisationsDonut.loading
  );
  const organisations = React.useMemo(() => {
    const orgsTotal = sumBy(organisationsData, "value");
    const expanded = expandedBlock === "organisations";
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        loading={organisationsLoading}
        onExpand={() => handleSetExpandedBlock("organisations")}
        shareLink="/explorer?e=organisations"
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {formatNumber(orgsTotal, true)} development aid
            </span>{" "}
            in {props.period}. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {((orgsTotal * 100) / overviewStats.total)
                .toFixed(2)
                .replace(".00", "")}
              % of the total development aid.
            </span>
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half={!(expanded && tablet)}
            data={organisationsData}
            unit="USD"
            period={props.period}
            unitType="organisations"
            height={
              expanded
                ? mobile
                  ? "300px"
                  : "500px"
                : mobile
                ? "120px"
                : "350px"
            }
            showLabel={!(expanded && tablet)}
            showEmphasisLabel={expanded && tablet}
            labelPosition={expanded && tablet ? "center" : "outside"}
          />
        }
        expanded={expandedBlock === "organisations"}
      />
    );
  }, [
    props.period,
    expandedBlock,
    organisationsData,
    overviewStats.total,
    organisationsLoading,
    mobile,
    tablet,
  ]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[] = useStoreState((state) => get(state.ExplorerSectors.data, "data", []));
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerSectors.loading
  );
  const sectors = React.useMemo(() => {
    const Legend = (lprops: {
      name: string;
      code: string;
      year: string;
      value: string;
      percentageChange: number;
    }) => {
      return (
        <Box>
          <Link to={`/explorer/sectors/${lprops.code}`}>
            <Typography variant="subtitle2" fontWeight="bold">
              {lprops.name}
            </Typography>
          </Link>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={colors.secondary.blue}
                lineHeight={"normal"}
              >
                {lprops.value}
              </Typography>
              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                {lprops.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                fontSize="14px"
                color={
                  lprops.percentageChange > 0 ? "#2EA390" : colors.secondary.red
                }
              >
                {lprops.percentageChange > 0 && "+"}
                {lprops.percentageChange.toFixed(2)}%
              </Typography>
              {lprops.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    const items: {
      name: string;
      code: string;
      value: number;
      prevValue: number;
      percentageChange: number;
      year: string;
    }[] = sectorsData.map((item) => ({
      name: item.name,
      code: find(sectorsFilterOptions, { label: item.name })?.value ?? "",
      value: item.value,
      prevValue: item.prevValue,
      percentageChange: item.changePercentage,
      year: props.period,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sectors"
        loading={sectorsLoading}
        onExpand={() => handleSetExpandedBlock("sectors")}
        shareLink="/explorer?e=sectors"
        description={
          <React.Fragment>
            {sectorsData.length > 0 && (
              <Link to={`/explorer/sectors/${sectorsData[0].code}`}>
                {sectorsData[0].name}
              </Link>
            )}{" "}
            was the sector to have the highest percentage of growth for{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Sectors with significant funding rate changes for {props.period}:
            </Typography>
            <Box height={20} />
            <ChartsBlock
              gridCount={mobile ? 1 : tablet ? 2 : 4}
              items={items
                .slice(0, expandedBlock === "sectors" ? 8 : 4)
                .map((item, index) => (
                  <React.Fragment key={item.name}>
                    <LineChart
                      id={`sector-${index}`}
                      data={{
                        xAxisValues: [
                          (parseInt(item.year, 10) - 1).toString(),
                          item.year,
                        ],
                        series: [
                          {
                            name: "USD",
                            values: [item.prevValue, item.value],
                          },
                        ],
                      }}
                      yAxisLabelName="USD"
                      variant="signalLine"
                      height={mobile ? "205px" : "250px"}
                    />
                    <Box height={10} />
                    <Legend
                      {...item}
                      value={`${formatNumber(item.value, true)}`}
                    />
                  </React.Fragment>
                ))}
            />

            {expandedBlock === "sectors" && tablet && !mobile ? (
              <Box
                sx={{
                  padding: "10px 0",
                  marginTop: "20px",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={expandedBlock === "sectors"}
      />
    );
  }, [expandedBlock, sectorsData, sectorsLoading, tablet, mobile]);

  const impactData = useStoreState(
    (state) =>
      get(
        state.ExplorerImpactResults,
        "data.data",
        []
      ) as ImpactChartItemProps[]
  );
  const impactLoading = useStoreState(
    (state) => state.ExplorerImpactResults.loading
  );
  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        loading={impactLoading}
        onExpand={() => handleSetExpandedBlock("impact")}
        shareLink="/explorer?e=impact"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {Object.keys(groupBy(impactData, "title")).length} activities
            </span>{" "}
            went above and beyond their target results in {props.period},
            showcasing noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          expandedBlock === "impact" ? (
            <Table
              withSearch
              rows={impactData}
              headerFontSize="12px"
              columns={tablet ? IMPACT_COLUMNS_TABLET : IMPACT_COLUMNS}
              getRowHeight={() => (tablet ? "auto" : 40)}
            />
          ) : (
            <ImpactChart items={impactData.slice(0, 4)} period={props.period} />
          )
        }
        expanded={expandedBlock === "impact"}
      />
    );
  }, [expandedBlock, impactData, impactLoading, props.period, tablet]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (expandedBlock) {
      case "locations":
        view = locations;
        break;
      case "sdgs":
        view = sdgs;
        break;
      case "networks":
        view = networks;
        break;
      case "budget":
        view = budget;
        break;
      case "organisations":
        view = organisations;
        break;
      case "sectors":
        view = sectors;
        break;
      case "impact":
        view = impact;
        break;
      default:
        break;
    }

    return <React.Fragment>{view}</React.Fragment>;
  }, [
    expandedBlock,
    locations,
    sdgs,
    networks,
    budget,
    organisations,
    sectors,
    impact,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );
  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );
  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const budgetBlockHeight = React.useMemo(
    () => getBlockHeight("block-Budget"),
    [budget]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sectors"),
    [sectors]
  );
  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  return (
    <Grid container spacing={{ xs: "20px", md: "30px" }}>
      {expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} lg={5}>
        {overview}
      </Grid>
      <Grid item xs={12} lg={7}>
        {expandedBlock !== "locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />

        {tablet ? (
          <Box
            sx={{
              display: "flex",
              columnGap: { xs: "12px", md: "20px" },
            }}
          >
            <CtaCommonBtn
              variant={"large"}
              label={mobile ? "AIDA Explorer" : "Your Favourites"}
              text={
                !mobile
                  ? "Introducing 'Your Bookmarks' - your personalized area for humanitarian insights."
                  : undefined
              }
              link={mobile ? "/explorer" : "/favourites"}
              bgIcon={<ExplorerLargeCtaIcon />}
            />
            <CtaCommonBtn
              variant={"large"}
              label={mobile ? "AIDA Publisher" : "Publish Your Data"}
              text={
                !mobile
                  ? "Join the ranks of NGOs that are already benefiting from AIDA Publication Service."
                  : undefined
              }
              link="/publisher"
              bgIcon={<PublisherLargeCtaIcon />}
              bgColor="green"
            />
          </Box>
        ) : (
          <Box
            sx={{
              gap: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CtaCommonBtn
              label="Your Favourites"
              variant="medium"
              bgColor="blue"
              bgIcon={<CtaFavouriteIcon />}
              link="/favourites"
            />
            <CtaCommonBtn
              label="Publish Your Data"
              variant="medium"
              bgColor="blue"
              bgIcon={<CtaPublisherIcon />}
              link="/publisher"
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} lg={7}>
        {expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {expandedBlock !== "budget" ? (
          budget
        ) : (
          <ChartPlaceholder height={budgetBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} lg={7}>
        {expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            [customBreakpointsMax.tablet]: {
              display: "none",
            },
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
            link="/explorer/locations"
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
            link="/explorer/organisations"
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
            link="/explorer/sectors"
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
            link="/explorer/activities"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
