import React from "react";
import { Box, Collapse, Typography, useMediaQuery } from "@mui/material";
import { FeatureLine } from "../line";
import { colors } from "app/theme";
import { FeatureBlockProps } from "./data";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";

const FeatureBlock = ({ feature }: FeatureBlockProps) => {
  const tablet = useMediaQuery("(max-width: 1199px)");

  const [open, setOpen] = React.useState(false);

  if (tablet) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: `1px solid ${colors.secondary.iconGray}`,
            padding: {
              xs: "10px 5px",
              md: "10px 20px",
            },
            svg: {
              path: {
                fill: colors.primary.black,
              },
              transition: "transform 0.3s",
            },
            cursor: "pointer",
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography
            variant="h3"
            fontSize={{
              xs: "18px",
              md: "24px",
            }}
            lineHeight={"normal"}
          >
            {feature.title}
          </Typography>
          <ArrowDown transform={`rotate(${open ? "180" : "0"})`} />
        </Box>

        <Collapse in={open}>
          {feature.content.map((content) => (
            <Box key={content.title}>
              {content.title ? (
                <Box
                  sx={{
                    borderBottom: `1px solid ${colors.secondary.iconGray}`,
                    padding: {
                      xs: "10px 5px",
                      md: "10px 20px",
                    },
                  }}
                >
                  <Typography
                    variant="h4"
                    fontSize={{ xs: "14px", md: "18px" }}
                    lineHeight={"normal"}
                  >
                    {content.title}
                  </Typography>
                </Box>
              ) : null}
              <Box>
                {content.options.map((option, index) => (
                  <FeatureLine key={index} option={option} index={index} />
                ))}
              </Box>
            </Box>
          ))}
        </Collapse>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="h3"
        fontSize={"24px"}
        lineHeight={"normal"}
        padding={"10px 0"}
        margin={"10px 0"}
      >
        {feature.title}
      </Typography>

      {feature.content.map((content) => (
        <Box key={content.title}>
          {content.title ? (
            <Typography
              variant="h4"
              fontSize={"18px"}
              lineHeight={"normal"}
              padding={"5px 0"}
              margin={"10px 0"}
            >
              {content.title}
            </Typography>
          ) : null}
          <Box sx={{ borderLeft: `3px solid ${colors.primary.blue}` }}>
            {content.options.map((option, index) => (
              <FeatureLine key={index} option={option} index={index} />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default FeatureBlock;
