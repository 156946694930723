import React from "react";
import { colors } from "app/theme";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";

interface SelectFieldProps {
  id: string;
  value: string | string[];
  options: { value: string; label: string | React.ReactNode }[];
  onChange?: (value: SelectChangeEvent) => void;
  variant?: "outlined" | "standard" | "filled";
  name?: string;
  label?: string;
  width?: any;
  height?: string;
  multiple?: boolean;
  fontWeight?: string;
  fontSize?: string;
  placeholder?: string;
  borderColor?: string;
}

export const SelectField: React.FC<SelectFieldProps> = (
  props: SelectFieldProps
) => {
  return (
    <FormControl
      fullWidth
      sx={{
        ".MuiInputBase-root": {
          width: props.width ?? "100%",
          height: props.height ?? "32px",
          borderRadius: "5px",
          background:
            props.variant === "outlined"
              ? colors.secondary.lightGrey
              : props.variant === "filled"
              ? colors.primary.blue
              : "transparent",
          border:
            props.variant === "outlined"
              ? `1px solid ${props.borderColor ?? colors.secondary.iconGray}`
              : "none",
        },
        ".MuiInputBase-input": {
          fontSize: props.fontSize ?? "12px",
          fontWeight: props.fontWeight ?? "700",
          lineHeight: "normal",
          textAlign: "start",
          padding: props.variant === "outlined" ? "10px 20px" : "10px 8px",
          color:
            props.variant === "filled"
              ? colors.primary.white
              : colors.text.title,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderStyle: "none",
        },
        ".MuiSelect-icon": {
          color: colors.primary.white,
        },
        ".MuiMenuItem-root": {
          fontSize: "12px",
          fontWeight: "700",
        },
        ".MuiSelect-paper": {
          background: `colors.primary.green !important`,
          padding: "100px",
          borderRadius: "10px",
          border: `1px solid ${colors.secondary.iconGray}`,
        },
      }}
    >
      {props.label && (
        <Typography
          lineHeight={"normal"}
          variant="body2"
          fontSize={"12px"}
          component={"label"}
          htmlFor={props.id}
          marginBottom={"5px"}
        >
          {props.label}
        </Typography>
      )}
      <Select
        displayEmpty
        id={props.id}
        name={props.name}
        multiple={props.multiple}
        onChange={props.onChange}
        labelId={`${props.id}-label`}
        data-cy="select-field"
        value={props.value as string}
        IconComponent={(iconProps) => (
          <ArrowDown
            {...iconProps}
            stroke={props.variant === "filled" ? "white" : colors.text.title}
            strokeWidth={props.variant === "filled" ? 0.2 : 1}
          />
        )}
        renderValue={(selected: string | string[]) => {
          if (props.multiple && typeof selected === "object") {
            if (selected.length === 0) {
              return props.placeholder;
            }

            return props.options
              .filter((v) => selected.includes(v.value))
              .map((v) => v.label)
              .join(", ");
          }
          if (selected === "") {
            return props.placeholder;
          }

          return props.options.find((o) => o.value === selected)?.label;
        }}
        MenuProps={{
          // @ts-ignore
          "data-cy": "select-menu",
          className: "select-menu",
          sx: {
            ".MuiPopover-paper": {
              background: colors.secondary.lightGrey,
              padding: "10px",
              borderRadius: "10px",
              border: `1px solid ${colors.secondary.iconGray}`,
              maxHeight: "350px",
              overflowY: "hidden",
              marginTop: "8px",
            },
            "& ul": {
              maxHeight: "350px",
              overflowY: "scroll",
            },
            ".Mui-selected": {
              background: colors.secondary.disableButton,
              borderRadius: "10px",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        sx={{
          ".MuiSelect-select": {
            minHeight: "auto !important",
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              marginBottom: "10px",
              ":hover": {
                borderRadius: "10px",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
