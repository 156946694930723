import React from "react";
import axios from "axios";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { customBreakpointsMax } from "app/theme";
import { PageHeader } from "app/components/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { ShareButton } from "app/components/share-button";
import { CanonicalUrl } from "app/components/canonical-url";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { DownloadButton } from "app/components/download-button";
import { AutoCompleteField } from "app/components/autocomplete-field";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { PeriodSelectButton } from "app/components/period-select-button";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { InsightsBlock } from "app/pages/explorer/pages/sectors/pages/sector/components/insights-block";
import { ReactComponent as ExplorerSectorHeaderIcon } from "app/assets/vectors/ExplorerSectorHeaderIcon.svg";

export const ExplorerSector: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerSectorPageOverviewStats.fetch
  );
  const fetchLocations = useStoreActions(
    (actions) => actions.ExplorerSectorPageLocations.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerSectorPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerSectorOrganisationsNetwork.fetch
  );
  const fetchSectorFunding = useStoreActions(
    (actions) => actions.ExplorerSectorPageSectorFunding.fetch
  );
  const fetchOrganisationsDonut = useStoreActions(
    (actions) => actions.ExplorerSectorOrganisationsDonut.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerSectorPageImpactResults.fetch
  );
  const fetchActivityTable = useStoreActions(
    (actions) => actions.ExplorerSectorPageActivityTable.fetch
  );

  const sectorName = useStoreState((state) =>
    get(state.ExplorerSectorPageOverviewStats, "data.data.name", "")
  );
  const sectorFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageActivityTable.loading
  );

  const sectors: {
    code: string;
    name: string;
  }[] = React.useMemo(() => {
    return sectorFilterOptions.map(
      (sector: { value: string; label: string }) => ({
        code: sector.value,
        name: sector.label,
      })
    );
  }, [sectorFilterOptions]);

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    start: new Date(`${defaultYear}-01-01`),
    end: new Date(`${defaultYear}-12-31`),
  });
  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: [
      "locations",
      "sdgs",
      "networks",
      "sectors",
      "organisations",
      "impact",
      "recent-activities",
    ],
  });
  const [proceed, setProceed] = React.useState(false);

  const getSectorBestSuitedTransactionTypeForSelectedPeriod = async (
    period: string,
    id: string
  ) => {
    return axios
      .get(
        `${process.env.REACT_APP_API}/explorer/general/transaction-type-availability/${period}/?sector=${id}`
      )
      .then((response) => {
        const typeCode = get(response, "data.type", 0);
        if (typeCode > 0) {
          const type = TRANSACTION_TYPES.find((t) => t.code === typeCode);
          if (type) {
            setTransactionType(type);
          }
          setProceed(true);
        } else {
          const year = parseInt(period.slice(0, 4), 10) - 1;
          setSelectedPeriod({
            start: new Date(`${year}-01-01`),
            end: new Date(`${year}-12-31`),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSectorChange = (
    value: {
      label: string;
      value: string;
    } | null
  ) => {
    if (!value) return;
    navigate(`/explorer/sectors/${value.value}`);
  };

  const onLoadMoreActivities = () => {
    if (activitiesLoading) return;
    setActivitiesPage((prev) => {
      fetchActivityTable({
        addOnData: true,
        filterString: `sector=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const periodLabel = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    const startDate = selectedPeriod.start.getDate();
    const endDate = selectedPeriod.end.getDate();
    const startMonth = selectedPeriod.start.getMonth() + 1;
    const endMonth = selectedPeriod.end.getMonth() + 1;
    const startYear = selectedPeriod.start.getFullYear();
    const endYear = selectedPeriod.end.getFullYear();
    return `${startYear}-${startMonth < 10 ? "0" : ""}${startMonth}-${
      startDate < 10 ? "0" : ""
    }${startDate}|${endYear}-${endMonth < 10 ? "0" : ""}${endMonth}-${
      endDate < 10 ? "0" : ""
    }${endDate}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    setProceed(false);
  }, [id]);

  React.useEffect(() => {
    if (id && !proceed) {
      getSectorBestSuitedTransactionTypeForSelectedPeriod(periodRouteParam, id);
    }
  }, [id, periodRouteParam, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchLocations({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchOrganisationsDonut({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchSDGs({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchSectorFunding({
        filterString: `sector=${id}`,
        routeParams: {
          year: selectedPeriod.start.getFullYear().toString(),
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod.start, transactionType, proceed]);

  React.useEffect(() => {
    if (id && proceed) {
      fetchOrganisationsNetwork({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivityTable({
        filterString: `sector=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam, proceed]);

  const sector = React.useMemo(() => {
    return sectors.find((s) => s.code === id);
  }, [id, sectors]);

  const sectorOptions = React.useMemo(() => {
    return uniqBy(
      sectors.map((s) => ({
        label: s.name,
        value: String(s.code),
      })),
      "label"
    );
  }, [sectors]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/explorer/sectors/${id}`} />
      <Box>
        <PageHeader
          variant="explorer"
          title={sectorName}
          subtitle={`Showcasing sector-specific data for ${sectorName}.`}
          icon={<ExplorerSectorHeaderIcon />}
        />

        <Box height={30} />
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [customBreakpointsMax.tablet]: {
              justifyContent: "space-between",
            },
            [customBreakpointsMax.mobile]: {
              display: "block",
            },
          }}
        >
          <AutoCompleteField
            id="sector-autocomplete"
            options={sectorOptions}
            value={{ label: sector?.name!, value: String(sector?.code) }}
            onChange={handleSectorChange}
            variant="outlined"
            width={{
              xs: "100%",
              md: "300px",
            }}
          />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
              [customBreakpointsMax.mobile]: {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "10px",
              },
            }}
          >
            <TransactionTypeSelect
              value={transactionType}
              setValue={setTransactionType}
            />
            <PeriodSelectButton
              data={selectedPeriod}
              setData={setSelectedPeriod}
              width="100%"
            />
            <ShareButton
              id="share-button"
              width="100%"
              link={`/explorer/sectors/${id}`}
            />
            <DownloadButton id="download-button" width="100%" />
          </Grid>
        </Grid>
        <Box height={30} />
        <InsightsBlock
          sector={sectorName}
          period={periodLabel}
          expandedBlock={expandedBlock}
          transactionType={transactionType}
          setExpandedBlock={handleSetExpandedBlock}
          loadMoreActivities={onLoadMoreActivities}
        />
      </Box>
    </React.Fragment>
  );
};
