import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { customBreakpointsMax } from "app/theme";
import Typography from "@mui/material/Typography";
import { useHoverDirty, useMeasure } from "react-use";
import { ChartTooltip } from "app/components/charts/tooltip";

interface LocationsBlockProps {
  items: {
    code: string;
    name: string;
    funds: string;
    activities: number;
  }[];
  transactionType: string;
}

const LocationBlockItem: React.FC<{
  transactionType: string;
  item: LocationsBlockProps["items"][0];
}> = (props) => {
  const navigate = useNavigate();

  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);
  const [refContent, { width, height }] = useMeasure();

  const handleClick = () => {
    navigate(`/explorer/locations/${props.item.code.toUpperCase()}`);
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={3}
      ref={ref}
      onClick={handleClick}
      sx={{ cursor: "pointer", position: "relative" }}
    >
      <Box
        ref={refContent}
        sx={{
          gap: { xs: "10px", lg: "20px" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography variant="h4">{props.item.name}</Typography>
        </Box>
        <Box>
          <Typography variant="h6">{props.transactionType}</Typography>
          <Typography variant="h4">{props.item.funds}</Typography>
        </Box>
        <Typography variant="h6">
          {props.item.activities} Activit
          {props.item.activities === 1 ? "y" : "ies"}
        </Typography>
      </Box>
      {isHovering && (
        <ChartTooltip
          top={height / 2}
          left={(width / 4) * -1}
          title={`${props.item.funds} ${props.transactionType}`}
          subtitle={`See the country page of ${props.item.name}`}
        />
      )}
    </Grid>
  );
};

export const LocationsBlock: React.FC<LocationsBlockProps> = (
  props: LocationsBlockProps
) => {
  return (
    <Grid
      container
      spacing={2}
      data-cy="locations-block"
      sx={{
        paddingTop: { xs: 0, md: "16px" },
        h6: {
          fontSize: "12px",
          fontWeight: "400",
        },
        h4: {
          fontSize: "18px",
          fontWeight: "700",
        },
        "> div": {
          gap: "10px",
          display: "flex",
          paddingBottom: "16px",
          flexDirection: "column",
          "&:not(:last-of-type)": {
            borderRight: "1px solid #E0E0E0",
          },
          [customBreakpointsMax.tablet]: {
            "&:not(:last-child)": {
              borderRight: "none",
            },
            ":nth-of-type(odd)": {
              borderRight: "1px solid #E0E0E0",
            },
            [`:not(:last-of-type):not(:nth-of-type(${
              props.items.length - 1
            }))`]: {
              borderBottom: "1px solid #E0E0E0",
            },
          },
          [customBreakpointsMax.mobile]: {
            borderRight: "none",
            "&:not(:last-child)": {
              borderRight: "none",
            },
            ":nth-of-type(odd)": {
              borderRight: "none",
            },
            [`:not(:last-child)`]: {
              borderBottom: "1px solid #E0E0E0",
            },
          },
        },
      }}
    >
      {props.items.map((item) => (
        <LocationBlockItem
          item={item}
          key={item.code}
          transactionType={props.transactionType}
        />
      ))}
    </Grid>
  );
};
