import { Button } from "@mui/material";
import { ReactComponent as ShareButtonIcon } from "app/assets/vectors/ShareIcon.svg";
import { ShareDialog } from "../share-dialog";
import { ShareButtonProps } from "./data";

const ButtonStyle = {
  display: "flex",
  alignItems: "center",
  columnGap: "5px",
  padding: "6px 10px",
};

export const ShareButton: React.FC<ShareButtonProps> = (props) => {
  return (
    <ShareDialog
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id={props.id}
      link={props.link}
    >
      {(handleOpen) => (
        <Button
          variant="general"
          sx={{ ...ButtonStyle, width: props.width ?? "unset" }}
          onClick={handleOpen}
          data-cy="share-button"
        >
          <ShareButtonIcon />
          Share
        </Button>
      )}
    </ShareDialog>
  );
};
