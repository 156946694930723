import { Box, Button, Divider, Grid, Popover, Typography } from "@mui/material";
import { colors } from "app/theme";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import React from "react";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";
import { DragHandlerIcon } from "app/assets/vectors/jsx/DragHandlerIcon";
import { ItemInterface, ReactSortable } from "react-sortablejs";

const ButtonStyle = {
  fontSize: "12px",
  padding: "8px 10px",
  lineHeight: "normal",
  width: {
    xs: "100%",
    md: "auto",
  },
};

export const SortByButton = (props: { id: string; width?: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [statuses, setStatuses] = React.useState<ItemInterface[]>(
    Object.keys(ACTIVITY_STATUS_COLORS).map((status) => ({
      id: status,
    }))
  );
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? props.id : undefined;

  return (
    <Box>
      <Button
        aria-describedby={"sort"}
        variant="contained"
        endIcon={<ArrowDown transform={`rotate(${open ? "180" : "0"})`} />}
        sx={ButtonStyle}
        onClick={handleOpen}
      >
        Sort By
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          data-cy="sort-by-popover"
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "16px",
            width: "272px",
            marginTop: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="h6" lineHeight={"normal"} fontSize={"12px"}>
            Sort by
          </Typography>
          <Divider />

          <Typography
            variant="h6"
            fontWeight={"400"}
            fontSize={"12px"}
            lineHeight={"normal"}
            sx={{
              span: {
                color: colors.text.body,
                fontStyle: "italic",
                fontSize: "10px",
              },
            }}
          >
            Status <span>Drag and drop to customise your order.</span>
          </Typography>

          <Box
            sx={{
              "> div": {
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              },
            }}
          >
            <ReactSortable list={statuses} setList={setStatuses}>
              {statuses.map((status) => (
                <Box
                  key={status.id}
                  sx={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: `0.5px solid ${colors.secondary.iconGray}`,
                    background: colors.primary.white,
                    padding: "8px",
                    cursor: "grab",
                    ":active": { cursor: "grabbing" },
                  }}
                >
                  <DragHandlerIcon />

                  <Box
                    sx={{
                      background:
                        ACTIVITY_STATUS_COLORS[
                          status.id as keyof typeof ACTIVITY_STATUS_COLORS
                        ],
                      width: "9px",
                      height: "9px",
                      borderRadius: "50%",
                      marginLeft: "4px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={"400"}
                    fontSize={"12px"}
                    lineHeight={"normal"}
                  >
                    {status.id}
                  </Typography>
                </Box>
              ))}
            </ReactSortable>
          </Box>

          <Divider />

          <Box display="flex" justifyContent="end" columnGap="10px">
            <Button variant="general" disabled>
              Close
            </Button>
            <Button variant="general" onClick={handleClose}>
              Sort By
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
