import { Box, Divider, SxProps, Typography } from "@mui/material";
import { GenericTableColumn } from "app/components/generic-table/data";
import { get } from "lodash";
import React from "react";
import { SimpleTableProps } from "./data";
import { colors } from "app/theme";

export const SimpleTable = <T,>(props: SimpleTableProps<T>) => {
  const headerBoxStyle = {
    ...props.boxStyle,
    ...props.headerBoxStyle,
  };
  return (
    <React.Fragment>
      {props.variant === "borderless" ? (
        <Box>
          <Divider />
          <Box height={10} />
          {props.rows.map((row, rowIndex) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
              key={rowIndex}
            >
              {props.columns.map((column, columnIndex) => (
                <Box key={columnIndex}>
                  <Typography
                    variant="h6"
                    fontSize={"12px"}
                    color={"#373D43"}
                    lineHeight={"normal"}
                    padding={"4px 10px"}
                  >
                    {column.headerName}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={"400"}
                    fontSize={"12px"}
                    color={"#373D43"}
                    sx={{
                      wordBreak: "break-word",
                      ":hover": {
                        cursor: props.onRowClick ? "pointer" : "default",
                        opacity: props.onRowClick ? 0.7 : 1,
                      },
                    }}
                    lineHeight={"normal"}
                    padding={"4px 10px"}
                    textAlign={"justify"}
                    onClick={() => props.onRowClick?.(row)}
                  >
                    {column.renderCell
                      ? column.renderCell({
                          row,
                          value: get(row, column.field, ""),
                        })
                      : get(row, column.field, "-")}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                props.gridTemplateColumns ??
                `repeat(${
                  props.variant === "horizontal"
                    ? props.columns.length
                    : 1 + props.rows.length
                }, minmax(0, 1fr))`,
            }}
          >
            {props.columns.map((column, columnIndex) => (
              <React.Fragment key={columnIndex}>
                <Box
                  sx={{
                    padding: "10px 5px",
                    borderTop: "0.5px solid #D7D8D9",
                    borderRight: "0.5px solid #D7D8D9",
                    ":first-of-type": {
                      borderLeft: "0.5px solid #D7D8D9",
                    },
                    ...(props.variant === "vertical"
                      ? {
                          borderLeft: "0.5px solid #D7D8D9",
                          borderBottom:
                            columnIndex === props.columns.length - 1
                              ? "0.5px solid #D7D8D9"
                              : "none",
                        }
                      : {}),
                    background: "white",
                    ...headerBoxStyle,
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize={"12px"}
                    color={"#373D43"}
                    lineHeight={"normal"}
                  >
                    {column.headerName}
                  </Typography>
                </Box>
                {props.variant === "vertical"
                  ? props.rows.map((row, rowIndex) => (
                      <Box
                        key={rowIndex}
                        sx={{
                          padding: "10px 5px",
                          borderTop: "0.5px solid #D7D8D9",
                          borderRight: "0.5px solid #D7D8D9",
                          borderBottom:
                            columnIndex === props.columns.length - 1
                              ? "0.5px solid #D7D8D9"
                              : "none",
                          background: "white",

                          ...props.boxStyle,
                          ":hover": {
                            cursor: props.onRowClick ? "pointer" : "default",
                            background: props.onRowClick
                              ? colors.secondary.lightGrey
                              : "transparent",
                          },
                        }}
                        onClick={() => props.onRowClick?.(row)}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={"400"}
                          fontSize={"12px"}
                          color={"#373D43"}
                          sx={{ wordBreak: "break-word" }}
                          lineHeight={"normal"}
                        >
                          {column.renderCell
                            ? column.renderCell({
                                row,
                                value: get(row, column.field, ""),
                              })
                            : get(row, column.field, "-")}
                        </Typography>
                      </Box>
                    ))
                  : null}
              </React.Fragment>
            ))}
          </Box>
          {props.variant === "horizontal"
            ? props.rows.map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      props.gridTemplateColumns ??
                      `repeat(${
                        props.variant === "horizontal"
                          ? props.columns.length
                          : 1 + props.rows.length
                      }, minmax(0, 1fr))`,
                    ":hover": {
                      cursor: props.onRowClick ? "pointer" : "default",
                      background: props.onRowClick
                        ? colors.secondary.lightGrey
                        : "transparent",
                    },
                  }}
                  onClick={() => props.onRowClick?.(row)}
                >
                  {props.columns.map((column, columnIndex) => (
                    <Box
                      key={columnIndex}
                      sx={{
                        padding: "10px 5px",
                        borderTop: "0.5px solid #D7D8D9",
                        borderRight: "0.5px solid #D7D8D9",
                        borderLeft:
                          columnIndex === 0 ? "0.5px solid #D7D8D9" : "",
                        borderBottom:
                          rowIndex === props.rows.length - 1
                            ? "0.5px solid #D7D8D9"
                            : "",
                        ...props.boxStyle,
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={"400"}
                        fontSize={"12px"}
                        color={"#373D43"}
                        lineHeight={"normal"}
                      >
                        {column.renderCell
                          ? column.renderCell({
                              row,
                              value: get(row, column.field, ""),
                            })
                          : get(row, column.field, "-")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))
            : null}{" "}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
