import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import { PageHeaderProps } from "app/components/page-header/data";
import { useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageHeader: React.FC<PageHeaderProps> = (
  props: PageHeaderProps
) => {
  const tablet = useMediaQuery("(max-width: 1199px)");
  const icon = React.useMemo(() => {
    if (props.locationISO2) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleFlag
            width="60"
            height="60"
            countryCode={props.locationISO2.toLowerCase()}
          />
        </Box>
      );
    }
    if (props.icon) {
      return props.icon;
    }
    return null;
  }, [props.icon, props.locationISO2]);

  const title = React.useMemo(() => {
    return (
      <Typography
        margin={0}
        variant="h1"
        fontSize={{ xs: "18px", md: "36px", lg: "40px" }}
        color={colors.primary.white}
        sx={{
          lineClamp: "2",
          overflow: "hidden",
          WebkitLineClamp: "2",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        }}
      >
        {props.title}
      </Typography>
    );
  }, [props.title]);

  const subtitle = React.useMemo(() => {
    if (tablet) return null;
    if (!props.subtitle) return null;
    return (
      <Typography
        margin={0}
        variant="h5"
        fontSize="14px"
        color={colors.primary.white}
      >
        {props.subtitle}
      </Typography>
    );
  }, [props.subtitle, tablet]);

  const content = React.useMemo(() => {
    return (
      <React.Fragment>
        <Container>
          {icon}
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "calc(100% - 120px)",
              marginLeft: {
                xs: "10px",
                md: "20px",
                lg: "0",
              },
            }}
          >
            {title}
            {subtitle}
          </Box>
        </Container>
        {!tablet && (
          <Button
            variant="outlined"
            color="primary"
            endIcon={<ArrowOutward fontSize="small" />}
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              textWrap: "nowrap",
              borderStyle: "none",
              height: "fit-content",
              color: colors.primary.white,
              ":hover": {
                borderStyle: "none",
              },
              ".MuiButton-icon": {
                borderRadius: "50%",
                background:
                  props.variant === "explorer"
                    ? "#638ADC"
                    : colors.shades.green[200],
              },
            }}
            component={NavLink}
            to={props.variant === "explorer" ? "/publisher" : "/explorer"}
          >
            Switch to {props.variant === "explorer" ? "Publisher" : "Explorer"}
          </Button>
        )}
      </React.Fragment>
    );
  }, [icon, title, subtitle, props.variant, tablet]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: {
          xs: "10px",
          md: "20px",
        },
        height: "130px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background:
          props.variant === "explorer"
            ? colors.primary.blue
            : colors.primary.green,
        [customBreakpointsMax.tablet]: {
          svg: {
            width: "80px",
            height: "80px",
          },
          height: "120px",
        },
        [customBreakpointsMax.mobile]: {
          svg: {
            width: "50px",
            height: "50px",
          },
          height: "57px",
        },
      }}
      data-cy="page-header"
    >
      {content}
    </Box>
  );
};
