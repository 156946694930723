import React from "react";
import { colors } from "app/theme";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import {
  TRANSACTION_TYPES,
  TransactionTypeProps,
} from "app/pages/explorer/components/transaction-type/data";

export const TransactionTypeSelect = (props: TransactionTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    const fType = TRANSACTION_TYPES.find((type) => type.value === value);
    if (fType) {
      props.setValue(fType);
    }
  };

  return (
    <FormControl
      sx={{
        ".MuiInputBase-root": {
          width: "100%",
          height: "30px",
          borderRadius: "5px",
          background: colors.primary.blue,
        },
        ".MuiInputBase-input": {
          fontSize: "12px",
          fontWeight: "700",
          textAlign: "start",
          padding: "0px 8px",
          color: colors.primary.white,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderStyle: "none",
        },
        ".MuiSelect-icon": {
          color: colors.primary.white,
        },
        ".MuiMenuItem-root": {
          fontSize: "12px",
          fontWeight: "700",
        },
        ".MuiSelect-paper": {
          background: `colors.primary.green !important`,
          padding: "100px",
          borderRadius: "10px",
          border: `1px solid ${colors.secondary.iconGray}`,
        },
      }}
    >
      <Select
        value={props.value.value}
        IconComponent={ArrowDown}
        onChange={handleChange}
        MenuProps={{
          // @ts-ignore
          "data-cy": "transaction-type-select-menu",
          sx: {
            "& ul": {
              background: colors.secondary.lightGrey,
              padding: "10px",
              borderRadius: "10px",
              border: `1px solid ${colors.secondary.iconGray}`,
              maxHeight: "350px",
              overflowY: "scroll",
              marginTop: "8px",
              width: "224px",
            },
            ".Mui-selected": {
              background: colors.secondary.disableButton,
              borderRadius: "10px",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        data-cy="transaction-type-select"
      >
        {TRANSACTION_TYPES.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              marginBottom: "10px",
              ":hover": {
                borderRadius: "10px",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
