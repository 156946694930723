import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
// import { useStoreState } from "app/state/store/hooks";
import { AccountProfileCard } from "app/components/account-profile-card";
import { UsernameSettings } from "app/pages/account-profile/components/username";
import { JobTitleSettings } from "app/pages/account-profile/components/job-title";
import { AccountProfileLayout } from "app/pages/account-profile/components/layout";
import { AddMemberSetting } from "app/pages/account-profile/components/add-member";
import { PricingPlanSetting } from "app/pages/account-profile/components/pricing-plan";
import { FileAutomationSetting } from "app/pages/account-profile/components/file-automation";
import { PaymentDetailsSetting } from "app/pages/account-profile/components/payment-details";
import { ReactComponent as GoogleDriveIcon } from "app/assets/vectors/ExploreGoogleDriveLogo.svg";
import { ConnectedAccountsSetting } from "app/pages/account-profile/components/connected-accounts";
import { OrganisationMembersSetting } from "app/pages/account-profile/components/organisation-members";
import { ChangePaymentMethodSetting } from "app/pages/account-profile/components/change-payment-method";
import { OrganisationInformationSetting } from "app/pages/account-profile/components/organisation-information";
import { ChangeBillingInformationSetting } from "app/pages/account-profile/components/change-billing-information";
import { CanonicalUrl } from "app/components/canonical-url";

export const AccountProfile: React.FC = () => {
  const { user } = useAuth0();

  const [activeSetting, setActiveSetting] = React.useState("");

  // const storedUser = useStoreState((state) => state.user.data);

  const activeSettingView = React.useMemo(() => {
    switch (activeSetting) {
      case "username":
        return <UsernameSettings setActiveSetting={setActiveSetting} />;
      case "job-title":
        return <JobTitleSettings setActiveSetting={setActiveSetting} />;
      case "organisation-information":
        return (
          <OrganisationInformationSetting setActiveSetting={setActiveSetting} />
        );
      case "organisation-members":
        return (
          <OrganisationMembersSetting setActiveSetting={setActiveSetting} />
        );
      case "add-member":
        return <AddMemberSetting setActiveSetting={setActiveSetting} />;
      case "connected-accounts":
        return <ConnectedAccountsSetting setActiveSetting={setActiveSetting} />;
      case "google-drive-file-automation":
        return (
          <FileAutomationSetting
            option="google-drive"
            setActiveSetting={setActiveSetting}
          />
        );
      case "microsoft-cloud-file-automation":
        return (
          <FileAutomationSetting
            option="microsoft-cloud"
            setActiveSetting={setActiveSetting}
          />
        );
      case "pricing-plan":
        return <PricingPlanSetting setActiveSetting={setActiveSetting} />;
      case "payment-details":
        return <PaymentDetailsSetting setActiveSetting={setActiveSetting} />;
      case "change-payment-method":
        return (
          <ChangePaymentMethodSetting setActiveSetting={setActiveSetting} />
        );
      case "change-billing-information":
        return (
          <ChangeBillingInformationSetting
            setActiveSetting={setActiveSetting}
          />
        );
      default:
        return (
          <AccountProfileCard
            title=""
            content={<React.Fragment />}
            setActiveSetting={setActiveSetting}
          />
        );
    }
  }, [activeSetting]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/account-profile" />
      <AccountProfileLayout>
        {activeSetting ? (
          <Box>{activeSettingView}</Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "20px",
                md: "30px",
                lg: "40px",
              },
            }}
          >
            <AccountProfileCard
              title="Profile"
              content={[
                {
                  key: "username",
                  content: ["User Name", user?.name],
                },
                {
                  key: "job-title",
                  content: ["Job Title", "Finance Officer"],
                },
              ]}
              setActiveSetting={setActiveSetting}
            />

            <AccountProfileCard
              title="Organisation"
              content={[
                {
                  key: "organisation-information",
                  content: ["Organisation Information", "Zimmerman"],
                },
                {
                  key: "organisation-members",
                  content: ["Members", "5"],
                },
                {
                  key: "connected-accounts",
                  content: [
                    "Connected Accounts",
                    <Button
                      sx={{
                        fontSize: "12px",
                        lineHeight: "normal",
                        display: "flex",
                        alignItems: "center",
                      }}
                      variant="contained"
                      startIcon={<GoogleDriveIcon />}
                    >
                      Connected to Google Drive
                    </Button>,
                  ],
                },
              ]}
              setActiveSetting={setActiveSetting}
            />

            <AccountProfileCard
              title="Pricing Plan"
              content={[
                {
                  key: "pricing-plan",
                  content: [
                    "Pricing Plan",
                    <b>Standard</b>,
                    <span>
                      <b>5</b> User Licenses
                    </span>,
                    <span>
                      <b>Active</b> Support
                    </span>,
                  ],
                },
                {
                  key: "payment-details",
                  content: ["Payment Method"],
                },
              ]}
              setActiveSetting={setActiveSetting}
            />
          </Box>
        )}
      </AccountProfileLayout>
    </React.Fragment>
  );
};
