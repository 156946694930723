import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { colors, customBreakpointsMax } from "app/theme";

export const HeaderResourcesView: React.FC = () => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        display: "grid",
        gap: "20px",
        a: {
          h5: {
            [customBreakpointsMax.tablet]: {
              color: `${colors.primary.blue} !important`,
              fontWeight: "bold",
            },
          },
        },
      }}
    >
      <Grid
        gridColumn={{
          xs: "span 12",
          md: "span 6",
          lg: "span 4",
        }}
        sx={{
          borderRight: "1px solid #A1AEBD",
          [customBreakpointsMax.mobile]: {
            borderRight: "none",
          },
          a: {
            display: "block",
            textDecoration: "none",
            color: "#000",
            marginBottom: "16px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          marginBottom={{
            xs: "20px",
            md: "24px",
          }}
        >
          AIDA User Guide
        </Typography>
        <Link to="/user-guide#about-aida">
          <Typography
            variant="h5"
            fontSize="14px"
            fontWeight="400"
            marginBottom="10px"
          >
            About AIDA
          </Typography>
        </Link>
        <Link to="/user-guide#aida-faqs">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            Frequently Asked Questions
          </Typography>
        </Link>
        <Link to="/user-guide#aida-tutorials">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            Tutorials
          </Typography>
        </Link>
      </Grid>
      <Grid
        gridColumn={{
          xs: "span 12",
          md: "span 4",
        }}
        sx={{
          a: {
            display: "block",
            textDecoration: "none",
            color: "#000",
            marginBottom: "16px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          marginBottom={{
            xs: "20px",
            md: "24px",
          }}
        >
          Data Services
        </Typography>
        <Link to="/data-services?v=publisher">
          <Typography
            variant="h5"
            fontSize="14px"
            fontWeight="400"
            marginBottom="10px"
          >
            AIDA Publisher
          </Typography>
        </Link>
        <Link to="/data-services?v=explorer">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            AIDA Explorer
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
