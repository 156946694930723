import { colors } from "app/theme";
import Typography from "@mui/material/Typography";
import { SimpleTableColumn } from "app/components/simple-table/data";

export interface IIndicatorItem {
  title: string;
  description: string;
  items: {
    facet: string;
    actual: string;
    target: string;
    baseline: string;
    percentage: string;
    period: string;
    vocab: string;
    code: string;
  }[];
}

export interface IResultItem {
  name: string;
  description: string;
  indicators: IIndicatorItem[];
}

export interface ResultItems {
  output: IResultItem[];
  outcome: IResultItem[];
  impact: IResultItem[];
}

export const RESULT_INDICATOR_COLUMNS: SimpleTableColumn<
  keyof IIndicatorItem["items"][0]
>[] = [
  {
    headerName: "Facet",
    field: "facet",
  },
  {
    headerName: "Baseline",
    field: "baseline",
    renderCell: (row) => (
      <Typography
        variant="body1"
        fontSize={"12px"}
        lineHeight={"normal"}
        fontWeight={"700"}
        color={"#ffffff"}
        width={"100%"}
        textAlign={"center"}
        sx={{
          background: colors.charts[1],
          borderRadius: "5px",
          padding: "2px",
        }}
      >
        {row.value}
      </Typography>
    ),
  },
  {
    headerName: "Target",
    field: "target",
    renderCell: (row) => (
      <Typography
        variant="body1"
        fontSize={"12px"}
        lineHeight={"normal"}
        fontWeight={"700"}
        color={"#ffffff"}
        textAlign={"center"}
        sx={{
          background: colors.charts[9],
          borderRadius: "5px",
          padding: "2px",
        }}
        width={"100%"}
      >
        {row.value}
      </Typography>
    ),
  },
  {
    headerName: "Actual",
    field: "actual",
    renderCell: (row) => (
      <Typography
        variant="body1"
        fontSize={"12px"}
        lineHeight={"normal"}
        fontWeight={"700"}
        color={"#ffffff"}
        sx={{
          background: colors.charts[7],
          borderRadius: "5px",
          padding: "2px",
        }}
        width={"100%"}
        textAlign={"center"}
      >
        {row.value}
      </Typography>
    ),
  },
  {
    headerName: "%",
    field: "percentage",
  },
  {
    headerName: "Period",
    field: "period",
  },
];
