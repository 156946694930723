import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Table } from "app/components/table";
import { useSessionStorage } from "react-use";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useStoreState } from "app/state/store/hooks";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { InlineSmallLoader } from "app/components/page-loader";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { ReactComponent as NoFilesIcon } from "app/assets/vectors/NoFilesPoorCow.svg";
import {
  ConvertedFile,
  GET_CONVERTED_FILES_COLUMNS,
} from "app/components/table/data";
import { GridCallbackDetails, GridRowSelectionModel } from "@mui/x-data-grid";
import { downloadFileFromSpaces } from "app/utils/downloadFileFromSpaces";

interface UploadedDataBlockViewProps {
  loading: boolean;
  files: ConvertedFile[];
  changePublishStatus: (
    fileId: string,
    value: boolean,
    isValid: boolean,
    registryFileId?: string
  ) => void;
  deleteFiles: (fileIds: string[]) => void;
  handleShowValidationReport: (fileId: string) => void;
  replaceFileWithEditVersion: (fileId: string) => void;
}

export const UploadedDataBlock: React.FC<UploadedDataBlockViewProps> = (
  props: UploadedDataBlockViewProps
) => {
  const navigate = useNavigate();

  const isAdmin = useStoreState(
    (state) => get(state.user, "data.data.app_metadata.role") === "Admin"
  );

  const setActiveStep = useSessionStorage("publisher-active-step", -1)[1];

  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<ConvertedFile[]>(props.files);
  const [expandedFilesId, setExpandedFilesId] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<ConvertedFile[]>([]);

  const editFile = (fileId: string) => {
    const file = props.files.find((f) => f.id === fileId);
    if (file) {
      setActiveStep(0);
      setTimeout(() => {
        navigate(`/publisher?fileId=${file.id}`);
      }, 100);
    }
  };

  const onRowClick = (row: any) => {
    if (row.editVersion) {
      setExpandedFilesId((prev) =>
        prev.includes(row.id)
          ? prev.filter((id) => id !== row.id)
          : [...prev, row.id]
      );
    }
  };

  const onRowSelect = (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    if (rowSelectionModel.length === 0) {
      setSelectedRows([]);
      return;
    }
    const newSelectedRows = rowSelectionModel.map((rowId) => {
      const params = details.api.getRowParams(rowId);
      return params.row;
    });
    setSelectedRows(newSelectedRows);
  };

  const deleteSelectedFiles = () => {
    const fileIds = selectedRows.map((file) => file.id);
    props.deleteFiles(fileIds);
    setSelectedRows([]);
  };

  const downloadSelectedFiles = async () => {
    setLoading(true);
    for (const file of selectedRows) {
      await downloadFileFromSpaces(file.url, file.title, "xml", "activities");
    }
    setLoading(false);
    setSelectedRows([]);
  };

  const view = React.useMemo(() => {
    if (props.loading || loading) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "220px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InlineSmallLoader />
        </Box>
      );
    }
    if (rows.length === 0) {
      return <NoFilesView />;
    }
    return (
      <Table
        withSearch
        rows={rows}
        rowSelection
        withFilterButton
        withAddFileButton
        withColumnsButton
        withSampleFilesDownloadButton
        loading={props.loading || loading}
        onRowSelectionModelChange={onRowSelect}
        onDeleteButtonClick={deleteSelectedFiles}
        withDeleteButton={selectedRows.length > 0}
        withDowloadButton={selectedRows.length > 0}
        onDownloadButtonClick={downloadSelectedFiles}
        getRowClassName={(row) => row.row.rowClassName}
        onAddButtonClick={() => navigate("/publisher")}
        columns={GET_CONVERTED_FILES_COLUMNS(
          props.changePublishStatus,
          props.handleShowValidationReport,
          isAdmin,
          false,
          props.deleteFiles,
          editFile,
          props.replaceFileWithEditVersion,
          onRowClick
        )}
      />
    );
  }, [rows, loading, props.loading, props.changePublishStatus, selectedRows]);

  const description = React.useMemo(() => {
    if (props.files.length === 0) {
      return "You've haven’t shared any data files yet. Start uploading data and share your impact with the world.";
    }
    const activityCount = sumBy(props.files, (file) => file.activityCount);
    return (
      <React.Fragment>
        You've shared insights from{" "}
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          {activityCount} {activityCount ? "impactful" : ""} activities
        </span>
      </React.Fragment>
    );
  }, [props.files]);

  React.useEffect(() => {
    const ids: string[] = [];
    props.files.forEach((file) => {
      if (file.editVersion) {
        ids.push(file.id);
      }
    });
    setExpandedFilesId(ids);
  }, [props.files]);

  React.useEffect(() => {
    if (expandedFilesId.length === 0) {
      setRows(props.files);
    } else {
      expandedFilesId.forEach((id) => {
        const file = props.files.find((f) => f.id === id);
        if (file) {
          const newRows = [...props.files];
          const index = newRows.findIndex((f) => f.id === id);
          if (file.editVersion)
            newRows.splice(index + 1, 0, {
              id: file.editVersion.id,
              date: file.editVersion.createdDate,
              title: file.editVersion.fileName,
              type:
                file.editVersion.type === "activity"
                  ? "Activity"
                  : "Organisation",
              status: file.editVersion.validation.isValid ? "Valid" : "Invalid",
              isPublished: file.editVersion.published,
              activityCount: file.editVersion.count,
              url: file.editVersion.path,
              iatiVersion: file.editVersion.iatiVersion,
              rowClassName: "table-row-expanded",
              originalFileId: file.id,
            });
          setRows(newRows);
        }
      });
    }
  }, [props.files, expandedFilesId]);

  return (
    <GenericPageBlockItem
      toolbarShare
      content={view}
      description={description}
      title="Your uploaded data"
    />
  );
};

const NoFilesView = () => {
  const navigate = useNavigate();

  const setActiveStep = useSessionStorage("publisher-active-step", -1)[1];

  const onButtonClick = () => {
    setActiveStep(0);
    setTimeout(() => {
      navigate("/publisher");
    }, 100);
  };

  return (
    <Box
      sx={{
        gap: "50px",
        width: "100%",
        height: "220px",
        padding: "30px",
        display: "flex",
        background: colors.secondary.lightGrey,
      }}
    >
      <NoFilesIcon />
      <Box
        sx={{
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" fontSize="24px">
          You haven’t shared any data files yet.
        </Typography>
        <Typography
          variant="h5"
          fontSize="14px"
          fontWeight="400"
          marginBottom="10px"
        >
          Start publishing your data to see your impact visualised, share your
          data with IATI community with ease and make the most of AIDA!
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={onButtonClick}
          sx={{ lineHeight: "1" }}
          endIcon={<ArrowForward />}
        >
          Start Publishing
        </Button>
      </Box>
    </Box>
  );
};
