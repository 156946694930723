import React from "react";
import { Box, Button, IconButton, SxProps, useMediaQuery } from "@mui/material";
import FullScreenIcon, {
  ExitFullScreenIcon,
} from "app/assets/vectors/jsx/FullScreenIcon";
import { MinusIcon } from "app/assets/vectors/jsx/MinusIcon";
import { PanIcon } from "app/assets/vectors/jsx/PanIcon";
import { PlusIcon } from "app/assets/vectors/jsx/PlusIcon";
import { ResetIcon } from "app/assets/vectors/jsx/ResetIcon";
import { colors, customBreakpointsMax } from "app/theme";
import { ZoomWidgetProps } from "./data";
import { Close } from "@mui/icons-material";

export const ZoomWidget = (props: ZoomWidgetProps) => {
  const tablet = useMediaQuery("(max-width: 1199px)");

  const ButtonStyle: SxProps = {
    padding: "0",
    boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    background: colors.primary.white,
    border: `0.5px solid ${colors.secondary.iconGray}`,
    width: props.fullScreen ? "40px" : "51px",
    minWidth: { xs: "60px", md: props.fullScreen ? "40px" : "51px" },
    height: props.fullScreen ? "40px" : "51px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
    ":hover": {
      background: "#EBEBEB",
    },
  };

  const zoomIn = () => {
    props.setZoomProperties({
      ...props.zoomProperties,
      zoom: props.zoomProperties.zoom + 1,
    });
  };

  const zoomOut = () => {
    if (props.zoomProperties.zoom > 1) {
      props.setZoomProperties({
        ...props.zoomProperties,
        zoom: props.zoomProperties.zoom - 1,
      });
    }
  };

  const reset = () => {
    props.setZoomProperties({
      zoom: props.defaultZoom ?? 1,
      roam: props.zoomProperties.roam,
    });
  };

  const roam = () => {
    props.setZoomProperties({
      ...props.zoomProperties,
      roam: !props.zoomProperties.roam,
    });
  };

  return (
    <React.Fragment>
      {tablet ? (
        <Button sx={ButtonStyle} onClick={reset}>
          <ResetIcon width={18} />
        </Button>
      ) : (
        <React.Fragment>
          {props.fullScreen ? (
            <IconButton
              sx={{
                marginLeft: "auto",
                width: "max-content",
                marginBottom: "16px",
                padding: "0",
                display: "block",
                color: colors.secondary.iconGray,
              }}
              onClick={() => {
                props?.setFullScreen?.(false);
              }}
            >
              <Close fontSize="large" sx={{ display: "block" }} />
            </IconButton>
          ) : null}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: props.fullScreen ? "row-reverse" : "column",
              gap: "18px",
              height: props.fullScreen
                ? "auto"
                : `calc(100% - ${!props.focusCountry ? 40 : 0}px)`,
              justifyContent: "space-between",
              [customBreakpointsMax.mobile]: {
                justifyContent: "space-between",
              },
            }}
          >
            <Button
              sx={ButtonStyle}
              onClick={() => {
                props?.setFullScreen?.(!props.fullScreen);
              }}
            >
              {props.fullScreen ? (
                <ExitFullScreenIcon width={30} height={30} />
              ) : (
                <FullScreenIcon
                  fill={colors.text.body}
                  width={18}
                  height={18}
                />
              )}
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: props.fullScreen ? "row-reverse" : "column",
                gap: props.fullScreen ? "18px" : "30px",
              }}
            >
              <Button sx={ButtonStyle} onClick={reset}>
                <ResetIcon width={18} />
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: props.fullScreen ? "row" : "column",
                  gap: props.fullScreen ? "18px" : "0px",
                }}
              >
                <Button sx={ButtonStyle} onClick={zoomIn}>
                  <PlusIcon fill={colors.text.body} width={18} height={18} />
                </Button>
                <Button sx={ButtonStyle} onClick={zoomOut}>
                  <MinusIcon fill={colors.text.body} width={18} />
                </Button>

                {props.fullScreen ? (
                  <Button
                    sx={{
                      ...ButtonStyle,
                      background: props.zoomProperties?.roam
                        ? colors.secondary.blue
                        : "white",
                    }}
                    onClick={roam}
                  >
                    <PanIcon width={18} />
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
