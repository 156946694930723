import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import { Box, Typography } from "@mui/material";
import { colors, customBreakpointsMax } from "app/theme";
import { SelectField } from "app/components/select-field";

export const OrganisationInformationSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [orgName, setOrgName] = React.useState("");
  const [orgType, setOrgType] = React.useState("Private Sector");
  return (
    <AccountProfileCard
      title="Organisation"
      disableSave={!orgName && !orgType}
      content={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "50px",
            input: {
              width: "300px",
              height: "32px",
              fontSize: "12px",
              padding: "0 20px",
              borderRadius: "5px",
              color: colors.text.title,
              background: colors.secondary.lightGrey,
              border: `1px solid ${colors.secondary.iconGray}`,
              ":focus": {
                outline: "none",
              },
            },
            [customBreakpointsMax.mobile]: {
              input: {
                width: "100%",
              },
              flexDirection: "column",
              gap: "20px",
            },
          }}
        >
          <Box>
            <Typography
              variant="body2"
              lineHeight={"20px"}
              marginBottom={"10px"}
              component={"label"}
              htmlFor="org-name"
              display={"block"}
            >
              Organisation Name
            </Typography>

            <input
              type="text"
              placeholder="Jane Doe"
              name="orgName"
              id="org-name"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              variant="body2"
              lineHeight={"20px"}
              marginBottom={"10px"}
              component={"label"}
              htmlFor="org-type"
              display={"block"}
            >
              Organisation Type
            </Typography>

            <SelectField
              id="org-type"
              value={orgType}
              onChange={(e) => setOrgType(e.target.value)}
              options={[
                { label: "Government", value: "Government" },
                {
                  label: "Non-Governmental Organisation (NGO)",
                  value: "Non-Governmental Organisation (NGO)",
                },
                {
                  label: "Public Private Partnership",
                  value: "Public Private Partnership",
                },
                { label: "Multilateral", value: "Multilateral" },
                { label: "Foundation", value: "Foundation" },
                { label: "Private Sector", value: "Private Sector" },
                {
                  label: "Academic, Teaching and Training",
                  value: "Academic, Teaching and Training",
                },
                { label: "Other", value: "Other" },
              ]}
              width={{
                xs: "100%",
                md: "300px",
              }}
              variant="outlined"
              fontWeight="400"
            />
          </Box>
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
