import React from "react";

const PublisherSmallCtaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      {...props}
    >
      <path
        d="M24.9411 36.6815C11.8918 40.5243 3.87424 41.326 3.87424 41.326C3.87424 41.326 2.48677 41.7886 1.09915 40.401C-0.288467 39.0134 0.174121 37.6259 0.174121 37.6259C0.174121 37.6259 0.975868 29.6085 4.81861 16.5591C5.36517 14.703 6.87141 13.2944 8.77863 12.9682C11.3125 12.5349 15.312 11.9868 20.5001 11.7273L23.2999 10.8L30.7001 18.2003L29.7728 21C29.5135 26.1881 28.9653 30.1876 28.5319 32.7215C28.2057 34.6287 26.7971 36.135 24.9411 36.6815Z"
        fill="#00726E"
      />
      <path
        d="M20.5 11.7274C20.8372 7.680                                                                                                           4 22.1515 3.59255 22.8507 1.6681C23.1075 0.961336 23.7821 0.514279 24.5274 0.613951C26.6819 0.902064 31.2934 1.99001 35.4017 6.09828C39.51 10.2065 40.598 14.8182 40.8862 16.9727C40.9859 17.718 40.5387 18.3926 39.832 18.6495C37.9075 19.3486 33.8197 20.6628 29.7727 21.0001C29.7727 21.0001 28.8823 18.206 26.0882 15.412C23.2941 12.6178 20.5 11.7274 20.5 11.7274Z"
        fill="#00726E"
      />
      <path
        d="M0.437012 39.5181C2.71056 37.0072 7.81265 31.3837 12.1824 26.6479C12.1031 26.346 12.0609 26.0292 12.0609 25.7025C12.0609 23.654 13.7215 21.9934 15.7699 21.9934C17.8185 21.9934 19.479 23.654 19.479 25.7025C19.479 27.7509 17.8185 29.4116 15.7699 29.4116C15.4433 29.4116 15.1264 29.3693 14.8247 29.29C10.0831 33.665 4.45188 38.774 1.9454 41.0436C1.67147 40.8945 1.38539 40.687 1.09931 40.401C0.800391 40.1021 0.587332 39.8032 0.437012 39.5181Z"
        fill="#008F8A"
      />
    </svg>
  );
};

export default PublisherSmallCtaIcon;
