import React from "react";
import Box from "@mui/material/Box";
import { Hero } from "app/pages/home/explorer/components/hero";
import { IatiDataInNumbers } from "app/pages/home/explorer/components/iati-in-numbers";
import { LiveDataUpdates } from "app/pages/home/explorer/components/live-data-updates";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import get from "lodash/get";

export const ExplorerLanding: React.FC = () => {
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerHomeSDGs.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerHomeBudgetBySector.fetch
  );
  const fetchOrgNetwork = useStoreActions(
    (actions) => actions.ExplorerHomeOrganisationsNetwork.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerHomeSectors.fetch
  );
  const fetchOrgDonut = useStoreActions(
    (actions) => actions.ExplorerHomeOrganisationsDonut.fetch
  );
  const fetchActivities = useStoreActions(
    (actions) => actions.ExplorerHomeActivityTable.fetch
  );

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerHomeSDGs, "data.items", {
      count: 0,
      data: [],
    })
  );
  const budgetBySector = useStoreState((state) =>
    get(state.ExplorerHomeBudgetBySector, "data.data", {
      bars: [],
      series: [],
      values: [],
    })
  );
  const orgNetwork = useStoreState((state) =>
    get(state.ExplorerHomeOrganisationsNetwork, "data.data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const sectors = useStoreState((state) =>
    get(state.ExplorerHomeSectors, "data.data", [])
  );
  const orgDonut = useStoreState((state) =>
    get(state.ExplorerHomeOrganisationsDonut, "data.data", [])
  );
  const activities = useStoreState((state) =>
    get(state.ExplorerHomeActivityTable, "data.data", [])
  );

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();

  React.useEffect(() => {
    fetchSDGs({
      routeParams: {
        period: `${defaultYear}-01-01|${defaultYear}-12-31`,
        transactionTypeCode: "1",
      },
    });
    fetchBudgetBySector({
      routeParams: {
        years: `2020|${defaultYear}`,
      },
    });
    fetchOrgNetwork({
      routeParams: {
        period: `${defaultYear}-01-01|${defaultYear}-12-31`,
      },
    });
    fetchSectors({
      routeParams: {
        year: defaultYear,
        transactionTypeCode: "3",
      },
    });
    fetchOrgDonut({
      routeParams: {
        transactionTypeCode: "1",
        period: `${defaultYear}-01-01|${defaultYear}-12-31`,
      },
    });
    const date = new Date();
    date.setDate(date.getDate() - 2);
    fetchActivities({
      routeParams: { page: "1" },
      filterString: `lastUpdatedDate=${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`,
    });
  }, []);

  return (
    <React.Fragment>
      <Hero />
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <IatiDataInNumbers
        sdgsData={sdgsData}
        orgDonut={orgDonut}
        orgNetwork={orgNetwork}
        sectors={sectors.slice(0, 4)}
        budgetBySector={budgetBySector}
      />
      <Box
        height={{
          xs: 20,
          md: 30,
          lg: 50,
        }}
      />
      <LiveDataUpdates activities={activities} />
    </React.Fragment>
  );
};
