import { Box, Typography } from "@mui/material";
import { CheckIcon } from "app/assets/vectors/jsx/CheckIcon";
import { colors, customBreakpointsMax } from "app/theme";
import React from "react";

export const FeatureLine = ({
  option,
  index,
}: {
  option: { title: string; values: (boolean | string)[] };
  index: number;
}) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Box
      sx={{
        borderTop:
          index === 0 ? `0.5px solid ${colors.secondary.iconGray}` : "none",
        borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: hovered
          ? colors.secondary.disableButton
          : "transparent",
        [customBreakpointsMax.tablet]: {
          flexDirection: "column",
          borderTop: "none",
          alignItems: "flex-start",
          gap: "8px",
          padding: "10px 0",
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Typography
        variant="h5"
        fontSize={"14px"}
        lineHeight={"20px"}
        fontWeight={"400"}
        padding={{ xs: 0, lg: "5px 15px" }}
      >
        {option.title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          [customBreakpointsMax.tablet]: {
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            width: "100%",
          },
        }}
      >
        {option.values.map((value, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
              width: "245px",
              svg: {
                rect: {
                  fill: hovered ? colors.primary.blue : colors.text.title,
                },
              },
              [customBreakpointsMax.tablet]: {
                padding: "0",
                width: "100%",
              },
            }}
          >
            {value === true ? (
              <CheckIcon />
            ) : value === false ? (
              hovered ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <path
                    d="M3.45893 12.0414C3.57643 12.1589 3.73068 12.218 3.88494 12.218C4.03919 12.218 4.19345 12.1589 4.31095 12.0414L7.50029 8.85207L10.6896 12.0414C10.8071 12.1589 10.9614 12.218 11.1156 12.218C11.2699 12.218 11.4242 12.1589 11.5417 12.0414C11.7773 11.8058 11.7773 11.425 11.5417 11.1894L8.35231 8.00005L11.5417 4.8107C11.7773 4.5751 11.7773 4.19428 11.5417 3.95868C11.3061 3.72308 10.9252 3.72308 10.6896 3.95868L7.50029 7.14803L4.31095 3.95868C4.07535 3.72308 3.69453 3.72308 3.45893 3.95868C3.22333 4.19428 3.22333 4.5751 3.45893 4.8107L6.64827 8.00005L3.45893 11.1894C3.22333 11.425 3.22333 11.8058 3.45893 12.0414Z"
                    fill="black"
                  />
                </svg>
              ) : null
            ) : (
              <Typography
                variant="h5"
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"400"}
              >
                {value}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
